import React, { useState, useEffect } from "react"
import {
  FormControl,
  InputAdornment,
  FilledInput,
  InputLabel,
  Typography,
  Button,
} from "@mui/material"
import OutlinedInput from "@mui/material/OutlinedInput"
import { Slider } from "@material-ui/core"
import ReactSwitch from "react-switch"
import DownloadCSV from "../../global/DownloadCSV"
import "../../../styles/analysisTools/projectedcosts.css"
import "../../../styles/table.css"

const ProjectedCostsTable = (props) => {
  const [values, setValues] = React.useState({ amount: "" })
  const [instruction, setActiveInstruction] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState("")
  const [sliderValue, setSliderValue] = React.useState(5)
  const [sliderTwoValue, setSliderTwoValue] = React.useState(5)
  const [budget, setBudget] = React.useState(0)
  const [inputList, setInputList] = useState(["", "", "", "", ""])
  const [tableList, setTableList] = useState([])
  const [checked, setChecked] = useState(false)
  const reg = new RegExp("^[0-9]+$")

  useEffect(() => {}, [props.dataset, budget])

  const parseCSV = (tableData) => {
    return tableData.map((row) => row)
  }

  const handleCheckedChange = (val) => {
    setChecked(val)
  }

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue)
  }

  const handleSliderTwoChange = (event, secNewValue) => {
    setSliderTwoValue(secNewValue)
    let fields = []
    for (let i = 0; i < secNewValue; i++) {
      if (inputList[i]) {
        fields.push(inputList[i])
      } else {
        fields.push("")
      }
    }
    setInputList(fields)
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleInputChange = (e, index) => {
    const { value } = e.target
    const list = [...inputList]
    list[index] = value
    setInputList(list)
  }

  function renderTableHeaderRow(data, filename) {
    return props.headers.map((value, index) => {
      return (
        <th className='sticky top-0' key={index}>
          <div className='flex justify-between items-center '>
            {value}
          </div>
        </th>
      )
    })
  }

  function renderTableDataRows(data) {
    let nullData = [["No Available ECMs", "-", "-", "-", "-"]]
    let dontDisplayIndex = [0, 2, 6, 7]
    let stringIndices = [4, 5, 8]

    if (data && data.length > 0) {
      return data.map((row) => (
        <tr key={data.indexOf(row)} className='row'>
          {row.map((data, index) => {
            if (dontDisplayIndex.includes(index)) {
              return <></>
            } else if (data !== "NaN" && !stringIndices.includes(index)) {
              return (
                <td key={index} className='columnItem portfolio-table-data'>
                  {index == 1 ? (
                    <div className='table-headers'>
                      {props.headers[index - 1]}
                    </div>
                  ) : (
                    <div className='table-headers'>
                      {props.headers[index - 2]}
                    </div>
                  )}

                  <div className='mt-2'>{data}</div>
                </td>
              )
            } else if (index === 4 || index === 5) {
              return (
                <td className='columnItem portfolio-table-data'>
                  <div className='table-headers'>
                    {props.headers[index - 2]}
                  </div>
                  <div className='mt-2'>
                    {"$ " + parseFloat(data).toLocaleString("en-us")}
                  </div>
                </td>
              )
            } else if (data !== "NaN" && index === 8) {
              return (
                <td className='columnItem portfolio-table-data'>
                  <div className='table-headers'>
                    {props.headers[index - 4]}
                  </div>
                  <div className='mt-2'>
                    {parseFloat(data).toLocaleString("en-us")}
                  </div>
                </td>
              )
            }
          })}
        </tr>
      ))
    } else {
      return nullData.map((row) => (
        <tr key={nullData.indexOf(row)} className='row'>
          {row.map((data, index) => {
            return (
              <td key={index} className='columnItem portfolio-table-data'>
                <div className='table-headers'>{props.headers[index]}</div>
                <div className='mt-2'>{data}</div>
              </td>
            )
          })}
        </tr>
      ))
    }
  }

  function renderManualEntryRows() {
    return (
      <div id='budget-for-year'>
        {inputList.map((x, i) => {
          return (
            <div>
              <p className='col-span-2 mb-2 mt-2'>
                Budget for {new Date().getFullYear() + i}:{" "}
              </p>
              <div className='col-span-1'>
                <FormControl variant='filled' sx={{ width: "100%" }}>
                  <OutlinedInput
                    id='filled-adornment-amount'
                    name='amount'
                    value={x}
                    onChange={(e) => handleInputChange(e, i)}
                    autoComplete='off'
                    sx={{
                      borderRadius: "4px",
                      height: "40px",
                      width: "100%",
                    }}
                  />
                </FormControl>
              </div>
            </div>
          )
        })}
        {instruction === "" ? (
          <></>
        ) : (
          <div className='text-red-600 text-base'>{instruction}</div>
        )}
      </div>
    )
  }

  function clearFilters() {
    setTableList([])
    setInputList(["", "", "", "", ""])
    setSliderValue(5)
    setSliderTwoValue(5)
    setValues({ amount: "" })
    setActiveInstruction("")
    setErrorMessage("")
    setBudget(0)
  }

  function applyManualFilter() {
    let errorCount = 0
    let budgetList = []
    for (let i = 0; i < inputList.length; i++) {
      if (
        parseInt(inputList[i]) === "NaN" ||
        !parseInt(inputList[i]) ||
        parseInt(inputList[i]) <= 0 ||
        !reg.test(inputList[i])
      ) {
        errorCount += 1
      } else {
        budgetList.push(parseInt(inputList[i]))
      }
    }
    if (errorCount > 0) {
      setActiveInstruction("Please check your input budgets.")
    } else {
      setActiveInstruction("")
      let data = props.dataset
      let newData = [...data].sort((a, b) =>
        b[8].toString().localeCompare(a[8].toString(), "en", {
          numeric: true,
          sensitivity: "base",
        })
      )
      let tablesContainer = []
      let indexes = []
      let carriedBudget = 0
      if (budgetList) {
        for (let i = 0; i < budgetList.length; i++) {
          let yearList = []
          let currentBudget = budgetList[i] + carriedBudget
          let currentBudgetCopy = currentBudget
          for (let j = 0; j < newData.length; j++) {
            if (
              parseFloat(newData[j][4]) <= currentBudget &&
              currentBudget - newData[j][4] >= 0 &&
              !indexes.includes(j)
            ) {
              yearList.push(newData[j])
              currentBudget -= parseFloat(newData[j][4])
              indexes.push(j)
            }
          }
          carriedBudget = currentBudget
          tablesContainer.push(
            <div className='mb-5 custom-frame'>
              <table id='portfolio' className={"text-sm w-full overflow-auto"}>
                <thead>
                  <tr>
                    <th colSpan={"100%"} className='table-title'>
                      <div className='flex justify-between items-center'>
                        <p className=''>
                          ECMs for {new Date().getFullYear() + i}, with a budget
                          of:{" "}
                          {"$ " +
                            parseFloat(currentBudgetCopy).toLocaleString(
                              "en-us"
                            )}
                        </p>
                        <DownloadCSV
                          headers={props.downloadCSVHeaders}
                          data={parseCSV(yearList)}
                          filename={`ECMs for ${new Date().getFullYear() + i}`}
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <thead id='thread-row-status'>
                  <tr>{renderTableHeaderRow()}</tr>
                </thead>
                <tbody>{renderTableDataRows(yearList)}</tbody>
              </table>
            </div>
          )
        }
        setTableList(tablesContainer)
      }
    }
  }

  function applyFilter() {
    setBudget(values.amount)
    if (
      parseInt(values.amount) === "NaN" ||
      !parseInt(values.amount) ||
      parseInt(values.amount) <= 0 ||
      !reg.test(values.amount)
    ) {
      setErrorMessage("Please Enter a Valid Number")
      setTableList([])
    } else {
      setErrorMessage("")
      let data = props.dataset
      let newData = [...data].sort((a, b) =>
        b[8].toString().localeCompare(a[8].toString(), "en", {
          numeric: true,
          sensitivity: "base",
        })
      )
      let tablesContainer = []
      let indexes = []
      let carriedBudget = 0
      if (values.amount) {
        let totalBudget = values.amount
        for (let i = 0; i < sliderValue; i++) {
          let yearList = []
          let currentBudget = parseFloat(totalBudget) + carriedBudget
          let currentBudgetCopy = currentBudget
          for (let j = 0; j < newData.length; j++) {
            if (
              parseFloat(newData[j][4]) <= currentBudget &&
              currentBudget - newData[j][4] >= 0 &&
              !indexes.includes(j)
            ) {
              yearList.push(newData[j])
              currentBudget -= parseFloat(newData[j][4])
              indexes.push(j)
            }
          }
          carriedBudget = currentBudget
          tablesContainer.push(
            <div className='mb-5 custom-frame'>
              <div colSpan={"100%"} className='table-title'>
                <div className='flex justify-between items-center'>
                  <p className='section_heading_3 p-4'>
                    ECMs for {new Date().getFullYear() + i}, with a budget of:{" "}
                    {"$ " +
                      parseFloat(currentBudgetCopy).toLocaleString("en-us")}
                  </p>
                  <div className='p-2'>
                    <DownloadCSV
                      headers={props.downloadCSVHeaders}
                      data={parseCSV(yearList)}
                      filename={`ECMs for ${new Date().getFullYear() + i}`}
                    />
                  </div>
                </div>
              </div>
              <table id='portfolio' className={"text-sm w-full overflow-auto"}>
                <thead id='thread-row-status'>
                  <tr>{renderTableHeaderRow()}</tr>
                </thead>
                <tbody>{renderTableDataRows(yearList)}</tbody>
              </table>
            </div>
          )
        }
        setTableList(tablesContainer)
      }
    }
  }

  return (
    <>
      <div className='flex'>
        {!checked ? (
          <div className='porfolio-planner-container'>
            <div className='switch-button'>
              <ReactSwitch checked={checked} onChange={handleCheckedChange} />
              <p>Variable Annual Budget</p>
            </div>
            <div className='budget-filter-container'>
              <div>
                <h3 className=' font-semibold mb-3'>
                  Number of Forecasted Years and Budgets:{" "}
                </h3>
                <div className='year-slider'>
                  <Slider
                    value={sliderValue}
                    min={1}
                    max={10}
                    step={1}
                    onChange={handleSliderChange}
                    marks
                    aria-label='Default'
                    valueLabelDisplay='auto'
                  />
                  <p className=''>{sliderValue} years</p>
                </div>
              </div>
              <div>
                <h3 className=' font-semibold mb-3'>
                  Enter Annual Budget ($):{" "}
                </h3>
                <FormControl variant='filled' sx={{ width: "100%" }}>
                  <OutlinedInput
                    id='filled-adornment-amount'
                    value={values.amount}
                    onChange={handleChange("amount")}
                    autoComplete='off'
                    error={errorMessage.length === 0 ? false : true}
                    helperText={errorMessage}
                    sx={{
                      borderRadius: "4px",
                      height: "40px",
                      width: "100%",
                    }}
                  />
                  {errorMessage === "" ? (
                    <></>
                  ) : (
                    <>
                      <div className='mt-1' style={{ color: "red" }}>
                        {errorMessage}
                      </div>
                    </>
                  )}
                </FormControl>
              </div>
              <div className=' budget-filter-buttons-container'>
                <Button
                  id='reset-filters-button'
                  variant='contained'
                  onClick={() => clearFilters()}
                >
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 600,
                      fontSize: "1rem",
                      fontFamily: "Mukta",
                    }}
                  >
                    Reset
                  </Typography>
                </Button>
                <Button
                  id='apply-filters-button'
                  variant='contained'
                  onClick={applyFilter}
                >
                  <Typography
                    style={{
                      textTransform: "none",
                      fontWeight: 600,
                      fontSize: "1rem",
                      fontFamily: "Mukta",
                    }}
                  >
                    Apply Filters
                  </Typography>
                </Button>

                {/* </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className='porfolio-planner-container'>
            <div className='switch-button'>
              <ReactSwitch checked={checked} onChange={handleCheckedChange} />
              <p>Variable Annual Budget</p>
            </div>
            <div className='variable-annual-budget-on'>
              <div>
                <h3 className='font-semibold mb-3'>
                  Number of Forecasted Years and Budgets:{" "}
                </h3>
                <div className=' year-slider'>
                  <Slider
                    value={sliderTwoValue}
                    min={1}
                    max={10}
                    step={1}
                    onChange={handleSliderTwoChange}
                    marks
                    aria-label='Default'
                    valueLabelDisplay='auto'
                  />
                  <p>{sliderTwoValue} years</p>
                </div>
              </div>
              <div className='budget-filter-buttons-container'>
                <Button
                  id='reset-filters-button'
                  variant='contained'
                  onClick={() => clearFilters()}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Reset
                  </Typography>
                </Button>
                <Button
                  id='apply-filters-button'
                  variant='contained'
                  onClick={applyManualFilter}
                >
                  <Typography style={{ textTransform: "none" }}>
                    Apply Filters
                  </Typography>
                </Button>
              </div>
            </div>
            {renderManualEntryRows()}
          </div>
        )}
      </div>
      <div className='mt-5'>{tableList}</div>
    </>
  )
}

export default ProjectedCostsTable
