import React, { useState, useEffect } from "react"
import Panel from "./Panel"
import VerticalTableMultipleColumns from "./VerticalTableMultipleColumns"
import PropTypes from "prop-types"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import "../../styles/verticalTable.css"

function ExpandedTablePanel(props) {
  const [tableValues, setTableValues] = useState([])
  const [panelData, setPanelData] = useState([{}])

  useEffect(() => {
    setTableValues(props.data)
    setPanelData(props.panels)
  }, [props.data])

  const defaultColors = {
    0: "bg-cyan-400/40",
    1: "bg-green-400/40",
    2: "bg-zinc-500/40",
  }

  // Get the keys of panelData as an array
  const panelDataKeys = Object.keys(panelData)

  // Generate the colours object based on panelData length
  const colours = {}

  if (panelDataKeys.length > 3) {
    colours[0] = "bg-[#F3C3A9]" // Set a different color for the first panel
    for (let i = 1; i <= panelDataKeys.length; i++) {
      colours[i] = defaultColors[(i - 1) % Object.keys(defaultColors).length]
    }
  } else {
    for (let i = 0; i < panelDataKeys.length; i++) {
      colours[i] = defaultColors[i]
    }
  }

  return (
    <div className='p-5'>
      <div
        id='expanded-tablepanel-container'
        className={`grid gap-x-4 gap-y-2 grid-cols-${props.numColumns} mb-4`}
      >
        {Object.keys(panelData).map((key, index) => (
          <Panel
            key={key}
            header={key}
            data={panelData[key]}
            colour={colours[index % props.numColumns]}
          />
        ))}
      </div>
      <div className='custom-frame'>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span className='table_heading'>More details</span>
          </AccordionSummary>
          <AccordionDetails>
            <VerticalTableMultipleColumns
              data={tableValues}
              tableHeaders={props.tableHeaders}
              categories={props.categories}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

ExpandedTablePanel.propTypes = {
  data: PropTypes.array.isRequired,
  tableHeaders: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  panels: PropTypes.array.isRequired,
  numColumns: PropTypes.number.isRequired,
}

export default ExpandedTablePanel
