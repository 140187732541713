export const BasicInfoLabels = {
  name_label: "Site Name",
  street_address_label: "Site Address",
  city_municipality_label: "City",
  state_province_label: "Province",
  county_label: "County/District",
  postal_code_label: "Postal Code",
  owner_organization_label: "Owner/Organization",
  office_address_label: "Office Address",
  applicant_name_label: "Applicant Name",
  telephone_label: "Telephone",
  email_label: "Email",
  program_job_no_label: "Program Job #",
  site_contact_name_label: "Site Contact Name",
  site_auditors_label: "Site Auditors",
  program_label: "Program",
  responsible_project_engineer_label: "Responsible Engineer",
  email_of_responsible_engineer_label: "Responsible Engineer Email",
  site_visit_date_label: "Site Visit Date",
}
