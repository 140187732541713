import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import DownloadCSV from "./DownloadCSV"
import "../../styles/table.css"
import "../../styles/index.css"

/**
 * Takes in an API endpoint on the backend as a string as a prop
 * ALso takes an array of headers as a prop
 */
function Table(props) {
  const [tableData, setTableData] = useState([])
  const [order, setOrder] = useState("0DSC")

  useEffect(() => {
    let vals = props.data
    for (let i = 0; i < vals.length; i++) {
      if (Array.isArray(vals[i])) {
        for (let j = 0; j < vals[i].length; j++) {
          if (
            vals[i][j] == "NaN" ||
            vals[i][j] == null ||
            vals[i][j] == undefined
          ) {
            vals[i][j] = "-"
          }
        }
      }
    }
    setTableData(props.data)
  }, [props.data])

  const parseCSV = (tableData) => {
    return tableData.map((row) => row)
  }

  //generate the table data to columns
  const generateTableData = () => {
    return tableData.map((building, index) => (
      <tr
        key={"building-" + index}
        className={props.fixFirstColumn ? "row row-upgrade" : ""}
      >
        {building.map((data, index) => {
          if (props.skipFirstCol === false) {
            if (!isNaN(data)) {
              if (props.addLocale === true) {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle portfolio-table-data'
                  >
                    <div className='table-headers'>{props.headers[index]}</div>
                    <div>{parseFloat(data).toLocaleString("en-us")}</div>
                  </td>
                )
              } else {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle portfolio-table-data'
                  >
                    <div className='table-headers'>{props.headers[index]}</div>
                    <div>{data}</div>
                  </td>
                )
              }
            } else {
              return (
                <td
                  key={index}
                  className='columnItem align-middle portfolio-table-data'
                >
                  <div className='table-headers'>{props.headers[index]}</div>
                  <div>{data}</div>
                </td>
              )
            }
          } else {
            if (index !== 0) {
              if (!isNaN(data)) {
                if (props.addLocale === true) {
                  return (
                    <td
                      key={index}
                      className='columnItem align-middle portfolio-table-data'
                    >
                      <div className='table-headers'>
                        {props.headers[index - 1]}
                      </div>
                      <div>{parseFloat(data).toLocaleString("en-US")}</div>
                    </td>
                  )
                } else {
                  return (
                    <td
                      key={index}
                      className='columnItem align-middle portfolio-table-data'
                    >
                      <div className='table-headers'>
                        {props.headers[index]}
                      </div>
                      <div>{data}</div>
                    </td>
                  )
                }
              } else {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle portfolio-table-data'
                  >
                    <div className='table-headers'>
                      {props.headers[index - 1]}
                    </div>
                    <div>{data}</div>
                  </td>
                )
              }
            }
          }
        })}

        {props.isEditable ? (
          <td className='text-center'>
            {React.createElement(props.deleteButton, {
              deleted_id: building[0],
              deleted_item_name: building[1],
              project_id: props.project_id,
              onDeleted: props.onDeleted,
            })}
          </td>
        ) : null}
      </tr>
    ))
  }

  //sort by columns function, takes column index and state to determine which column to sort
  const sorting = (col) => {
    if (!props.skipFirstCol) {
      if (order.substring(1) === "ASC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(a[col]).localeCompare(
              JSON.stringify(b[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return a[col] - b[col]
          }
        })
        setTableData(sorted)
        setOrder(col + "DSC")
      }
      if (order.substring(1) === "DSC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(b[col]).localeCompare(
              JSON.stringify(a[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return b[col] - a[col]
          }
        })
        setTableData(sorted)
        setOrder(col + "ASC")
      }
    } else {
      col = col + 1
      if (order.substring(1) === "ASC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(a[col]).localeCompare(
              JSON.stringify(b[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return a[col] - b[col]
          }
        })
        setTableData(sorted)
        setOrder(col - 1 + "DSC")
      }

      if (order.substring(1) === "DSC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(b[col]).localeCompare(
              JSON.stringify(a[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return b[col] - a[col]
          }
        })
        setTableData(sorted)
        setOrder(col - 1 + "ASC")
      }
    }
  }
  return (
    <div>
      {/* header */}
      {/* <div className=' h-full md:overflow-y-scroll'> */}
      <div className='table-title'>
        <div className='flex justify-between items-center'>
          <p className='table-title-text'>{props.title}</p>

          <div className='btn-container flex gap-1.5 items-center'>
            {props.isEditable
              ? React.createElement(props.addButton, {
                  project_id: props.project_id,
                  onAdded: props.onAdded,
                })
              : null}

            <DownloadCSV
              headers={
                props.id_col
                  ? ["identifier"].concat(props.headers)
                  : props.headers
              }
              data={parseCSV(tableData)}
              filename={props.filename}
              title={props.title}
            />
          </div>
        </div>
      </div>

      {/* table */}
      {/* the code on #249 will make a scroll bar when height is set; however, white background will accomodate based on the height of a table. This affects Envelope, Euipment, Lights, Plumbing, and 3 tables in the portfolio */}
      {/* <div className='overflow-y-scroll h-full table-scroll'> */}
      <div className='overflow-y-auto max-h-[500px] custom-frame'>
        <table id='portfolio' className='text-md w-full table'>
          <link
            href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined'
            rel='stylesheet'
          />
          {/* <thead>
            <tr>
              <th colSpan={"100%"} className='table-title'>
                <div className='flex justify-between items-center'>
                  <p className=''>{props.title}</p>
                  <div className='flex gap-3'>
                    {props.isEditable
                      ? React.createElement(props.addButton, {
                          project_id: props.project_id,
                          onAdded: props.onAdded,
                        })
                      : null}
                    <DownloadCSV
                      headers={
                        props.id_col
                          ? ["identifier"].concat(props.headers)
                          : props.headers
                      }
                      data={parseCSV(tableData)}
                      filename={props.filename}
                      title={props.title}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </thead> */}
          <thead id='thread-row-status'>
            <tr className='sticky top-0'>
              {props.headers.map((value, index) => {
                const isEquipmentType =
                  value === "Equipment Type" || value === "Site Name"
                const stickyClass =
                  isEquipmentType && props.fixFirstColumn ? "sticky-header" : ""

                if (order === index + "ASC") {
                  return (
                    <th
                      key={index}
                      className={`sticky top-0 z-2 ${stickyClass}`}
                    >
                      <div className='flex justify-between items-center'>
                        {value}
                        <span
                          className='material-symbols-outlined'
                          onClick={() => sorting(index)}
                        >
                          expand_more
                        </span>
                      </div>
                    </th>
                  )
                }
                return (
                  <th key={index} className={`sticky top-0 z-2 ${stickyClass}`}>
                    <div className='flex justify-between items-center '>
                      {value}
                      <span
                        className='material-symbols-outlined'
                        onClick={() => sorting(index)}
                      >
                        expand_less
                      </span>
                    </div>
                  </th>
                )
              })}
              {props.isEditable ? (
                <th className='sticky top-0 z-10'>Action</th>
              ) : null}
            </tr>
          </thead>
          <tbody className='mt-12'>{generateTableData()}</tbody>
        </table>
      </div>
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
}

export default Table
