import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { BrowserRouter } from "react-router-dom"

// Microsoft Authentication Library for React
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import { msalConfig, msalConfigPowerBI } from "./authConfig.js"

// Global MSAL instance
const msalInstance = new PublicClientApplication(msalConfig)
export const msalInstancePowerBI = new PublicClientApplication(
  msalConfigPowerBI
)

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account)
  }
})

await msalInstance.initialize()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstancePowerBI}>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)
