import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import "../../../../styles/importProperty/StatusMessage.css"

function StatusMessage({ completed, msg_top, msg_bot, fn_close }) {
  const [topMessage, setTopMessage] = useState("")
  const [bottomMessage, setBottomMessage] = useState("")

  const container_ref = useRef(null)
  const icon_ref = useRef(null)

  const top_message_ref = useRef(null)
  const bottom_message_ref = useRef(null)

  const navigate = useNavigate()

  function handleClose() {
    fn_close({ msg_top: "", msg_bot: "", status: null })
  }

  function handleRedirect() {
    navigate("/")
  }

  useEffect(() => {
    if (completed.includes("Accepted")) {
      container_ref.current.style.backgroundColor = "lightgreen"
      container_ref.current.style.borderColor = "green"
      container_ref.current.onclick = handleRedirect
      top_message_ref.current.style.color = "darkgreen"
      bottom_message_ref.current.style.color = "darkgreen"
      icon_ref.current.style.color = "green"
      icon_ref.current.innerText = "check_circle"
    } else if (completed.includes("Rejected")) {
      container_ref.current.style.backgroundColor = "salmon"
      container_ref.current.style.borderColor = "red"
      container_ref.current.onclick = handleClose
      top_message_ref.current.style.color = "red"
      bottom_message_ref.current.style.color = "red"
      icon_ref.current.style.color = "red"
      icon_ref.current.innerText = "error"
    } else {
      container_ref.current.style.backgroundColor = "gold"
      container_ref.current.style.borderColor = "darkorange"
      container_ref.current.onclick = handleRedirect
      top_message_ref.current.style.color = "hsl(33, 100%, 40%)"
      bottom_message_ref.current.style.color = "hsl(33, 100%, 40%)"
      icon_ref.current.style.color = "darkorange"
      icon_ref.current.innerText = "warning"
    }

    if (msg_top.length > 0) {
      setTopMessage(msg_top)
    }
    if (msg_bot.length > 0) {
      setBottomMessage(msg_bot)
    }
  }, [completed, msg_top, msg_bot])

  return (
    <div ref={container_ref} className='component-container'>
      <div className='icon-container'>
        <span ref={icon_ref} className='material-symbols-outlined'>
          error
        </span>
      </div>
      <div className='message-container'>
        <p ref={top_message_ref} className='status-message'>
          {topMessage}
        </p>
        <p ref={bottom_message_ref} className='status-message'>
          {bottomMessage}
        </p>
      </div>
    </div>
  )
}

export default StatusMessage
