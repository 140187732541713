import { useState, useEffect, useRef } from "react"
import Select from "react-select"
import ECMTable from "./components/ECMTable"
import {
  parseFloatAndFormat,
  parseIntAndFormat,
} from "../../../utils/DataFormatter.js"
import "../../../styles/analysisTools/multi-filter.css"

function MultiFilter(props) {
  // [ "site_name_1", "site_name_2", ... ]
  const [siteNameList, setSiteNameList] = useState([])
  const [savedPlansList, setSavedPlansList] = useState([])

  // default slider position / update value on slider change
  const [ROIValue, setROIValue] = useState(0)

  // table data to be passed into ECM Table
  const [TableData, setTableData] = useState([])

  // used to filter out ECMs that are selected
  // { <ecm_identifier>: <boolean> }
  const [selectedECMs, setSelectedECMs] = useState({})

  // state for plan value
  const [selectedPlanOption, setSelectedPlanOption] = useState([])

  // state for site name value
  const [selectedSiteNameOption, setSelectedSiteNameOption] = useState([])

  // list of account options
  const [accountOptionsList, setAccountOptionsList] = useState([])

  // selected account option
  const [selectedAccountOption, setSelectedAccountOption] = useState([])

  const form_ref = useRef(null)
  const select_ref = useRef(null)
  const saved_plan_ref = useRef(null)
  const account_ref = useRef(null)

  useEffect(() => {
    let selected_ecms_copy = { ...selectedECMs }
    props.planned_ecms.forEach((ecm) => {
      selected_ecms_copy[parseInt(ecm.ecm_identifier)] = true
    })

    let account_number = selectedAccountOption.value
    if (account_number) {
      let account_ecm_table_data = props.all_ecm_table_data[account_number]

      //delete the ecms and it's associated Ecm group in the selected_ecms_copy if it is not in the planned_ecms array.
      for (let ecm_id in selected_ecms_copy) {
        if (selected_ecms_copy[ecm_id]) {
          const existsInPlannedEcms = props.planned_ecms.some(
            (ecm) => parseInt(ecm.ecm_identifier) === parseInt(ecm_id)
          )
          if (!existsInPlannedEcms) {
            //delete the object in selected_ecms_copy if it is not in the planned_ecms array and delete the associated ECM group number object if it exists.
            delete selected_ecms_copy[ecm_id]

            //delete the object in the selected_ecms_copy if it has the same ECM group number as the object in the planned_ecms array.
            const project_number = account_ecm_table_data.filter(
              (ecm) => parseInt(ecm[0]) === parseInt(ecm_id)
            )[0][20]
            const data = account_ecm_table_data.filter(
              (ecm) => parseInt(ecm[20]) === parseInt(project_number)
            )
            let ecm_group_number = data.filter(
              (ecm) => parseInt(ecm[0]) === parseInt(ecm_id)
            )[0][16]

            if (ecm_group_number) {
              data.forEach((ecm) => {
                if (
                  parseInt(ecm[16]) === ecm_group_number &&
                  parseInt(ecm[0]) !== parseInt(ecm_id)
                ) {
                  delete selected_ecms_copy[parseInt(ecm[0])]
                }
              })
            }
          }
        }
      }

      //Set the value to false for the ecm has the same ECM group number in the project that are not identified in selected_ecms_copy .
      for (let ecm_id in selected_ecms_copy) {
        if (selected_ecms_copy[ecm_id]) {
          const project_number = account_ecm_table_data.filter(
            (ecm) => parseInt(ecm[0]) === parseInt(ecm_id)
          )[0][20]
          const data = account_ecm_table_data.filter(
            (ecm) => parseInt(ecm[20]) === parseInt(project_number)
          )
          let ecm_group_number = data.filter(
            (ecm) => parseInt(ecm[0]) === parseInt(ecm_id)
          )[0][16]

          if (ecm_group_number) {
            data.forEach((ecm) => {
              if (
                parseInt(ecm[16]) === ecm_group_number &&
                parseInt(ecm[0]) !== parseInt(ecm_id)
              ) {
                selected_ecms_copy[parseInt(ecm[0])] = false
              }
            })
          }
        }
      }

      // setSelectedAccountNumber(() => {
      //   const account_number = props.data.user_email_and_account_number.find(
      //     (account) => account.email === selectedAccountEmail
      //   ).account_number
      //   console.log("account_number", account_number)
      //   props.set_selected_account_number(account_number)
      //   return account_number
      // })
    }

    setSelectedECMs(selected_ecms_copy)
  }, [props.planned_ecms])

  // all_account_options list
  useEffect(() => {
    if (props.all_account_options.length > 0) {
      setAccountOptionsList(props.all_account_options)
    }
  }, [props.all_account_options])

  // when account is selected, set site name list and plans options list
  useEffect(() => {
    if (selectedAccountOption.value > 0) {
      let account_number = selectedAccountOption.value
      // get list of site names
      let account_ecm_table_data = props.all_ecm_table_data[account_number]
      let site_names = []
      let site_name_options = []

      if (account_ecm_table_data.length > 0) {
        account_ecm_table_data.forEach((ecm) => {
          if (!site_names.includes(ecm[1])) {
            site_names.push(ecm[1])
            site_name_options.push({
              value: ecm[20],
              label: ecm[1],
            })
          }
        })
        setSiteNameList(site_name_options)
      }

      // get list of all decarb plans for the account
      let saved_plan_options = getAllPlanOptions([], site_name_options)
      if (saved_plan_options.length > 0) {
        setSavedPlansList(saved_plan_options)
      }
    } else {
      setSelectedPlanOption([])
      setSelectedSiteNameOption([])
      setSavedPlansList([])
      setSiteNameList([])
    }
  }, [selectedAccountOption])

  // for populating site name list and plan list
  useEffect(() => {
    if (selectedSiteNameOption.length > 0) {
      let project_numbers = selectedSiteNameOption.map((option) => {
        return option.value
      })
      let filtered_plans = filterPlans(project_numbers)
      setSavedPlansList(filtered_plans)
    }
  }, [selectedSiteNameOption])

  useEffect(() => {
    let project_numbers = []
    let filtered_plans = []
    if (selectedSiteNameOption.length > 0) {
      selectedSiteNameOption.forEach((option) => {
        project_numbers.push(option.value)
      })
    }
    filtered_plans = filterPlans(project_numbers)
    setSavedPlansList(filtered_plans)
  }, [selectedPlanOption, props.all_decarb_plans])

  useEffect(() => {
    if (props.is_plan_deleted) {
      saved_plan_ref.current.clearValue()
      props.set_selected_plan_option([])
      setSelectedPlanOption([])
      props.set_is_plan_deleted(false)
    }
  }, [props.is_plan_deleted])

  useEffect(() => {
    if (props.selected_plan_option.value) {
      let filtered_plans = filterPlans(props.selected_project_numbers)
      setSavedPlansList(filtered_plans)
      setSelectedPlanOption(props.selected_plan_option)
    } else {
      let filtered_plans = filterPlans([])
      setSavedPlansList(filtered_plans)
      setSelectedPlanOption([])
    }
  }, [props.selected_plan_option])

  function getFormData() {
    const formData = new FormData(form_ref.current)
    const data = Object.fromEntries(formData)
    return data
  }

  // account_number required
  function getAllPlanOptions(project_numbers, site_name_list) {
    const account_number = selectedAccountOption.value
    let saved_plan_options = []

    // account number is required
    if (account_number) {
      let account_decarb_plans = props.all_decarb_plans[account_number]

      // account has saved decarb plans
      if (account_decarb_plans.length > 0) {
        // filter plans by project numbers
        if (project_numbers.length > 0) {
          let added_plan_ids = []
          let filtered_plans_by_project_number = []

          project_numbers.forEach((project_number) => {
            account_decarb_plans.forEach((plan) => {
              if (
                plan.project_numbers.includes(project_number) &&
                !added_plan_ids.includes(plan.plan_id)
              ) {
                added_plan_ids.push(plan.plan_id)
                filtered_plans_by_project_number.push(plan)
              }
            })
          })

          filtered_plans_by_project_number.forEach((plan) => {
            let site_names = ""
            site_name_list.forEach((option) => {
              if (plan.project_numbers.includes(option.value)) {
                site_names += option.label + ", "
              }
            })
            saved_plan_options.push({
              value: plan.plan_id,
              label: site_names.slice(0, -2) + " - " + plan.plan_name,
            })
          })
        } else {
          // no project numbers selected
          account_decarb_plans.forEach((plan) => {
            let site_names = ""
            site_name_list.forEach((option) => {
              if (plan.project_numbers.includes(option.value)) {
                site_names += option.label + ", "
              }
            })
            saved_plan_options.push({
              value: plan.plan_id,
              label: site_names.slice(0, -2) + " - " + plan.plan_name,
            })
          })
        }
      }
    }

    return saved_plan_options
  }

  // filter plans from the saved plans list
  function filterPlans(project_numbers) {
    let all_plan_options = getAllPlanOptions(project_numbers, siteNameList)
    return all_plan_options
  }

  function getSelectedPlanECMTable(plan_site_name_array) {
    let account_number = selectedAccountOption.value

    if (account_number) {
      let account_ecm_table_data = props.all_ecm_table_data[account_number]

      let searchResults = []
      account_ecm_table_data.forEach((ecm) => {
        plan_site_name_array.forEach((selection) => {
          if (ecm[20] === selection.value) {
            searchResults.push({
              ecm_identifier: parseInt(ecm[0]),
              site_name: ecm[1],
              ecm_description: ecm[3],
              ecm_gas_gj_savings: parseIntAndFormat(ecm[10]),
              ecm_electric_kwh_savings: parseIntAndFormat(ecm[11]),
              ecm_electric_kw_savings: parseIntAndFormat(ecm[12]),
              ecm_water_m3_savings: parseIntAndFormat(ecm[13]),
              ecm_capital_cost: parseIntAndFormat(ecm[4]),
              ecm_roi: parseIntAndFormat(ecm[9]),
              ecm_npv: parseIntAndFormat(ecm[8]),
              ecm_payback: parseIntAndFormat(ecm[7]),
              ecm_incentive: parseIntAndFormat(ecm[14]),
              ecm_savings: parseIntAndFormat(ecm[15]),
              ecm_group_number: parseInt(ecm[16]) ? parseInt(ecm[16]) : null,
              ecm_hierarchy: parseInt(ecm[17]) ? parseInt(ecm[17]) : null,
              year_of_implementation: parseInt(ecm[18])
                ? parseInt(ecm[18])
                : null,
              annual_onsite_ghg_savings: parseFloatAndFormat(ecm[19]),
              project_number: parseInt(ecm[20]),
            })
          }
        })
      })
      setTableData(searchResults)
    }
  }

  function filterData(e) {
    props.set_sofiac_qualification({})
    props.set_cmhc_level("")
    props.reset_ecm_years()
    props.set_decarb_plan_ecms([])
    props.set_selected_plan_option([])
    setSelectedECMs({})
    setSelectedPlanOption([])
    e.preventDefault()

    let {
      ecm_description,
      ecm_capital_cost,
      ecm_roi,
      ecm_npv,
      ecm_payback,
      ecm_incentive,
      ecm_savings,
    } = getFormData()

    let searchResults = []

    // Multi select get array of values
    let selected_array = select_ref.current.getValue()

    let account_number = selectedAccountOption.value
    if (account_number) {
      let account_ecm_table_data = props.all_ecm_table_data[account_number]

      account_ecm_table_data.forEach((ecm) => {
        selected_array.forEach((selection) => {
          if (
            ecm[20] === selection.value &&
            ecm[3].toLowerCase().includes(ecm_description) &&
            ecm[4] <=
              (parseInt(ecm_capital_cost)
                ? parseInt(ecm_capital_cost)
                : Number.MAX_SAFE_INTEGER) &&
            ecm[9] >=
              (parseInt(ecm_roi)
                ? parseInt(ecm_roi)
                : Number.MIN_SAFE_INTEGER) &&
            ecm[8] >=
              (parseInt(ecm_npv)
                ? parseInt(ecm_npv)
                : Number.MIN_SAFE_INTEGER) &&
            ecm[7] <=
              (parseInt(ecm_payback)
                ? parseInt(ecm_payback)
                : Number.MAX_SAFE_INTEGER) &&
            ecm[14] >=
              (parseInt(ecm_incentive)
                ? parseInt(ecm_incentive)
                : Number.MIN_SAFE_INTEGER) &&
            ecm[15] >=
              (parseInt(ecm_savings)
                ? parseInt(ecm_savings)
                : Number.MIN_SAFE_INTEGER)
          ) {
            searchResults.push({
              ecm_identifier: parseInt(ecm[0]),
              site_name: ecm[1],
              ecm_description: ecm[3],
              ecm_gas_gj_savings: parseIntAndFormat(ecm[10]),
              ecm_electric_kwh_savings: parseIntAndFormat(ecm[11]),
              ecm_electric_kw_savings: parseIntAndFormat(ecm[12]),
              ecm_water_m3_savings: parseIntAndFormat(ecm[13]),
              ecm_capital_cost: parseIntAndFormat(ecm[4]),
              ecm_roi: parseIntAndFormat(ecm[9]),
              ecm_npv: parseIntAndFormat(ecm[8]),
              ecm_payback: parseIntAndFormat(ecm[7]),
              ecm_incentive: parseIntAndFormat(ecm[14]),
              ecm_savings: parseIntAndFormat(ecm[15]),
              ecm_group_number: parseInt(ecm[16]) ? parseInt(ecm[16]) : null,
              ecm_hierarchy: parseInt(ecm[17]) ? parseInt(ecm[17]) : null,
              year_of_implementation: parseInt(ecm[18])
                ? parseInt(ecm[18])
                : null,
              annual_onsite_ghg_savings: parseFloatAndFormat(ecm[19]),
              project_number: parseInt(ecm[20]),
            })
          }
        })
      })
    }

    setTableData(searchResults)
  }

  const header_names = [
    { column: "select", header: "" },
    { column: "site_name", header: "Site Name" },
    { column: "ecm_description", header: "Description" },
    {
      column: "ecm_gas_gj_savings",
      header: "Annual Gas Energy Saving (GJ/yr)",
    },
    {
      column: "ecm_electric_kwh_savings",
      header: "Annual Electrical Energy Saving (kWh/yr)",
    },
    {
      column: "ecm_electric_kw_savings",
      header: "Annual Electrical Power Reduction (kW)",
    },
    { column: "ecm_water_m3_savings", header: "Annual Water Saving (m³/yr)" },
    { column: "ecm_capital_cost", header: "ECM Capital Cost ($)" },
    { column: "ecm_roi", header: "ROI (%)" },
    { column: "ecm_npv", header: "NPV ($)" },
    { column: "ecm_payback", header: "Simple Payback (yrs)" },
    { column: "ecm_incentive", header: "Incentive ($)" },
    { column: "ecm_savings", header: "First Year Saving ($)" },
  ]

  function generateECMGroupsList() {
    let site_names = []
    let groups_list = {}

    for (let ecm of TableData) {
      if (!site_names.includes(ecm.site_name)) {
        site_names.push(ecm.site_name)
        groups_list[ecm.site_name] = {}
      }
    }

    for (let ecm of TableData) {
      if (ecm.ecm_group_number !== null) {
        if (!groups_list[ecm.site_name][ecm.ecm_group_number]) {
          groups_list[ecm.site_name][ecm.ecm_group_number] = new Set()
        }
      }
    }

    for (let ecm of TableData) {
      if (ecm.ecm_group_number !== null) {
        if (
          !groups_list[ecm.site_name][ecm.ecm_group_number].has(
            ecm.ecm_identifier
          )
        ) {
          groups_list[ecm.site_name][ecm.ecm_group_number].add(
            ecm.ecm_identifier
          )
        }
      }
    }

    return groups_list
  }

  function getGroupECMList(data) {
    if (data.ecm_group_number) {
      let ecm_groups = generateECMGroupsList()
      return Array.from(ecm_groups[data.site_name][data.ecm_group_number])
    } else {
      return [data.ecm_identifier]
    }
  }

  function createGroup() {
    if (props.planned_ecms.length > 0) {
      let selected_ecms_data = [...props.planned_ecms]
      for (let ecm of TableData) {
        if (selectedECMs[ecm.ecm_identifier]) {
          const exists = selected_ecms_data.some(
            (ecm_data) => ecm_data.ecm_identifier === ecm.ecm_identifier
          )
          if (!exists) {
            ecm.year_of_implementation = null
            selected_ecms_data.push(ecm)
          }
        }
      }
      selected_ecms_data = selected_ecms_data.filter(
        (ecm) => selectedECMs[ecm.ecm_identifier]
      )

      props.set_update_plan(true)
      props.set_decarb_plan_ecms(selected_ecms_data)
      props.set_analyze_plan(false)
      props.set_sofiac_qualification({})
      props.set_cmhc_level("")
    } else {
      let selected_ecms_data = []
      for (let ecm of TableData) {
        if (selectedECMs[ecm.ecm_identifier]) {
          selected_ecms_data.push(ecm)
        }
      }
      props.create_group(selected_ecms_data)
      props.set_analyze_plan(false)
      props.set_sofiac_qualification({})
      props.set_cmhc_level("")
    }
  }

  function handleAccountChange(selected, { action }) {
    if (selected) {
      setSelectedAccountOption(selected)
      props.set_selected_account_option(selected)
    } else {
      setSelectedAccountOption([])
      props.set_selected_account_option([])
    }
  }

  function handleSiteNameChange(selected, { action }) {
    if (action === "clear") {
      let filtered_plans = filterPlans([])
      setSavedPlansList(filtered_plans)
      props.reset_ecm_years()
      props.set_decarb_plan_ecms([])
      setSelectedSiteNameOption([])
      setSelectedPlanOption([])
      form_ref.current.reset()
    } else if (action === "remove-value") {
      setSelectedSiteNameOption(selected)
    } else {
      if (selected) {
        setSelectedSiteNameOption(selected)
        if (selected.length > 0) {
          let filtered_plans = []
          let project_numbers = []
          for (let selected_option of selected) {
            filtered_plans = filterPlans([selected_option.value])
            project_numbers.push(selected_option.value)
          }
          setSavedPlansList(filtered_plans)
          props.set_selected_project_numbers(project_numbers)
        }
      }
    }
  }

  function handlePlanChange(selected, { action }) {
    if (action === "clear") {
      props.reset_ecm_years()
      props.set_decarb_plan_ecms([])
      props.set_selected_plan_option([])
      setSelectedPlanOption([])
    } else {
      if (selected) {
        let project_numbers = getProjectNumbers(selected)
        props.set_selected_project_numbers(project_numbers)
        let site_name_options = getSiteOption(project_numbers)
        setSelectedSiteNameOption(site_name_options)
        props.set_selected_plan_option(selected)
        setSelectedPlanOption(selected)
        getSelectedPlanECMTable(site_name_options)
      }
    }
  }

  function getProjectNumbers(plan_option) {
    let account_plans = props.all_decarb_plans[selectedAccountOption.value]
    let result = []
    if (account_plans.length > 0) {
      let selected_plan = account_plans.filter(
        (plan) => plan.plan_id === plan_option.value
      )
      result = selected_plan[0].project_numbers
    }
    return result
  }

  function getSiteOption(project_numbers) {
    let site_name_options = []
    for (let project_number of project_numbers) {
      let site_name_option = siteNameList.filter(
        (option) => parseInt(option.value) === parseInt(project_number)
      )
      site_name_options.push(site_name_option[0])
    }
    return site_name_options
  }

  return (
    <div className='flex flex-col h-fit mb-8'>
      <div className='flex w-full items-center justify-between'>
        <p className='section_heading_2 p-4'>Available ECMs</p>
      </div>
      <div className='h-full flex flex-col gap-8 custom-frame p-6'>
        <form
          id='filter-form'
          ref={form_ref}
          className='filter-form'
          onSubmit={filterData}
          onReset={(e) => {
            select_ref.current.clearValue()
            saved_plan_ref.current.clearValue()
            setROIValue(0)
            setSelectedECMs({})
            setTableData([])
            setSavedPlansList([])
            props.set_selected_project_numbers([])
            props.set_selected_plan_option([])
            props.set_sofiac_qualification({})
            props.set_cmhc_level("")
          }}
        >
          <div className='first-row-inputs'>
            <div id='f-plan' className='filter-parameter'>
              <p className='filter-label'>Select Account</p>
              <Select
                name='account_number'
                id='account_number'
                className='select-options'
                ref={account_ref}
                options={accountOptionsList}
                value={selectedAccountOption}
                placeholder='Select Account'
                isClearable={true}
                isSearchable={true}
                onChange={handleAccountChange}
              />
            </div>

            <div id='f-siteInput' className='filter-parameter'>
              <p className='filter-label'>Site Name(s)</p>
              <Select
                name='site_name'
                id='site_name'
                className='select-options'
                ref={select_ref}
                options={siteNameList}
                value={selectedSiteNameOption}
                placeholder='Select Site'
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                onChange={handleSiteNameChange}
              />
            </div>
            <div id='f-plan' className='filter-parameter'>
              <p className='filter-label'>Select Decarbonization Plan</p>
              <Select
                name='plan_name'
                id='plan_name'
                className='select-options'
                ref={saved_plan_ref}
                options={savedPlansList}
                value={selectedPlanOption}
                placeholder='Select Plan'
                isClearable={true}
                isSearchable={true}
                onChange={handlePlanChange}
              />
            </div>
          </div>
          <div>
            <div id='f-descriptionInput' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_description'>
                Description
              </label>
              <input
                name='ecm_description'
                id='ecm_description'
                className='filter-input'
                type='text'
                size={60}
              />
            </div>
          </div>
          <div className='second-row-inputs'>
            <div id='f-capitalcost' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_capital_cost'>
                Max. ECM Capital Cost ($)
              </label>
              <input
                name='ecm_capital_cost'
                id='ecm_capital_cost'
                className='filter-input'
                type='text'
              />
            </div>
            <div id='f-roi' className='filter-parameter'>
              <p className='filter-label'>Min. ROI (%)</p>
              <div id='f-roi-input'>
                <input
                  type='range'
                  id='ecm_roi'
                  name='ecm_roi'
                  max={20}
                  value={ROIValue}
                  onChange={(e) => setROIValue(e.target.value)}
                  className='filter-slider'
                />
                <div id='f-roi-text'>{ROIValue}%</div>
              </div>
            </div>
            <div id='f-npv' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_npv'>
                Min. NPV ($)
              </label>
              <input
                name='ecm_npv'
                id='ecm_npv'
                className='filter-input'
                type='text'
              />{" "}
            </div>
            <div id='f-payback' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_payback'>
                Max. Simple Payback (yrs)
              </label>
              <input
                name='ecm_payback'
                id='ecm_payback'
                className='filter-input'
                type='text'
              />{" "}
            </div>
          </div>
          <div className='third-row-inputs'>
            <div id='f-incentive' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_incentive'>
                Min. Incentive ($)
              </label>
              <input
                name='ecm_incentive'
                id='ecm_incentive'
                className='filter-input'
                type='text'
              />{" "}
            </div>
            <div id='f-savings' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_savings'>
                Min. First Year Saving ($)
              </label>
              <input
                name='ecm_savings'
                id='ecm_savings'
                className='filter-input'
                type='text'
              />
            </div>
            <div id='filter-buttons'>
              <button
                type='reset'
                className='reset-button-gray'
                // disabled={isResetDisabled}
              >
                Reset
              </button>
              <button type='submit' className='submit-button-blue'>
                Search
              </button>
            </div>
          </div>
        </form>
        <ECMTable
          id='multi-filter-table'
          table_headers={header_names}
          ecm_list={TableData}
          selected_ecms={selectedECMs}
          set_selected_ecms={setSelectedECMs}
          fn_get_group_ecms={getGroupECMList}
        />
        <p className='upgrade-restrict-text'>
          Note: Selecting certain ECMs may restrict other ECMs due to their
          effect on each other.
        </p>

        <div id='filter-buttons'>
          <button
            className='submit-button-blue'
            onClick={createGroup}
            // disabled={selectedPlanOption && selectedPlanOption.value}
          >
            {props.planned_ecms.length > 0
              ? props.update_button_text
              : props.submit_button_text}
          </button>
        </div>
      </div>
    </div>
  )
}
export default MultiFilter
