import "./table.css"
import { useEffect, useState } from "react"
import TableHeaderRow from "./TableHeaderRow"
import TableDataRow from "./TableDataRow"
import TableAddDataRow from "./TableAddDataRow"

function Table({
  headers_array,
  data_array,
  input_props_array,
  fn_add_new_row,
  fn_delete_row,
  fn_select_row,
  fn_update_row,
  table_entity_name,
  no_data_message,
  custom_no_data_class,
  add_new_row,
  parent_key_header,
  children_key_headers,
  fn_disable_children,
  fn_new_children_values,
  row_key,
  table_disabled,
}) {
  /**
   * Props:
   *
   * - headers_array: (Array) Array of objects with data about the headers.
   *    - object should have name and sort_order properties.
   * [
   *  {
   *    header_name: "Header One",
   *    header_key: "header_name_one",
   *    is_required: true || false,
   *    is_sortable: true || false,
   *    sort_order: "asc" || "dsc" || null,
   *    cell_width: "fill" || "small" || null,
   *  },
   *  {
   *    header_name: "Header Two",
   *    header_key: "header_name_two",
   *    is_required: true || false,
   *    is_sortable: true || false,
   *    sort_order: "asc" || "dsc" || null,
   *    cell_width: "fill" || "small" || null,
   *  }
   * ]
   *
   * - input_props_array: (Array) Array of objects with the input props for each row.
   * [
   *  {
   *    header_name: 'File Name',
   *    header_key: 'file_name',
   *    input_type: 'text',
   *    is_required: true,
   *    default_value: 'File - 1',
   *    place_holder: 'Enter file name',
   *  },
   *  {
   *    header_name: 'File Type',
   *    header_key: 'file_type',
   *    input_type: 'select',
   *    is_required: true,
   *    default_value: 'Other',
   *    options: ['Flat', 'Tiered', 'TOU', 'Seasonal', 'Block'],
   *    place_holder: 'Select file name',
   *  },
   * ]
   *
   * - data_array: (Array) Array of objects with the data for each row.
   *    - object should have [row_data] and [is_selected] properties.
   * [
   *  {
   *    row_data: {
   *                header_name_one: 'string',
   *                header_name_two: 1,
   *                header_name_three: <Component />,
   *              },
   *    is_selected: true || false,
   *  },
   *  {
   *    row_data: {
   *                header_name_one: 'string',
   *                header_name_two: 2,
   *                header_name_three: <Component />,
   *              },
   *    is_selected: true || false,
   *  }
   * ]
   *
   * - row_key: (String) header_key for the row identifier.
   *
   */

  const [headersArray, setHeadersArray] = useState([])
  const [dataArray, setDataArray] = useState([])
  const [inputPropsArray, setInputPropsArray] = useState([])

  useEffect(() => {
    if (headers_array) {
      setHeadersArray(headers_array)
    }
    if (data_array) {
      setDataArray(data_array)
    }

    if (input_props_array) {
      setInputPropsArray(input_props_array)
    }
  }, [headers_array, data_array, input_props_array])

  // useEffect(() => {
  //   console.log("input props array", inputPropsArray)
  // }, [inputPropsArray])

  // sorting function
  function sortTableData(header_name) {
    // use header value to sort data
    // check number / string
    // update headersArray with new sort_order
    console.log("sort by: ", header_name)
  }

  // select all function
  function selectAllHandler() {
    // update the is_selected property of the data_array[row_key]
    // update css modifier for all rows
    // update is_selected prop for all rows
  }

  // selection function
  function selectRowHandler(row_index) {
    // update the is_selected property of the data_array[key_header]
    // update css modifier for selected row
    if (fn_select_row && !table_disabled) {
      fn_select_row(row_index)
    }
  }

  // delete row function
  function deleteRowHandler(row_index) {
    // remove row from data_array using row_key_value
    if (fn_delete_row && !table_disabled) {
      fn_delete_row(row_index)
    }
  }

  // update value function
  function updateRowHandler(row_data) {
    // update row data in data_array using row_key_value
    if (fn_update_row && !table_disabled) {
      fn_update_row(row_data)
    }
  }

  // no data message when data_array is empty

  // add new row handler
  function addNewRowHandler(row_data) {
    if (fn_add_new_row && !table_disabled) {
      fn_add_new_row(row_data)
    }
  }

  return (
    <>
      {/* <div className='table_container'> */}
      <div className='table'>
        {headersArray.length > 0 && (
          <>
            <TableHeaderRow
              headers_array={headersArray}
              fn_sort_by_header={sortTableData}
              fn_select_all={selectAllHandler}
              disabled={table_disabled}
            />
          </>
        )}
        <div className='table__body_overflow'>
          {dataArray.length > 0 ? (
            dataArray.map((data, index) => {
              return (
                <TableDataRow
                  key={`${table_entity_name}-${index}`}
                  headers_array={headersArray}
                  data={data}
                  input_props_array={inputPropsArray}
                  fn_select_row={selectRowHandler}
                  fn_delete_row={deleteRowHandler}
                  fn_update_row={updateRowHandler}
                  entity_name={table_entity_name}
                  parent_key_header={parent_key_header}
                  fn_disable_children={fn_disable_children}
                  children_key_headers={children_key_headers}
                  fn_new_children_values={fn_new_children_values}
                  disabled={table_disabled}
                />
              )
            })
          ) : (
            <div
              className={`${
                custom_no_data_class
                  ? custom_no_data_class
                  : "table__no_data_box"
              } ${table_disabled ? "table__no_data_box--disabled" : ""}
            `}
            >
              <p>{no_data_message}</p>
            </div>
          )}
        </div>
        {add_new_row && (
          <TableAddDataRow
            new_row_index={dataArray.length + 1}
            headers_array={headersArray}
            input_props_array={inputPropsArray}
            fn_add_new_row={addNewRowHandler}
            fn_delete_row={deleteRowHandler}
            entity_name={table_entity_name}
            parent_key_header={parent_key_header}
            fn_disable_children={fn_disable_children}
            children_key_headers={children_key_headers}
            fn_new_children_values={fn_new_children_values}
            disabled={table_disabled}
          />
        )}
      </div>
      {/* </div> */}
    </>
  )
}
export default Table
