import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2359a1',
    color: theme.palette.common.white,
    border: `1px solid rgba(221, 221, 221, 1)`,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: `1px solid rgba(221, 221, 221, 1)`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function CustomizedTables(props) {
  function createData(
    account_number,
    account_name,
    email,
    phone,
    total_consumption
  ) {
    return { account_number, account_name, email, phone, total_consumption };
  }

  const rows = props.data.map((eachRow) =>
    createData(eachRow[0], eachRow[1], eachRow[2], eachRow[3], eachRow[4])
  );

  return (
    <>
      <TableContainer
        component={Paper}
        className='overflow-x-scroll overflow-y-scroll'
      >
        <Table sx={{ minWidth: 700 }} aria-label='customized table'>
          <TableHead>
            <TableRow>
              {props.data[0] &&
                Object.keys(props.data[0]).map((key) => (
                  <StyledTableCell key={key} align='left'>
                    {key}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row, index) => (
              <StyledTableRow key={index}>
                {Object.values(row).map((cell, index) => (
                  <StyledTableCell key={index} align='left'>
                    {cell}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
