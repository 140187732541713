import { useState, useEffect, useContext } from "react"
import "../../../../styles/addProperty/InputContainer.css"
import {
  DefaultDraftContext,
  UserDraftContext,
} from "../../context/DraftContext"
import {
  water_fixtures_headers_array,
  water_fixtures_input_props_array,
} from "./WaterFixtureOptions.js"
import Input from "../../../global/components/input/Input"
import Select from "../../../global/components/select/Select"
import "../../style/dataCollection.css"
import Button from "../../../global/components/button/Button"
import CheckBox from "../../../global/components/CheckBox/CheckBox"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import Table from "../../../global/components/Table/Table"
import Textarea from "../../../global/components/textarea/Textarea.js"

const PropertyWaterFixtures = ({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
}) => {
  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)

  const [waterFixturesAckInputs, setWaterFixturesAckInputs] = useState({})
  const [waterFixturesDataArray, setWaterFixturesDataArray] = useState([])

  const [isWaterFixturesDisabled, setIsWaterFixturesDisabled] = useState(true)
  const [constructionYear, setConstructionYear] = useState("")

  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  useEffect(() => {
    if (userDraftState.property_water_fixtures) {
      let water_fixtures = buildFixturesTableDataArray(
        userDraftState.property_water_fixtures
      )
      setWaterFixturesDataArray(water_fixtures)
    }
    if (userDraftState.property_water_fixtures_ack) {
      if (
        userDraftState.property_water_fixtures_ack
          .isNoWaterFixtureInformation === true ||
        userDraftState.property_water_fixtures_ack.isOnlyUploadDocuments ===
          true
      ) {
        setIsWaterFixturesDisabled(true)
      } else {
        setIsWaterFixturesDisabled(false)
      }
    }
    // construction year required to assign default values for water fixtures
    if (userDraftState.building_data.year_built) {
      setConstructionYear(userDraftState.building_data.year_built)
    }
  }, [userDraftState])

  const handleCheckedBoxChange = (newValue) => {
    userDraftDispatch({
      type: "property_water_fixtures_save_ack",
      payload: { [newValue.name]: newValue.checked },
    })
  }

  const hasValidFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isValid = hasValidFields() //true or false

    if (!isValid) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isValid ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation("good")
    }
  }

  // function to building water fixtures data array
  function buildFixturesTableDataArray(data_array) {
    /**
     * data = [
     *  {
     *    fixture_type: "Kitchen Sinks",
     *    num_of_fixtures: 0,
     *    max_flow_rate: 5,
     *    flow_rate_unit: "GPM",
     *  },
     * ]
     */

    let fixtures_table_data = data_array.map((data, index) => {
      let fixture_data_object = {
        row_data: {
          row_index: index,
          fixture_type: data.fixture_type,
          num_of_fixtures: data.num_of_fixtures,
          max_flow_rate: data.max_flow_rate,
          flow_rate_unit: data.flow_rate_unit,
        },
        is_selected: false,
      }
      return fixture_data_object
    })
    return fixtures_table_data
  }

  function updateFixtureRowHandler(row_data) {
    userDraftDispatch({
      type: "property_water_fixtures_update_fixture",
      payload: row_data,
      row_index: row_data.row_index,
    })
  }

  function addNewFixtureRowHandler(row_data) {
    userDraftDispatch({
      type: "property_water_fixtures_add_fixture",
      payload: row_data,
    })
  }

  function deleteFixtureRowHandler(row_index) {
    if (row_index >= 0) {
      userDraftDispatch({
        type: "property_water_fixtures_delete_fixture",
        row_index: row_index,
      })
    }
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>Water Fixtures</p>
        <Button
          type='button'
          className='button__large button__large--gray complete-button-upper'
          buttonText='Check this tab'
          disabled={isButtonDisabled === null}
          onClick={handleValidation}
        />
      </div>

      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Add Water Fixtures Information'
          content={
            <>
              In this section, provide information about the building's water
              fixtures. <br />
              Feel free to skip this section if you lack the information, or if
              you intend to upload documents containing the required
              information.
            </>
          }
          containerClassName='main-instructions'
        />
        <div className='form__description'>
          <CheckBox
            label='I do not have the water fixtures information.'
            name='isNoWaterFixtureInformation'
            checked={
              userDraftState.property_water_fixtures_ack
                .isNoWaterFixtureInformation
            }
            onChange={handleCheckedBoxChange}
          />
        </div>
        <div className='form__description'>
          <CheckBox
            label='I will ONLY upload documents containing the required water fixture information.'
            name='isOnlyUploadDocuments'
            checked={
              userDraftState.property_water_fixtures_ack.isOnlyUploadDocuments
            }
            onChange={handleCheckedBoxChange}
          />
        </div>
      </div>

      {/* water fixtures */}
      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Water Fixtures'
          content="If a water fixture is not listed, you can use the 'Add New Water Fixture' option to include additional fixtures."
          containerClassName='basicInfo-instruction__container'
        />
        <Table
          headers_array={water_fixtures_headers_array}
          data_array={waterFixturesDataArray}
          no_data_message={"No data available, please add a new fixture"}
          input_props_array={water_fixtures_input_props_array}
          fn_update_row={updateFixtureRowHandler}
          fn_add_new_row={addNewFixtureRowHandler}
          fn_delete_row={deleteFixtureRowHandler}
          table_entity_name={"water fixture"}
          add_new_row={true}
          table_disabled={isWaterFixturesDisabled}
        />
        <Textarea
          label='General Comments'
          name='general_comments'
          value={waterFixturesAckInputs.general_comments}
          onChange={handleCheckedBoxChange}
          disabled={isWaterFixturesDisabled}
        />
      </div>
    </div>
  )
}

export default PropertyWaterFixtures
