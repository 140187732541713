import React, { useState, useEffect } from 'react';
import '../../../styles/report-tab.css';
import {
  default as ElectricityProviderRates,
  default as ElectricityProviderBasicCharge,
  default as ElectricityCityRates,
  default as GasProviderRates,
  default as GasProviderBasicCharge,
  default as GasCityRates,
} from './ManageRatesTable';
import {
  deleteProviderElectricityRatePost,
  deleteProviderElectricityBasicChargePost,
  deleteCityRatesPost,
  deleteProviderGasRatePost,
  deleteProviderGasBasicChargePost,
} from '../../../utils/HttpClient';
import VerticalTable from '../../report/VerticalTable';
import { useNavigate } from 'react-router-dom';

const RateTabs = (props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState({
    provider_electricity_energy_rate: [[]],
    provider_electricity_basic_charge: [[]],
    city_electricity_energy_rate: [[]],
    provider_gas_energy_rate: [[]],
    provider_gas_basic_charge: [[]],
    city_gas_energy_rate: [[]],
    financial_rate: {
      gst: 0,
      electrical_inflation_rate: 0,
      gas_inflation_rate: 0,
      water_inflation_rate: 0,
      fuel_inflation_rate: 0,
      general_inflation_rate: 0,
      om_cost_inflation_rate: 0,
      discount_rate: 0,
    },
  });

  const toggleTab = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props]);

  return (
    <div>
      <ul className='flex report-tabs'>
        <div
          className={activeTab === 0 ? 'tabs active-tab' : 'tabs'}
          onClick={() => toggleTab(0)}
        >
          <span>Electricity</span>
        </div>
        <div
          className={activeTab === 1 ? 'tabs active-tab' : 'tabs'}
          onClick={() => toggleTab(1)}
        >
          <span>Gas</span>
        </div>
        <div
          className={activeTab === 2 ? 'tabs active-tab' : 'tabs'}
          onClick={() => toggleTab(2)}
        >
          <span>Water</span>
        </div>
        <div
          className={activeTab === 3 ? 'tabs active-tab' : 'tabs'}
          onClick={() => toggleTab(3)}
        >
          <span>Fuel</span>
        </div>
        <div
          className={activeTab === 4 ? 'tabs active-tab' : 'tabs'}
          onClick={() => toggleTab(4)}
        >
          <span>Financial</span>
        </div>
        <div
          className={activeTab === 5 ? 'tabs active-tab' : 'tabs'}
          onClick={() => toggleTab(5)}
        >
          <span>Carbon Tax</span>
        </div>
      </ul>

      <div className='tab-contents'>
        <div className={activeTab === 0 ? 'content active-content' : 'content'}>
          <div>
            <div className='widget-header'>
              <h2>Provider Rates</h2>
            </div>
            <div className='widget'>
              <ElectricityProviderRates
                data={data.provider_electricity_energy_rate}
                addButtonText={'Add Provider Rate'}
                addButtonLink={'/rates/provider/electricity/add'}
                skipFirstCol={true}
                headers={[
                  'Provider Name',
                  'Provider Category',
                  'Step Num',
                  'Value per kWh',
                  'Lower Threshold',
                  'Upper Threshold',
                ]}
                onEdit={(id) => {
                  navigate('/rates/provider/electricity/edit', {
                    state: data.provider_electricity_energy_rate.filter(
                      (r) => r[0] == id
                    )[0],
                  });
                }}
                onDelete={(id) => {
                  deleteProviderElectricityRatePost({
                    provider_electricity_energy_rate_id: id,
                  }).then((res) => {
                    window.location.reload(false);
                  });
                }}
              />
            </div>
          </div>
          <div className='mt-8 grid grid-cols-4 gap-4'>
            <div className='col-span-2'>
              <div className='widget-header'>
                <h2>Provider Basic Charge</h2>
              </div>
              <div className='widget'>
                <ElectricityProviderBasicCharge
                  data={data.provider_electricity_basic_charge}
                  addButtonText={'Add Provider Basic Charge'}
                  addButtonLink={'/rates/provider/electricity/basiccharge/add'}
                  skipFirstCol={true}
                  headers={[
                    'Provider Name',
                    'Provider Category',
                    'Value per day',
                  ]}
                  onEdit={(id) => {
                    navigate('/rates/provider/electricity/basiccharge/edit', {
                      state: data.provider_electricity_basic_charge.filter(
                        (r) => r[0] == id
                      )[0],
                    });
                  }}
                  onDelete={(id) => {
                    deleteProviderElectricityBasicChargePost({
                      provider_electricity_energy_admin_rate_id: id,
                    }).then((res) => {
                      window.location.reload(false);
                    });
                  }}
                />
              </div>
            </div>
            <div className='col-span-2'>
              <div className='widget-header'>
                <h2>City Rates</h2>
              </div>
              <div className='widget'>
                <ElectricityCityRates
                  data={data.city_electricity_energy_rate}
                  addButtonText={'Add City Rate'}
                  addButtonLink={'/rates/city/electricity/add'}
                  skipFirstCol={true}
                  headers={['Province', 'City', 'Value per kWh']}
                  onEdit={(id) => {
                    navigate('/rates/city/electricity/edit', {
                      state: data.city_electricity_energy_rate.filter(
                        (r) => r[0] == id
                      )[0],
                    });
                  }}
                  onDelete={(id) => {
                    deleteCityRatesPost({ city_other_energy_rate_id: id }).then(
                      (res) => {
                        window.location.reload(false);
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={activeTab === 1 ? 'content active-content' : 'content'}>
          <div className='grid grid-cols-4 gap-4'>
            <div className='col-span-2'>
              <div>
                <div className='widget-header'>
                  <h2>Provider Rates</h2>
                </div>
                <div className='widget'>
                  <GasProviderRates
                    data={data.provider_gas_energy_rate}
                    addButtonText={'Add Provider Rate'}
                    addButtonLink={'/rates/provider/gas/add'}
                    skipFirstCol={true}
                    headers={[
                      'Provider Name',
                      'Provider Category',
                      'Value per GJ',
                    ]}
                    onEdit={(id) => {
                      navigate('/rates/provider/gas/edit', {
                        state: data.provider_gas_energy_rate.filter(
                          (r) => r[0] == id
                        )[0],
                      });
                    }}
                    onDelete={(id) => {
                      deleteProviderGasRatePost({
                        provider_gas_rate_id: id,
                      }).then((res) => {
                        window.location.reload(false);
                      });
                    }}
                  />
                </div>
              </div>
              <div className='mt-8'>
                <div className='widget-header'>
                  <h2>Provider Basic Charge</h2>
                </div>
                <div className='widget'>
                  <GasProviderBasicCharge
                    data={data.provider_gas_basic_charge}
                    addButtonText={'Add Provider Basic Charge'}
                    addButtonLink={'/rates/provider/gas/basiccharge/add'}
                    skipFirstCol={true}
                    headers={[
                      'Provider Name',
                      'Provider Category',
                      'Value per day',
                    ]}
                    onEdit={(id) => {
                      navigate('/rates/provider/gas/basiccharge/edit', {
                        state: data.provider_gas_basic_charge.filter(
                          (r) => r[0] == id
                        )[0],
                      });
                    }}
                    onDelete={(id) => {
                      deleteProviderGasBasicChargePost({
                        provider_gas_admin_rate_id: id,
                      }).then((res) => {
                        window.location.reload(false);
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-span-2'>
              <div className='widget-header'>
                <h2>City Rates</h2>
              </div>
              <div className='widget'>
                <GasCityRates
                  data={data.city_gas_energy_rate}
                  addButtonText={'Add City Rate'}
                  addButtonLink={'/rates/city/gas/add'}
                  skipFirstCol={true}
                  headers={['Province', 'City', 'Value per GJ']}
                  onEdit={(id) => {
                    navigate('/rates/city/gas/edit', {
                      state: data.city_gas_energy_rate.filter(
                        (r) => r[0] == id
                      )[0],
                    });
                  }}
                  onDelete={(id) => {
                    deleteCityRatesPost({ city_other_energy_rate_id: id }).then(
                      (res) => {
                        window.location.reload(false);
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={activeTab === 2 ? 'content active-content' : 'content'}>
          <div className='widget-header'>
            <h2>City Rates</h2>
          </div>
        </div>
        <div className={activeTab === 3 ? 'content active-content' : 'content'}>
          <div className='widget-header'>
            <h2>City Rates</h2>
          </div>
        </div>
        <div className={activeTab === 4 ? 'content active-content' : 'content'}>
          <div className='widget'>
            <VerticalTable
              title={'Financial Rates'}
              data={[
                data.financial_rate.gst * 100 + '%',
                data.financial_rate.electrical_inflation_rate * 100 + '%',
                data.financial_rate.gas_inflation_rate * 100 + '%',
                data.financial_rate.water_inflation_rate * 100 + '%',
                data.financial_rate.fuel_inflation_rate * 100 + '%',
                data.financial_rate.general_inflation_rate * 100 + '%',
                data.financial_rate.om_cost_inflation_rate * 100 + '%',
                data.financial_rate.discount_rate * 100 + '%']}
              headers={[
                'GST Rate',
                'Electrical Inflation Rate',
                'Gas Inflation Rate',
                'Water Inflation Rate',
                'Fuel Inflation Rate',
                'General Inflation Rate',
                'O&M Cost Inflation Rate',
                'Discount Rate',
              ]}
            ></VerticalTable>
          </div>
        </div>
        <div className={activeTab === 5 ? 'content active-content' : 'content'}>
          <div className='widget-header'>
            <h2>Carbon Tax Rates</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateTabs;
