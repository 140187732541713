import IncentivesStyle from "../../../styles/analysisTools/incentives.module.css"

function Incentives(props) {
  // const cmhcResult = props.result
  return (
    <div>
      <h1 className={IncentivesStyle.heading}>{props.data.heading}</h1>
      <div className={IncentivesStyle.container}>
        <img
          src={props.data.logo}
          alt='logo'
          className={
            props.data.log === "sofiac"
              ? IncentivesStyle.sofiacLogo
              : IncentivesStyle.cmhcLogo
          }
        />
        <div>
          <p>{props.data.title}</p>
          <p className={IncentivesStyle.orgIntro}>{props.data.intro}</p>
        </div>
        <div>
          <p className={IncentivesStyle.approachHead}>
            {props.data.approachHeader}
          </p>
          <ul className={IncentivesStyle.DetailPoints}>
            {props.data.approachDetails.map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <div>
            {props.data.approachNotes.map((note, index) => (
              <p key={index}>{note}</p>
            ))}
          </div>
        </div>
        <div>
          <p className={IncentivesStyle.eligibilityHeader}>
            {props.data.eligibilityHeader}
          </p>

          {props.data.name === "sofiac" ? (
            <div className={IncentivesStyle.eligibilityDetailSection}>
              {props.data.eligibilityDetails.map((detail, index) => (
                <div key={index} className={IncentivesStyle.eligibilityDetail}>
                  <p
                    className={
                      props.result[index] === "Eligible"
                        ? IncentivesStyle.eligibaleGreen
                        : props.result[index] === "Not Eligible"
                        ? IncentivesStyle.notEligibleRed
                        : IncentivesStyle.noData
                    }
                  >
                    {props.result[index]}
                  </p>
                  <p>{detail}</p>
                </div>
              ))}
            </div>
          ) : (
            <>
              <ul className={IncentivesStyle.DetailPoints}>
                {props.data.eligibilityDetails.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>

              <p className={IncentivesStyle.incentiveResult}>
                {props.result !== "" ? (
                  props.result === 1 ||
                  props.result === 2 ||
                  props.result === 3 ? (
                    <>
                      {"Your decarbonization plan is eligible for: "}
                      <span className={IncentivesStyle.eligibaleGreen}>
                        {`Level ${props.result}`}
                      </span>
                    </>
                  ) : props.result == "multi" ? (
                    <>
                      {"Multi-building plan is "}
                      <span className={IncentivesStyle.notEligibleRed}>
                        {"not eligible for MLI Select."}
                      </span>
                    </>
                  ) : (
                    <>
                      {"Your decarbonization plan is "}
                      <span className={IncentivesStyle.notEligibleRed}>
                        {"not eligible for MLI Select."}
                      </span>
                    </>
                  )
                ) : (
                  <>
                    <span className={IncentivesStyle.noData}>
                      {"No available data"}
                    </span>
                  </>
                )}
              </p>
            </>
          )}
        </div>
        <div className={IncentivesStyle.annotates}>
          <div>{props.data.annotate1}</div>
          <div>{props.data.annotate2}</div>
        </div>
      </div>
    </div>
  )
}
export default Incentives
