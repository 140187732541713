import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccountTableRow from './AccountTableRow.js';
import SingleProjectTableRow from './SingleProjectTableRow.js';
import '../../../../styles/table.css';

function AccountTable(props) {
  const [tableData, setTableData] = useState([]);
  const selectedAccountNum = props.selectedAccountNum;
  const selectedProjectNum = props.selectedProjectNum;

  useEffect(() => {
    setTableData(Object.keys(props.dataset));
  }, [props]);

  // Account table row and header
  const generateTableData = () => {
    if (selectedAccountNum) {
      return (
        <SingleProjectTableRow
          searchQuery={props.searchQuery}
          expandedSearch={props.expandedSearch}
          key={selectedAccountNum}
          account={selectedAccountNum}
          username={props.dataset[selectedAccountNum].user_name}
          email={props.dataset[selectedAccountNum].email}
          headers={['Project Number', 'Site Name', 'Details']}
          selectedProjectNum={selectedProjectNum}
          filterProjectData={
            props.dataset[selectedAccountNum].projects[selectedProjectNum]
          }
          allAccountProjects={props.dataset[selectedAccountNum].projects}
        />
      );
    }
    return tableData.map((account) => (
      <AccountTableRow
        key={account}
        account={account}
        username={props.dataset[account].user_name}
        email={props.dataset[account].email}
        projects={props.dataset[account].projects}
        headers={['Project Number', 'Site Name', 'Details']}
      />
    ));
  };

  const renderTableHeaderRow = () => {
    return props.headers.map((value, index) => {
      return (
        <th className='' key={index}>
          <div className='flex justify-between items-center table-header-row'>
            {value}
          </div>
        </th>
      );
    });
  };

  return (
    <>
      <table id='portfolio-plus' className='overflow-auto text-sm'>
        <thead>
          <tr>
            <th colSpan='4' className='table-title'>
              <div className='flex justify-between items-center '>
                <p>{props.title}</p>
              </div>
            </th>
          </tr>
        </thead>
        <thead>
          <tr>{renderTableHeaderRow()}</tr>
        </thead>
        <tbody>{generateTableData()}</tbody>
      </table>
    </>
  );
}

// assign what data type each prop should be
AccountTable.propTypes = {
  dataset: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
};

export default AccountTable;
