import { useState } from "react"
import "./button.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

/*
====Regular button====:
prop:
- buttonText: string
- className:
  "button__large"(Essential for the next 4 className)
  "button__large--primary"
  "button__large--secondary"
  "button__large--gray"
  "button__large--secondary--gray"

  "button__medium"(Essential for the next 4 className)
  "button__medium--primary"
  "button__medium--secondary"
  "button__medium--gray"
  "button__medium--secondary--gray"

  "button__small"(Essential for the next 4 className)
  "button__small--primary"
  "button__small--secondary"
  "button__small--gray"
  "button__small--secondary--gray"

====Button with icon and text====:
prop:
- buttonText: string
- icon: FontAwesomeIcon
- className:
  "button__with__icon"(Essential for the next 2 className)
  "button__wiht__icon--primary"
  "button__with__icon--secondary"

====Button for banner====:
prop:
- buttonText: string
- className:
  "button__banner"(Essential for the next className)
  "button__banner--link"

====Icon act as a button====:
prop:
- icon: FontAwesomeIcon
- className:
  "icon--only"


====Button for sidebar====:
prop:
- buttonText: string
- icon: FontAwesomeIcon
- siderbarButton: true
- className:
  "button__sidebar"

*/

const Button = ({
  id,
  buttonText,
  className,
  disabled,
  icon,
  onClick,
  sidebarButton,
  type,
  role, // for accessibility, Add role attribute for further context
  ariaLabel, // for accessibility, Convey purpose of button clearly
  ariaLabelledBy, // for accessibility, Associate an element with the button on the page that serves as its label (use id of the element)
}) => {
  const handleOnClick = (e) => {
    if (!disabled && onClick) {
      onClick(e)
    }
  }
  return (
    <>
      <button
        id={id}
        type={type}
        onClick={handleOnClick}
        className={`button  ${className}`}
        disabled={disabled}
        role={role}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-disabled={disabled}
      >
        {buttonText ? (
          <>
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className={`icon--with--text ${
                  sidebarButton ? "icon--sidebar" : ""
                }`}
              />
            )}
            <div className={`${sidebarButton ? "text-display" : ""}`}>
              {buttonText}
            </div>
          </>
        ) : (
          <FontAwesomeIcon icon={icon} className='icon--only' />
        )}
      </button>
    </>
  )
}

export default Button
