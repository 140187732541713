
import NavigationTab from '../NavigationTabs/NavigationTab';
import './dropdown.css'

const Dropdown = ({ data, activeStep, handleTabClick }) => {

  return (
    <ul className='navigationTab__dropdown' role='dropdown-tablist'>
      {data.map((tab) => (
        <NavigationTab
          key={tab.id}
          label={tab.label}
          isSelected={activeStep === tab.id}
          className='navigationTab-mobile__small'
          onClick={() => handleTabClick(tab.id, tab.label)}
          iconPosition={tab.iconPosition}
          rightIconState={tab.rightIconState}
        >
          {tab.label}
        </NavigationTab>
      ))}
    </ul>
  );
};

export default Dropdown;
