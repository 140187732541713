import { useState, useEffect } from "react"
import LoadingSpinner from "../../../global/LoadingSpinner"
import UsersRegisteredPage from "./UsersRegisteredPage"
import { getAllUsers, deleteUserOnAdmin } from "../../../../utils/HttpClient"

function UsersPage(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [usersList, setUsersList] = useState([])

  useEffect(() => {
    setIsLoading(true)
    if (props.data.user_role === "admin") {
      getAllUsersData()
    }
  }, [props])

  function getAllUsersData() {
    setIsLoading(true)
    getAllUsers()
      .then((res) => {
        setUsersList(res)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  function handleOnDeleteUser(email) {
    console.log("Deleting user with email: ", email)
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      deleteUserOnAdmin(email)
        .then((res) => {
          setIsLoading(false)
          getAllUsersData()
          resolve(res)
        })
        .catch((err) => {
          console.error(err)
          setIsLoading(false)
          reject(err)
        })
    })
  }

  return (
    <div className='mt-7 mb-16'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <UsersRegisteredPage
          users_list={usersList}
          get_user_data={props.get_user_data}
          handleOnDeleteUser={handleOnDeleteUser}
        />
      )}
    </div>
  )
}

export default UsersPage
