export const WaterFuelTypes = [
  "Potable Water - Indoor",
  "Potable Water - Outdoor",
  "Potable Water - Mixed",
  "Reclaimed Water - Indoor",
  "Reclaimed Water - Outdoor",
  "Reclaimed Water - Mixed",
  "Well Water - Indoor",
  "Well Water - Outdoor",
  "Well Water - Mixed",
  "Other - Indoor",
  "Other - Outdoor",
  "Other - Mixed",
]

export const WaterMeterUnits = [
  "ccf (hundred cubic feet)",
  "cf (cubic feet)",
  "cGal (hundred gallons) (UK)",
  "cGal (hundred gallons) (US)",
  "cm (cubic meters)",
  "Gallons (UK)",
  "Gallons (US)",
  "kcf (thousand cubic feet)",
  "kcm (thousand cubic meters)",
  "kGal (thousand gallons) (UK)",
  "kGal (thousand gallons) (US)",
  "Liters",
  "Mcf (million cubic feet)",
  "MGal (million gallons) (UK)",
  "MGal (million gallons) (US)",
]

export const water_util_headers_array = [
  {
    header_name: "edit_row",
    header_key: "edit_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
  {
    header_name: "Utility Name",
    header_key: "utility_name",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Fuel",
    header_key: "fuel",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Energy Unit",
    header_key: "energy_unit",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "Average Rate ($/Energy Unit)",
    header_key: "average_rate",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "delete_row",
    header_key: "delete_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
]

export const water_util_input_props_array = [
  {
    header_name: "Utility Name",
    header_key: "utility_name",
    input_type: "text",
    is_required: true,
    default_value: "",
    place_holder: "Enter utility name",
  },
  {
    header_name: "Fuel",
    header_key: "fuel",
    input_type: "select",
    options: WaterFuelTypes,
    is_required: true,
    default_value: "Potable Water - Indoor",
    place_holder: "Select fuel",
    add_new_option: true,
  },
  {
    header_name: "Energy Unit",
    header_key: "energy_unit",
    input_type: "select",
    options: WaterMeterUnits,
    is_required: true,
    default_value: "cm (cubic meters)",
    place_holder: "Select energy unit",
  },
  {
    header_name: "Average Rate ($/Energy Unit)",
    header_key: "average_rate",
    input_type: "number",
    is_required: false,
    default_value: "",
    place_holder: "Enter average rate",
  },
]

// Water Meters Props
export const water_meter_headers_array = [
  {
    header_name: "edit_row",
    header_key: "edit_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
  {
    header_name: "Meter Name",
    header_key: "meter_name",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "lg",
  },
  {
    header_name: "Meter Utility",
    header_key: "meter_utility",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Upload Bills",
    header_key: "upload_bills",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "View Bills",
    header_key: "view_bills",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "delete_row",
    header_key: "delete_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
]
