import React, { useState, useEffect } from "react"
import { Chart } from "react-chartjs-2"

function StackedHistogram(props) {
  const [chartValues, setChartValues] = useState([])
  var dates = []
  var hdd = []
  var consumption = []
  var values = []
  useEffect(() => {
    splitArray(props.data)
  }, [])

  function splitArray(arr) {
    const options = { year: "numeric", month: "numeric", day: "numeric" }
    arr.forEach((element) => {
      dates.push(new Date(element[0]).toLocaleDateString("en-US", options))
      consumption.push(element[1])
      hdd.push(element[2])
    })
    values.push(dates)
    values.push(consumption)
    values.push(hdd)
    setChartValues(values)
  }

  return (
    <div className='p-3'>
      <Chart
        data={{
          datasets: [
            {
              type: "line",
              label: "HDD per Month",
              yAxisID: "hdd",
              borderColor: props.line_color,
              backgroundColor: props.line_color,
              data: chartValues[2],
              fill: true,
              tension: 0.4,
            },
            {
              type: "bar",
              label: props.legend_label,
              yAxisID: "val",
              backgroundColor: props.bar_color,
              data: chartValues[1],
              fill: true,
              tension: 0.4,
            },
          ],
          labels: chartValues[0],
        }}
        options={{
          scales: {
            hdd: {
              type: "linear",
              position: "right",
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
              title: {
                display: true,
                text: "HDD",
              },
            },
            val: {
              type: "linear",
              position: "left",
              title: {
                display: true,
                text: props.y_label,
              },
            },
          },
          plugins: {
            legend: {
              display: true,
              position: "bottom",
              labels: {
                color: "rgb(0, 0, 0)",
              },
            },
          },
          layout: {
            padding: {
              top: 30,
              bottom: 30,
              left: 30,
              right: 30,
            },
          },
        }}
      />
    </div>
  )
}

export default StackedHistogram
