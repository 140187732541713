const EnergyFuelTypes = [
  "Coal (anthracite)",
  "Coal (bituminous)",
  "Coke",
  "Diesel",
  "District Chilled Water-Electric",
  "District Chilled Water-Absorption",
  "District Chilled Water-Engine",
  "District Chilled Water-Other",
  "District Hot Water",
  "District Steam",
  "Electric-Grid",
  "Electric-Solar",
  "Electric-Wind",
  "Fuel Oil (No. 1)",
  "Fuel Oil (No. 2)",
  "Fuel Oil (No. 4)",
  "Fuel Oil (No. 5 and No. 6)",
  "Kerosene",
  "Natural Gas",
  "Renewable Natural Gas",
  "Propane",
  "Wood",
]

export const RateStructureTypes = [
  "BC Hydro-Residential Conservation Rate",
  "BC Hydro-Small General Service Rate",
  "BC Hydro-Medium General Service Rate",
  "BC Hydro-Large General Service Rate",
  "BC Hydro-Exempt General Service Rate for Rate Zone 1B",
  "BC Hydro-Exempt Residential Service Rate for farms",
  "BC Hydro-Exempt General Service Rate for Rate Zone 1B",
  "Fortis BC-Electricity-Residential rates",
  "Fortis BC-Electricity-Small Commercial < 40 kW",
  "Fortis BC-Electricity-Large Commercial > 40 kW",
  "Fortis BC-Gas-Residential natural gas rates",
  "Fortis BC-Gas-Small Commercial < 2000 Gj",
  "Fortis BC-Gas-Large Commercial > 2000 Gj",
  "Other",
]

export const util_headers_array = [
  {
    header_name: "edit_row",
    header_key: "edit_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
  {
    header_name: "Utility Name",
    header_key: "utility_name",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Rate Structure",
    header_key: "rate_structure",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Fuel",
    header_key: "fuel",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Energy Unit",
    header_key: "energy_unit",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "Average Rate ($/Energy Unit)",
    header_key: "average_rate",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "Average Demand Rate ($/kW)",
    header_key: "average_demand_rate",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "delete_row",
    header_key: "delete_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
]

export const util_input_props_array = [
  {
    header_name: "Utility Name",
    header_key: "utility_name",
    input_type: "text",
    is_required: true,
    default_value: "",
    place_holder: "Enter utility name",
  },
  {
    header_name: "Rate Structure",
    header_key: "rate_structure",
    input_type: "select",
    is_required: true,
    default_value: "Other",
    options: RateStructureTypes,
    place_holder: "Select rate structure",
    add_new_option: true,
  },
  {
    header_name: "Fuel",
    header_key: "fuel",
    input_type: "select",
    options: EnergyFuelTypes,
    is_required: true,
    default_value: "Natural Gas",
    place_holder: "Select fuel",
    add_new_option: true,
  },
  {
    header_name: "Energy Unit",
    header_key: "energy_unit",
    input_type: "select",
    options: ["kWh", "GJ", "etc."],
    is_required: true,
    default_value: "GJ",
    place_holder: "Select energy unit",
  },
  {
    header_name: "Average Rate ($/Energy Unit)",
    header_key: "average_rate",
    input_type: "number",
    is_required: false,
    default_value: "",
    place_holder: "Enter average rate",
  },
  {
    header_name: "Average Demand Rate ($/kW)",
    header_key: "average_demand_rate",
    input_type: "number",
    is_required: false,
    default_value: "",
    place_holder: "Enter average demand rate",
  },
]

// Meters Props

export const meter_headers_array = [
  {
    header_name: "edit_row",
    header_key: "edit_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
  {
    header_name: "Meter Name",
    header_key: "meter_name",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "lg",
  },
  {
    header_name: "Meter Utility",
    header_key: "meter_utility",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "Upload Bills",
    header_key: "upload_bills",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "View Bills",
    header_key: "view_bills",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "delete_row",
    header_key: "delete_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
]
