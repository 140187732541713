import { useState, useRef } from "react"
import FileUploaderModal from "../global/components/fileUploader/FileUploaderModal"
import FileDetailsTable from "../global/components/fileUploader/FileDetailsTable"
import InstructionalText from "../global/components/InstructionalText/InstructionalText"
import Button from "../global/components/button/Button"
import "./updateReportText.css"
import { updateReportText } from "../../utils/HttpClient"
import { Navigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LinearProgress } from "@mui/material"

const apiUrl = process.env.REACT_APP_API_URL

function UpdateReportText({ user_data, building_schema }) {
  // building schema - identifier for destination
  const [buildingSchema, setBuildingSchema] = useState(building_schema)

  // list of uploaded files - for the session
  const [sessionFilesList, setSessionFilesList] = useState([])

  // progress state
  const [jobCompleted, setJobCompleted] = useState(false)
  const [statusText, setStatusText] = useState("")
  const [showProgress, setShowProgress] = useState(false)

  // file uploader modal ref
  const file_uploader_modal = useRef(null)

  // remove file from list handler
  function removeFileFromListHandler(index) {
    let new_list = [...sessionFilesList]
    new_list.splice(index, 1)
    setSessionFilesList(new_list)
  }

  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  // TODO: update code to call report text update

  function handleSelectFiles(files) {
    setSessionFilesList(files)
    file_uploader_modal.current.close()
  }

  function updateReportTextHandler() {
    startUpdateReportText(sessionFilesList)
  }

  // call updare report text API
  function startUpdateReportText(files) {
    if (files.length !== 0) {
      setShowProgress(true)
      updateReportText(files)
        .then((res) => {
          if (res["result"]) {
            setJobCompleted(true)
            setStatusText("Job successfully completed")
            setSessionFilesList([])
          } else {
            // server error
            alert(
              "Server Error: job did not start, job ID is not assigned! Please check the attached file!"
            )
          }
        })
        .catch((err) => {
          //show error message
          if (err.status === 401) {
            Navigate("/")
          }
          console.log(err)
        })
        .finally(() => {
          setShowProgress(false)
        })
    } else {
      alert("Please attach files to upload to the server!")
    }
  }

  return (
    <div className='mb-16'>
      <div className='title'>
        <h1 className='section_heading_1'>Update Report Text</h1>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div className='import_container'>
        <div className='instruction_container'>
          <InstructionalText
            type='Main Instructions'
            title='Upload Report Contents template file to update report text.'
            content={
              <>
                Select template excel files for uploading to update the report
                text shown on the screening report.
              </>
            }
          />
          <InstructionalText
            type='Additional Instructions'
            content={
              <>
                The report data model is automatically refreshed every hour
                during the business day starting with the first update of the
                day at{" "}
                <span className='inline_text--bold'>
                  9AM and at every hour until 4PM PST.
                </span>
                <br />
                Please note that the report data model
                <span className='inline_text--bold'> must be refreshed</span> to
                populate the report with the updated data.
              </>
            }
          />
        </div>

        <div className='buttons_container'>
          <Button
            type='button'
            className='button  button__large button__large--secondary'
            buttonText='Select Files'
            onClick={() => file_uploader_modal.current.showModal()}
          />
        </div>

        <FileDetailsTable
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          data_list={sessionFilesList}
          set_data_list={setSessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          fn_download_file={handleOnDownloadFile}
        />

        <FileUploaderModal
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "message",
            "status",
            "delete",
          ]}
          modal_header='Attach Report Contents Template File'
          content_title='Select a formatted excel file.'
          content_description='The maximum file size supported is 5MB. This tool supports the following file types: Formatted Excel spreadsheets (.xlsx).'
          modal_ref={file_uploader_modal}
          uploaded_tableData={sessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          set_uploaded_tableData={setSessionFilesList}
          accepted_file_types={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          fn_upload_handler={handleSelectFiles}
        />

        <div className='buttons_container'>
          <Button
            type='button'
            className='button  button__large button__large--primary'
            buttonText='Update Report Text'
            onClick={updateReportTextHandler}
          />
        </div>

        {showProgress && (
          <div>
            <p>Processing attachment(s) :</p>
            <LinearProgress variant='indeterminate' />
          </div>
        )}

        {jobCompleted && (
          <div
            className={
              "text_with_icon " +
              (jobCompleted ? "status_text--complete" : "status_text--error")
            }
          >
            {jobCompleted ? (
              <FontAwesomeIcon icon='fa-light fa-circle-check' />
            ) : (
              <FontAwesomeIcon icon='fa-light fa-circle-exclamation' />
            )}
            {statusText}
          </div>
        )}
      </div>
    </div>
  )
}
export default UpdateReportText
