import React, { useEffect, useState } from "react"
import { getData } from "../../utils/HttpClient"
import { useNavigate } from "react-router-dom"
import LoadingSpinner from "../global/LoadingSpinner"
import ReportTabs from "./ReportTabs"
import PDFReport from "./PDF-Report"
import XLSXReport from "./XLSX-Report"
import "../../styles/report.css"

export const Report = (props) => {
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState({
    report_gas_breakdown: ["No data to show"],
    report_electrical_breakdown: ["No data to show"],
  })

  useEffect(() => {
    setIsLoading(true)
    getData(
      `report/${window.location.pathname.substring(8)}`,
      props.removeToken,
      props.clearCacheData,
      navigate
    ).then((res) => {
      if (res.complete_gas_usage) delete res.complete_gas_usage[0]
      if (res.complete_electricity_usage)
        delete res.complete_electricity_usage[0]
      if (res.report_gas_breakdown) delete res.report_gas_breakdown[0] // delete 'total' row
      if (res.report_electrical_breakdown)
        delete res.report_electrical_breakdown[0] // delete 'total' row
      if (res.report_upgrades_rec_energy_solutions_data)
        formatStringsToDash(res.report_upgrades_rec_energy_solutions_data)
      formatReportUtilityRateData(res.report_utility_rate_data)
      formatTotalConsumptionData(res.report_total_consumption_data)
      setData(res)
      setIsLoading(false)
    })
  }, [])

  function formatStringsToDash(data) {
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (data[i][j] == null) {
          data[i][j] = "-"
        }
      }
    }
  }

  // add units to utility rate data
  const formatReportUtilityRateData = (utility_rates_data) => {
    // check utility type then add units

    // add /kWh to electricity data
    utility_rates_data[0]?.forEach((value, index) => {
      if (index > 1) {
        // ignore first 2 columns
        utility_rates_data[0][index] = value + " /kWh"
      }
    })
    // add /GJ to gas data
    utility_rates_data[1]?.forEach((value, index) => {
      if (index > 1) {
        // ignore first 2 columns
        utility_rates_data[1][index] = value + " /GJ"
      }
    })
    // add /m3 to water data
    utility_rates_data[2].forEach((value, index) => {
      if (index > 1) {
        // ignore first 2 columns
        utility_rates_data[2][index] = value + " /m³"
      }
    })
  }

  const formatTotalConsumptionData = (total_consumption_data) => {
    total_consumption_data[0][0] = total_consumption_data[0][0] + " kWh/yr"
    total_consumption_data[1][0] = total_consumption_data[1][0] + " GJ/yr"
    total_consumption_data[2][0] = total_consumption_data[2][0] + " GJ/yr"
  }

  const onReportUpgradesRecEnergySolutionsDataChange = (newDataArray) => {
    var project_number = window.location.pathname.substring(8)
    props.onReportUpgradesRecEnergySolutionsDataChange(
      project_number,
      newDataArray
    )
  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div id='report-container' className='mb-4'>
          <div className='title'>
            <h1 className='section_heading_1'>Assessments</h1>
          </div>
          <div className='hr_container'>
            <hr className='hr_breaker' />
          </div>
          <div className='table-border'>
            <div className='ecm-header report-header'>
              <div className='title-btn-box'>
                <p className='report-title-text'>
                  {data.report_summary_participant_table_data[1]} - Assessment
                  Report
                </p>
                <div className='flex report-header'>
                  <PDFReport data={data} /> <div className='pr-2' />
                  <XLSXReport data={data} />
                </div>
              </div>
            </div>

            <ReportTabs
              data={data}
              onReportUpgradesRecEnergySolutionsDataChange={
                onReportUpgradesRecEnergySolutionsDataChange
              }
              tableData={props.tableData}
            />
          </div>
        </div>
      )}
    </>
  )
}
