import { useEffect, useState, useContext, useRef } from "react"
import {
  DefaultDraftContext,
  UserDraftContext,
} from "../../context/DraftContext.js"
import "../../style/dataCollection.css"
import Button from "../../../global/components/button/Button.js"
import CheckBox from "../../../global/components/CheckBox/CheckBox"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText.js"
import Table from "../../../global/components/Table/Table.js"
import { dateTimeFormatter } from "../../../../utils/DataFormatter.js"

// water meter constants
import {
  water_util_headers_array,
  water_util_input_props_array,
  water_meter_headers_array,
} from "./WaterMeterTypes.js"

// file upload
import FileUploaderModal from "../../../global/components/fileUploader/FileUploaderModal.js"
import UploadedFileModal from "../../../global/components/fileUploader/UploadedFileModal.js"

const apiUrl = process.env.REACT_APP_API_URL

function PropertyWaterMeters({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
}) {
  // context objects
  const { userDraftState, userDraftDispatch, userData } =
    useContext(UserDraftContext)
  const { defaultDraftObject, siteInputOptions } =
    useContext(DefaultDraftContext)

  // validation states
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const [utilDataArray, setUtilDataArray] = useState([])
  const [meterDataArray, setMeterDataArray] = useState([])

  const [selectedMeterName, setSelectedMeterName] = useState("")
  const [uploadedBillFiles, setUploadedBillFiles] = useState([])

  const [meterInputPropsArray, setMeterInputPropsArray] = useState([])

  const [isWaterDisabled, setIsWaterDisabled] = useState(false)

  const file_uploader_modal_ref = useRef(null)
  const uploaded_file_modal_ref = useRef(null)

  // function to build utility object from data
  function buildUtilityTableDataArray(data_array) {
    /**
     *  data = {
     *   row_data: {
     *               row_index: index,
     *               utility_name: "Potable - Indoor - 1",
     *               fuel: "Potable - Indoor",
     *               energy_unit: "cm (cubic meters)",
     *               average_rate: "0.01",
     *             },
     *   is_selected: true || false,
     * },
     */
    let utility_table_data = data_array.map((data, index) => {
      let utility_object = {
        row_data: {
          row_index: index,
          utility_name: data.utility_name,
          fuel: data.fuel,
          energy_unit: data.energy_unit,
          average_rate: data.average_rate,
        },
        is_selected: false,
      }
      return utility_object
    })
    return utility_table_data
  }

  // function to build meter object from data
  function buildMeterTableDataArray(data_array) {
    /**
     *  data = {
     *           meter_name: "Potable - Indoor - Meter - 1",
     *           meter_utility: "Potable - Indoor - 1",
     * },
     */

    let meter_table_data = data_array.map((data, index) => {
      let meter_object = {
        row_data: {
          row_index: index,
          meter_name: data.meter_name,
          meter_utility: data.meter_utility,
          upload_bills: (
            <>
              <Button
                type='button'
                className='button  button__medium button__medium--secondary'
                buttonText='Upload Bills'
                onClick={() => openFileUploaderModal(data.meter_name)}
                disabled={isWaterDisabled}
              />
            </>
          ),
          view_bills: (
            <>
              <Button
                type='button'
                className='button  button__medium button__medium--secondary'
                buttonText='View Bills'
                onClick={() => openUploadedFileModal(data.meter_name)}
                disabled={isWaterDisabled}
              />
            </>
          ),
        },
        is_selected: false,
      }
      return meter_object
    })
    return meter_table_data
  }

  // load all draft objects into state
  useEffect(() => {
    if (userDraftState.property_water_ack) {
      if (
        userDraftState.property_water_ack.isNoWaterInformation === true ||
        userDraftState.property_water_ack.isOnlyUploadDocuments === true
      ) {
        setIsWaterDisabled(true)
      } else {
        setIsWaterDisabled(false)
      }
    }

    if (userDraftState.property_water_utilities) {
      let util_data_array = buildUtilityTableDataArray(
        userDraftState.property_water_utilities
      )
      setUtilDataArray(util_data_array)
    }
    if (userDraftState.property_water_meters) {
      let meter_data_array = buildMeterTableDataArray(
        userDraftState.property_water_meters
      )
      setMeterDataArray(meter_data_array)
    }

    if (userDraftState.property_water_bills && selectedMeterName !== "") {
      let meter_bill = userDraftState.property_water_bills.find(
        (bill) => bill.meter_name === selectedMeterName
      )
      // set the array of bills for the meter
      if (meter_bill) {
        let meter_bill_files = meter_bill.bill_files
        setUploadedBillFiles(meter_bill_files)
      } else {
        setUploadedBillFiles([])
      }
    }
  }, [userDraftState])

  // meter input props generated from utility data
  useEffect(() => {
    if (utilDataArray.length > 0) {
      let utilities_list = utilDataArray.map((row) => row.row_data.utility_name)
      let meter_input_props_array = [
        {
          header_name: "Meter Name",
          header_key: "meter_name",
          input_type: "text",
          is_required: true,
          place_holder: "Enter meter name",
        },
        {
          header_name: "Meter Utility",
          header_key: "meter_utility",
          input_type: "select",
          is_required: true,
          default_value: utilities_list[0],
          options: utilities_list,
          place_holder: "Select utility",
        },
      ]
      setMeterInputPropsArray([...meter_input_props_array])
    }
  }, [utilDataArray])

  useEffect(() => {
    if (userDraftState.property_water_meters) {
      let meter_data_array = buildMeterTableDataArray(
        userDraftState.property_water_meters
      )
      setMeterDataArray(meter_data_array)
    }
  }, [isWaterDisabled])

  function openFileUploaderModal(meter_name) {
    setSelectedMeterName(meter_name)

    let meter_bill = userDraftState.property_water_bills.find(
      (bill) => bill.meter_name === meter_name
    )

    // set the array of bills for the meter
    if (meter_bill) {
      let meter_bill_files = meter_bill.bill_files
      setUploadedBillFiles(meter_bill_files)
    } else {
      setUploadedBillFiles([])
    }

    file_uploader_modal_ref.current.showModal()
  }

  function openUploadedFileModal(meter_name) {
    setSelectedMeterName(meter_name)

    let meter_bill = userDraftState.property_water_bills.find(
      (bill) => bill.meter_name === meter_name
    )

    // set the array of bills for the meter
    if (meter_bill) {
      let meter_bill_files = meter_bill.bill_files
      setUploadedBillFiles(meter_bill_files)
    } else {
      setUploadedBillFiles([])
    }

    uploaded_file_modal_ref.current.showModal()
  }

  // checking required Inputs if they are filled.
  // If false, there are required fields that are not filled.
  const hasRequiredFields = () => {
    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isEmpty = hasRequiredFields()

    if (isEmpty) {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation(null)
    } else {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isEmpty ? "good" : "error")
    }
  }

  function updateUtilRowHandler(row_data) {
    let row_index = row_data.row_index
    delete row_data.row_index

    let found_index = userDraftState.property_water_utilities.findIndex(
      (obj) => obj.utility_name === row_data.utility_name
    )
    if (found_index > -1 && found_index !== row_index) {
      alert("Utility Name already exists. Please enter a different name.")
      Object.assign(row_data, utilDataArray[row_index].row_data)
      return
    }

    // if name is changed, update the meter utility name
    if (
      utilDataArray[row_index].row_data.utility_name !== row_data.utility_name
    ) {
      // update the meter utility
      let meter_data_array_copy = [...meterDataArray]
      meter_data_array_copy.forEach((meter) => {
        if (
          meter.row_data.meter_utility ===
          utilDataArray[row_index].row_data.utility_name
        ) {
          let new_row_data = {
            ...meter.row_data,
            meter_utility: row_data.utility_name,
          }
          updateMeterRowHandler(new_row_data)
        }
      })
    }
    userDraftDispatch({
      type: "property_water_utilities_update_utility",
      payload: row_data,
      row_index: row_index,
    })
  }

  function updateMeterRowHandler(row_data) {
    let row_index = row_data.row_index
    delete row_data.row_index
    delete row_data.upload_bills
    delete row_data.view_bills

    // check for duplicate name
    let found_index = userDraftState.property_water_meters.findIndex(
      (obj) => obj.meter_name === row_data.meter_name
    )
    if (found_index > -1 && found_index !== row_index) {
      alert("Meter Name already exists. Please enter a different name.")
      Object.assign(row_data, meterDataArray[row_index].row_data)
      return
    }

    // if name is changed, update the bill files
    if (meterDataArray[row_index].row_data.meter_name !== row_data.meter_name) {
      // find bill file for meter
      let meter_bills = userDraftState.property_water_bills.find(
        (bills) =>
          bills.meter_name === meterDataArray[row_index].row_data.meter_name
      )

      if (meter_bills) {
        userDraftDispatch({
          type: "property_water_bills_update_meter_name",
          meter_name: meter_bills.meter_name,
          new_meter_name: row_data.meter_name,
        })
      }
    }

    userDraftDispatch({
      type: "property_water_meters_update_meter",
      payload: row_data,
      row_index: row_index,
    })
  }

  function addNewUtilRowHandler(row_data) {
    if (
      userDraftState.property_water_utilities.find(
        (obj) => obj.utility_name === row_data.utility_name
      )
    ) {
      alert("Utility Name already exists. Please enter a different name.")
    } else {
      userDraftDispatch({
        type: "property_water_utilities_add_utility",
        payload: row_data,
      })
    }
  }

  function addNewMeterRowHandler(row_data) {
    if (
      userDraftState.property_water_meters.find(
        (obj) => obj.meter_name === row_data.meter_name
      )
    ) {
      alert("Meter Name already exists. Please enter a different name.")
    } else {
      userDraftDispatch({
        type: "property_water_meters_add_meter",
        payload: row_data,
      })
    }
  }

  function deleteUtilRowHandler(row_index) {
    if (row_index >= 0) {
      userDraftDispatch({
        type: "property_water_utilities_delete_utility",
        row_index: row_index,
      })
    }
  }

  function deleteMeterRowHandler(row_index) {
    if (row_index >= 0) {
      userDraftDispatch({
        type: "property_water_meters_delete_meter",
        row_index: row_index,
      })
    }
  }

  //File Uploader Modal Handlers
  function removeBillFileFromQueueHandler(row_index) {
    userDraftDispatch({
      type: "property_water_bills_delete_bills",
      meter_name: selectedMeterName,
      row_index: row_index,
    })
  }

  function uploadBillFileHandler(file_blobs_list) {
    // add files to the list of supporting documents
    // create a new array of objects with the file_blobs_list
    let new_bill_files_list = file_blobs_list.map((file_blob) => {
      return {
        folder_name: file_blob.folder_name,
        org_folder_name: file_blob.folder_name,
        file_name: file_blob.file_name,
        org_file_name: file_blob.file_name,
        file_path: file_blob.file_path,
        file_size: file_blob.file_size,
        message: file_blob.message,
        status: file_blob.status,
        is_deleted: false,
        uploaded_date: dateTimeFormatter(new Date()),
        file_blob: file_blob.file_blob,
      }
    })
    userDraftDispatch({
      type: "property_water_bills_add_bills",
      meter_name: selectedMeterName,
      payload: new_bill_files_list,
    })
  }

  function downloadBillFileHandler(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  function handleOnEditFileName(index, new_name, isFolder) {
    // use selectedMeterName to find the energy bill_files list
    // and update the list with the index and new name
    let water_bills = userDraftState.property_water_bills.map((bill) => {
      if (bill.meter_name === selectedMeterName) {
        let bill_files = bill.bill_files.map((file, file_index) => {
          if (file_index === index) {
            if (isFolder) {
              return {
                ...file,
                folder_name: new_name,
              }
            } else {
              return {
                ...file,
                file_name: new_name,
              }
            }
          } else {
            return file
          }
        })
        return {
          ...bill,
          bill_files: bill_files,
        }
      } else {
        return bill
      }
    })

    userDraftDispatch({
      type: "property_water_bills_update_all",
      payload: water_bills,
    })
  }

  function handleCheckedBoxChange(newValue) {
    userDraftDispatch({
      type: "property_water_save_ack",
      payload: { [newValue.name]: newValue.checked },
    })
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>Water Utilities & Meters</p>
        <Button
          type='button'
          className='button__large button__large--gray'
          buttonText='Check this tab'
          disabled={isButtonDisabled === null}
          onClick={handleValidation}
        />
      </div>

      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Add Water Information'
          content={
            <>
              In this section, provide information about the building's water
              information. <br />
              Feel free to skip this section if you lack the information, or if
              you intend to upload documents containing the required
              information.
            </>
          }
          containerClassName='main-instructions'
        />
        <div className='form__description'>
          <CheckBox
            label='I do not have the water information.'
            name='isNoWaterInformation'
            checked={userDraftState.property_water_ack.isNoWaterInformation}
            onChange={handleCheckedBoxChange}
          />
        </div>
        <div className='form__description'>
          <CheckBox
            label='I will ONLY upload documents containing the required energy information.'
            name='isOnlyUploadDocuments'
            checked={userDraftState.property_water_ack.isOnlyUploadDocuments}
            onChange={handleCheckedBoxChange}
          />
        </div>
      </div>
      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Water utilities'
          content='Include all relevant water utility rates that are pertinent to the analysis.'
          // additionalContent={[
          //   "For example, for a multi-unit residential building, you may have two electricity rates - one for common areas, such as 'BC Hydro Small General Service Rate', and another for residential units, like 'BC Hydro Residential'. In case you cannot find the rate structure from the dropdown, enter it manually. For utilities with complex rate structures, input an average or marginal rate, whichever is more suitable.",
          // ]}
          containerClassName='basicInfo-instruction__container'
        />
        <Table
          headers_array={water_util_headers_array}
          data_array={utilDataArray}
          no_data_message={"No data available, please add a new utility"}
          input_props_array={water_util_input_props_array}
          fn_update_row={updateUtilRowHandler}
          fn_add_new_row={addNewUtilRowHandler}
          fn_delete_row={deleteUtilRowHandler}
          table_entity_name={"utility"}
          add_new_row={true}
          table_disabled={isWaterDisabled}
        />
      </div>
      {/* water meters */}
      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Water meters'
          content='Enter the primary meter groups for the building. Each meter group should share the same utility rate, and their bills will be collectively analyzed.'
          // additionalContent={[
          //   "For instance, a multi-unit residential building might have two meter groups: one for common area electricity and one for residential units. Each meter group may encompass more than one physical meter, such as all in-suite meters in a building.",
          // ]}
          containerClassName='basicInfo-instruction__container'
        />
        <Table
          headers_array={water_meter_headers_array}
          data_array={meterDataArray}
          no_data_message={"No data available, please add a new meter"}
          input_props_array={meterInputPropsArray}
          fn_update_row={updateMeterRowHandler}
          fn_add_new_row={addNewMeterRowHandler}
          fn_delete_row={deleteMeterRowHandler}
          table_entity_name={"meter"}
          add_new_row={true}
          table_disabled={isWaterDisabled}
        />
        <FileUploaderModal
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          modal_header='Upload Bills'
          content_title='Attach your energy meter bills.'
          content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB. Our platform supports the following file types: images (.png, .jpg, .jpeg, .heic), documents (.pdf, .docx, .doc) and spreadsheets (.xlsx, .csv).'
          modal_ref={file_uploader_modal_ref}
          user_data={userData}
          accepted_file_types={[
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/heic",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "text/csv",
          ]}
          fn_remove_row={null}
          fn_upload_handler={uploadBillFileHandler}
        />
        <UploadedFileModal
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          modal_header='View Uploaded Bills'
          content_title='Download or delete your uploaded bills.'
          content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB. Our platform supports the following file types: images (.png, .jpg, .jpeg, .heic), documents (.pdf, .docx, .doc) and spreadsheets (.xlsx, .csv).'
          modal_ref={uploaded_file_modal_ref}
          uploaded_tableData={uploadedBillFiles}
          user_data={userData}
          accepted_file_types={[
            "image/png",
            "image/jpg",
            "image/jpeg",
            "image/heic",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
            "text/csv",
          ]}
          fn_remove_row={removeBillFileFromQueueHandler}
          fn_download_file={downloadBillFileHandler}
          fn_on_edit_handler={handleOnEditFileName}
          fn_set_data_list={setUploadedBillFiles}
        />
      </div>
    </div>
  )
}

export default PropertyWaterMeters
