import React, { useState, useEffect } from "react"
import "../../styles/verticalTable.css"

function VerticalTable(props) {
  const [tableValues, setTableValues] = useState([])

  useEffect(() => {
    let vals = props.data
    for (let i = 0; i < vals.length; i++) {
      if (vals[i] == "NaN") {
        vals[i] = "-"
      }
    }
    setTableValues(vals)
  }, [tableValues])

  return (
    <div>
      <div className='widget-header'>
        <h2 className='section_heading_2'>{props.title}</h2>
      </div>
      <table id='table_description'>
        <tbody>
          {tableValues.map((field, index) => (
            <tr key={index}>
              <td>{props.headers[index]}</td>
              <td>{field}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default VerticalTable
