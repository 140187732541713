import { useEffect, useState, useContext, useRef } from "react"
import Button from "../../../global/components/button/Button.js"
import Input from "../../../global/components/input/Input.js"
import Select from "../../../global/components/select/Select.js"
import RadioGroup from "../../../global/components/RadioButton/RadioGroup.js"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText.js"
import {
  UserDraftContext,
  DefaultDraftContext,
} from "../../context/DraftContext.js"
import { dateTimeFormatter } from "../../../../utils/DataFormatter.js"
import FileDetailsTable from "../../../global/components/fileUploader/FileDetailsTable.js"
import FileUploaderModal from "../../../global/components/fileUploader/FileUploaderModal.js"
import {
  LightTechnologyList,
  LightAreaServed,
  LightTechnologyWattsPerLamp,
  LightControlTypeList,
} from "./LightOptions.js"

/**
 * @param {object} props
 * @param {boolean} props.tabValidationStatus
 * @param {function} props.onTabValidation
 * @param {boolean} props.isButtonDisabled
 * @param {function} props.handleSaveDraft
 * @param {number} props.lightId - index of the light in the property_lights[]
 * @param {function} props.handleClose
 * @returns {JSX.Element} - LightForm component
 * @description
 * LightForm component for adding/editing lights manually
 */
function LightForm({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
  lightId,
  handleClose,
}) {
  const apiUrl = process.env.REACT_APP_API_URL

  // user draft state and dispatch context methods / objects
  const { userDraftState, userDraftDispatch, userData } =
    useContext(UserDraftContext)
  const { defaultDraftObject } = useContext(DefaultDraftContext)

  // light data set to current light name / id if editing
  // otherwise, set to default light data
  const [lightInputs, setLightInputs] = useState({})

  // file uploader modal ref and state
  const file_uploader_modal = useRef(null)
  const [lightFiles, setLightFiles] = useState([])
  const [lightFilesTableData, setLightFilesTableData] = useState([])

  // validation constants
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (lightId === null) {
      setLightInputs(defaultDraftObject.property_lights[0])
      setLightFiles([])
    } else {
      setLightInputs(userDraftState.property_lights[lightId])
      setLightFiles(userDraftState.property_lights[lightId].light_files_list)
    }
  }, [lightId])

  // set files list for selected light
  useEffect(() => {
    if (lightInputs.light_files_list) {
      setLightFiles(lightInputs.light_files_list)
    }
  }, [lightInputs])

  useEffect(() => {
    if (lightFiles) {
      populateUploadedFilesTable(lightFiles)
    }
  }, [lightFiles])

  function handleCancel() {
    setLightInputs({})
    setLightFiles([])
    handleClose()
  }

  function handleAddLight() {
    if (!hasRequiredFields()) {
      handleValidation()
      alert("Please fill in all required fields")
    } else {
      let new_light_inputs = {
        ...lightInputs,
        created_date: dateTimeFormatter(new Date()),
        light_files_list: [...lightFiles],
      }
      userDraftDispatch({
        type: "property_lights_add_light",
        payload: new_light_inputs,
      })
      handleCancel()
    }
  }

  function handleSaveLight() {
    if (!hasRequiredFields()) {
      handleValidation()
      alert("Please fill in all required fields")
    } else {
      let new_light_inputs = {
        ...lightInputs,
        light_files_list: [...lightFiles],
      }
      userDraftDispatch({
        type: "property_lights_save_light",
        row_index: lightId,
        payload: new_light_inputs,
      })
      handleCancel()
    }
  }

  /**
   * update the lightInputs object onChange
   * @param {object} newValue - {name: string, value: string}
   * @returns {void}
   *
   * if the updated input is the 'technology', update the watts_per_lamp value
   * min/max for 'lights_on_time' is 0-24
   */
  function handleInputChange(newValue) {
    if (newValue.name === "technology") {
      let technology_watts_per_lamp = LightTechnologyWattsPerLamp.find(
        (technology) => technology.label === newValue.value
      )
      if (technology_watts_per_lamp) {
        setLightInputs((lightInputs) => ({
          ...lightInputs,
          watts_per_lamp: technology_watts_per_lamp.watts_per_lamp,
          light_files_list: lightFiles,
          [newValue.name]: newValue.value,
        }))
      } else {
        setLightInputs((lightInputs) => ({
          ...lightInputs,
          light_files_list: lightFiles,
          [newValue.name]: newValue.value,
        }))
      }
    } else if (newValue.name === "power_information") {
      if (newValue.value === "Detailed power info") {
        setLightInputs((lightInputs) => ({
          ...lightInputs,
          [newValue.name]: newValue.value,
          watts_per_lamp: 34,
          num_lamps_per_fixture: 1,
          num_fixtures: 1,
          watts_per_area: "",
          area_if_not_whole_building: "",
          light_files_list: lightFiles,
        }))
      } else {
        setLightInputs((lightInputs) => ({
          ...lightInputs,
          [newValue.name]: newValue.value,
          watts_per_lamp: "",
          num_lamps_per_fixture: "",
          num_fixtures: "",
          watts_per_area: 0.5,
          area_if_not_whole_building:
            userDraftState.building_data.gross_floor_area,
          light_files_list: lightFiles,
        }))
      }
    } else {
      setLightInputs((lightInputs) => ({
        ...lightInputs,
        light_files_list: lightFiles,
        [newValue.name]: newValue.value,
      }))
    }
    setValidationObject({})
  }

  // File upload functions

  // updates the light files
  function uploadLightFilesHandler(file_blobs_list) {
    // create a new array of objects with the file_blobs_list
    let new_light_files_list = file_blobs_list.map((file_blob) => {
      return {
        folder_name: file_blob.folder_name,
        org_folder_name: file_blob.folder_name,
        file_name: file_blob.file_name,
        org_file_name: file_blob.file_name,
        file_path: file_blob.file_path,
        file_size: file_blob.file_size,
        message: file_blob.message,
        status: file_blob.status,
        is_deleted: false,
        uploaded_date: dateTimeFormatter(new Date()),
        file_blob: file_blob.file_blob,
      }
    })
    setLightFiles([...lightFiles, ...new_light_files_list])
  }

  function populateUploadedFilesTable(files) {
    if (files.length > 0) {
      let generated_file_list = files.map((file) => {
        return {
          folder_name: file.folder_name,
          org_folder_name: file.org_folder_name || file.folder_name,
          file_name: file.file_name,
          org_file_name: file.org_file_name || file.file_name,
          file_path: file.file_path,
          file_size: file.file_size || file.size,
          message: file.message,
          status: file.status || "Uploaded",
          uploaded_date: file.uploaded_date,
          file_blob: file.file_blob,
        }
      })
      setLightFilesTableData(generated_file_list)
    } else {
      setLightFilesTableData([])
    }
  }

  // remove file from lightFiles
  function removeLightFileHandler(index) {
    let updated_light_files = lightFiles.filter(
      (file, file_index) => file_index !== index
    )
    setLightFiles(updated_light_files)
  }

  function lightFileNameHandler(index, new_name, isFolder) {
    let new_light_files = lightFiles.map((file, file_index) => {
      if (file_index === index) {
        if (isFolder) {
          file.folder_name = new_name
        } else {
          file.file_name = new_name
        }
      }
      return file
    })
    setLightFiles(new_light_files)
  }

  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  function hasRequiredFields() {
    // checking reqruied Inputs if they are filled.
    // If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
  }

  function handleValidation() {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      setOnValidation(true)
    } else {
      setOnValidation(false)
    }
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>
          {lightId === null ? "Add Light Manually" : "Edit Light Manually"}
        </p>
      </div>
      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Light Details'
          content={
            <>
              <p>
                Follow the survey to provide information about lights. You can
                add a custom light technology, or select one from the list of
                available options.
              </p>
            </>
          }
          containerClassName='main-instructions'
        />
        <div>
          <div className='form-title-button__container'>
            <div className='page__subheader'>Light documents and images</div>
            <Button
              type='button'
              className='button  button__large button__large--secondary'
              buttonText='Attach Files'
              onClick={() => file_uploader_modal.current.showModal()}
            />
          </div>
          <InstructionalText
            type='Additional Instructions'
            title='File status : '
            content={
              <>
                When a file is <span className='bold_text'>Attached</span>, it
                is not uploaded to our servers yet. Once you save the draft, we
                then upload the files to our secure cloud servers and the status
                will become <span className='bold_text'>Uploaded</span>.
              </>
            }
          />
          {/* add container to set max height with scroll */}
          <FileDetailsTable
            table_headers={[
              "folder_name",
              "file_name",
              "file_size",
              "uploaded_date",
              "status",
              "download",
              "delete",
            ]}
            data_list={lightFilesTableData}
            set_data_list={setLightFilesTableData}
            fn_remove_row={removeLightFileHandler}
            fn_download_file={handleOnDownloadFile}
            fn_edit_name={lightFileNameHandler}
          />
          <FileUploaderModal
            table_headers={[
              "folder_name",
              "file_name",
              "file_size",
              "message",
              "status",
              "delete",
            ]}
            modal_header='Attach Light Documents and Images'
            content_title='Attach related light documents and images.'
            content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB.
        Our platform supports the following file types: images (.png, .jpg, .jpeg, .heic), documents (.pdf, .docx, .doc) and spreadsheets (.xlsx, .csv). Attachments will be uploaded upon saving.'
            modal_ref={file_uploader_modal}
            uploaded_tableData={lightFilesTableData}
            fn_remove_row={removeLightFileHandler}
            set_uploaded_tableData={setLightFilesTableData}
            user_data={userData}
            accepted_file_types={[
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/heic",
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
              "text/csv",
            ]}
            fn_upload_handler={uploadLightFilesHandler}
          />
        </div>

        <div className='form__inputs__container'>
          <div className='form__inputs__container input_width--1-2'>
            <div className='input_width--1-2'>
              <Select
                label='Technology'
                name='technology'
                options={LightTechnologyList}
                onChange={handleInputChange}
                value={lightInputs.technology}
                placeholder='Select light technology'
                disabled={lightId !== null}
                addNewOption={true}
                required
                validation={onValidation}
                set_validation_object={setValidationObject}
              />
            </div>
            <div className='input_width--1-2'>
              <Select
                label='Area Served'
                name='area_served'
                options={LightAreaServed}
                onChange={handleInputChange}
                value={lightInputs.area_served}
                placeholder='Select area served'
                addNewOption={true}
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
          </div>
          <div className='input_width--1-2'>
            <Input
              label='Space Description'
              type='text'
              name='space_description'
              onChange={handleInputChange}
              value={lightInputs.space_description}
              placeholder='Enter space description'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>

          <div className='form__inputs__container input_width--full'>
            <div className='input_width--1-4'>
              <RadioGroup
                legend='Power Information'
                name='power_information'
                radioOptions={[
                  {
                    value: "Detailed power info",
                    label: "Detailed power info",
                  },
                  {
                    value: "Lighting power per area",
                    label: "Lighting power per area",
                  },
                ]}
                onChange={handleInputChange}
                value={lightInputs.power_information}
              />
            </div>

            {lightInputs.power_information === "Detailed power info" ? (
              <>
                <div className='input_width--1-4'>
                  <Input
                    label='Watts per lamp (W)'
                    type='number'
                    name='watts_per_lamp'
                    onChange={handleInputChange}
                    value={lightInputs.watts_per_lamp}
                    placeholder='Enter watts per lamp'
                    required={
                      lightInputs.power_information === "Detailed power info"
                    }
                    validation={
                      lightInputs.power_information === "Detailed power info"
                        ? onValidation
                        : false
                    }
                    set_validation_object={
                      lightInputs.power_information === "Detailed power info"
                        ? setValidationObject
                        : null
                    }
                  />
                </div>
                <div className='input_width--1-4'>
                  <Input
                    label='No. of lamps per fixture'
                    type='number'
                    name='num_lamps_per_fixture'
                    onChange={handleInputChange}
                    value={lightInputs.num_lamps_per_fixture}
                    placeholder='Enter number of lamps'
                    required={
                      lightInputs.power_information === "Detailed power info"
                    }
                    validation={
                      lightInputs.power_information === "Detailed power info"
                        ? onValidation
                        : false
                    }
                    set_validation_object={
                      lightInputs.power_information === "Detailed power info"
                        ? setValidationObject
                        : null
                    }
                  />
                </div>
                <div className='input_width--1-4'>
                  <Input
                    label='No. of fixtures'
                    type='number'
                    name='num_fixtures'
                    onChange={handleInputChange}
                    value={lightInputs.num_fixtures}
                    placeholder='Enter number of fixtures'
                    required={
                      lightInputs.power_information === "Detailed power info"
                    }
                    validation={
                      lightInputs.power_information === "Detailed power info"
                        ? onValidation
                        : false
                    }
                    set_validation_object={
                      lightInputs.power_information === "Detailed power info"
                        ? setValidationObject
                        : null
                    }
                  />
                </div>
              </>
            ) : (
              <div className='form__inputs__container input_width--3-4'>
                <div className='input_width--1-2'>
                  <Input
                    label='Watts per area (W/sqft)'
                    type='number'
                    name='watts_per_area'
                    onChange={handleInputChange}
                    value={lightInputs.watts_per_area}
                    placeholder='Enter watts per area'
                    required={
                      lightInputs.power_information ===
                      "Lighting power per area"
                    }
                    validation={
                      lightInputs.power_information ===
                      "Lighting power per area"
                        ? onValidation
                        : false
                    }
                    set_validation_object={
                      lightInputs.power_information ===
                      "Lighting power per area"
                        ? setValidationObject
                        : null
                    }
                  />
                </div>
                <div className='input_width--1-2'>
                  <Input
                    label='Area, if not the whole building (sqft)'
                    type='number'
                    name='area_if_not_whole_building'
                    onChange={handleInputChange}
                    value={lightInputs.area_if_not_whole_building}
                    placeholder='Enter area in sqft'
                    required={
                      lightInputs.power_information ===
                      "Lighting power per area"
                    }
                    validation={
                      lightInputs.power_information ===
                      "Lighting power per area"
                        ? onValidation
                        : false
                    }
                    set_validation_object={
                      lightInputs.power_information ===
                      "Lighting power per area"
                        ? setValidationObject
                        : null
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className='form__inputs__container input_width--1-2'>
            <div className='input_width--1-2'>
              <Input
                label='Lights-on time (hrs/day)'
                type='number'
                name='lights_on_time'
                min={0}
                max={24}
                onChange={handleInputChange}
                value={lightInputs.lights_on_time}
                placeholder='Enter lights on time'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
            <div className='input_width--1-2'>
              <Select
                label='Control Type'
                name='control_type'
                options={LightControlTypeList}
                onChange={handleInputChange}
                value={lightInputs.control_type}
                placeholder='Select control type'
                required
                validation={onValidation}
                set_validation_object={setValidationObject}
              />
            </div>
          </div>

          <div className='input_width--1-2'>
            <Input
              label='Control comments'
              type='text'
              name='control_comments'
              onChange={handleInputChange}
              value={lightInputs.control_comments}
              placeholder='Enter control comments'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>

          <div className='form__buttons input_width--full'>
            <Button
              type='button'
              buttonText='Cancel'
              className='button__large button__large--secondary'
              onClick={handleCancel}
            />
            <Button
              type='button'
              buttonText={lightId === null ? "Add Light" : "Save Light"}
              className='button__large button__large--primary'
              onClick={lightId === null ? handleAddLight : handleSaveLight}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default LightForm
