import { useEffect, useState, useContext, useRef, Fragment } from "react"
import Button from "../../../global/components/button/Button"
import Input from "../../../global/components/input/Input"
import Select from "../../../global/components/select/Select"
import CheckboxGroup from "../../../global/components/CheckBox/CheckBoxGroup"
import {
  EndUseCheckBoxList,
  EquipmentTypeList,
  AreaServedList,
  ControlTypeList,
  HeatingUnitTypeList,
  ControlTypeHeatingCoolingList,
  CoolingTypeList,
  EquipmentTypeEndUsesList,
  EndUseDefaultInputsList,
  PowerCapacityUnitsList,
} from "./EquipmentOptions.js"
import RadioGroup from "../../../global/components/RadioButton/RadioGroup.js"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText.js"
import Textarea from "../../../global/components/textarea/Textarea.js"
import SimpleModal from "../../../global/components/SimpleModal/SimpleModal.js"
import {
  UserDraftContext,
  DefaultDraftContext,
} from "../../context/DraftContext"
import { dateTimeFormatter } from "../../../../utils/DataFormatter.js"
import HeatingSpecs from "./HeatingSpecs.js"
import CoolingSpecs from "./CoolingSpecs.js"
import PumpSpecs from "./PumpSpecs.js"
import FanSpecs from "./FanSpecs.js"
import FileDetailsTable from "../../../global/components/fileUploader/FileDetailsTable"
import FileUploaderModal from "../../../global/components/fileUploader/FileUploaderModal.js"

/**
 * @param {object} props
 * @param {boolean} props.tabValidationStatus
 * @param {function} props.onTabValidation
 * @param {boolean} props.isButtonDisabled
 * @param {function} props.handleSaveDraft
 * @param {number} props.equipmentId - index of the equipment in the property_equipments[]
 * @param {function} props.handleClose
 * @returns {JSX.Element} - EquipmentForm component
 * @description
 * EquipmentForm component for adding/editing equipment manually
 */
function EquipmentForm({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
  equipmentId,
  handleClose,
}) {
  const apiUrl = process.env.REACT_APP_API_URL
  const START_YEAR = 1900
  const END_YEAR = 2100

  // dynamic end use list with custom end uses
  // end use object for selected end uses
  const [endUseCheckBoxList, setEndUseCheckBoxList] = useState([])
  const [selectedEndUsesObject, setSelectedEndUsesObject] = useState({})

  // generated end use components
  const [generatedEndUseComponents, setGeneratedEndUseComponents] = useState([])

  // user draft state and dispatch context methods / objects
  const { userDraftState, userDraftDispatch, userData } =
    useContext(UserDraftContext)
  const { defaultDraftObject } = useContext(DefaultDraftContext)

  // equipment data set to current equipment name / id if editing
  // otherwise, set to default equipment data
  const [equipmentInputs, setEquipmentInputs] = useState({})
  const [generalSpecInputs, setGeneralSpecInputs] = useState({})

  // end use data for current equipment
  // default end uses for new equipment
  const [endUseInputs, setEndUseInputs] = useState([])
  const [propertyMetersList, setPropertyMetersList] = useState([])

  // new end use modal ref and state
  const enduse_modal_ref = useRef(null)
  const [newEndUse, setNewEndUse] = useState({
    end_use_name: "",
    end_use_type: "",
  })

  // file uploader modal ref and state
  const file_uploader_modal = useRef(null)
  const [equipmentFiles, setEquipmentFiles] = useState([])
  const [equipmentFilesTableData, setEquipmentFilesTableData] = useState([])

  // new end use form validation
  const [newEndUseValidationObject, setNewEndUseValidationObject] = useState({})

  // year selection list
  const [yearSelectionList, setYearSelectionList] = useState([])

  // validation constants
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const [equipmentTypesList, setEquipmentTypesList] = useState([])

  // scroll to top on render
  useEffect(() => {
    window.scrollTo(0, 0)
    let years_array = []
    for (let i = START_YEAR; i <= END_YEAR; i++) {
      let year = new String(i)
      years_array.push(year)
    }
    setYearSelectionList(years_array)
  }, [])

  useEffect(() => {
    setPropertyMetersList(
      userDraftState.property_energy_meters.map((meter) => meter.meter_name)
    )

    // create list of equipment type count
    let equipment_types_count = EquipmentTypeList.map((equipment) => {
      let type_count = 0
      userDraftState.property_equipments.forEach((equip) => {
        if (equip.type === equipment) {
          type_count += 1
        }
      })

      return {
        type: equipment,
        count: type_count,
      }
    })
    setEquipmentTypesList(equipment_types_count)
  }, [userDraftState])

  /**
   * when editing an equipment,
   * update the end use checkbox list to add custom end uses
   * generate the end use components based on the end use inputs and populate with existing data
   * when creating a new equipment, add the required end uses from the equipmentTypeEndUsesList
   * update the checkbox list with the required end uses, and generate the end use components
   */
  useEffect(() => {
    if (equipmentId === null) {
      setEquipmentInputs(defaultDraftObject.property_equipments[0])
      setEquipmentFiles([])
    } else {
      setEquipmentInputs(userDraftState.property_equipments[equipmentId])
      setEquipmentFiles(
        userDraftState.property_equipments[equipmentId].equipment_files_list
      )
    }
  }, [equipmentId])

  // once equipment inputs is set, use it for other states
  useEffect(() => {
    if (equipmentInputs.general_specifications) {
      setGeneralSpecInputs(equipmentInputs.general_specifications)
    }
    if (equipmentInputs.end_uses_list) {
      setEndUseCheckBoxList(equipmentInputs.end_uses_list)
    }
    if (equipmentInputs.equipment_files_list) {
      setEquipmentFiles(equipmentInputs.equipment_files_list)
    }
  }, [equipmentInputs])

  // when new end use is added
  useEffect(() => {
    if (endUseCheckBoxList.length > 0) {
      let selected_end_uses = generateSelectedEndUsesObject(endUseCheckBoxList)
      setSelectedEndUsesObject(selected_end_uses)
    }
  }, [endUseCheckBoxList])

  // when selected end uses object is updated
  useEffect(() => {
    /**
     * 1. checkbox list has changed
     * 2. update the end use inputs with default ones
     * 3. generate the end use components
     */
    // current equipment's end use inputs
    let selected_end_use_properties = endUseCheckBoxList.filter(
      (end_use) => selectedEndUsesObject[end_use.name]
    )

    // list of selected end use properties
    if (selected_end_use_properties.length > 0) {
      let selected_end_use_inputs = generateSelectedEndUseInputs(
        equipmentInputs.end_uses,
        selected_end_use_properties
      )
      setEndUseInputs(selected_end_use_inputs)
    }
  }, [selectedEndUsesObject])

  // when end use inputs is updated, generate the end use components
  useEffect(() => {
    let generated_end_use_components = generateEndUseComponents(endUseInputs)
    setGeneratedEndUseComponents(generated_end_use_components)
  }, [endUseInputs])

  // generate end use components based on the selected end uses
  function generateEndUseComponents(selected_end_use_inputs) {
    let generated_end_use_components = []

    for (let i = 0; i < selected_end_use_inputs.length; i++) {
      let end_use_type = selected_end_use_inputs[i].end_use_type
      let end_use_inputs = selected_end_use_inputs[i]

      if (end_use_type === "heating") {
        generated_end_use_components.push(
          <HeatingSpecs
            input_data={end_use_inputs}
            fn_set_input_data={handleEndUseInputChange}
            options_list_obj={{
              meters_list: propertyMetersList,
              units_list: PowerCapacityUnitsList,
            }}
            validation={onValidation}
            set_validation_object={setValidationObject}
          />
        )
      } else if (end_use_type === "cooling") {
        generated_end_use_components.push(
          <CoolingSpecs
            input_data={end_use_inputs}
            fn_set_input_data={handleEndUseInputChange}
            options_list_obj={{
              meters_list: propertyMetersList,
              units_list: PowerCapacityUnitsList,
            }}
            validation={onValidation}
            set_validation_object={setValidationObject}
          />
        )
      } else if (end_use_type === "pumps") {
        generated_end_use_components.push(
          <PumpSpecs
            input_data={end_use_inputs}
            fn_set_input_data={handleEndUseInputChange}
            options_list_obj={{
              meters_list: propertyMetersList,
              units_list: PowerCapacityUnitsList,
            }}
            validation={onValidation}
            set_validation_object={setValidationObject}
          />
        )
      } else if (end_use_type === "fans") {
        generated_end_use_components.push(
          <FanSpecs
            input_data={end_use_inputs}
            fn_set_input_data={handleEndUseInputChange}
            options_list_obj={{
              meters_list: propertyMetersList,
              units_list: PowerCapacityUnitsList,
            }}
            validation={onValidation}
            set_validation_object={setValidationObject}
          />
        )
      }
    }
    return generated_end_use_components
  }

  function generateSelectedEndUsesObject(end_use_check_box_list) {
    let selected_end_uses = {}
    end_use_check_box_list.forEach((checkbox) => {
      if (checkbox.checked) {
        selected_end_uses[checkbox.name] = true
      } else {
        selected_end_uses[checkbox.name] = false
      }
    })
    return selected_end_uses
  }

  function generateSelectedEndUseInputs(
    current_end_use_inputs,
    selected_end_use_check_box_list
  ) {
    let selected_end_use_inputs = []
    selected_end_use_check_box_list.forEach((checkbox) => {
      let existing_end_use_input = current_end_use_inputs.find(
        (end_use) => end_use.end_use_name === checkbox.label
      )
      if (existing_end_use_input) {
        selected_end_use_inputs.push(existing_end_use_input)
      } else {
        let default_end_use_input = EndUseDefaultInputsList.find(
          (end_use) => end_use.end_use_type === checkbox.type
        )
        if (default_end_use_input) {
          selected_end_use_inputs.push({
            ...default_end_use_input,
            end_use_name: checkbox.label,
          })
        }
      }
    })
    return selected_end_use_inputs
  }

  function handleCancel() {
    setEquipmentInputs({})
    setEndUseCheckBoxList([])
    setEndUseInputs([])
    setGeneralSpecInputs({})
    setEquipmentFiles([])
    handleClose()
  }

  function handleAddEquipment() {
    if (!hasRequiredFields()) {
      handleValidation()
      alert("Please fill in all required fields")
    } else {
      // check name duplicates here
      let found_index = userDraftState.property_equipments.findIndex(
        (obj) => obj.name === equipmentInputs.name
      )
      if (found_index > -1 && found_index !== equipmentId) {
        alert("Equipment Name already exists. Please enter a different name.")
      } else {
        let new_equipment_inputs = {
          ...equipmentInputs,
          created_date: dateTimeFormatter(new Date()),
          end_uses: [...endUseInputs],
          general_specifications: { ...generalSpecInputs },
          end_uses_list: [...endUseCheckBoxList],
          equipment_files_list: [...equipmentFiles],
        }
        userDraftDispatch({
          type: "property_equipments_add_equipment",
          payload: new_equipment_inputs,
        })
        handleCancel()
      }
    }
  }

  function handleSaveEquipment() {
    if (!hasRequiredFields()) {
      handleValidation()
    } else {
      // check name duplicates here
      let found_index = userDraftState.property_equipments.findIndex(
        (obj) => obj.name === equipmentInputs.name
      )
      if (found_index > -1 && found_index !== equipmentId) {
        alert("Equipment Name already exists. Please enter a different name.")
      } else {
        let new_equipment_inputs = {
          ...equipmentInputs,
          end_uses: [...endUseInputs],
          general_specifications: { ...generalSpecInputs },
          end_uses_list: [...endUseCheckBoxList],
          equipment_files_list: [...equipmentFiles],
        }
        userDraftDispatch({
          type: "property_equipments_save_equipment",
          row_index: equipmentId,
          payload: new_equipment_inputs,
        })
        handleCancel()
      }
    }
  }

  /**
   * update the equipmentInputs object onChange
   * @param {object} newValue - {name: string, value: string}
   * @returns {void}
   *
   * if the updated input is the equipment type,
   * update the endUseCheckBoxList with the end-uses for the equipment type
   * and update the selected end uses object
   * which will trigger the generation of the end-use components with their respective data
   */
  function handleInputChange(newValue) {
    if (newValue.name === "type") {
      let equipment_type_obj = EquipmentTypeEndUsesList.find(
        (equipment_end_uses) =>
          equipment_end_uses.equipment_type === newValue.value
      )
      let updated_end_use_check_box_list = []
      // if there is a match update endUseCheckBoxList
      if (equipment_type_obj) {
        let equipment_type_end_uses = equipment_type_obj.end_uses
        updated_end_use_check_box_list = endUseCheckBoxList.map((end_use) => {
          let end_use_obj = equipment_type_end_uses.find(
            (equipment_end_use) => equipment_end_use === end_use.label
          )
          if (end_use_obj) {
            return {
              ...end_use,
              checked: true,
            }
          } else {
            return end_use
          }
        })
      }

      let default_name = ""
      // get default name for equipment
      let equipment_type_count = equipmentTypesList.find(
        (equipment) => equipment.type === newValue.value
      )
      if (equipment_type_count) {
        default_name = `${newValue.value} - ${equipment_type_count.count + 1}`
      }

      // get updated end use inputs
      let selected_end_use_inputs = generateSelectedEndUseInputs(
        endUseInputs,
        updated_end_use_check_box_list
      )

      setEquipmentInputs((equipmentInputs) => ({
        ...equipmentInputs,
        name: default_name,
        general_specifications: generalSpecInputs,
        end_uses: selected_end_use_inputs,
        end_uses_list: updated_end_use_check_box_list,
        equipment_files_list: equipmentFiles,
        [newValue.name]: newValue.value,
      }))
    } else {
      setEquipmentInputs((equipmentInputs) => ({
        ...equipmentInputs,
        general_specifications: generalSpecInputs,
        end_uses: endUseInputs,
        end_uses_list: endUseCheckBoxList,
        equipment_files_list: equipmentFiles,
        [newValue.name]: newValue.value,
      }))
    }
  }

  function handleOpenDialog() {
    enduse_modal_ref.current.showModal()
  }

  function handleCheckBoxOnChange(check_box_list) {
    let selected_end_uses = generateSelectedEndUsesObject(check_box_list)
    setSelectedEndUsesObject(selected_end_uses)
  }

  function handleNewEndUseInputChange(newValue) {
    setNewEndUse((newEndUse) => ({
      ...newEndUse,
      [newValue.name]: newValue.value,
    }))
  }

  function handleGeneralInputChange(newValue) {
    setGeneralSpecInputs((generalSpecInputs) => ({
      ...generalSpecInputs,
      [newValue.name]: newValue.value,
    }))
  }

  /**
   * update the endUseInputs object onChange
   * @param {object} action - {type: string, payload: object}
   * @returns {void}
   */
  function handleEndUseInputChange(action) {
    switch (action.type) {
      case "update_end_use":
        // filter currently selected end uses
        let selected_end_uses = endUseCheckBoxList.filter(
          (end_use) => selectedEndUsesObject[end_use.name]
        )

        // get any new end use inputs and add defaults
        let new_end_use_inputs = []
        selected_end_uses.forEach((end_use) => {
          let existing_end_use_input = endUseInputs.find(
            (end_use_input) => end_use_input.end_use_name === end_use.label
          )
          if (existing_end_use_input) {
            new_end_use_inputs.push(existing_end_use_input)
          } else {
            let default_end_use_input = EndUseDefaultInputsList.find(
              (end_use_input) => end_use_input.end_use_type === end_use.type
            )
            if (default_end_use_input) {
              new_end_use_inputs.push({
                ...default_end_use_input,
                end_use_name: end_use.label,
              })
            }
          }
        })

        // update the end use input with the new values
        let updated_end_use_inputs = new_end_use_inputs.map((end_use) => {
          if (end_use.end_use_name === action.payload.end_use_name) {
            return action.payload
          } else {
            return end_use
          }
        })
        setEndUseInputs(updated_end_use_inputs)
        break
      default:
        try {
          throw new Error('Invalid action type for "handleEndUseInputChange"')
        } catch (e) {
          console.log(e)
        }
        break
    }
  }

  // TODO: save the equipment types list in the userDraftState set it
  // TODO: equipment type list dynamically generated from the equipment_type_end_uses_list

  // File upload functions
  useEffect(() => {
    if (equipmentFiles) {
      populateUploadedFilesTable(equipmentFiles)
    }
  }, [equipmentFiles])

  // updates the equipment object
  function uploadEquipmentFilesHandler(file_blobs_list) {
    // create a new array of objects with the file_blobs_list
    let new_equipment_files_list = file_blobs_list.map((file_blob) => {
      return {
        folder_name: file_blob.folder_name,
        org_folder_name: file_blob.folder_name,
        file_name: file_blob.file_name,
        org_file_name: file_blob.file_name,
        file_path: file_blob.file_path,
        file_size: file_blob.file_size,
        message: file_blob.message,
        status: file_blob.status,
        is_deleted: false,
        uploaded_date: dateTimeFormatter(new Date()),
        file_blob: file_blob.file_blob,
      }
    })
    setEquipmentFiles([...equipmentFiles, ...new_equipment_files_list])
  }

  function populateUploadedFilesTable(files) {
    if (files.length > 0) {
      let generated_file_list = files.map((file) => {
        return {
          folder_name: file.folder_name,
          org_folder_name: file.org_folder_name || file.folder_name,
          file_name: file.file_name,
          org_file_name: file.org_file_name || file.file_name,
          file_path: file.file_path,
          file_size: file.file_size || file.size,
          message: file.message,
          status: file.status || "Uploaded",
          uploaded_date: file.uploaded_date,
          file_blob: file.file_blob,
        }
      })
      setEquipmentFilesTableData(generated_file_list)
    } else {
      setEquipmentFilesTableData([])
    }
  }

  // remove file from equipmentFiles
  function removeEquipmentFileHandler(index) {
    let updated_equipment_files = equipmentFiles.filter(
      (file, file_index) => file_index !== index
    )
    setEquipmentFiles(updated_equipment_files)
  }

  function equipmentFileNameHandler(index, new_name, isFolder) {
    let new_equipment_files = equipmentFiles.map((file, file_index) => {
      if (file_index === index) {
        if (isFolder) {
          file.folder_name = new_name
        } else {
          file.file_name = new_name
        }
      }
      return file
    })
    setEquipmentFiles(new_equipment_files)
  }

  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  function hasRequiredFields() {
    // checking reqruied Inputs if they are filled.
    // If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
  }

  function handleValidation() {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      setOnValidation(true)
    } else {
      setOnValidation(false)
    }
  }

  return (
    <div id='equipment-form' className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>
          {equipmentId === null
            ? "Add Equipment Manually"
            : "Edit Equipment Manually"}
        </p>
      </div>
      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Equipment Details'
          content={
            <>
              <p>
                Follow the survey to provide information about the equipment
                manually.
                <br />
                You have the flexibility to select multiple options for end-uses
                of an equipment.
                <br />
                For example, if a boiler serves both hydronic space heating and
                outdoor air conditioning, include both end-uses for the boiler.
              </p>
              <p>
                <b>
                  If an end-use is not listed in the dropdown, you can add
                  custom end-uses using the "add end-use" button.
                </b>
              </p>
              <br />
              <p>
                Use the "Other" option for equipment end-uses that have either
                negligible energy consumption or there is no measure for them to
                save energy.
                <br />
                The frequency of service per year can be a number less than 1,
                e.g., for an equipment that receives service every other year,
                enter "0.5" for the frequency of service, or "0" if there is no
                scheduled service.
              </p>
            </>
          }
          containerClassName='main-instructions'
        />

        <div>
          <div className='form-title-button__container'>
            <div className='page__subheader'>
              Equipment documents and images
            </div>
            <Button
              type='button'
              className='button  button__large button__large--secondary'
              buttonText='Attach Files'
              onClick={() => file_uploader_modal.current.showModal()}
            />
          </div>
          <InstructionalText
            type='Additional Instructions'
            title='File status : '
            content={
              <>
                When a file is <span className='bold_text'>Attached</span>, it
                is not uploaded to our servers yet. Once you save the draft, we
                then upload the files to our secure cloud servers and the status
                will become <span className='bold_text'>Uploaded</span>.
              </>
            }
          />
          <FileDetailsTable
            table_headers={[
              "folder_name",
              "file_name",
              "file_size",
              "uploaded_date",
              "status",
              "download",
              "delete",
            ]}
            data_list={equipmentFilesTableData}
            set_data_list={setEquipmentFilesTableData}
            fn_remove_row={removeEquipmentFileHandler}
            fn_download_file={handleOnDownloadFile}
            fn_edit_name={equipmentFileNameHandler}
          />
          <FileUploaderModal
            table_headers={[
              "folder_name",
              "file_name",
              "file_size",
              "message",
              "status",
              "delete",
            ]}
            modal_header='Attach Equipment Documents and Images'
            content_title='Attach related equipment documents and images.'
            content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB.
        Our platform supports the following file types: images (.png, .jpg, .jpeg, .heic), documents (.pdf, .docx, .doc) and spreadsheets (.xlsx, .csv). Attachments will be uploaded upon saving.'
            modal_ref={file_uploader_modal}
            uploaded_tableData={equipmentFilesTableData}
            fn_remove_row={removeEquipmentFileHandler}
            set_uploaded_tableData={setEquipmentFilesTableData}
            user_data={userData}
            accepted_file_types={[
              "image/png",
              "image/jpg",
              "image/jpeg",
              "image/heic",
              "application/pdf",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/vnd.ms-excel",
              "text/csv",
            ]}
            fn_upload_handler={uploadEquipmentFilesHandler}
          />
        </div>
        {/* add container to set max height with scroll */}

        <div className='equipment-form__inputs'>
          <div id='equipment-type' className='input_width--3-4'>
            <Select
              label='Equipment Type'
              name='type'
              options={EquipmentTypeList}
              onChange={handleInputChange}
              value={equipmentInputs.type}
              placeholder='Select equipment type'
              className='default'
              required
              disabled={equipmentId !== null}
              // addNewOption={true}
              validation={onValidation}
              set_validation_object={setValidationObject}
            />
          </div>
          <div id='quantity' className='input_width--1-4'>
            <Input
              label='Quantity'
              type='number'
              name='quantity'
              onChange={handleInputChange}
              value={equipmentInputs.quantity}
              placeholder='Enter quantity'
              className='default'
              required
              validation={onValidation}
              set_validation_object={setValidationObject}
              min={1}
            />
          </div>
          <div id='equipment-name' className='input_width--1-3'>
            <Input
              label='Equipment Name'
              type='text'
              name='name'
              onChange={handleInputChange}
              value={equipmentInputs.name}
              placeholder='Enter equipment name'
              className='default'
              required
              validation={onValidation}
              set_validation_object={setValidationObject}
            />
          </div>
          <div id='equipment-description' className='input_width--2-3'>
            <Input
              label='Equipment Description'
              type='text'
              name='description'
              onChange={handleInputChange}
              value={equipmentInputs.description}
              placeholder='Enter equipment description'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div id='installation-year' className='input_width--1-3'>
            <Select
              label='Installation Year'
              name='installation_year'
              options={yearSelectionList}
              onChange={handleInputChange}
              value={
                equipmentInputs.installation_year
                  ? equipmentInputs.installation_year
                  : userDraftState.building_data.original_construction_year
              }
              placeholder='Enter installation year'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>

          <div id='make' className='input_width--1-3'>
            <Input
              label='Make'
              type='text'
              name='make'
              onChange={handleInputChange}
              value={equipmentInputs.make}
              placeholder='Enter make'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div id='model' className='input_width--1-3'>
            <Input
              label='Model'
              type='text'
              name='model'
              onChange={handleInputChange}
              value={equipmentInputs.model}
              placeholder='Enter model'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div id='area-served' className='input_width--1-2'>
            <Select
              label='Area Served'
              name='area_served'
              options={AreaServedList}
              onChange={handleInputChange}
              value={equipmentInputs.area_served}
              placeholder='Select area served'
              className='default'
              addNewOption={true}
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div id='location' className='input_width--1-2'>
            <Input
              label='Location'
              type='text'
              name='location'
              onChange={handleInputChange}
              value={equipmentInputs.location}
              placeholder='Enter location'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div id='frequency-of-service-per-year' className='input_width--1-3'>
            <Input
              label='Frequency of Service per Year'
              type='number'
              name='frequency_of_service_per_year'
              onChange={handleInputChange}
              value={equipmentInputs.frequency_of_service_per_year}
              placeholder='Enter 0.5 for one service per 2 years'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div
            id='comments-on-service-maintenance-procedure'
            className='input_width--2-3'
          >
            <Input
              label='Comments on Service/Maintenance Procedure'
              type='text'
              name='service_maintenance_procedure_comments'
              onChange={handleInputChange}
              value={equipmentInputs.service_maintenance_procedure_comments}
              placeholder='Enter comments on service/maintenance procedure'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
            />
          </div>
          <div id='end-use' className='input_width--full'>
            <SimpleModal
              modal_ref={enduse_modal_ref}
              content_title='Add End-use'
              content_body='Enter the name and select the type of the end-use.'
              btn_confirm_primary='Add End-use'
              btn_cancel='Cancel'
              on_validation={true}
              inner_components={
                // input and select components linked to newEndUse object
                <>
                  <Input
                    label='Name'
                    type='text'
                    name='end_use_name'
                    onChange={handleNewEndUseInputChange}
                    value={newEndUse.end_use_name}
                    placeholder='Enter end-use name'
                    className='default'
                    required
                    validation={true}
                    set_validation_object={setNewEndUseValidationObject}
                  />
                  <Select
                    label='Type'
                    name='end_use_type'
                    options={["General", "Heating", "Cooling", "Pumps", "Fans"]}
                    onChange={handleNewEndUseInputChange}
                    value={newEndUse.end_use_type}
                    placeholder='Select end-use type'
                    className='default'
                    required
                    validation={true}
                    set_validation_object={setNewEndUseValidationObject}
                  />
                </>
              }
              fn_confirm_primary={() => {
                // check the end_use_name and end_use_type
                // if valid, add to the endUseCheckBoxList
                // if invalid, show error message
                let has_required_fields = hasRequiredFields(
                  newEndUseValidationObject
                )
                if (has_required_fields) {
                  let end_use_label = newEndUse.end_use_name
                  let end_use_name_formatted = newEndUse.end_use_name
                    .replaceAll(" ", "_")
                    .toLowerCase()
                  let end_use_type_formatted =
                    newEndUse.end_use_type.toLowerCase()
                  let new_end_use_checkbox_list = [
                    ...endUseCheckBoxList,
                    {
                      label: end_use_label,
                      name: end_use_name_formatted,
                      type: end_use_type_formatted,
                      checked: true,
                    },
                  ]

                  setEndUseCheckBoxList(new_end_use_checkbox_list)
                  setNewEndUse({
                    end_use_name: "",
                    end_use_type: "",
                  })
                  return true
                } else {
                  return false
                }
              }}
            />
            <CheckboxGroup
              legend='End-use'
              values={endUseCheckBoxList}
              canAddAdditional={true}
              addAdditionalText='Add Additional End-use'
              fn_openDialog={handleOpenDialog}
              fn_onChange={handleCheckBoxOnChange}
            />
          </div>
          <div id='electrical-power-requirement' className='input_width--full'>
            <div className='electrical-power-requirement-title'>
              <label className='input__label input__lable--default'>
                Electrical Power Requirement
              </label>
              <hr />
            </div>
            <div className='content'>
              <Input
                label='Current'
                type='number'
                name='electrical_power_current'
                onChange={handleInputChange}
                value={equipmentInputs.electrical_power_current}
                placeholder='Enter current (amps)'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
              <Input
                label='Voltage'
                type='number'
                name='electrical_power_voltage'
                onChange={handleInputChange}
                value={equipmentInputs.electrical_power_voltage}
                placeholder='Enter voltage (V)'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
              <RadioGroup
                legend='Single-Phase or 3-Phase?'
                name='single_phase_or_three_phase'
                radioOptions={[
                  {
                    value: "Single-Phase",
                    label: "Single-Phase",
                  },
                  {
                    value: "3-Phase",
                    label: "3-Phase",
                  },
                ]}
                onChange={handleInputChange}
                value={equipmentInputs.single_phase_or_three_phase}
              />
            </div>
          </div>
          <div id='general-specifications' className='input_width--full'>
            <hr />
            <InstructionalText
              type='Main Instructions'
              title='General Specifications'
              content={
                <>
                  In this section, please provide details regarding the power,
                  capacity, and control strategy of all equipment,{" "}
                  <b>
                    excluding those associated with heating, cooling, and
                    domestic hot water.
                  </b>{" "}
                  Equipment related to heating should be documented in the
                  "Heating" section, while equipment related to cooling should
                  be documented in the "Cooling" section, or both, if
                  applicable.
                </>
              }
              containerClassName='main-instructions'
            />
            <div className='content'>
              <RadioGroup
                legend='Please specify the rating for Input Power or Output Capacity'
                name='power_or_capacity'
                radioOptions={[
                  {
                    value: "Input Power",
                    label: "Input Power",
                  },
                  {
                    value: "Capacity",
                    label: "Capacity",
                  },
                ]}
                onChange={handleGeneralInputChange}
                value={generalSpecInputs.power_or_capacity}
              />
              <Input
                label='Rating'
                type='number'
                name='rating'
                onChange={handleGeneralInputChange}
                value={generalSpecInputs.rating}
                placeholder='Enter rating'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
              <Select
                label='Unit'
                name='unit'
                options={PowerCapacityUnitsList}
                onChange={handleGeneralInputChange}
                value={generalSpecInputs.unit}
                placeholder='Select unit'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
              <Select
                label='Meter'
                name='meter'
                options={propertyMetersList}
                onChange={handleGeneralInputChange}
                value={generalSpecInputs.meter}
                placeholder='Select meter'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
            <div className='content'>
              <Select
                label='Control Type'
                name='control_type'
                options={ControlTypeList}
                onChange={handleGeneralInputChange}
                value={generalSpecInputs.control_type}
                placeholder='Select control type'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
              <Input
                label='Average Annual Hours of Operation'
                type='number'
                name='average_annual_hours_of_operation'
                onChange={handleGeneralInputChange}
                value={generalSpecInputs.average_annual_hours_of_operation}
                placeholder='(0 to 8760 hrs/yr)'
                className='default'
                // validation={onValidation}
                // set_validation_object={setValidationObject}
              />
            </div>
            <div className='content'>
              <div className='general-comments'>
                <Textarea
                  label='General Comments'
                  type='text'
                  name='general_comments'
                  onChange={handleGeneralInputChange}
                  value={generalSpecInputs.general_comments}
                  placeholder='Enter your general comments here, e.g., Comments on control...'
                  className='default'
                  // validation={onValidation}
                  // set_validation_object={setValidationObject}
                />
              </div>
            </div>
          </div>
          <div className='end-uses-content input_width--full'>
            {generatedEndUseComponents.map((end_use_component, index) => {
              return <Fragment key={index}>{end_use_component}</Fragment>
            })}
          </div>
          <div className='form__buttons input_width--full'>
            <Button
              type='button'
              buttonText='Cancel'
              className='button__large button__large--secondary'
              onClick={handleCancel}
            />
            <Button
              type='button'
              buttonText={
                equipmentId === null ? "Add Equipment" : "Save Equipment"
              }
              className='button__large button__large--primary'
              onClick={
                equipmentId === null ? handleAddEquipment : handleSaveEquipment
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default EquipmentForm
