import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/table.css';
import '../../../styles/index.css';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

/**
 * Takes in an API endpoint on the backend as a string as a prop
 * ALso takes an array of headers as a prop
 */
function ManageRatesTable(props) {
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState('0DSC');
  const editRate = (details) => {
    props.onEdit(details[0]);
  };
  const deleteRate = (details) => {
    props.onDelete(details[0]);
  };

  useEffect(() => {
    let vals = props.data;
    for (let i = 0; i < vals.length; i++) {
      if (Array.isArray(vals[i])) {
        for (let j = 0; j < vals[i].length; j++) {
          if (vals[i][j] == 'NaN') {
            vals[i][j] = '-';
          }
        }
      }
    }
    setTableData(props.data);
  }, [props.data]);

  //generate the table data to columns
  const generateTableData = () => {
    return tableData.map((building) => (
      <tr key={building[0]} className='row'>
        {building.map((data, index) => {
          if (props.skipFirstCol === false) {
            if (!isNaN(data)) {
              if (props.addLocale === true) {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle'
                  >
                    {parseFloat(data).toLocaleString('en-us')}
                  </td>
                );
              } else {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle'
                  >
                    {data}
                  </td>
                );
              }
            } else {
              return (
                <td key={index} className='columnItem align-middle'>
                  {data}
                </td>
              );
            }
          } else {
            if (index !== 0) {
              if (!isNaN(data)) {
                if (props.addLocale === true) {
                  return (
                    <td
                      key={index}
                      className='columnItem align-middle'
                    >
                      {parseFloat(data).toLocaleString('en-US')}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={index}
                      className='columnItem align-middle'
                    >
                      {data}
                    </td>
                  );
                }
              } else {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle'
                  >
                    {data}
                  </td>
                );
              }
            }
          }
        })}
        <td className='text-center'>
          <IconButton
            style={{ width: '35px', height: '40px', color: '#03045e' }}
            onClick={() => {
              editRate(building);
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              style={{ width: '30px', height: '30px' }}
              fill='currentColor'
              viewBox='0 0 1024 1024'
            >
              <path d='M834.3 705.7c0 82.2-66.8 149-149 149H325.9c-82.2 0-149-66.8-149-149V346.4c0-82.2 66.8-149 149-149h129.8v-42.7H325.9c-105.7 0-191.7 86-191.7 191.7v359.3c0 105.7 86 191.7 191.7 191.7h359.3c105.7 0 191.7-86 191.7-191.7V575.9h-42.7v129.8z' />
              <path d='M889.7 163.4c-22.9-22.9-53-34.4-83.1-34.4s-60.1 11.5-83.1 34.4L312 574.9c-16.9 16.9-27.9 38.8-31.2 62.5l-19 132.8c-1.6 11.4 7.3 21.3 18.4 21.3 0.9 0 1.8-0.1 2.7-0.2l132.8-19c23.7-3.4 45.6-14.3 62.5-31.2l411.5-411.5c45.9-45.9 45.9-120.3 0-166.2zM362 585.3L710.3 237 816 342.8 467.8 691.1 362 585.3zM409.7 730l-101.1 14.4L323 643.3c1.4-9.5 4.8-18.7 9.9-26.7L436.3 720c-8 5.2-17.1 8.7-26.6 10z m449.8-430.7l-13.3 13.3-105.7-105.8 13.3-13.3c14.1-14.1 32.9-21.9 52.9-21.9s38.8 7.8 52.9 21.9c29.1 29.2 29.1 76.7-0.1 105.8z' />
            </svg>
          </IconButton>
          <IconButton
            style={{
              width: '35px',
              color: 'rgb(239 68 68 / var(--tw-bg-opacity))',
            }}
            onClick={() => {
              deleteRate(building);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </td>
      </tr>
    ));
  };

  //sort by columns function, takes column index and state to determine which column to sort
  const sorting = (col) => {
    if (props.skipFirstCol === false) {
      if (order.substring(1) === 'ASC') {
        const sorted = [...tableData].sort((a, b) =>
          a[col].localeCompare(b[col], 'en', {
            numeric: true,
            sensitivity: 'base',
          })
        );
        setTableData(sorted);
        setOrder(col + 'DSC');
      }

      if (order.substring(1) === 'DSC') {
        const sorted = [...tableData].sort((a, b) =>
          b[col].localeCompare(a[col], 'en', {
            numeric: true,
            sensitivity: 'base',
          })
        );
        setTableData(sorted);
        setOrder(col + 'ASC');
      }
    } else {
      col = col + 1;
      if (order.substring(1) === 'ASC') {
        const sorted = [...tableData].sort((a, b) =>
          a[col].localeCompare(b[col], 'en', {
            numeric: true,
            sensitivity: 'base',
          })
        );
        setTableData(sorted);
        setOrder(col - 1 + 'DSC');
      }

      if (order.substring(1) === 'DSC') {
        const sorted = [...tableData].sort((a, b) =>
          b[col].localeCompare(a[col], 'en', {
            numeric: true,
            sensitivity: 'base',
          })
        );
        setTableData(sorted);
        setOrder(col - 1 + 'ASC');
      }
    }
  };
  return (
    <div className='overflow-y-auto h-full'>
      <Button
        style={{
          margin: '1rem',
          maxWidth: '50%',
          backgroundColor: '#03045e',
          color: 'var(--white)',
          width: 'auto',
        }}
        component={Link}
        to={props.addButtonLink}
      >
        {props.addButtonText}
      </Button>
      <div className='m-4 mt-0'>
        <table id='portfolio' className='rounded-lg text-sm w-full table-fixed'>
          <link
            href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined'
            rel='stylesheet'
          />
          <thead>
            <tr>
              {props.headers.map((value, index) => {
                if (order === index + 'ASC') {
                  return (
                    <th key={index} className='sticky top-0 '>
                      <div className='flex justify-between items-center '>
                        {value}
                        <span
                          className='material-symbols-outlined'
                          onClick={() => sorting(index)}
                        >
                          expand_more
                        </span>
                      </div>
                    </th>
                  );
                }
                return (
                  <th className='sticky top-0' key={index}>
                    <div className='flex justify-between items-center '>
                      {value}
                      <span
                        className='material-symbols-outlined'
                        onClick={() => sorting(index)}
                      >
                        expand_less
                      </span>
                    </div>
                  </th>
                );
              })}
              <th style={{ textAlign: 'center' }}>Action</th>
            </tr>
          </thead>
          <tbody className='mt-12'>{generateTableData()}</tbody>
        </table>
      </div>
    </div>
  );
}

ManageRatesTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default ManageRatesTable;
