import React, { useEffect, useState } from "react"
import { AddNewBuildingComponent } from "./components/AddNewBuildingComponent"
import { DisplayTableComponent } from "./components/DisplayTableComponent"
import LoadingSpinner from "../../global/LoadingSpinner"
import "../../../styles/adminBuildings/adminBuildingsIndex.css"

export const BuildingsPage = (props) => {
  const [editBuildingMode, setEditBuildingMode] = useState(true)
  const [addNewBuildingMode, setAddNewBuildingMode] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    all_projects: [],
    user_email_and_account_number: ["No data to show"],
  })

  useEffect(() => {
    /* on load, turn on spinner awaiting getData response, then turn off spinner when data is set */
    setIsLoading(true)
    if (props.data) {
      setData(props.data)
      setIsLoading(false)
    }
  }, [props])

  // temporary fix for the issue of the data not being updated on building page after adding a new building.
  // [TODO] Find a better way to update the building list
  const handleEditBuildingMode = async () => {
    setEditBuildingMode(true)
    setAddNewBuildingMode(false)
    props.clearCacheData()
    await props.getUserData()
  }

  const handleAddNewBuildingMode = () => {
    setAddNewBuildingMode(true)
    setEditBuildingMode(false)
  }

  return (
    <div className='mb-16 mt-9 drop-shadow-md rounded-lg buildingBox'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='btnContainer'>
          <button
            className={
              editBuildingMode ? "editOrAddBtnActive" : "editOrAddBtnInactive"
            }
            onClick={handleEditBuildingMode}
          >
            Edit Building
          </button>
          <button
            className={
              addNewBuildingMode ? "editOrAddBtnActive" : "editOrAddBtnInactive"
            }
            onClick={handleAddNewBuildingMode}
          >
            Add Building
          </button>
        </div>
      )}

      {editBuildingMode ? (
        <DisplayTableComponent data={data} />
      ) : (
        <AddNewBuildingComponent
          data={data.user_email_and_account_number}
          getUserData={props.getUserData}
          clearCacheData={props.clearCacheData}
        />
      )}
    </div>
  )
}
