import React, { useEffect, useState } from "react"
import Table from "./Table"
import LoadingSpinner from "../global/LoadingSpinner"
import NoBuildingsDiv from "../global/NoBuildingDiv"
import "../../styles/home.css"
import { removeHomeProjects, updateHomeProjects } from "../../utils/HttpClient"

/**
 * Home page is similar to portfolio page layout. Main body should be flex column with height to 100%.
 * Each graph/chart is enclosed in a parent flex div with padding, drop shadow, and rounded edges.
 * PieChart specifically, takes className styles as a prop instead of changed inside of the component. This is because different developers worked on it . . .
 */

export const Home = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    piechart_home_gas: ["No data to show"],
    piechart_home_electric: ["No data to show"],
    table_data: [],
  })

  useEffect(() => {
    /* on load, turn on spinner awaiting getData response, then turn off spinner when data is set */
    setIsLoading(true)
    if (props.data) {
      setData(props.data)
      setIsLoading(false)
    }
  }, [props])
  /* Remove row from table and get new user data */
  async function removeBuildingHandler(project_numbers) {
    return new Promise((resolve, reject) => {
      let account_number = props.user_data.account_number
      removeHomeProjects({
        account_numbers: [account_number],
        project_numbers: project_numbers,
      })
        .then((res) => {
          if (res) {
            resolve({ error: false, message: "Successfully Removed" })
          } else {
            reject({ error: true, message: "Server Error" })
          }
        })
        .catch((err) => {
          console.error(err)
          reject({ error: true, message: "Server Error" })
        })
    })
  }

  /**
   * Update building data in database using existing project number and new pdf report
   */
  async function updateBuildingHandler(project_numbers, files) {
    return new Promise((resolve, reject) => {
      let form_data = new FormData()
      form_data.append("project_numbers", project_numbers)
      for (let file of files) {
        form_data.append("pdf_reports", file, file.name)
      }
      updateHomeProjects(form_data)
        .then((res) => {
          if (res) {
            if (res.failed_reports.length > 0) {
              reject({ error: true, message: res.failed_reports[0].error })
            }
            if (res.successful_reports.length > 0) {
              let prev_new_projects_list = JSON.parse(
                localStorage.getItem("new_projects_list")
              )
              if (prev_new_projects_list) {
                localStorage.setItem(
                  "new_projects_list",
                  JSON.stringify([
                    ...prev_new_projects_list,
                    res.successful_reports[0].project_number,
                  ])
                )
              } else {
                localStorage.setItem(
                  "new_projects_list",
                  JSON.stringify([res.successful_reports[0].project_number])
                )
              }
              resolve({ error: false, message: "Successfully Updated" })
            }
          } else {
            reject({ error: true, message: "Server Error" })
          }
        })
        .catch((err) => {
          console.error(err)
          reject({ error: true, message: "Server Error" })
        })
    })
  }

  /* Conditionally render hoomepage components based on presence of building data */
  function HomePageContainer() {
    return (
      <div id='home-container' className=' flex flex-col gap-10 '>
        {/* <div className='bg-white flex flex-col rounded drop-shadow-md p-5'> */}
        <Table
          id='grid-5'
          dataset={data.table_data}
          title={"Properties"}
          filename={"your_properties.csv"}
          headers={[
            "Site Assessment Report",
            "EUI",
            "GHGI",
            "Annual GHG Emission",
            "Identified GHG Saving",
            "Decarbonization Plan",
            "Delete Building",
          ]}
          fn_remove_building={removeBuildingHandler}
          fn_update_building={updateBuildingHandler}
          get_user_data={props.get_user_data}
        />
        {/* </div> */}
      </div>
    )
  }

  function Display() {
    // const [scrollTop, setScrollTop] = useState(0)

    // window.addEventListener("scroll", function () {
    //   setScrollTop(this.window.scrollY)
    // })
    return (
      <div>
        {data["table_data"].length !== 0 ? (
          <HomePageContainer />
        ) : (
          <NoBuildingsDiv />
        )}
        {/* <a
          href='#home-container'
          className={`${scrollTop >= 300 ? "go_top" : "go_top fade-out"}`}
        >
          <i className='fa-sharp fa-solid fa-arrow-up'></i>
        </a> */}
      </div>
    )
  }

  return (
    <div className='mb-16 home-component'>
      <div className='title'>
        <h1 className='section_heading_1'>Assessments</h1>
        <div className='flex flex-row gap-5'></div>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      {isLoading ? <LoadingSpinner /> : <Display />}
    </div>
  )
}
