import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAllProjectsButUser,
  getAllUserProjects,
  addUserProjects,
  removeUserProjects,
} from '../../../../utils/HttpClient';
import UserDetails from './UserDetails';
import UserProjects from './UserProjects';
import LoadingSpinner from '../../../global/LoadingSpinner';

function UserProjectsPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userProjects, setUserProjects] = useState([]);
  const [allProjectsButUser, setAllProjectsButUser] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.user_data) {
      setUserDetails(state.user_data);
      getAllUserProjects(state.user_data.account_number)
        .then((res) => {
          setUserProjects(res);
        })
        .catch((err) => {
          console.error(err);
        });
      getAllProjectsButUser(state.user_data.account_number)
        .then((res) => {
          setAllProjectsButUser(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [state.user_data, refreshTable]);

  function addUserProjectHandler(project_numbers) {
    addUserProjects({
      account_numbers: [state.user_data.account_number],
      project_numbers: project_numbers,
    })
      .then(() => {
        props.getUserData();
      })
      .then(() => {
        setIsLoading(false);
        setRefreshTable(!refreshTable);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function removeUserProjectHandler(project_numbers) {
    removeUserProjects({
      account_numbers: [state.user_data.account_number],
      project_numbers: project_numbers,
    })
      .then(() => {
        props.getUserData();
      })
      .then(() => {
        setIsLoading(false);
        setRefreshTable(!refreshTable);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className='mt-7 mb-16'>
      <div className='bg-white flex flex-col rounded drop-shadow-md p-5 h-fit'>
        <div className='bg-sisa-header w-full flex justify-between'>
          <p className='text-xl text-white p-2 m-2'>Manage User Projects</p>
          <button className='button-action-white' onClick={() => navigate(-1)}>
            back
          </button>
        </div>
        <div className='h-full flex flex-col gap-10'>
          <div className='bg-white flex flex-col drop-shadow-md p-5'>
            <UserDetails user_data={userDetails} />
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <UserProjects
                user_projects={userProjects}
                all_projects_but_user={allProjectsButUser}
                add_user_project={addUserProjectHandler}
                remove_user_project={removeUserProjectHandler}
                is_loading={isLoading}
                set_is_loading={setIsLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProjectsPage;
