import '../../../styles/analysisTools/stat-card.css';

function StatCard({
  title_text,
  units_text,
  value_text,
  top_bg_color,
  top_text_color,
  bot_bg_color,
  bot_text_color,
  width,
  height,
  margin,
  padding,
}) {
  /**
   * create classes for custom card
   *
   * widths - auto / 25% / 33% / 50% / 75% / 100%
   * heights - auto / 25% / 33% / 50% / 75% / 100%
   * bg_colors - any color
   * text_colors - any color
   * text_size - sm / md / lg
   * margins - 0 / 5px / 10px
   * paddings - 0 / 5px / 10px
   */

  return (
    <div className='card-container' style={{ width: width }}>
      <div className='card-top' style={{ backgroundColor: top_bg_color }}>
        <p className='card-title' style={{ color: top_text_color }}>
          {title_text}
        </p>
        <p className='card-units' style={{ color: top_text_color }}>
          {units_text}
        </p>
      </div>
      <div className='card-bot' style={{ backgroundColor: bot_bg_color }}>
        <p className='card-value' style={{ color: bot_text_color }}>
          {value_text}
        </p>
      </div>
    </div>
  );
}

export default StatCard;
