import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

export const WorksheetConverter = (file) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      setData(XLSX.utils.sheet_to_json(worksheet));
    };
    reader.readAsArrayBuffer(file);
  }, [file]);

  return data;
};
