export const CoolingTypeList = [
  "Air Source",
  "Water Source",
  "Heat-recovery chillers",
  "Hydronic",
  "N/A",
]

export const HeatingUnitTypeList = [
  "Atmospheric",
  "Heat pump - Air Source",
  "Condensing",
  "Electric Resistance",
  "Furnace",
  "Non-condensing",
  "Heat pump - Water Source",
  "Hydronic",
  "N/A",
]

export const ControlTypeList = [
  "Manual",
  "On-demand",
  "Scheduled On/Off",
  "Scheduled Modulating",
  "N/A",
]

export const ControlTypeHeatingCoolingList = [
  "Manual",
  "On-demand",
  "OA reset",
  "Scheduled On/Off",
  "Scheduled Modulating",
  "Smart Thermostat",
  "Temperature reset",
  "Traditional thermostat",
  "N/A",
]

export const AreaServedList = ["Common", "Exterior", "Parking", "Suites"]

export const EquipmentTypeList = [
  "Air Handler",
  "Boiler",
  "Condenser Unit",
  "Cooling Tower",
  "Domestic Hot Water Boiler",
  "Domestic Hot Water Heat Pump",
  "Domestic Hot Water Heat Exchanger",
  "Domestic Hot Water Storage Tank",
  "Dryer",
  "Electric Baseboard",
  "Electric Heater",
  "Fan",
  "Fancoil",
  "Fireplace",
  "Floor Heating",
  "Furnace",
  "Gas Range/Oven",
  "Heat Exchanger",
  "Heat Pump",
  "Make-up Air Unit",
  "Plug Load",
  "Pump",
  "Radiant Heating",
  "Refrigeration",
  "Rooftop Unit",
  "Split Cooling",
  "Washer (front-load)",
  "Washer (top-load)",
  "Walk-in Fridge",
  "Walk-in Freezer",
  "Other",
]

export const PowerCapacityUnitsList = [
  "kW",
  "HP",
  "W/m2",
  "W",
  "Ton",
  "btu/hr",
  "MBH",
  "Gal",
]

export const EndUseCheckBoxList = [
  {
    label: "Space Heating",
    name: "space_heating",
    type: "heating",
    checked: false,
  },
  {
    label: "Space Cooling",
    name: "space_cooling",
    type: "cooling",
    checked: false,
  },
  {
    label: "Domestic Hot Water",
    name: "domestic_hot_water",
    type: "heating",
    checked: false,
  },
  {
    label: "Fans",
    name: "fans",
    type: "fans",
    checked: false,
  },
  {
    label: "Laundry",
    name: "laundry",
    type: "general",
    checked: false,
  },
  {
    label: "Lighting",
    name: "lighting",
    type: "general",
    checked: false,
  },
  {
    label: "Pumps",
    name: "pumps",
    type: "pumps",
    checked: false,
  },
  {
    label: "Outdoor Air Cooling",
    name: "outdoor_air_cooling",
    type: "cooling",
    checked: false,
  },
  {
    label: "Outdoor Air Heating",
    name: "outdoor_air_heating",
    type: "heating",
    checked: false,
  },
  {
    label: "Water",
    name: "water",
    type: "general",
    checked: false,
  },
  {
    label: "Other",
    name: "other",
    type: "general",
    checked: false,
  },
]

export const EquipmentTypeEndUsesList = [
  {
    equipment_type: "Air Handler",
    end_uses: ["Fans"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Boiler",
    end_uses: ["Space Heating"],
    default_meter_type: "gas",
    avg_efficiency_cop: 0.85,
  },
  {
    equipment_type: "Condenser Unit",
    end_uses: ["Space Heating", "Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 3,
  },
  {
    equipment_type: "Cooling Tower",
    end_uses: ["Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Domestic Hot Water Boiler",
    end_uses: ["Domestic Hot Water"],
    default_meter_type: "gas",
    avg_efficiency_cop: 0.85,
  },
  {
    equipment_type: "Domestic Hot Water Heat Pump",
    end_uses: ["Domestic Hot Water"],
    default_meter_type: "electric",
    avg_efficiency_cop: 3,
  },
  {
    equipment_type: "Domestic Hot Water Heat Exchanger",
    end_uses: ["Domestic Hot Water"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Domestic Hot Water Storage Tank",
    end_uses: ["Domestic Hot Water"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Dryer",
    end_uses: ["Laundry"],
    default_meter_type: "gas",
    avg_efficiency_cop: 0.85,
  },
  {
    equipment_type: "Electric Baseboard",
    end_uses: ["Space Heating"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Electric Heater",
    end_uses: ["Space Heating"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Fan",
    end_uses: ["Fans"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Fancoil",
    end_uses: ["Space Heating", "Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Fireplace",
    end_uses: ["Space Heating"],
    default_meter_type: "gas",
    avg_efficiency_cop: 0.85,
  },
  {
    equipment_type: "Floor Heating",
    end_uses: ["Space Heating"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Furnace",
    end_uses: ["Space Heating"],
    default_meter_type: "gas",
    avg_efficiency_cop: 0.85,
  },
  {
    equipment_type: "Gas Range/Oven",
    end_uses: ["Other"],
    default_meter_type: "gas",
    avg_efficiency_cop: 0.85,
  },
  {
    equipment_type: "Heat Exchanger",
    end_uses: ["Space Heating", "Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Heat Pump",
    end_uses: ["Space Heating", "Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 3,
  },
  {
    equipment_type: "Make-up Air Unit",
    end_uses: ["Space Heating", "Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Plug Load",
    end_uses: ["Other"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Pump",
    end_uses: ["Pumps"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Radiant Heating",
    end_uses: ["Space Heating"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Refrigeration",
    end_uses: ["Other"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Rooftop Unit",
    end_uses: ["Space Heating", "Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Split Cooling",
    end_uses: ["Space Cooling"],
    default_meter_type: "electric",
    avg_efficiency_cop: 3,
  },
  {
    equipment_type: "Washer (front-load)",
    end_uses: ["Laundry"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Washer (top-load)",
    end_uses: ["Laundry"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Walk-in Fridge",
    end_uses: ["Other"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Walk-in Freezer",
    end_uses: ["Other"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
  {
    equipment_type: "Other",
    end_uses: ["Other"],
    default_meter_type: "electric",
    avg_efficiency_cop: 1,
  },
]

let equipment = {
  type: "Boiler",
  quantity: 1,
  name: "Boiler-1",
  description: "",
  installation_year: "",
  make: "",
  model: "",
  area_served: "",
  location: "",
  frequency_of_service_per_year: "",
  service_maintenance_procedure_comments: "",
  electrical_power_current: "",
  electrical_power_voltage: "",
  single_phase_or_three_phase: "3-Phase",
  general_specifications: {
    power_or_capacity: "Power",
    rating: "",
    unit: "",
    meter: "",
    control_type: "",
    average_annual_hours_of_operation: "",
    general_comments: "",
  },
  end_uses: [
    {
      end_use_name: "Space Heating",
      end_use_type: "heating",
      heating_unit_type: "",
      primary_or_secondary_unit: "Primary",
      average_efficiency_or_cop: "",
      capacity_rating: "",
      unit: "",
      meter: "",
      control_type: "",
      average_annual_hours_of_operation: "",
      unit_has_backup: "No",
      backup_heating_unit_type: "",
      backup_efficiency: "",
      backup_meter: "",
      backup_control_comment: "",
      unit_has_heat_recovery: "No",
      heat_recovery_efficiency: "",
      heat_recovery_comment: "",
      general_comments: "",
    },
  ],
  equipment_images_list: [],
}

export const EndUseDefaultInputsList = [
  {
    end_use_name: "",
    end_use_type: "heating",
    heating_unit_type: "",
    primary_or_secondary_unit: "Primary",
    average_efficiency_or_cop: "",
    capacity_rating: "",
    unit: "",
    meter: "",
    control_type: "",
    average_annual_hours_of_operation: "",
    unit_has_backup: "No",
    backup_heating_unit_type: "",
    backup_efficiency: "",
    backup_meter: "",
    backup_control_comment: "",
    unit_has_heat_recovery: "No",
    heat_recovery_efficiency: "",
    heat_recovery_comment: "",
    general_comments: "",
  },
  {
    end_use_name: "",
    end_use_type: "cooling",
    cooling_unit_type: "",
    primary_or_secondary_unit: "Primary",
    capacity_rating: "",
    unit: "",
    meter: "",
    average_efficiency_or_cop: "",
    control_type: "",
    average_annual_hours_of_operation: "",
    general_comments: "",
  },
  {
    end_use_name: "",
    end_use_type: "pumps",
    water_flow: "",
    variable_speed_or_frequency: "No",
    variable_speed_or_frequency_comment: "",
  },
  {
    end_use_name: "",
    end_use_type: "fans",
    air_flow: "",
    outside_air_percentage: "",
    variable_speed_or_frequency: "No",
    variable_speed_or_frequency_comment: "",
  },
]
