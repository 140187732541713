import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faMinus } from "@fortawesome/free-solid-svg-icons"
import "./checkbox.css"

const CheckBox = ({
  label,
  inputLabel,
  name,
  checked,
  onChange,
  disabled,
  indeterminate,
  invalid,
}) => {
  const [value, setValue] = useState(false)

  useEffect(() => {
    setValue(checked)
  }, [checked])

  const handleOnChange = (e) => {
    // setValue(e.target.checked)
    onChange(e.target)
  }

  return (
    <div
      className={`checkbox ${disabled ? "disabled" : ""} ${
        invalid ? "invalid" : ""
      }`}
    >
      {inputLabel && (
        <label
          htmlFor={name}
          className={`input__label ${
            disabled ? "input__label--disabled" : "input__label--default"
          }`}
        >
          {inputLabel}
        </label>
      )}
      <input
        id={`checkbox-${name}`}
        type='checkbox'
        className={`sr-only ${invalid ? "invalid" : ""}`}
        checked={value}
        onChange={handleOnChange}
        disabled={disabled}
        name={name}
        aria-checked={value ? "true" : "false"}
        aria-labelledby={`label-${name} span-${name}`}
      />
      <label
        className={`checkbox__label${disabled ? "--disabled" : ""}`}
        htmlFor={`checkbox-${name}`}
        id={`label-${name}`}
      >
        <span
          className={`checkbox__icon ${disabled ? "disabled" : ""} ${
            value ? "checked" : ""
          } `}
          id={`span-${name}`}
          aria-hidden='true'
        >
          {value && !indeterminate && (
            <FontAwesomeIcon icon={faCheck} style={{ color: "#F9F9F9" }} />
          )}
          {indeterminate && (
            <FontAwesomeIcon icon={faMinus} style={{ color: "#F9F9F9" }} />
          )}
        </span>
        {label}
      </label>
    </div>
  )
}
export default CheckBox
