import LoadingSpinner from "../../../global/LoadingSpinner"
import UserProjectsTable from "./ProjectUsersTable"
import { useState, useRef } from "react"
import ModalDialog from "../../../home/components/ModalDialog"

function ProjectUsers({
  project_users,
  all_users_but_project,
  add_user_project,
  remove_user_project,
  is_loading,
  set_is_loading,
}) {
  const header_names = ["account_number", "user_name", "user_role", "select"]

  const [selectedUsersAdd, setSelectedUsersAdd] = useState({})
  const [selectedUsersDelete, setSelectedUsersDelete] = useState({})

  const [isRemoveButtonDisabled, setIsRemoveButtonDisabled] = useState(true)
  const modal_ref = useRef(null)

  function handleOpen() {
    modal_ref.current.showModal()
  }

  function onClickSelectUserAdd(account_number) {
    if (selectedUsersAdd[account_number]) {
      setSelectedUsersAdd({ ...selectedUsersAdd, [account_number]: false })
    } else {
      setSelectedUsersAdd({ ...selectedUsersAdd, [account_number]: true })
    }
  }

  function onClickSelectUserDelete(account_number) {
    const updatedSelectedUsersDelete = {
      ...selectedUsersDelete,
      [account_number]: !selectedUsersDelete[account_number],
    }

    setSelectedUsersDelete(updatedSelectedUsersDelete)

    const isDisabled = Object.values(updatedSelectedUsersDelete).every(
      (isSelected) => !isSelected
    )
    setIsRemoveButtonDisabled(isDisabled)
  }

  function onClickAddQuery(e) {
    let account_numbers = []

    if (Object.keys(selectedUsersAdd).length > 0) {
      set_is_loading(true)

      for (let account_number in selectedUsersAdd) {
        if (selectedUsersAdd[account_number]) {
          account_numbers.push(account_number)
        }
      }
      add_user_project(account_numbers)
      setSelectedUsersAdd({})
    }
  }

  function onClickDeleteQuery(e) {
    let account_numbers = []

    if (Object.keys(selectedUsersDelete).length > 0) {
      set_is_loading(true)

      for (let account_number in selectedUsersDelete) {
        if (selectedUsersDelete[account_number]) {
          account_numbers.push(account_number)
        }
      }
      remove_user_project(account_numbers)
      setSelectedUsersDelete({})
    }
  }

  return (
    <div className='projects-container bg-white rounded drop-shadow-md'>
      {is_loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div id='user_projects'>
            <div className='bg-sisa-header w-full'>
              <p className='text-md text-white p-2 m-2'>Project Users</p>
            </div>
            <UserProjectsTable
              list_of_data={project_users}
              header_names={header_names}
              selected_accounts={selectedUsersDelete}
              set_selected_accounts={setSelectedUsersDelete}
              onClick_function={onClickSelectUserDelete}
            />
            <div className='table-button-container'>
              <button
                className='button-action'
                onClick={handleOpen}
                disabled={isRemoveButtonDisabled}
              >
                remove from project
              </button>
              <ModalDialog
                content_title={"Delete User?"}
                content_body={
                  <>
                    Deleting the user(s) will result in the loss of all saved
                    analysis data associated with the user(s). <br />
                    Are you sure you want to delete the user(s)?
                  </>
                }
                btn_confirm={"Delete User"}
                btn_cancel={"Cancel"}
                btn_close={true}
                fn_confirm={onClickDeleteQuery}
                modal_ref={modal_ref}
              />
            </div>
          </div>

          <div id='all_projects'>
            <div className='bg-sisa-header w-full'>
              <p className='text-md text-white p-2 m-2'>All Users</p>
            </div>
            <UserProjectsTable
              list_of_data={all_users_but_project}
              header_names={header_names}
              selected_accounts={selectedUsersAdd}
              set_selected_accounts={setSelectedUsersAdd}
              onClick_function={onClickSelectUserAdd}
            />
            <div className='table-button-container'>
              <button className='button-action' onClick={onClickAddQuery}>
                add to project
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default ProjectUsers
