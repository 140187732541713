export const LightTechnologyList = [
  "CFL",
  "High Pressure Sodium",
  "Incandescent",
  "LED",
  "Metal Halide",
  "T8 (Fluorescent)",
  "T12 (Fluorescent)",
  "Others",
]

export const LightAreaServed = [
  "Common",
  "Exterior",
  "Parking",
  "Suites",
  "Other",
  "N/A",
]

export const LightControlTypeList = [
  "Always on",
  "Scheduled",
  "Manual",
  "Motion sensor",
  "Daylight control",
  "Other",
  "N/A",
]

export const LightTechnologyWattsPerLamp = [
  { label: "CFL", value: "CFL", watts_per_lamp: 13 },
  {
    label: "High Pressure Sodium",
    value: "High Pressure Sodium",
    watts_per_lamp: 70,
  },
  { label: "Incandescent", value: "Incandescent", watts_per_lamp: 60 },
  { label: "LED", value: "LED", watts_per_lamp: 9 },
  { label: "Metal Halide", value: "Metal Halide", watts_per_lamp: 100 },
  { label: "T8 (Fluorescent)", value: "T8 (Fluorescent)", watts_per_lamp: 34 },
  {
    label: "T12 (Fluorescent)",
    value: "T12 (Fluorescent)",
    watts_per_lamp: 40,
  },
  { label: "Others", value: "Others", watts_per_lamp: 30 },
]
