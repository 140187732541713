import React from "react"
import "./button.css"

// Async Button courtesy of https://ironeko.com/posts/how-to-asynchronous-buttons-with-loading-state-in-react

const Button = (props) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <button
      disabled={loading}
      className='report-download'
      onClick={async (e) => {
        if (props.onClick) {
          const onClickIsPromise = true
          // props.onClick.constructor.name === "AsyncFunction";
          if (onClickIsPromise) setLoading(true)
          await props.onClick?.(e)
          if (onClickIsPromise) setLoading(false)
        }
      }}
    >
      {loading ? (
        // <span style={{ height: 20 }}>
        <div className='report-download-loading'>
          <svg
            id='loading'
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            fill='none'
            viewBox='0 0 256 256'
          >
            <rect width='256' height='256' fill='none'></rect>
            <path
              d='M168,40.7a96,96,0,1,1-80,0'
              fill='none'
              stroke='currentColor'
              strokeLinecap={"round"}
              strokeLinejoin={"round"}
              strokeWidth={"28"}
            ></path>
          </svg>
        </div>
      ) : (
          <span className='report-pdf-btn'>{props.children}</span> 
      )}
    </button>
  )
}

export default Button
