import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "../../styles/verticalTable.css"

function Panel(props) {
  const [header, setHeader] = useState()
  const [headerClasses, setHeaderClasses] = useState()
  const [data, setData] = useState("")

  useEffect(() => {
    setData(props.data)
    setHeader(props.header)
    setHeaderClasses(
      "px-2 py-3 border-solid border-grey-light border-b " + props.colour
    )
  }, [props.header, props.data, props.colour])

  return (
    <div id='panel-container' className='custom-frame'>
      <div className={headerClasses}>{header}</div>
      <div className='p-3 text-3xl text-center'>
        {data && !data.includes("NaN") && !data.includes("null") ? data : "-"}
      </div>
    </div>
  )
}

Panel.propTypes = {
  data: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  colour: PropTypes.string.isRequired,
}

export default Panel
