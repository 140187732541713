import React, { useState, useEffect } from "react"
import Checkbox from "./CheckBox"
import Button from "../button/Button"

function CheckboxGroup({
  legend,
  values,
  canAddAdditional,
  addAdditionalText,
  fn_openDialog,
  fn_onChange,
}) {
  const [checkboxes, setCheckboxes] = useState(values)

  useEffect(() => {
    if (values) {
      setCheckboxes(values)
    }
  }, [values])

  function handleCheckboxChange(index) {
    const updatedCheckboxes = [...checkboxes]
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked
    setCheckboxes([...updatedCheckboxes])
    if (fn_onChange) {
      fn_onChange([...updatedCheckboxes])
    }
  }

  function handleAddCheckbox() {
    console.log("handleAddCheckbox")
    if (fn_openDialog) {
      fn_openDialog()
    }
  }

  return (
    <fieldset>
      <legend>{legend}</legend>
      <div className='checkboxgroup'>
        {checkboxes.map((checkbox, index) => (
          <Checkbox
            key={index}
            label={checkbox.label}
            name={checkbox.name}
            checked={checkbox.checked ? true : false}
            onChange={() => handleCheckboxChange(index)}
            invalid={checkbox.invalid}
            disabled={checkbox.disabled}
            indeterminate={checkbox.indeterminate}
          />
        ))}
        {canAddAdditional && (
          <Button
            type='button'
            className='button__medium button__medium--primary'
            buttonText={
              addAdditionalText ? addAdditionalText : "Add Additional"
            }
            onClick={handleAddCheckbox}
          ></Button>
        )}
      </div>
    </fieldset>
  )
}

export default CheckboxGroup
