import { useState, useEffect } from "react"
import Select from "react-select"
import ECMTable from "../components/ECMTable"
import {
  parseIntAndFormat,
  parseFloatAndFormat,
  parseFloatOrNull,
} from "../../../utils/DataFormatter"
import ProgressBar from "../components/ProgressBar"

function BuildingFilter(props) {
  // [ "site_name_1", "site_name_2", ... ]
  const [siteNameList, setSiteNameList] = useState([])

  // table data to be passed into ECM Table
  const [retrofitTableData, setRetrofitTableData] = useState([])

  const [selectedECMs, setSelectedECMs] = useState({})

  const ecm_analysis_table = props.data.ecm_analysis_table

  const retrofit_header_names = [
    { column: "select", header: "" },
    { column: "ecm_description", header: "Description" },
    { column: "ecm_gas_gj_savings", header: "Annual Gas Energy Saving (GJ/yr)" },
    {
      column: "ecm_electric_kwh_savings",
      header: "Annual Electrical Energy Saving (kWh/yr)",
    },
    { column: "ecm_electric_kw_savings", header: "Annual Electrical Power Reduction (kW)" },
    { column: "ecm_water_m3_savings", header: "Annual Water Saving (m³/yr)" },
    { column: "ecm_capital_cost", header: "ECM Capital Cost ($)" },
    { column: "ecm_roi", header: "ROI (%)" },
    { column: "ecm_npv", header: "NPV ($)" },
    { column: "ecm_payback", header: "Simple Payback (yrs)" },
    { column: "ecm_incentive", header: "Incentive ($)" },
    { column: "ecm_savings", header: "First Year Saving ($)" },
    {
      column: "ecm_annual_onsite_emission_cost",
      header: "Annual GHG Emission/Cost (Ton CO₂e/$)",
    },
  ]

  useEffect(() => {
    if (props.data && siteNameList.length === 0) {
      let select_options = []
      let site_names = []
      ecm_analysis_table.forEach((ecm) => {
        if (!site_names.includes(ecm[1])) {
          site_names.push(ecm[1])
          select_options.push({ value: ecm[1], label: ecm[1] })
        }
      })
      setSiteNameList(select_options)
    }

    let selected_ecms_object = Object.entries(selectedECMs)
    let selected_ecms_list = []

    if (selected_ecms_object.length > 0) {
      selected_ecms_object.forEach(([key, value]) => {
        if (value) selected_ecms_list.push(key)
      })
      props.set_selected_building_ecms(selected_ecms_list)
    } else {
      props.set_selected_building_ecms([])
    }
  }, [props.data, selectedECMs])

  function generateECMGroupsList() {
    let group_list = []
    let ecm_groups = {}

    retrofitTableData.forEach((ecm) => {
      if (ecm.ecm_group_number !== null) {
        if (!group_list.includes(ecm.ecm_group_number)) {
          group_list.push(ecm.ecm_group_number)
          ecm_groups = { ...ecm_groups, [ecm.ecm_group_number]: new Set() }
        }
      }
    })

    for (let ecm of retrofitTableData) {
      if (ecm.ecm_group_number !== null) {
        ecm_groups = {
          ...ecm_groups,
          [ecm.ecm_group_number]: ecm_groups[ecm.ecm_group_number].add(
            ecm.ecm_identifier
          ),
        }
      }
    }
    return ecm_groups
  }

  function getGroupECMList(data) {
    if (data.ecm_group_number) {
      let ecm_groups = generateECMGroupsList()
      return Array.from(ecm_groups[data.ecm_group_number])
    } else {
      return [data.ecm_identifier]
    }
  }

  function onChangeHandler(select) {
    setSelectedECMs({})
    if (select) {
      let selected_building = select.value ? select.value : ""
      let table_data = []

      // change to if props.data.ghg_reduction_data[selected_building] != null
      // if (Object.keys(ecmsList).includes(selected_building)) {
      //     props.set_selected_building(selected_building)
      //     props.set_selected_building_ecms(ecmsList[selected_building])

      let building_ecms = ecm_analysis_table.filter(
        (ecm) => ecm[1] === selected_building
      )

      building_ecms.forEach((ecm) => {
        let annual_onsite_emission_cost = parseFloat(
          (parseFloat(ecm[18]) / parseInt(ecm[4])) * 1000
        ).toPrecision(3)
        table_data.push({
          ecm_identifier: parseInt(ecm[0]),
          site_name: ecm[1],
          ecm_description: ecm[3],
          ecm_gas_gj_savings: parseIntAndFormat(ecm[10]),
          ecm_electric_kwh_savings: parseIntAndFormat(ecm[11]),
          ecm_electric_kw_savings: parseIntAndFormat(ecm[12]),
          ecm_water_m3_savings: parseIntAndFormat(ecm[13]),
          ecm_capital_cost: parseIntAndFormat(ecm[4]),
          ecm_roi: parseIntAndFormat(ecm[9]),
          ecm_npv: parseIntAndFormat(ecm[8]),
          ecm_payback: parseIntAndFormat(ecm[7]),
          ecm_incentive: parseIntAndFormat(ecm[14]),
          ecm_savings: parseIntAndFormat(ecm[15]),
          ecm_group_number: parseInt(ecm[16]) ? parseInt(ecm[16]) : null,
          ecm_hierarchy: parseInt(ecm[17]) ? parseInt(ecm[17]) : null,
          ecm_annual_onsite_emission_cost: isNaN(annual_onsite_emission_cost)
            ? "-"
            : annual_onsite_emission_cost,
        })
      })
      table_data.sort((a, b) => {
        return (
          b.ecm_annual_onsite_emission_cost - a.ecm_annual_onsite_emission_cost
        )
      })
      setRetrofitTableData(table_data)
    } else {
      setRetrofitTableData([])
    }
  }
  return (
    <div className=' flex flex-col h-fit mb-10'>
      <div className='bg-sisa-header w-full'>
        <p className='text-lg text-white px-2 m-2'>Available ECMs</p>
      </div>
      <div className='h-full flex flex-col gap-10 custom-frame p-6'>
        <div className=' flex flex-col'>
          <div className='retrofit-select-site'>
            <p className='filter-label'>Site Name</p>
            <Select
              name='site_name'
              id='site_name'
              className='select-options'
              options={siteNameList}
              defaultValue={""}
              isClearable={true}
              isSearchable={true}
              onChange={onChangeHandler}
            />
          </div>
          <ECMTable
            table_headers={retrofit_header_names}
            ecm_list={retrofitTableData}
            selected_ecms={selectedECMs}
            set_selected_ecms={setSelectedECMs}
            fn_get_group_ecms={getGroupECMList}
            is_sorted={true}
          />
          <p className='upgrade-restrict-text'>
            Note: Selecting certain ECMs may restrict other ECMs due to their
            effect on each other.
          </p>
          <div className='flex flex-row justify-around'>
            <ProgressBar value={props.ghg_reduction_value} />
            <div className='buttons-container'>
              <button
                className='button-action-white'
                onClick={() => setSelectedECMs({})}
              >
                clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildingFilter
