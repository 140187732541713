import "./simpleModal.css"

/**
 * ===========================================
 * Using Material UI Dialog / Modal Components
 * ===========================================
 * - Major performance issues when closing dialog / modal
 *  - transparent MUi root layer can be extremely slow to unmount even after dialog / modal is closed
 *   - identified unmounting delay is preventing user from further interaction until unmounted
 *    - delay can be short at first but increases with more usage
 *  - using 'keepMounted' prop to prevent unmounting had no effect on the issue
 *
 * related github issues:
 * - https://github.com/mui/material-ui/issues/9116
 * - https://github.com/mui/material-ui/issues/17196
 *
 * possible solutions:
 * - nest the dialog in the same component as the button that opens it
 *
 * ===========================================
 * Using Native HTML Dialog Element
 * ===========================================
 * - No performance issues when closing dialog / modal
 *
 * ===========================================
 * REQUIRED PROPS
 * ===========================================
 *  - modal_ref            - ref         - used to open / close dialog / modal from parent component
 *
 * ===========================================
 * OPTIONAL PROPS
 * ===========================================
 * - content_title        - string      - title of dialog / modal
 * - content_body         - jsx         - body of dialog / modal
 * - btn_confirm          - string      - text for confirm button
 * - btn_cancel           - string      - text for cancel button
 * - btn_close            - boolean     - show / hide close button
 * - fn_confirm           - fn          - returns promise, does not close dialog / modal
 * - fn_close             - fn          - function that will close the dialog / modal
 * - inner_components     - jsx         - components to be rendered inside dialog / modal
 *
 * ===========================================
 * USAGE
 * ===========================================
 *
 * if no method of closing present, [esc] key will close the dialog / modal
 *
 * Opening the dialog / modal [from parent component]
 * - call modal_ref.current.showModal() for Modal
 * - call modal_ref.current.show() for Dialog
 *
 * Closing the dialog / modal [from parent component]
 * - call modal_ref.current.close()
 *
 * [fn_confirm]
 * if provided, returns a promise in the following format:
 *  resolve - {error: false, message: "success message"}
 *  reject  - {error: true, message: "error message"}
 *  - useful for returning success / error messages from the function
 *  - loading spinner will be shown while waiting for the promise to resolve / reject
 * otherwise, dialog closes on Confirm Button click
 *
 * [fn_close]
 * if provided, does not return anything and must close the dialog / modal
 * by triggering re-render / call modal_ref.current.close() in the function
 * otherwise, dialog closes on Close / Cancel Button click
 *
 * [btn_close]
 * btn_close will show close button when true and hide when false
 *
 * [content_body]
 * if provided, will render the jsx provided in the main body under the title
 * - useful for rendering styled text in the body / main content of the dialog / modal
 *
 * [inner_components]
 * inner_components will render components inside the dialog / modal
 * - useful for rendering a file selector inside the dialog / modal
 * - clicking the inner component will remove the response message from fn_confirm
 *
 * ===========================================
 * STYLING
 * ===========================================
 * - styles/modalDialog.css
 *
 */

function SimpleModal({
  content_title,
  content_body,
  btn_confirm_primary,
  btn_confirm_secondary,
  btn_confirm_primary_disabled,
  btn_confirm_secondary_disabled,
  btn_cancel,
  btn_close,
  fn_confirm_primary,
  fn_confirm_secondary,
  fn_close,
  modal_ref,
  inner_components,
  on_validation,
}) {
  function handleClose() {
    if (fn_close) {
      fn_close()
    }
    modal_ref.current.close()
  }

  function handleConfirmPrimary() {
    if (on_validation) {
      let result = fn_confirm_primary()
      if (result) {
        handleClose()
      }
    } else {
      if (fn_confirm_primary) {
        fn_confirm_primary()
      }
      handleClose()
    }
  }

  function handleConfirmSecondary() {
    if (on_validation) {
      let result = fn_confirm_secondary()
      if (result) {
        handleClose()
      }
    } else {
      if (fn_confirm_secondary) {
        fn_confirm_secondary()
      }
      handleClose()
    }
  }

  function renderActions() {
    return (
      <>
        <div className='simple_modal-actions'>
          {btn_cancel && (
            <button
              className='simple_modal-btn btn-cancel'
              onClick={handleClose}
            >
              {btn_cancel}
            </button>
          )}
          {btn_confirm_primary && (
            <button
              className='simple_modal-btn btn-confirm'
              onClick={handleConfirmPrimary}
              disabled={btn_confirm_primary_disabled}
              aria-disabled={btn_confirm_primary_disabled}
            >
              {btn_confirm_primary}
            </button>
          )}
          {btn_confirm_secondary && (
            <button
              className='simple_modal-btn btn-confirm'
              onClick={handleConfirmSecondary}
              disabled={btn_confirm_secondary_disabled}
              aria-disabled={btn_confirm_secondary_disabled}
            >
              {btn_confirm_secondary}
            </button>
          )}
        </div>
      </>
    )
  }

  return (
    <dialog
      ref={modal_ref}
      className='simple_modal-container'
      onClose={handleClose}
    >
      <div className='simple_modal-content'>
        {content_title && (
          <>
            <div className='simple_modal-title-container'>
              <p className='simple_modal-title'>{content_title}</p>
              <button className='empty'></button>
              {btn_close && (
                <button
                  className='simple_modal-btn btn-close'
                  onClick={handleClose}
                >
                  <span className='material-symbols-outlined'>close</span>
                </button>
              )}
            </div>
            <hr className='simple_modal-divider' />
          </>
        )}
        <div className='simple_modal-content-body'>
          {content_body && content_body}
          {inner_components && (
            <div className='inner-components'>{inner_components}</div>
          )}
          {renderActions()}
        </div>
      </div>
    </dialog>
  )
}

export default SimpleModal
