import {
  pdf,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer"
import { saveAs } from "file-saver"
import {
  Table,
  TableBody,
  TableCell,
  DataTableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table"
import { useEffect, useState } from "react"
import "../../styles/report-tab.css"
import frescoLogo from "../../FRESCoLogoCMYK.png"
import mcwLogo from "../../MCWLogo.png"
import Logo3 from "../../Logo3.png"
import Logo4 from "../../Logo4.png"
import Button from "./button/button"
import { formatNumberGrouping } from "../../utils/DataFormatter"

const PDFReport = (props) => {
  const projectNumber = `Project Number: ${window.location.pathname.substring(
    8
  )}`
  const buildingName = `${props.data.report_summary_participant_table_data[1]} - Assessment Report`
  const reportDate = "Report Download Date: " + new Date().toDateString()
  const clientName = `Applicant: ${props.data.report_summary_participant_table_data[0]}`

  let summaryHeaders = [
    "Applicant Name",
    "Site Name",
    "Site Address",
    "City",
    "Province",
    "Site Contact Name",
    "Office Address",
    "Telephone",
    "Email",
  ]

  let buildingProfileHeaders = [
    "Project Number",
    "Facility Use",
    "Building Type",
    "Year of Construction",
    "Year of Last Remodel",
    "Number of Floors",
    "Total Floor Area (sqft)",
    "Envelope Condition",
    "Full Time Employees",
    "Number of Residents",
    "Number of Suites",
    "Occupancy",
    "Space Heating Primary Fuel",
    "Domestic Hot Water Primary Fuel",
    "Outdoor Air Ventilation Heated By",
    "Electric Meters for Individual Suites",
  ]

  // let totalConsumptionHeaders = [
  //     "Total Consumption",
  //     "Suite Usage",
  //     "Total Cost / Year",
  //     "Proportion of Cost (%)",
  //     "GHG Emissions (Tonnes CO2)",
  //     "EUI (GJ / m".concat('\u00B2', ")"),
  //     "EUI (GJ / Occupant)",
  //     "Total Consumption (Cost / m".concat('\u00B2', ")"),
  //   ]

  // Jan 26, 2023 edit: split total consumption table into two tables: Total Consumption and Energy Use Intensities, still use the same dataset.
  let totalConsumptionHeaders = [
    "Total Consumption",
    "Suite Usage",
    "Total Cost ($/yr)",
    "Proportion of Cost (%)",
    "GHG Emissions (Ton CO₂e)",
  ]

  let energyUseIntensitiesHeaders = [
    "EUI (GJ/m".concat("\u00B2", ")"),
    "EUI (GJ/Occupant)",
    "Total Consumption ($/m".concat("\u00B2", ")"),
  ]

  const [userLogo, setUserLogo] = useState(null)

  // set userlogo with email account
  useEffect(() => {
    let user_email = localStorage.getItem("user_email")
    let domain = user_email.split("@")[1].toLowerCase()

    if (domain.includes("frescoltd.com")) {
      setUserLogo(frescoLogo)
    } else if (
      domain.includes("mcw.com")
      //  || user_email.includes("deployments")
    ) {
      setUserLogo(mcwLogo)
    } else {
      setUserLogo(Logo3)
    }
  }, [])

  function cleanConsumptionData(data) {
    // arr[1] == Total Consumption (GJ/yr)
    // arr[2] == Total Cost ($/Yr)
    // arr[7] == Total Consumption ($/m2)
    try {
      var arr = data.map((r) => (r.includes("NaN") ? "-" : r))
      if (!arr[1].includes("-")) {
        arr[1] = cleanValue(arr[1])
      }
      if (!arr[2].includes("-")) {
        arr[2] = cleanValue(arr[2], 0, "$")
      }
      if (!arr[7].includes("-")) {
        arr[7] = cleanValue(arr[7], 0, "$")
      }
      return arr
    } catch (err) {
      console.error(err)
    }
  }

  let totalConsumptionElectricData = cleanConsumptionData([
    ...props.data.report_total_consumption_data[0],
  ])
  let totalConsumptionGasData = cleanConsumptionData([
    ...props.data.report_total_consumption_data[1],
  ])
  let totalConsumptionTotalsData = cleanConsumptionData([
    ...props.data.report_total_consumption_data[2],
  ])

  // splice out data of interest for Energy Use Intensities table:
  let intensityProportionColumnData = totalConsumptionElectricData.splice(5)
  let intensityEnergyColumnData = totalConsumptionGasData.splice(5)
  let intensityCostColumnData = totalConsumptionTotalsData.splice(5)

  const ListItem = ({ children }) => {
    return (
      <View style={styles.row}>
        <View style={styles.bullet}>
          <Text>{"\u2022" + " "}</Text>
        </View>
        <Text>{children}</Text>
      </View>
    )
  }

  function cleanValue(value, precision, type) {
    // Cleans value to formatted and rounded numerical string with optional prefix, or returns "-" if value is not a number.
    // Usage: cleanValue(12345.6789, 0, '$') -> '$12,345'
    // UPDATE: also formats the negative numbers and adds the commas back in
    var prefix = type || ""
    let result = String(value).trim().replaceAll(",", "")
    let is_negative = result.startsWith("-")

    if (prefix === "$") {
      result = formatNumberGrouping(result)
      if (is_negative) {
        result = result.replaceAll("-", "")
        return result ? "-".concat(prefix.concat(result)) : "-"
      } else {
        return result ? prefix.concat(result) : "-"
      }
    } else {
      return !isNaN(result) ? formatNumberGrouping(result) : "-"
    }
  }

  const roundToPrecisionHelper = (value, precision) => {
    // Rounds value to specified precision, then converts the number to a formatted numerical string with comma separators.
    // Usage:
    // round(12345.6789, 2) -> 2 decimals: 12345.68
    // round(12345.6789, 1) -> 1 decimal: 12345.7
    // round(12345.6789) or round(12345.6789, 0) -> whole num: 12346
    // round(12345.6789, -1) -> nearest 10: 12350
    // round(12345.6789, -2) -> nearest 100: 12300

    var multiplier = Math.pow(10, precision || 0)
    return (Math.round(value * multiplier) / multiplier).toLocaleString("en-US")
  }

  // modify building description data (Row for Total Floor Area) to format with commas.
  let buildingDescriptionData = [...props.data.building_profile_data]
  if (!buildingDescriptionData[6]?.includes("NaN"))
    buildingDescriptionData[6] = cleanValue(buildingDescriptionData[6])

  const styles = StyleSheet.create({
    page: {
      //   paddingVertical: 50,
      marginBottom: 10,
      paddingTop: 60,
      paddingBottom: 95,
    },
    section: {
      margin: 10,
      marginVertical: 5,
      marginHorizontal: 50,
      paddingHorizontal: 8,
      paddingBottom: 13,
    },
    endSection: {
      marginVertical: 5,
      marginHorizontal: 50,
      paddingHorizontal: 8,
    },
    sectionHeader: {
      fontSize: 14,
      padding: 4,
      backgroundColor: "#20243c",
      color: "#E4E4E4",
    },
    plainTextBuildingName: {
      fontSize: 24,
      paddingBottom: 55,
      color: "#011148",
      forntWeight: 700,
    },
    plainTextHeader: {
      fontSize: 16,
      paddingBottom: 16,
    },
    plainTextFooter: {
      fontSize: 16,
    },
    tableHeader: {
      borderStyle: "solid",
      padding: 4,
      fontSize: 10,
      fontWeight: 500,
      backgroundColor: "#e0ffff",
    },
    tableCell: {
      borderStyle: "solid",
      padding: 4,
      fontSize: 10,
      display: "row",
      flexWrap: "nowrap",
      overflow: "hidden",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 10,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    logo: {
      marginVertical: 20,
      marginHorizontal: 10,
      position: "absolute",
      height: 100,
      width: 150,
      bottom: 5,
      right: 50,
      paddingTop: 50,
    },
    paragraph: {
      paddingVertical: 4,
      fontSize: 11,
      lineHeight: 1.5,
    },
    legend: {
      paddingTop: 4,
      fontSize: 10,
      color: "grey",
    },
    cover: {
      alignContent: "center",
      marginHorizontal: 50,
      paddingHorizontal: 8,
      paddingBottom: 10,
    },
    coverLogo: {
      height: 450,
      width: 250,
      marginVertical: 5,
      marginHorizontal: 30,
      paddingHorizontal: 8,
      paddingTop: 200,
      paddingBottom: 30,
    },
    row: {
      marginHorizontal: 10,
      display: "flex",
      flexDirection: "row",
    },
    bullet: {
      height: "100%",
    },
  })

  // const MyDoc = () => (
  const MyDoc = (
    <Document>
      <Page
        size='tabloid'
        orientation='landscape'
        style={styles.page}
        wrap={true}
      >
        <View style={styles.coverLogo}>
          {" "}
          <Image src={userLogo} />
        </View>
        <View style={styles.cover}>
          <Text style={styles.plainTextBuildingName}>{buildingName}</Text>
          <Text style={styles.plainTextHeader}>{projectNumber}</Text>
          <Text style={styles.plainTextHeader}>{clientName}</Text>
          <Text style={styles.plainTextFooter}>{reportDate}</Text>
        </View>
      </Page>

      <Page
        size='tabloid'
        orientation='landscape'
        style={styles.page}
        wrap={true}
        bookmark='Table of Contents'
      >
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />

        <View
          style={styles.section}
          bookmark={{ title: "ECMs' Financial Analysis", fit: false }}
        >
          <View style={styles.plainTextHeader}>
            <Text>Energy Conservation Measures (ECMs)</Text>
            <Text style={styles.paragraph}>
              The following tables show the energy conservation measures
              identified for the property.
            </Text>
          </View>
          <View style={styles.sectionHeader}>
            <Text>ECMs' Financial Analysis</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Description</TableCell>
                <TableCell style={styles.tableHeader}>
                  {"Adjusted Incremental Cost (Including Incentives) ($)"}
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  First Year Saving ($)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Simple Payback (yrs)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  {"ROI".concat("\u00B9", " (%)")}
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  {"IRR".concat("\u00B2", " (%)")}
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  {"NPV".concat("\u00B3", " ($)")}
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Discounted Payback (yrs)
                </TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_upgrades_rec_energy_solutions_data}>
            {/* <TableHeader includeTopBorder={false} includeBottomBorder={false} includeRightBorder={false} includeLeftBorder={false}/> */}
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[14], -3, "$")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) =>
                  cleanValue(
                    !isNaN(r[5]) /*& !(isNaN(r[23]))*/
                      ? parseFloat(r[5]) /*+ parseFloat(r[23])*/
                      : "-",
                    -2,
                    "$"
                  )
                }
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[4])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[8], 2)}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[9], 2)}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[10], 0, "$")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[11], 0)}
              />
            </TableBody>
          </Table>
          <Text style={styles.legend}>{"\u00B9"}ROI: Return on investment</Text>
          <Text style={styles.legend}>
            {"\u00B2"}IRR: Internal rate of return
          </Text>
          <Text style={styles.legend}>{"\u00B3"}NPV: Net present value</Text>
        </View>
        <View style={styles.logo} fixed>
          {" "}
          <Image src={Logo4} />
        </View>

        <View
          break
          style={styles.section}
          bookmark={{ title: "ECMs' Costing", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>ECMs' Costing</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Description</TableCell>
                <TableCell style={styles.tableHeader}>
                  {"Baseline Replacement Cost".concat("\u00B9", " ($)")}
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  {"ECM Capital Cost".concat("\u00B2", " ($)")}
                </TableCell>
                <TableCell style={styles.tableHeader}>Incentive ($)</TableCell>
                <TableCell style={styles.tableHeader}>
                  {"Incremental Cost (ECM Capital - Baseline) ($)"}
                </TableCell>
                <TableCell style={styles.tableHeader}>ECM Life (yrs)</TableCell>
                <TableCell style={styles.tableHeader}>
                  Baseline Life (yrs)
                </TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_upgrades_rec_energy_solutions_data}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[12], -3, "$")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[6], -3, "$")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[7], -3, "$")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[14], -3, "$")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[15])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[16])}
              />
            </TableBody>
          </Table>
          <Text style={styles.legend}>
            {"\u00B9"}Baseline Replacement Cost: Cost of replacements at their
            end of life without upgrading, e.g., the replacement cost of an
            existing atmospheric boiler with a new atmospheric one
          </Text>
          <Text style={styles.legend}>
            {"\u00B2"}ECM Capital cost: Cost of the proposed ECM, e.g., the
            replacement cost of an existing atmospheric boiler with a condensing
            one
          </Text>
        </View>

        {/* </Page>
        <Page size="A4" wrap={true}> */}
        <View
          break
          style={styles.section}
          bookmark={{
            title:
              "ECMs’ Utility Savings and Greenhouse Gas Emission Reduction",
            fit: false,
          }}
        >
          <View style={styles.sectionHeader}>
            <Text>
              ECMs’ Utility Savings and Greenhouse Gas Emission Reduction
            </Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Description</TableCell>
                <TableCell style={styles.tableHeader}>
                  Annual Onsite GHG Emission Saving (Ton CO₂e/yr)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Lifetime Onsite GHG reduction (Ton CO₂e)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Annual Electrical Energy Saving (kWh/yr)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Annual Electrical Power Reduction (kW)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Annual Gas Energy Saving (GJ/yr)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Annual Water Saving (m³/yr)
                </TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_upgrades_rec_energy_solutions_data}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[18])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[3])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[19])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[20])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[21])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[22])}
              />
            </TableBody>
          </Table>
        </View>
        {/* </Page>
        <Page size="A4" wrap={true}> */}
        <View
          break
          style={styles.section}
          bookmark={{ title: "Energy Breakdown", fit: false }}
        >
          <View>
            <Text style={styles.plainTextHeader}>Energy Breakdown</Text>
          </View>
          <View style={styles.sectionHeader}>
            <Text>Energy Use Intensities</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Category</TableCell>
                <TableCell style={styles.tableHeader}>Electricity</TableCell>
                <TableCell style={styles.tableHeader}>Gas</TableCell>
                <TableCell style={styles.tableHeader}>Totals</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={[1, 2, 3]}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => energyUseIntensitiesHeaders.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => intensityProportionColumnData.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => intensityEnergyColumnData.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => intensityCostColumnData.shift()}
              />
            </TableBody>
          </Table>
        </View>
        <View
          style={styles.section}
          bookmark={{ title: "Total Consumption", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Total Consumption</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Category</TableCell>
                <TableCell style={styles.tableHeader}>Electricity</TableCell>
                <TableCell style={styles.tableHeader}>Gas</TableCell>
                <TableCell style={styles.tableHeader}>Totals</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={[1, 2, 3, 4, 5]}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => totalConsumptionHeaders.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => totalConsumptionElectricData.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => totalConsumptionGasData.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => totalConsumptionTotalsData.shift()}
              />
            </TableBody>
          </Table>
        </View>

        <View
          break
          style={styles.section}
          bookmark={{ title: "Annual Electricity Breakdown", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Annual Electricity Breakdown</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Category</TableCell>
                <TableCell style={styles.tableHeader}>
                  Consumption Proportion (%)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Energy (kWh/yr)
                </TableCell>
                <TableCell style={styles.tableHeader}>Cost ($/yr)</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_electrical_breakdown}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[1])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[2], -2)}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[3], -2, "$")}
              />
            </TableBody>
          </Table>
        </View>

        <View
          style={styles.section}
          bookmark={{ title: "Gas Breakdown", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Gas Breakdown</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Category</TableCell>
                <TableCell style={styles.tableHeader}>
                  Consumption Proportion (%)
                </TableCell>
                <TableCell style={styles.tableHeader}>Energy (GJ/yr)</TableCell>
                <TableCell style={styles.tableHeader}>Cost ($/yr)</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_gas_breakdown}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[1])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[2])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[3], -2, "$")}
              />
            </TableBody>
          </Table>
        </View>

        <View
          style={styles.section}
          bookmark={{ title: "Energy Average Rates", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Energy Average Rates</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Category</TableCell>
                <TableCell style={styles.tableHeader}>Average Rates</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table
            data={[
              ["Electricity ($/kWh)", 0.19],
              ["Electrical Power ($/kW)", 5],
              ["Natural Gas ($/GJ)", 9.7],
              ["Other Fuels ($/GJ)", 0],
              ["Water ($/m³)", 0],
            ]}
          >
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[1], 0, "$")}
              />
            </TableBody>
          </Table>
        </View>
        {/* </Page>
        <Page size="A4" wrap={true}> */}
        <View
          break
          style={styles.section}
          bookmark={{ title: "Building Description", fit: false }}
        >
          <View style={styles.plainTextHeader}>
            <Text>Property and Asset Information </Text>
          </View>
          <View style={styles.sectionHeader}>
            <Text>Building Description</Text>
          </View>
          <Table data={buildingDescriptionData}>
            <TableBody>
              <DataTableCell
                style={styles.tableHeader}
                getContent={() => buildingProfileHeaders.shift()}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => (!(r == "NaN") ? r : "-")}
              />
            </TableBody>
          </Table>
        </View>

        <View
          style={styles.section}
          bookmark={{ title: "Envelope", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Envelope</Text>
          </View>
          <Table data={props.data.report_envelope_data}>
            <TableHeader>
              <TableCell style={styles.tableHeader}>Envelope Type</TableCell>
              <TableCell style={styles.tableHeader}>Description</TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[1]}
              />
            </TableBody>
          </Table>
        </View>
        {/* </Page>
        <Page size="A4" wrap={true}> */}
        <View
          break
          style={styles.section}
          bookmark={{ title: "Equipment", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Equipment</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Equipment</TableCell>
                <TableCell style={styles.tableHeader}>Quantity</TableCell>
                <TableCell style={styles.tableHeader}>
                  Installation Year
                </TableCell>
                <TableCell style={styles.tableHeader}>Make and Model</TableCell>
                <TableCell style={styles.tableHeader}>Identifier Tag</TableCell>
                <TableCell style={styles.tableHeader}>End Use</TableCell>
                <TableCell style={styles.tableHeader}>Area Served</TableCell>
                <TableCell style={styles.tableHeader}>Fuel</TableCell>
                <TableCell style={styles.tableHeader}>
                  Electrical Power Rating
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Gas Power Rating
                </TableCell>
                <TableCell style={styles.tableHeader}>Power Unit</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_equipment_data}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[1] == "NaN") ? r[1] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!isNaN(r[2]) ? r[2] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[3] == "NaN") ? r[3] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[4] == "NaN") ? r[4] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[5] == "NaN") ? r[5] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[6] == "NaN") ? r[6] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[7] == "NaN") ? r[7] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[8] == "NaN") ? r[8] : "-")}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[9], 2)}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[10], 2)}
              />
              <DataTableCell
                includeTopBorder={false}
                style={styles.tableCell}
                getContent={(r) => (!(r[11] == "NaN") ? r[11] : "-")}
              />
            </TableBody>
          </Table>
        </View>
        {/* </Page>
        <Page size="A4" wrap={true}> */}

        <View
          break
          style={styles.section}
          bookmark={{ title: "Lights", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Lights</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                {/* <TableCell style={styles.tableHeader}>Space Description</TableCell> */}
                <TableCell style={styles.tableHeader}>Area Served</TableCell>
                <TableCell style={styles.tableHeader}>Technology</TableCell>
                <TableCell style={styles.tableHeader}>
                  Number of Fixtures
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Lamp Per Fixture
                </TableCell>
                <TableCell style={styles.tableHeader}>W Per Lamp</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_light_data}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              {/* <DataTableCell style={styles.tableCell}  getContent={(r) => r[0]}/> */}
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => (!(r[1] == "NaN") ? r[1] : "-")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => (!(r[2] == "NaN") ? r[2] : "-")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => (!(r[3] == "NaN") ? r[3] : "-")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => (!(r[4] == "NaN") ? r[4] : "-")}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[5])}
              />
            </TableBody>
          </Table>
        </View>
        {/* </Page>
        <Page size="A4" wrap={true}> */}
        <View
          style={styles.section}
          bookmark={{ title: "Plumbing", fit: false }}
        >
          <View style={styles.sectionHeader}>
            <Text>Plumbing</Text>
          </View>
          <View fixed>
            <Table>
              <TableHeader>
                <TableCell style={styles.tableHeader}>Fixtures</TableCell>
                <TableCell style={styles.tableHeader}>Quantity</TableCell>
                <TableCell style={styles.tableHeader}>
                  Existing (LPM/LPF)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  Proposed (LPM/LPF)
                </TableCell>
                <TableCell style={styles.tableHeader}>
                  {"Savings (m".concat("\u00B3", "/yr)")}
                </TableCell>
                <TableCell style={styles.tableHeader}>Savings ($/yr)</TableCell>
              </TableHeader>
            </Table>
          </View>
          <Table data={props.data.report_plumbing_data}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => r[0]}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[1])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[2], 2)}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[3], 2)}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[4])}
              />
              <DataTableCell
                style={styles.tableCell}
                getContent={(r) => cleanValue(r[5], -2, "$")}
              />
            </TableBody>
          </Table>
        </View>

        <View
          break
          style={styles.section}
          bookmark={{ title: "Contact Summary", fit: false }}
        >
          <View style={styles.plainTextHeader}>
            <Text>Contact</Text>
          </View>
          <View style={styles.sectionHeader}>
            <Text>Contact Summary</Text>
          </View>
          <Table data={props.data.report_summary_participant_table_data}>
            <TableHeader
              includeTopBorder={false}
              includeBottomBorder={false}
              includeRightBorder={false}
              includeLeftBorder={false}
            />
            <TableBody>
              <DataTableCell
                style={styles.tableHeader}
                getContent={() => summaryHeaders.shift()}
              />
              <DataTableCell style={styles.tableCell} getContent={(r) => r} />
            </TableBody>
          </Table>
        </View>

        <View
          break
          style={styles.endSection}
          bookmark={{ title: "Assumptions and Notes", fit: false }}
        >
          <View style={styles.plainTextHeader}>
            <Text>Assumptions and Notes</Text>
          </View>
          <Text style={styles.paragraph}>
            Assumptions made in calculating savings
          </Text>
          <View style={styles.paragraph}>
            <ListItem>Electricity rate increase at 2.5% annually.</ListItem>
            <ListItem>Gas rate increase at 3.2% annually.</ListItem>
            <ListItem>General inflation is about 2% annually.</ListItem>
            <ListItem>Discount rate is assumed to be 6.1%.</ListItem>
            <ListItem>
              Carbon tax escalation in accordance with federal 2018 Greenhouse
              Gas Pricing Act.
            </ListItem>
            <ListItem>
              Proposed ECM and mechanical life spans are set based on the “DSM
              STANDARD, Effective Measure Life and Persistence - Revision 11,
              For External and Internal Use, March 2019” study done by BC Hydro.
            </ListItem>
            <ListItem>
              Cost assumptions for systems and equipment ECMs reflect industry
              commercial rates for material and labour.
            </ListItem>
          </View>
          <Text style={styles.paragraph}>Notes</Text>
          <View style={styles.paragraph}>
            <ListItem>
              Figures have been rounded and as a result the totals may not
              reflect the sum or each item.
            </ListItem>
            <ListItem>
              Figures provided are estimated; costs and savings are not
              guaranteed.
            </ListItem>
            <ListItem>
              The intent of estimates, given the time and budget constraints of
              this project, is to provide enough information with a level of
              accuracy that will enable informed decisions about energy cost
              reduction opportunities.
            </ListItem>
            <ListItem>
              Considerations other than estimated costs and savings may be
              relevant; contact SISA Energy Ltd to discuss the various options.
            </ListItem>
            <ListItem>
              SISA Energy does not advocate for any particular building upgrade
              option or contractor. If a participant is interested in pursuing a
              building upgrade SISA Energy can provide implementation support
              for major measures, but the owner is responsible for deciding
              whether/how to move forward with a building upgrade.
            </ListItem>
            <ListItem>
              SISA Energy has not designed any of the ECMs being considered. If
              a participant moves forward with a building upgrade it is the
              responsibility of each contractor carrying out the ECMs to ensure
              that the work meets all requirements (e.g. code restrictions,
              manufacturer specifications).
            </ListItem>
            <ListItem>
              SISA Energy does not make any representations or warranties
              regarding any of the upgrades considered and is not liable for any
              losses, should any occur, as a result of any upgrades that are
              implemented.
            </ListItem>
            <ListItem>
              Not all factors may be incorporated into the analysis presented
              above; the participant should do their due diligence before making
              any decisions.
            </ListItem>
          </View>
        </View>
        {/* </Page> */}
      </Page>
    </Document>
  )

  const generatePdfDocument = async () => {
    try {
      const blob = await pdf(MyDoc).toBlob()
      saveAs(blob, window.location.pathname.substring(8) + "-report.pdf")

      // reset shifted headers
      summaryHeaders = [
        "Applicant Name",
        "Site Name",
        "Site Address",
        "City",
        "Province",
        "Site Contact Name",
        "Office Address",
        "Telephone",
        "Email",
      ]
      buildingProfileHeaders = [
        "Project Number",
        "Facility Use",
        "Building Type",
        "Year of Construction",
        "Year of Last Remodel",
        "Number of Floors",
        "Total Floor Area (sqft)",
        "Envelope Condition",
        "Full Time Employees",
        "Number of Residents",
        "Number of Suites",
        "Occupancy",
        "Space Heating Primary Fuel",
        "Domestic Hot Water Primary Fuel",
        "Outdoor Air Ventilation Heated By",
        "Electric Meters for Individual Suites",
      ]
      totalConsumptionHeaders = [
        "Total Consumption",
        "Suite Usage",
        "Total Cost ($/yr)",
        "Proportion of Cost (%)",
        "GHG Emissions (Ton CO₂e)",
      ]
      energyUseIntensitiesHeaders = [
        "EUI (GJ/m".concat("\u00B2", ")"),
        "EUI (GJ/Occupant)",
        "Total Consumption ($/m".concat("\u00B2", ")"),
      ]
      totalConsumptionElectricData = cleanConsumptionData([
        ...props.data.report_total_consumption_data[0],
      ])
      totalConsumptionGasData = cleanConsumptionData([
        ...props.data.report_total_consumption_data[1],
      ])
      totalConsumptionTotalsData = cleanConsumptionData([
        ...props.data.report_total_consumption_data[2],
      ])
      intensityProportionColumnData = totalConsumptionElectricData.splice(5)
      intensityEnergyColumnData = totalConsumptionGasData.splice(5)
      intensityCostColumnData = totalConsumptionTotalsData.splice(5)
    } catch (error) {
      console.error(error)
      window.alert(
        "Error: Unable to generate Report PDF at this time. We apologize for the inconvenience."
      )
    }
  }

  return (
    <div className='pdf-down-btn'>
      <Button className='report-pdf-btn' onClick={() => generatePdfDocument()}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          height='2.2em'
          width='2.2em'
          fill='#FFFFFF'
          viewBox='0 0 640 512'
        >
          <path d='m201.9 244.5c18.6 0 33.6 15 33.6 33.6v45.1c0 18.6-15 33.6-33.6 33.6h-22.4c-6.1 0-11.2-5.1-11.2-11.2v-89.9c0-6.1 5.1-11.2 11.2-11.2h22.4zm0 22.4h-11.2v67.5h11.2c6.1 0 11.2-5.1 11.2-11.2v-45.1c0-6.1-5.1-11.2-11.2-11.2zM112 323.2h-11.2v22.4c0 6.1-5.1 11.2-11.2 11.2s-11.2-5.1-11.2-11.2v-89.9c0-6.1 5.1-11.2 11.2-11.2H112c21.8 0 39.4 17.6 39.4 39.4s-17.6 39.4-39.4 39.4zm17-39.4c0-9.3-7.7-17-17-17h-11.2v33.6H112c9.3 0 17-7.7 17-17zm418.6 110.1c-19.2 33.6-55 54.1-93.4 54.1s-74.2-20.5-93.4-54.1-19.2-74.6 0-108.2 55-54.1 93.4-54.1 74.2 20.5 93.4 54.1s19.2 74.6 0 108.2zm-20.8-96c-15-25.9-42.9-41.9-72.6-41.9s-57.6 16-72.6 41.9-15 57.9 0 83.8 42.9 41.9 72.6 41.9 57.6-16 72.6-41.9 15-57.9 0-83.8zm-372.8-76.2h-24V111.7c0-26.6 21.4-48 48-48H295c9.6 0 18.6 3.8 25.3 10.6c29.1 28.8 57.9 57.9 86.7 87c6.7 6.7 10.6 16 10.6 25.3v25.9c-8.3 2.2-16.3 5.4-24 9.6v-14.4h-83.8c-19.8 0-35.8-16-35.8-35.8V88h-96c-13.4 0-24 10.6-24 24v110.1zm144-49.9c0 6.7 5.4 12.2 12.2 12.2h83.5c-.6-2.2-1.6-3.8-3.2-5.4c-28.8-28.8-57.9-57.9-87-87c-1.6-1.6-3.5-2.6-5.4-3.2v83.5zm5.4 95c6.1 0 11.2-5.1 11.2-11.2s-5.1-11.2-11.2-11.2h-33.6c-6.1 0-11.2 5.1-11.2 11.2v89.9c0 6.1 5.1 11.2 11.2 11.2s11.2-5.1 11.2-11.2V312h22.4c6.1 0 11.2-5.1 11.2-11.2s-5.1-11.2-11.2-11.2H281v-22.4h22.4zm184 70.7c-7.4 7-14.4 14.4-21.4 21.4v-67.2c0-6.7-5.4-12.2-12.2-12.2s-12.2 5.4-12.2 12.2V359c-7.4-7.4-14.4-14.4-21.4-21.4c-4.8-4.8-12.2-4.8-17 0s-4.8 12.2 0 17c14.1 14.1 27.8 27.8 41.9 41.9c4.5 4.8 12.2 4.8 17 0c14.1-14.1 27.8-27.8 41.9-41.9c4.8-4.8 4.8-12.2 0-17s-12.2-4.8-17 0zM177.9 424c-13.4 0-24-10.6-24-24v-15.4h-24V400c0 26.6 21.4 48 48 48h192c2.6 0 4.8 0 7.4-.6c-9.3-6.7-17.9-14.7-25.3-23.4H177.9z' />
        </svg>
        <p className='download-report-pdf'>Download Data Appendix</p>
      </Button>
      <div className='mobile-version-btn' onClick={() => generatePdfDocument()}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#ffffff'
          strokeWidth={"2"}
          strokeLinecap={"round"}
          strokeLinejoin={"round"}
        >
          <path d='M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5' />
        </svg>
        <span>PDF</span>
      </div>
    </div>
  )
  // return (
  // <div>
  //     {/* <PDFDownloadLink document={<MyDoc />} fileName={(window.location.pathname).substring(8) + "-report.pdf"}>
  //     {({ blob, url, loading, error }) =>
  //         loading ? 'Loading document...' : 'Download Report PDF'
  //     }
  //     </PDFDownloadLink> */}
  // </div>
}

export default PDFReport
