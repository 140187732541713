import * as React from "react"
import "../../../styles/adminDashboard.css"
import { useState, useEffect } from "react"
import LoadingSpinner from "../../global/LoadingSpinner"
import PieChart from "../../global/TemplatePiechart"
import LineChartComponent from "./LineChartComponent"
import Table from "../../global/TemplateTable"

export const DashboardPage = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    top_portfolios: ["No data to show"],
    user_growth: ["No data to show"],
    top_ten_consumption_users: [["No data to show"]],
    total_users_building_newaccount_adminpage: ["No data to show"],
  })

  useEffect(() => {
    setIsLoading(true)
    if (props.data) {
      setData(props.data)
      setIsLoading(false)
    }
  }, [props])

  return (
    <div className=' admin-dashboardPage'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className='bigContainer'>
            {/* first container */}
            <div className='container drop-shadow-md'>
              <div className='topBox'>
                <div className='box topBar'>
                  <h2 className='text borderline'>Total Buildings</h2>
                  <h2 className='text borderline'>Total Users</h2>
                  <h2 className='account borderline'>
                    <div className='text'>New Accounts</div>
                    <div className='text2'>in last 30 days</div>
                  </h2>
                </div>

                <div className='box boxNumber'>
                  <div className='number line'>
                    {data.total_users_building_newaccount_adminpage[0]}
                  </div>
                  <div className='number line'>
                    {data.total_users_building_newaccount_adminpage[1]}
                  </div>
                  <div className='number'>
                    {data.total_users_building_newaccount_adminpage[2]}
                  </div>
                </div>
              </div>
            </div>
            {/* second container */}
            <div className='container drop-shadow-md'>
              <div className='boxes'>
                <div className='box'>
                  <div className='pieChart'>
                    <PieChart
                      widgetClassName='h-full w-full flex gap-5'
                      containerClassName='h-full w-full pt-10 px-5 custom-frame'
                      legendAlignment='start'
                      chartTitle='Top 15 Portfolios'
                      labelAttribute='site_name'
                      dataAttribute='ghg_tonnes_co2e'
                      dataset={data.top_portfolios}
                    />
                  </div>
                </div>

                <div className='box tableChart'>
                  <Table
                    data={data.top_ten_consumption_users}
                    skipFirstCol={false}
                    addLocale={true}
                    title={"Top 10 Most Valuable Accounts"}
                    filename={"top_10_most_valuable_account.csv"}
                    headers={["Account Name", "Email", "Total Consumption"]}
                  />
                </div>
              </div>
            </div>
            {/* third container */}
            <div className='bottomContainer drop-shadow-md'>
              <div className='widget-header'>
                <h2>User Growth in the last 12 months</h2>
              </div>

              <div className='graph'>
                <LineChartComponent dataset={data.user_growth} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
