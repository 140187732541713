import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

import { Bar } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

function BaselineBarChart({ data, options }) {
  return (
    <div className='flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>{options.plugins.title.text}</p>

      <div className=' flex flex-col items-center custom-frame p-6'>
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

export default BaselineBarChart
