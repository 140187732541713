import { useState, useRef } from 'react';
import ModalDialog from '../../../home/components/ModalDialog';

function ProjectsTable({
  list_of_data,
  action_function,
  header_names,
  filter_params,
  delete_project_function,
}) {
  // Table sticky header and scrollable body
  // sort functions ascending, descending, for each column
  // sort depending on data type
  // add 'Action' column with link to manage user_projects page
  // add 'Filters' button to open filters tab
  // add 'Set Filters' button to set filters and refresh table
  // add 'Clear Filters' button to clear filters and refresh table
  // add 'Search' input to search for specific user

  // parameters: list_of_data, action_function, header_names, filter_params
  // list_of_data: list of data to be displayed in table
  // header_names: list of column names to be displayed in table

  const [sortOrder, setSortOrder] = useState({});
  const modal_ref = useRef(null)
  const [deletedProject, setDeletedProject] = useState("")

  function handleOpen(data) {
    setDeletedProject(data)
    modal_ref.current.showModal()
  }
  function handleConfirmDeleteBuilding() {
    console.log("Deleting project with project number: ", deletedProject.project_number)
    return delete_project_function(deletedProject.project_number)
  }

  function sort(e) {
    // sort list_of_data by column_name
    // return sorted list_of_data

    let column_name = e.target.innerText.toLowerCase().replace(' ', '_');

    if (column_name !== 'users') {
      if (sortOrder[column_name] === 'asc') {
        list_of_data.reverse();
        setSortOrder({ [column_name]: 'dsc' });
      } else {
        if (list_of_data[0]) {
          if (typeof list_of_data[0][column_name] === 'number') {
            list_of_data.sort((a, b) => a[column_name] - b[column_name]);
          } else {
            list_of_data.sort((a, b) => {
              if (!a[column_name]) {
                return 1;
              } else if (!b[column_name]) {
                return -1;
              } else {
                return a[column_name].localeCompare(b[column_name]);
              }
            });
          }
        }
        setSortOrder({ [column_name]: 'asc' });
      }
    }
  }

  function SortIndicator({ column_name }) {
    if (sortOrder[column_name] === 'asc') {
      return <span className='material-symbols-outlined'>arrow_drop_up</span>;
    } else if (sortOrder[column_name] === 'dsc') {
      return <span className='material-symbols-outlined'>arrow_drop_down</span>;
    } else {
      return (
        <span
          className='material-symbols-outlined'
          style={{ visibility: 'hidden' }}
        >
          remove
        </span>
      );
    }
  }

  return (
    <>
      <div className='users-table-body-scroll'>
        <table className='users-table-container'>
          <thead className='table-header-sticky'>
            <tr>
              {header_names.map((column_name, i) => (
                <th key={i} className='px-4 py-2'>
                  <div className='table-header-sort'>
                    <div onClick={sort}>{column_name.replace('_', ' ')}</div>
                    <SortIndicator column_name={column_name} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list_of_data.length > 0 ? (
              list_of_data.map((data, i) => (
                <tr key={i}>
                  {header_names.map((column_name, i) => {
                    // if column_name is 'users' then return a button
                    if (column_name === 'users') {
                      return (
                        <td key={i} className='border px-4 py-2'>
                          <button
                            className='button-user-projects'
                            onClick={() => action_function(data)}
                          >
                            <span className='material-symbols-outlined'>
                              people
                            </span>
                          </button>
                        </td>
                      );
                    } else if (column_name === "delete") {
                      return (
                        <>
                          <td key={i} className='border px-4 py-2'>
                            <button
                              className='delete-user-button'
                              onClick={() => handleOpen(data)}
                            >
                              <span className='material-symbols-outlined'>
                                delete
                              </span>
                            </button>
                            <ModalDialog
                              content_title={"Delete Project?"}
                              content_body={
                                <>
                                  Deleting this property will result in
                                  the loss of all saved analysis data associated with {deletedProject.site_name}.
                                  <br />
                                  Are you sure you want to delete
                                  <span className='dialog-building-name'> {deletedProject.site_name}</span>?
                                </>
                              }
                              btn_confirm={"Delete Building"}
                              btn_cancel={"Cancel"}
                              btn_close={true}
                              fn_confirm={handleConfirmDeleteBuilding}
                              modal_ref={modal_ref}
                            />
                          </td>
                        </>
                      )
                    } else {
                      return (
                        <td key={i} className='border px-4 py-2'>
                          {data[column_name] ? data[column_name] : '-'}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={header_names.length}>
                  <div className='error-no-data-text'>No Records</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ProjectsTable;
