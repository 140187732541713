import { useEffect, useState } from "react"
import "../../../styles/importProperty/ActionButton.css"

function ActionButton({ theme, type, text }) {
  const [className, setClassName] = useState("")

  useEffect(() => {
    if (theme === "light") {
      setClassName("file-button-action-white")
    } else {
      setClassName("file-button-action")
    }
  }, [theme])

  return (
    <button type={type} className={className}>
      {text}
    </button>
  )
}
export default ActionButton
