import { useState, useEffect } from "react"
import ECMTable from "./components/ECMTable"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import "../../../styles/analysisTools/multi-filter.css"
import { save_update_decarb_plan } from "../../../utils/HttpClient"

function DecarbPlanner(props) {
  const [selectedECMs, setSelectedECMs] = useState({})
  const [planName, setPlanName] = useState("Decarbonization Plan")
  const [selectedPlanID, setSelectedPlanID] = useState(-1)

  const [tableData, setTableData] = useState([])
  const [ecmYearsData, setEcmYearsData] = useState({})

  const start_year = new Date().getFullYear()
  const [endYear, setEndYear] = useState(start_year)
  const [yearRange, setYearRange] = useState([])

  const header_names = [
    { column: "delete", header: "Delete" },
    { column: "dropdown", header: "Year" },
    { column: "site_name", header: "Site Name" },
    { column: "ecm_description", header: "Description" },
    {
      column: "ecm_gas_gj_savings",
      header: "Annual Gas Energy Saving (GJ/yr)",
    },
    {
      column: "ecm_electric_kwh_savings",
      header: "Annual Electrical Energy Saving (kWh/yr)",
    },
    {
      column: "ecm_electric_kw_savings",
      header: "Annual Electrical Power Reduction (kW)",
    },
    { column: "ecm_water_m3_savings", header: "Annual Water Saving (m³/yr)" },
    { column: "ecm_capital_cost", header: "ECM Capital Cost ($)" },
    { column: "ecm_roi", header: "ROI (%)" },
    { column: "ecm_npv", header: "NPV ($)" },
    { column: "ecm_payback", header: "Simple Payback (yrs)" },
    { column: "ecm_incentive", header: "Incentive ($)" },
    { column: "ecm_savings", header: "First Year Saving ($)" },
  ]

  useEffect(() => {
    if (props.planned_ecms.length > 0) {
      setTableData(props.planned_ecms)

      let ecm_years_data_copy = { ...ecmYearsData }
      const plannedEcmIdentifiers = new Set(
        props.planned_ecms.map((ecm) => ecm.ecm_identifier)
      )

      // Add or update sets based on planned_ecms
      for (let ecm of props.planned_ecms) {
        let ecm_year = ecm.year_of_implementation
        let ecm_identifier = ecm.ecm_identifier
        if (ecm_years_data_copy[ecm_year]) {
          ecm_years_data_copy[ecm_year].add(ecm_identifier)
        } else {
          ecm_years_data_copy[ecm_year] = new Set([ecm_identifier])
        }
      }
      // Remove identifiers from each year that are not in plannedEcmIdentifiers
      for (const [year, identifierSet] of Object.entries(ecm_years_data_copy)) {
        for (const identifier of Array.from(identifierSet)) {
          if (!plannedEcmIdentifiers.has(identifier)) {
            identifierSet.delete(identifier)
          }
        }
      }
      setEcmYearsData(ecm_years_data_copy)
    } else {
      setTableData([])
      setEndYear(start_year)
      props.set_analyze_plan(false)
    }
    if (props.selected_plan_name.length > 0) {
      setPlanName(props.selected_plan_name)
    }
    if (props.selected_plan_option.value) {
      setSelectedPlanID(props.selected_plan_option.value)
      if (props.loaded_plan) {
        setEndYear(props.selected_plan_end_year)
      } else {
        setEndYear(start_year)
      }
    } else {
      setSelectedPlanID(-1)
    }
  }, [props.planned_ecms])

  // when end year is set, update row
  useEffect(() => {
    if (selectedPlanID > 0 && props.loaded_plan) {
      if (Object.keys(props.selected_plan_contents).length > 0) {
        buildECMYearsDataFromPlan(props.selected_plan_contents)
      }
    } else if (
      Object.keys(ecmYearsData).some(
        (key) => key !== "null" && ecmYearsData[key].size > 0
      )
    ) {
      const ecm_years_data = updateECMYearsObject(endYear)
      let years_array = generateYearsArray(endYear)
      setYearRange(years_array)
      setEcmYearsData(ecm_years_data)
      props.set_end_year(endYear)
    } else {
      let years_array = generateYearsArray(endYear)
      let ecm_years_data = generateECMYearsObject(endYear)
      setYearRange(years_array)
      setEcmYearsData(ecm_years_data)
      props.set_end_year(endYear)
    }
  }, [endYear])

  function generateECMYearsObject(end) {
    let ecm_years_data = {}
    for (let year = start_year; year <= end; year++) {
      ecm_years_data[year] = new Set()
    }
    return ecm_years_data
  }

  function updateECMYearsObject(endYear) {
    let ecm_years_data_copy = { ...ecmYearsData }

    const years_list = Object.keys(ecm_years_data_copy).map((year) =>
      Number(year)
    )
    const max_year = Math.max(...years_list)

    if (endYear > max_year) {
      for (let year = max_year + 1; year <= endYear; year++) {
        ecm_years_data_copy[year] = new Set()
      }
    } else if (endYear < max_year) {
      for (let year = max_year; year > endYear; year--) {
        delete ecm_years_data_copy[year]
      }
    }
    return ecm_years_data_copy
  }

  function generateYearsArray(end) {
    let years_array = []
    for (let year = start_year; year <= end; year++) {
      years_array.push(year)
    }
    return years_array
  }

  function buildECMYearsDataFromPlan(plan_contents) {
    let years_array = []
    let ecm_years_data = {}

    let plan_contents_keys = Object.keys(plan_contents)
    let plan_end_year = Number(
      plan_contents_keys[plan_contents_keys.length - 1]
    )

    for (let year = start_year; year <= plan_end_year; year++) {
      years_array.push(year)
      ecm_years_data[year] = new Set(plan_contents[year])
    }

    setYearRange(years_array)
    setEcmYearsData(ecm_years_data)
  }

  function onChangeHandler(e) {
    if (!props.loaded_plan) {
      let input = parseInt(e.target.value)
      if (e.target.value.length > 3 && input < start_year) {
        setEndYear(start_year)
      } else if (input > start_year + 30) {
        setEndYear(start_year + 30)
      } else {
        setEndYear(input)
      }
    }
  }

  function onClickIncrement() {
    if (!props.loaded_plan) {
      if (endYear >= start_year + 30) return
      setEndYear(endYear + 1)
    }
  }

  function onClickDecrement() {
    if (!props.loaded_plan) {
      if (endYear <= start_year) return
      setEndYear(endYear - 1)
    }
  }

  function checkECMYearsValid() {
    for (let ecm of tableData) {
      if (!ecm.year_of_implementation) {
        return false
      }
    }
    return true
  }

  function onClickSavePlan(e) {
    e.preventDefault()
    let plan_object = new FormData()
    plan_object.append("plan_name", planName)

    let plan_contents = {}
    let new_plan_label = ""
    // loop through ecmYearsData's keys and add to plan_contents
    for (let key of Object.keys(ecmYearsData)) {
      let ecm_data = [...ecmYearsData[key]]
      plan_contents[key] = ecm_data
    }
    if (!checkECMYearsValid()) {
      alert("Please select the Year for each Upgrade")
    } else {
      let str_plan_contents = JSON.stringify(plan_contents)
      plan_object.append("plan_contents", str_plan_contents)

      if (props.selected_project_numbers.length > 0) {
        plan_object.append(
          "project_numbers",
          JSON.stringify(props.selected_project_numbers)
        )
      }

      plan_object.append("account_number", props.selected_account_option.value)

      // attach plan id if updating
      plan_object.append("plan_id", selectedPlanID)

      let account_ecm_table_data =
        props.all_ecm_table_data[props.selected_account_option.value]

      let project_numbers = []
      let site_name_project_numbers = []
      account_ecm_table_data.forEach((ecm) => {
        if (!project_numbers.includes(ecm[20])) {
          site_name_project_numbers.push({
            project_number: ecm[20],
            site_name: ecm[1],
          })
        }
      })

      let site_names = ""
      for (let project_number of props.selected_project_numbers) {
        let selected_site_name = site_name_project_numbers.filter(
          (site) => site.project_number === project_number
        )
        if (selected_site_name.length > 0) {
          site_names += selected_site_name[0].site_name + ", "
        }
      }
      new_plan_label = site_names.slice(0, -2) + " - " + planName

      // make api call to save plan
      save_update_decarb_plan(plan_object)
        .then((response) => {
          if (response.error) {
            console.error(response)
            alert(response.errorMessage)
          } else {
            setPlanName(response.plan_name)
            setSelectedPlanID(response.plan_id)
            props.set_selected_plan_option({
              value: response.plan_id,
              label: new_plan_label,
            })
            props.fn_get_user_data()
            props.analyze_plan()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  // TODO after reset, rebuild the ecmYearsData as Sets
  function onClickReset() {
    props.set_loaded_plan(false)
    props.reset_ecm_years()
    props.set_selected_plan_contents({})
    setEcmYearsData({})
    setEndYear(start_year)
    props.set_sofiac_qualification({})
    props.set_cmhc_level("")
  }

  function onClickClear() {
    onClickReset()
    setTableData([])
    setSelectedECMs({})
    setEcmYearsData({})
    props.set_decarb_plan_ecms([])
    props.set_selected_plan_option([])
    props.set_is_plan_deleted(true)
    props.set_sofiac_qualification({})
    props.set_cmhc_level("")
  }

  return (
    <div className='flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>Decarbonization Planner</p>

      <div className='h-full flex flex-col gap-10 custom-frame p-6'>
        <div className='flex flex-row gap-[10px] justify-center items-center decarb-end-year-container'>
          <div className='end-year-input-label'>
            <p className='m-2.5'>Decarbonization End Year (up to 30 years) :</p>
          </div>
          <div className='flex flex-row gap-[10px]'>
            <div onClick={onClickDecrement} className='end-year-dec-icon'>
              <RemoveIcon fontSize='14px' />
            </div>

            <input
              type='number'
              className='end-year-input'
              min={start_year}
              value={endYear}
              onChange={onChangeHandler}
            />

            <div onClick={onClickIncrement} className='end-year-inc-icon'>
              <AddIcon fontSize='14px' />
            </div>

            <button className='reset-button-gray' onClick={onClickReset}>
              Reset
            </button>
            <button className='submit-button-blue' onClick={onClickClear}>
              Clear Plan
            </button>
          </div>
        </div>
        {tableData.length > 0 ? (
          <>
            <ECMTable
              id='decarb-planner-table'
              table_headers={header_names}
              ecm_list={tableData}
              set_ecm_list={setTableData}
              selected_ecms={selectedECMs}
              set_selected_ecms={setSelectedECMs}
              ecm_year_range={yearRange}
              ecm_years_data={ecmYearsData}
              set_ecm_years_data={setEcmYearsData}
              update_decarb_plan_ecms={props.update_decarb_plan_ecms}
            />
            <div className='flex justify-end'>
              <div className='flex flex-row gap-5 justify-center items-center'>
                <div className='end-year-input-label'>
                  <p>Decarbonization Plan Name :</p>
                </div>
                <form
                  className='save_plan_name_form'
                  onSubmit={onClickSavePlan}
                >
                  <input
                    type='text'
                    className='plan-name-input'
                    name='plan_name'
                    value={planName}
                    required
                    placeholder='Enter Plan Name'
                    onChange={(e) => setPlanName(e.target.value)}
                  />

                  <div>
                    <button
                      className='button-action-white'
                      disabled={planName.length === 0}
                    >
                      Save Plan
                    </button>
                  </div>
                </form>
              </div>

              <button className='button-action' onClick={props.analyze_plan}>
                Analyze Plan
              </button>
            </div>
          </>
        ) : (
          <p className='error-no-data-text'>
            Create a plan, select implementation years, and analyze the plan.
          </p>
        )}
      </div>
    </div>
  )
}
export default DecarbPlanner
