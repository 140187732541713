export const water_fixture_types_max_flow_defaults = [
  {
    fixture_type: "Kitchen Sinks",
    max_flow_rate_default: [
      {
        construction_year: "0 to 1990",
        max_flow_rate: 5,
      },
      {
        construction_year: "1991 to 2006",
        max_flow_rate: 2.5,
      },
      {
        construction_year: "2007 to now",
        max_flow_rate: 2.4,
      },
    ],
  },
  {
    fixture_type: "Lavatories",
    max_flow_rate_default: [
      {
        construction_year: "0 to 1990",
        max_flow_rate: 2.5,
      },
      {
        construction_year: "1991 to 2006",
        max_flow_rate: 1.5,
      },
      {
        construction_year: "2007 to now",
        max_flow_rate: 1.2,
      },
    ],
  },
  {
    fixture_type: "Showers",
    max_flow_rate_default: [
      {
        construction_year: "0 to 1990",
        max_flow_rate: 5,
      },
      {
        construction_year: "1991 to 2006",
        max_flow_rate: 2.5,
      },
      {
        construction_year: "2007 to now",
        max_flow_rate: 2.4,
      },
    ],
  },
  {
    fixture_type: "Toilets",
    max_flow_rate_default: [
      {
        construction_year: "0 to 1990",
        max_flow_rate: 3.5,
      },
      {
        construction_year: "1991 to 2006",
        max_flow_rate: 1.6,
      },
      {
        construction_year: "2007 to now",
        max_flow_rate: 1.28,
      },
    ],
  },
  {
    fixture_type: "Urinals",
    max_flow_rate_default: [
      {
        construction_year: "0 to 1990",
        max_flow_rate: 3.5,
      },
      {
        construction_year: "1991 to 2006",
        max_flow_rate: 1.6,
      },
      {
        construction_year: "2007 to now",
        max_flow_rate: 1.28,
      },
    ],
  },
]

export const water_fixtures_headers_array = [
  {
    header_name: "edit_row",
    header_key: "edit_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
  {
    header_name: "Fixture Type",
    header_key: "fixture_type",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "md",
  },
  {
    header_name: "No. of Fixtures",
    header_key: "num_of_fixtures",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "Max. Flow Rate",
    header_key: "max_flow_rate",
    is_required: true,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "Units",
    header_key: "flow_rate_unit",
    is_required: false,
    is_sortable: false,
    sort_order: null,
    cell_width: "sm",
  },
  {
    header_name: "delete_row",
    header_key: "delete_row",
    is_required: false,
    is_sortable: false,
    sort_order: null,
  },
]

export const water_fixtures_input_props_array = [
  {
    header_name: "Fixture Type",
    header_key: "fixture_type",
    input_type: "select",
    options: ["Kitchen Sinks", "Lavatories", "Showers", "Toilets", "Urinals"],
    is_required: true,
    default_value: "Kitchen Sinks",
    place_holder: "Select fixture type",
    add_new_option: true,
  },
  {
    header_name: "No. of Fixtures",
    header_key: "num_of_fixtures",
    input_type: "number",
    is_required: true,
    default_value: "",
    place_holder: "Enter number of fixtures",
  },
  {
    header_name: "Max. Flow Rate",
    header_key: "max_flow_rate",
    input_type: "number",
    is_required: true,
    default_value: "",
    place_holder: "Enter max flow rate",
  },
  {
    header_name: "Units",
    header_key: "flow_rate_unit",
    input_type: "select",
    options: ["GPM", "GPF"],
    is_required: true,
    default_value: "GPM",
    place_holder: "Select units",
  },
]
