import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import {
  editProviderElectricityBasicChargePost,
  getAllProviders,
  getAllProviderRateCategories,
} from '../../../utils/HttpClient';
import { useLocation } from 'react-router-dom';

export const EditProviderElectricityBasicChargeRatePage = (props) => {
  const location = useLocation();

  const [defaultProviderCategories, setDefaultProviderCategories] = useState(
    []
  );
  const [provider, setProvider] = useState(null);
  const [providers, setProviders] = useState([]);
  const [providerCategory, setProviderCategory] = useState(null);
  const [providerCategories, setProviderCategories] = useState([]);

  const [data, setData] = useState({
    providerId: '',
    isNewProvider: false,
    providerCategoryId: '',
    isNewProviderCategory: false,
    valuePerDay: '',
  });
  const editProviderElectricityBasicChargeRate = (e) => {
    e.preventDefault();
    data.provider_electricity_energy_admin_rate_id = location.state[0];
    data.providerId = provider.value;
    data.isNewProvider = provider.isNew ?? false;
    data.providerCategoryId = providerCategory.value;
    data.isNewProviderCategory = providerCategory.isNew ?? false;
    editProviderElectricityBasicChargePost(data)
      .then((res) => {
        window.location.href = '/rates';
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setData({
      ...data,
      [event.target.name]: value,
    });
  };
  const handleChangeProvider = (newProvider) => {
    setProvider(newProvider);
    setProviderCategory(null);
    setProviderCategories(
      defaultProviderCategories.filter(
        (pc) => pc.providerId == newProvider.value
      )
    );
  };
  const handleCreateProvider = (newProvider) => {
    const newProviderObj = {
      label: newProvider,
      value: newProvider,
      isNew: true,
    };
    setProvider(newProviderObj);
    setProviders((prev) => [...prev, newProviderObj]);
    setProviderCategory(null);
    setProviderCategories([]);
  };
  const handleCreateProviderCategory = (newProviderCategory) => {
    const newProviderCategoryObj = {
      label: newProviderCategory,
      value: newProviderCategory,
      isNew: true,
    };
    setProviderCategory(newProviderCategoryObj);
    setProviderCategories((prev) => [...prev, newProviderCategoryObj]);
  };

  useEffect(() => {
    var promise1 = getAllProviders()
      .then((res) => {
        const allRes = res.data.map((r) => {
          return {
            value: r.provider_id,
            label: r.provider_name,
          };
        });
        setProviders(allRes);
        return allRes;
      })
      .catch((err) => console.log(err));
    var promise2 = getAllProviderRateCategories()
      .then((res) => {
        const allRes = res.data.map((r) => {
          return {
            value: r.provider_rate_category_id,
            providerId: r.provider_id,
            label: r.category_name,
          };
        });
        setDefaultProviderCategories(allRes);
        return allRes;
      })
      .catch((err) => console.log(err));
    Promise.all([promise1, promise2]).then(function (valArray) {
      setData({
        ...data,
        providerId: valArray[0].filter((c) => c.label == location.state[1])[0]
          .value,
        provider: valArray[0].filter((c) => c.label == location.state[1])[0]
          .label,
        providerCategoryId: valArray[1].filter(
          (c) => c.label == location.state[2]
        )[0].value,
        providerCategory: valArray[1].filter(
          (c) => c.label == location.state[2]
        )[0].label,
        valuePerDay: location.state[3],
      });
      setProvider(valArray[0].filter((c) => c.label == location.state[1])[0]);
      setProviderCategory(
        valArray[1].filter((c) => c.label == location.state[2])[0]
      );
      setProviderCategories(
        valArray[1].filter(
          (pc) =>
            pc.providerId ==
            valArray[0].filter((c) => c.label == location.state[1])[0].value
        )
      );
    });
  }, []);

  return (
    <div className='mt-7 mb-16 drop-shadow-md bg-white p-5'>
      <h1 className='mb-7 mt-3 text-center text-black'>
        Edit Provider Electricity Basic Charge
      </h1>
      <div className='pl-14 pr-14 pb-7'>
        <div>
          <div className='widget-header'>
            <h2>{props.title}</h2>
          </div>
          <table id='description'>
            <tbody>
              <tr>
                <td>Provider Name</td>
                <td>
                  <CreatableSelect
                    name='provider'
                    options={providers}
                    onChange={handleChangeProvider}
                    onCreateOption={handleCreateProvider}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={provider}
                  />
                </td>
              </tr>
              <tr>
                <td>Provider Category</td>
                <td>
                  <CreatableSelect
                    name='providerCategory'
                    options={providerCategories}
                    onChange={setProviderCategory}
                    onCreateOption={handleCreateProviderCategory}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={providerCategory}
                  />
                </td>
              </tr>
              <tr>
                <td>Value per Day</td>
                <td>
                  <TextField
                    name='valuePerDay'
                    onChange={handleChange}
                    value={data.valuePerDay}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr></hr>
          <div className='grid grid-cols-6 mt-5 mb-3'>
            <div className='col-start-6 mt-1'>
              <Button
                onClick={() => (window.location.href = '/rates')}
                sx={{ borderRadius: 28 }}
              >
                <Typography style={{ color: '#636363', textTransform: 'none' }}>
                  Cancel
                </Typography>
              </Button>
            </div>
            <div className='col-start-7'>
              <Button
                color='primary'
                onClick={editProviderElectricityBasicChargeRate}
                sx={{ borderRadius: 28 }}
                style={{
                  backgroundColor: '#03045e',
                  width: 150,
                  padding: 10,
                }}
                variant='contained'
              >
                <Typography style={{ color: 'var(--white)', textTransform: 'none' }}>
                  Save
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
