import { Slider } from "@material-ui/core"
import { createTheme, MuiThemeProvider, alpha } from "@material-ui/core/styles"
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from "@mui/material"
import OutlinedInput from "@mui/material/OutlinedInput"
import MuiInput from "@mui/material/Input"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from "react-select"
import "../../../styles/analysisTools/filter-menu.css"
import LoadingSpinner from "../../global/LoadingSpinner"
import FilterTable from "../../global/TemplateTable"
import ProjectedCostsTable from "./ProjectedCostsTable"

//general theme, styling for some mui elements
const theme = createTheme({
  palette: {
    primary: { main: "#03045e" },
    secondary: { main: "#03045e" },
  },
  typography: {
    Checkbox: { textTransform: "lower" },
  },
})

/**
 *  props.data = data.all_data.analysis
 *  {
 *     0: ecm.ecm_identifier
 *     1: participant.site_name
 *     2: participant.province
 *     3: ecm.description
 *     4: ecm.proposed_cost_to_install
 *     5: ecm.base_case_cost_to_install
 *     6: ecm.irr
 *     7: ecm.simple_payback
 *     8: ecm.npv
 *     9: ecm.roi
 *     10: ecm.gas_gj_savings_per_year
 *     11: ecm.electric_kwh_savings_per_year
 *     12: ecm.electric_kw_savings
 *     13: ecm.water_savings_m3
 *     14: ecm.incentive
 *     15: ecm.first_year_utility_savings
 *     16: ecm.group_number
 *     17: ecm.hierarchy
 *     18: ecm.year_of_implementation
 *  }
 *
 *  0 - 8 are for the Analysis page
 *  9 - 16 are for the Bundle page
 *  17 is for the Decarb page
 *
 */

export const Budget = (props) => {
  const InputSmall = styled(MuiInput)`
    width: 42px;
  `
  // States
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    utility_ghg: [["No data to show"]],
    building_analysis: [
      ["No data to show", "-", "-", "-", "-", "-", "-", "-", "-", "-"],
    ],
    ecm_analysis_table: [
      "-",
      "No ECM data to show",
      "No ECM data to show",
      "No ECM data to show",
    ],
    year_analysis: [["No data to show", "-", "-", "-", "-"]],
  })
  const [tableData, setTableData] = useState([
    ["-", "No ECM Data to show", "-", "-", "-", "-", "-", "-", "-"],
  ])

  // handles the values for the max replacement cost filter
  const [replacementCost, setReplacementCost] = React.useState({
    amount: "",
  })

  // handles the values of the multi-select dropdowns
  const [buildingNames, setBuildingNames] = useState([])
  const [provinceNames, setProvinceNames] = useState([])

  // handles the checkbox field for consumption
  const [energyType, setEnergyType] = React.useState({
    electricity: false,
    water: false,
    gas: false,
  })
  const [value, setValue] = React.useState(0)
  const [years, setYears] = React.useState({ year: "" })

  // data fills the dropdown menus for provinces, and buildings
  const [buildingsFiltered, setBuildingsFiltered] = useState([])

  const provinces = [
    { value: "bc", label: "BC" },
    { value: "ab", label: "AB" },
    { value: "sk", label: "SK" },
    { value: "mb", label: "MB" },
    { value: "on", label: "ON" },
    { value: "qc", label: "QC" },
    { value: "nl", label: "NL" },
    { value: "nb", label: "NB" },
    { value: "ns", label: "NS" },
    { value: "pe", label: "PE" },
    { value: "yt", label: "YT" },
    { value: "nt", label: "NT" },
    { value: "nu", label: "NU" },
  ]

  const provincesObject = {
    "british columbia": "BC",
    alberta: "AB",
    saskatchewan: "SK",
    manitoba: "MB",
    ontario: "ON",
    quebec: "QC",
    "new brunswick": "NB",
    "prince edward island": "PE",
    "nova scotia": "NS",
    "newfoundland and labrador": "NL",
    yukon: "YT",
    "northwest territories": "NT",
    nunavut: "NU",
  }

  const [scrollTop, setScrollTop] = useState(0)
  const { electricity, water, gas } = energyType

  //navigate button
  const navigate = useNavigate()

  // Handlers
  // handles user input in the replacement cost input field
  const handleReplacementChange = (prop) => (event) => {
    setReplacementCost({
      ...replacementCost,
      [prop]: event.target.value,
    })
  }

  // handles user input in the simple payback input field
  const handleYearsChange = (prop) => (event) => {
    setYears({ ...years, [prop]: event.target.value })
  }

  // handles energy type checkbox
  const handleCheckBoxChange = (event) => {
    setEnergyType({
      ...energyType,
      [event.target.name]: event.target.checked,
    })
  }

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value))
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue(0)
    } else if (value > 100) {
      setValue(100)
    }
  }

  function pushBuildingDropdownData(table) {
    let filteredData = []
    filteredData.push({
      value: table[0][1],
      label: table[0][1],
    })
    for (let i = 1; i < table.length; i++) {
      if (table[i][1] !== table[i - 1][1]) {
        filteredData.push({
          value: table[i][1],
          label: table[i][1],
        })
      }
    }

    return filteredData
  }

  window.addEventListener("scroll", function () {
    setScrollTop(this.window.scrollY)
  })

  // gets data
  useEffect(() => {
    /* at start of getData request, turn on spinner, when data is set turn off spinner */
    setIsLoading(true)
    if (props.data) {
      let ecmTable = props.data.ecm_analysis_table
      for (let index = 0; index < ecmTable.length; index++) {
        ecmTable[index][6] =
          ecmTable[index][6] <= 1
            ? ecmTable[index][6] * 100
            : ecmTable[index][6]
      }

      // removes the unnecessary columns from the ecm table
      ecmTable = ecmTable.map((row) => row.slice(0, 9))
      ecmTable = ecmTable.map((row) => {
        const province = row[2].trim().toLowerCase()
        if (provincesObject[province]) {
          row[2] = provincesObject[province]
        } else if (
          Object.values(provincesObject).includes(province.toUpperCase())
        ) {
          row[2] = province.toUpperCase()
        }
        return row
      })
      let new_data = { ...props.data, ecm_analysis_table: ecmTable }
      setData(new_data)
      setTableData(ecmTable)
      setBuildingsFiltered(pushBuildingDropdownData(ecmTable))
      setIsLoading(false)
    }
  }, [props])

  const applyFilters = () => {
    let filtered = data.ecm_analysis_table // applies filters to ecm table data.

    // building name
    if (buildingNames.length > 0) {
      let buildings = []
      buildingNames.forEach((building) => {
        buildings.push(
          ...filtered.filter((rows) => rows.includes(building["label"]))
        )
      })
      filtered = buildings
    }

    // region
    if (provinceNames.length > 0) {
      let buildings = []
      provinceNames.forEach((region) => {
        buildings.push(
          ...filtered.filter((rows) => rows.includes(region["label"]))
        )
      })
      filtered = buildings
    }

    // replacement cost
    if (replacementCost.amount > 0) {
      // !! index will change if SQL query is changed. index 4 is replacement cost currently
      filtered = [
        ...filtered.filter(
          (rows) => parseFloat(rows[4]) <= parseFloat(replacementCost.amount)
        ),
      ]
    }

    // IRR
    if (value) {
      filtered = [
        ...filtered.filter((rows) => parseFloat(rows[6]) > parseFloat(value)),
      ]
    }

    // Simple Payback
    if (years.year > 0) {
      // !! index will change if SQL query is changed. index 7 is simple payback (years) currently
      filtered = [
        ...filtered.filter(
          (rows) => parseFloat(rows[7]) <= parseFloat(years.year)
        ),
      ]
    }

    // apply if filters are present
    // if (filtered.length > 0) {
    setTableData([...filtered]) // July 2022 fix: In case filters are applied but no rows are returned, still display empty table. E.g. No buildings match filter by province.
    // }
  }

  // reset state of all filter menu options
  const resetFilters = () => {
    setTableData(data.ecm_analysis_table)
    setBuildingNames([])
    setProvinceNames([])
    setValue(0)
    setReplacementCost({ amount: "" })
    setYears({ year: "" })
    setEnergyType({ electricity: false, water: false, gas: false })
  }

  const [hamburgerTitle, setHambergerTitle] = useState("Building Details")
  const [activedHamburgerBtn, setActivedHamburgerBtn] = useState("active_menu")
  const [activeSection, setActiveSection] = useState(0)

  const toggleSection = (index) => {
    setActiveSection(index)
  }

  return (
    <div id='analysis-container' className='mb-8'>
      <div className='title w-full flex justify-between items-center'>
        <h1 className='section_heading_1'>Budgeting ECMs</h1>
        <button className='button-action-white' onClick={() => navigate(-1)}>
          back to analysis tools
        </button>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div className='hidden'>
        <ul id='toggle-container' className='flex report-tabs'>
          <div id='sub-hamburger-header'>
            {
              {
                0: "Portfolio Filters",
                1: "Available ECMs",
                2: "Portfolio Planning",
              }[activeSection]
            }
          </div>
          {/* <div className={`${subNavMenu} sub-hamberger-element tabs`}><a href="#total-consumption-section">Total Consumption</a></div> */}
          <div
            className={`${activedHamburgerBtn} ${
              activeSection === 0 ? "sections active-section" : "sections"
            }`}
            onClick={() => [
              toggleSection(0),
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              ),
            ]}
          >
            <a href='#portfolio-filter-section'>Portfolio Filters</a>
          </div>
          <div
            className={`${activedHamburgerBtn} ${
              activeSection === 1 ? "sections active-section" : "sections"
            }`}
            onClick={() => [
              toggleSection(1),
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              ),
            ]}
          >
            <a href='#available-upgrade-section-point'>Available ECMs</a>
          </div>
          <div
            className={`${activedHamburgerBtn} ${
              activeSection === 2 ? "sections active-section" : "sections"
            }`}
            onClick={() => [
              toggleSection(2),
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              ),
            ]}
          >
            <a href='#portfolio-upgrade-section'>Portfolio Planning</a>
          </div>
          <div
            className='navbar-toogleBtn2'
            onClick={() => {
              setActivedHamburgerBtn(
                activedHamburgerBtn === "" ? "active_menu" : ""
              )
            }}
          >
            <i className='fa-solid fa-bars'></i>
          </div>
        </ul>
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className='flex flex-col gap-8 h-full'>
          <MuiThemeProvider theme={theme}>
            <div>
              <h3 className='section_heading_2 p-4'>Filters</h3>
              <div id='portfolio-filter-section' className='custom-frame p-6'>
                <div
                  id='portfolio-filter-container'
                  className=' grid grid-cols-3'
                >
                  <div>
                    <div className=' w-full buget-input-lable'>
                      <h3>Max. ECM Capital Cost($)</h3>
                      <FormControl
                        sx={{ my: 1, width: "100%" }}
                        variant='standard'
                      >
                        <OutlinedInput
                          id='standard-adornment-amount'
                          value={replacementCost.amount}
                          onChange={handleReplacementChange("amount")}
                          sx={{
                            borderRadius: "4px",
                            height: "40px",
                            width: "100%",
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className='mt-6' id='buget-input-lable'>
                      <h3>IRR Over (%)</h3>
                      <Box className='slider-container'>
                        <Slider
                          size='small'
                          aria-label='IRR Over'
                          defaultValue={10}
                          color='primary'
                          valueLabelDisplay='auto'
                          step={0.1}
                          min={0}
                          max={20}
                          onChange={handleSliderChange}
                          value={typeof value === "number" ? value : 0}
                        />
                        <InputSmall
                          size='small'
                          value={value}
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                          inputProps={{
                            step: 10,
                            min: 0,
                            max: 100,
                            type: "number",
                            "aria-labelledby": "input-slider",
                            style: { textAlign: "center" },
                          }}
                          disableUnderline
                          sx={{ width: 40 }}
                        />
                      </Box>
                    </div>
                  </div>
                  <div>
                    <div className=''>
                      <div className='' id='buget-input-lable'>
                        <h3>Building Region</h3>
                        <div className='my-2 w-full'>
                          <Select
                            isMulti
                            name='colors'
                            options={provinces}
                            onChange={setProvinceNames}
                            value={provinceNames}
                            className='basic-multi-select'
                            classNamePrefix='select'
                            menuPortalTarget={document.body}
                          />
                        </div>
                      </div>
                      <div className='mt-8' id='buget-input-lable'>
                        <h3>Buildings</h3>
                        <div className='my-2'>
                          <Select
                            isMulti
                            name='colors'
                            options={buildingsFiltered}
                            onChange={setBuildingNames}
                            value={buildingNames}
                            className='basic-multi-select'
                            classNamePrefix='select'
                            menuPortalTarget={document.body}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='filter-last-col'>
                    {/* <div className=''>
                        <h3 className='pb-2'>Buildings</h3>
                        <FormGroup column>
                          <FormControlLabel
                            sx={{ m: -1 }}
                            control={
                              <Checkbox
                                checked={electricity}
                                onChange={handleCheckBoxChange}
                                name='electricity'
                                style={{ color: "#03045e" }}
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  color: "var(--black)",
                                  textTransform: "none",
                                }}
                              >
                                Electricity
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            sx={{ m: -1 }}
                            control={
                              <Checkbox
                                checked={water}
                                onChange={handleCheckBoxChange}
                                name='water'
                                style={{ color: "#03045e" }}
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  color: "var(--black)",
                                  textTransform: "none",
                                }}
                              >
                                Water
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            sx={{ m: -1 }}
                            control={
                              <Checkbox
                                checked={gas}
                                onChange={handleCheckBoxChange}
                                name='gas'
                                style={{ color: "#03045e" }}
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  color: "var(--black)",
                                  textTransform: "none",
                                }}
                              >
                                Gas
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </div> */}
                    <div className='w-full'>
                      <h3 className=''>Max. Simple Payback</h3>
                      <FormControl
                        sx={{ my: 1, width: "100%" }}
                        variant='standard'
                      >
                        <OutlinedInput
                          sx={{
                            borderRadius: "4px",
                            height: "40px",
                            width: "100%",
                          }}
                          onChange={handleYearsChange("year")}
                          value={years.year}
                        />
                      </FormControl>
                    </div>
                    <div id='available-upgrade-section-point'>
                      <div id='filter-buttons-container'>
                        <Button
                          // id='reset-button-gray'
                          id='budget-reset-btn'
                          variant='contained'
                          onClick={resetFilters}
                        >
                          <Typography
                            style={{
                              // color: "var(--white)",
                              textTransform: "none",
                              // boxShadow: "none",
                              fontWeight: 600,
                              fontSize: "1rem",
                              fontFamily: "Mukta",
                            }}
                          >
                            Reset
                          </Typography>
                        </Button>
                        <Button
                          // id='submit-button-blue'
                          id='budget-apply-btn'
                          variant='contained'
                          onClick={applyFilters}
                        >
                          <Typography
                            style={{
                              color: "var(--white)",
                              textTransform: "none",
                              // boxShadow: "none",
                              fontWeight: 600,
                              fontSize: "1rem",
                              fontFamily: "Mukta",
                            }}
                          >
                            Apply Filters
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MuiThemeProvider>
          <div id='available-upgrade-section'>
            <FilterTable
              data={tableData}
              skipFirstCol={true}
              title={"Available ECMs"}
              filename={"available_upgrades.csv"}
              addLocale={true}
              id_col={true}
              headers={[
                "Site Name",
                "Province",
                "Description",
                "ECM Capital Cost ($)",
                "Baseline Replacement Cost ($)",
                "IRR (%)",
                "Simple Payback (yrs)",
                "NPV ($)",
              ]}
            />
          </div>

          <div id='portfolio-upgrade-section'>
            <h3 className='section_heading_2 p-4'>Portfolio Planning</h3>
            <div className='custom-frame p-6'>
              <ProjectedCostsTable
                dataset={tableData}
                option='building'
                title={"Projected Total Incurred Costs After Upgrades"}
                filename={"projected_incurred_costs.csv"}
                headers={[
                  "Site Name",
                  "Description",
                  "ECM Capital Cost ($)",
                  "Baseline Replacement Cost ($)",
                  "NPV ($)",
                ]}
                downloadCSVHeaders={[
                  "ECM ID",
                  "Site Name",
                  "Province",
                  "Description",
                  "ECM Capital Cost ($)",
                  "Baseline Replacement Cost ($)",
                  "IRR (%)",
                  "Simple Payback (yrs)",
                  "NPV ($)",
                ]}
              />
            </div>

            <a
              href='#analysis-container'
              className={`${scrollTop >= 300 ? "go_top" : "go_top fade-out"}`}
            >
              <svg
                className='fill-white'
                // fill="white"
                width='40'
                height='40'
                xmlns='http://www.w3.org/2000/svg'
                fill-rule='evenodd'
                clip-rule='evenodd'
              >
                <path d='M11 2.206l-6.235 7.528-.765-.645 7.521-9 7.479 9-.764.646-6.236-7.53v21.884h-1v-21.883z' />
              </svg>
              <i className='fa-sharp fa-solid fa-arrow-up'></i>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
