import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getAllProjectUsers,
  getAllUsersButProject,
  addUserProjects,
  removeUserProjects,
} from '../../../../utils/HttpClient';
import ProjectDetails from './ProjectDetails';
import ProjectUsers from './ProjectUsers';
import LoadingSpinner from '../../../global/LoadingSpinner';

function ProjectUsersPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [projectDetails, setProjectDetails] = useState({});
  const [projectUsers, setProjectUsers] = useState([]);
  const [allUsersButProject, setAllUsersButProject] = useState([]);
  const [refreshTable, setRefreshTable] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (state.project_data) {
      setProjectDetails(state.project_data);
      getAllProjectUsers(state.project_data.project_number)
        .then((res) => {
          setProjectUsers(res);
        })
        .catch((err) => {
          console.error(err);
        });
      getAllUsersButProject(state.project_data.project_number)
        .then((res) => {
          setAllUsersButProject(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [state.project_data, refreshTable]);

  function addUserProjectHandler(account_numbers) {
    addUserProjects({
      account_numbers: account_numbers,
      project_numbers: [state.project_data.project_number],
    })
      .then(() => {
        props.getUserData();
      })
      .then(() => {
        setIsLoading(false);
        setRefreshTable(!refreshTable);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function removeUserProjectHandler(account_numbers) {
    removeUserProjects({
      account_numbers: account_numbers,
      project_numbers: [state.project_data.project_number],
    })
      .then(() => {
        props.getUserData();
      })
      .then(() => {
        setIsLoading(false);
        setRefreshTable(!refreshTable);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className='mt-7 mb-16'>
      <div className='bg-white flex flex-col rounded drop-shadow-md p-5 h-fit'>
        <div className='bg-sisa-header w-full flex justify-between'>
          <p className='text-xl text-white p-2 m-2'>Manage Project Users</p>
          <button className='button-action-white' onClick={() => navigate(-1)}>
            back
          </button>
        </div>
        <div className='h-full flex flex-col gap-10'>
          <div className='bg-white flex flex-col drop-shadow-md p-5'>
            <ProjectDetails project_data={projectDetails} />
            {isLoading ? (
              <LoadingSpinner />
            ) : (
              <ProjectUsers
                project_users={projectUsers}
                all_users_but_project={allUsersButProject}
                add_user_project={addUserProjectHandler}
                remove_user_project={removeUserProjectHandler}
                is_loading={isLoading}
                set_is_loading={setIsLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectUsersPage;
