import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OutputTableRow from './OutputTableRow.js';
import '../../../../styles/table.css';
import '../../../../styles/adminBuildings/adminTableBuildings.css';

function OutputTable(props) {
  const [tableData, setTableData] = useState([]);
  const info = props.dataset;

  useEffect(() => {
    setTableData(props.value);
  }, [props.value]);

  // generate any number of rows in the each table under project number
  const generateTableData = () => {
    return (
      <OutputTableRow
        data={tableData}
        dataset={info}
        title={props.title}
        projectId={props.projectId}
      />
    );
  };

  // generate the header row
  const renderTableHeaderRow = () => {
    return props.headers.map((value, index) => {
      return (
        <th className='' key={index}>
          <div className='flex justify-between items-center'>
            {value}
          </div>
        </th>
      );
    });
  };

  return (
    <>
      <div className='overflow-x-auto'>
        <table id='portfolio-plus' className='overflow-auto text-sm h-full'>
          <thead>
            <tr>
              <th colSpan={props.headers.length} className='table-title'>
                <div className='flex justify-between items-center'>
                  <p className=''>{props.title}</p>
                </div>
              </th>
            </tr>
          </thead>
          <thead>
            <tr>{renderTableHeaderRow()}</tr>
          </thead>
          <tbody>{generateTableData()}</tbody>
        </table>
      </div>
    </>
  );
}

OutputTable.propTypes = {
  dataset: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default OutputTable;
