function UserDetails({ user_data }) {
  // parameters: user_data
  // user_data: user data to be displayed

  return (
    <div className='bg-white rounded drop-shadow-md mb-5'>
      <div className='bg-sisa-header w-full'>
        <p className='text-md text-white px-4 py-2'>User Details</p>
      </div>
      <div className='user-details-container'>
        {Object.keys(user_data).map((key, i) => {
          return (
            <div key={i}>
              <div className='user-details-key'>{key.replace('_', ' ')}</div>
              <div className='user-details-value'>
                {user_data[key] ? user_data[key] : '-'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UserDetails;
