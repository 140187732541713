import { useEffect, useState } from "react"
import "../../../styles/analysisTools/ecm-table.css"

function ECMTable({
  id,
  ecm_list,
  set_ecm_list,
  fn_get_group_ecms,
  table_headers,
  action_function,
  selected_ecms,
  set_selected_ecms,
  id_column_name,
  ecm_year_range,
  set_ecm_years_data,
  ecm_years_data,
  is_sorted,
  update_decarb_plan_ecms,
}) {
  const [sortOrder, setSortOrder] = useState({})
  const [selectedAll, setSelectedAll] = useState(false)

  useEffect(() => {
    if (id === "multi-filter-table") {
      if (
        Object.keys(selected_ecms).length > 0 &&
        Object.keys(selected_ecms).length === ecm_list.length
      ) {
        setSelectedAll(true)
      } else {
        setSelectedAll(false)
      }
      updateRowClasses()
    }
    if (id === "retro-fit-bundle") {
      if (
        Object.keys(selected_ecms).length > 0 &&
        Object.keys(selected_ecms).length === ecm_list.length
      ) {
        setSelectedAll(true)
      } else {
        setSelectedAll(false)
      }
      updateRowClasses()
    }
  }, [selected_ecms])

  function sort(e) {
    // sort list_of_data by column_name
    // return sorted list_of_data

    let column_header = e.target.innerText
    let column_name = ""

    if (column_header === "Year") {
      column_name = "year_of_implementation"
    } else {
      column_name = table_headers.find(
        (column) => column["header"] === column_header
      )["column"]
    }

    if (column_name !== "select") {
      if (sortOrder[column_name] === "asc") {
        ecm_list.reverse()
        setSortOrder({ ...sortOrder, [column_name]: "dsc" })
      } else {
        if (ecm_list[0]) {
          if (!isNaN(ecm_list[0][column_name].replaceAll(",", ""))) {
            ecm_list.sort((a, b) => {
              return (
                parseFloat(a[column_name].replaceAll(",", "")) -
                parseFloat(b[column_name].replaceAll(",", ""))
              )
            })
          } else {
            ecm_list.sort((a, b) => {
              if (!a[column_name]) {
                return 1
              } else if (!b[column_name]) {
                return -1
              } else {
                return a[column_name].localeCompare(b[column_name])
              }
            })
          }
        }
        setSortOrder({ [column_name]: "asc" })
      }
    }
  }

  function SortIndicator({ column_name }) {
    if (sortOrder[column_name] === "asc") {
      return <span className='material-symbols-outlined'>arrow_drop_up</span>
    } else if (sortOrder[column_name] === "dsc") {
      return <span className='material-symbols-outlined'>arrow_drop_down</span>
    } else {
      return (
        <span
          className='material-symbols-outlined'
          style={{ visibility: "hidden" }}
        >
          remove
        </span>
      )
    }
  }

  function SelectedIndicator({ ecm_identifier }) {
    return (
      <span className='material-symbols-outlined'>
        {selected_ecms[ecm_identifier] && selected_ecms[ecm_identifier] === true
          ? "check_box"
          : selected_ecms[ecm_identifier] === undefined
          ? "check_box_outline_blank"
          : "indeterminate_check_box"}
      </span>
    )
  }

  function SelectAllIndicator(e) {
    return (
      <span className='material-symbols-outlined'>
        {selectedAll ? "check_box" : "check_box_outline_blank"}
      </span>
    )
  }

  function DropDownSelector({ options, row_data }) {
    function onClickHandler(e) {
      let selectedOption = e.target.selectedOptions[0].value
      let ecm_identifier = row_data["ecm_identifier"]
      let current_imp_year = row_data["year_of_implementation"]
      let ecm_data_copy = { ...ecm_years_data }
      if (!current_imp_year || current_imp_year === "") {
        row_data["year_of_implementation"] = parseInt(selectedOption)
        Object.keys(ecm_data_copy).forEach((year) => {
          if (year === "null" || year === "") {
            delete ecm_data_copy[year]
          }
        })
        set_ecm_years_data({
          ...ecm_data_copy,
          [selectedOption]: ecm_years_data[selectedOption].add(ecm_identifier),
        })
      } else if (selectedOption === "") {
        // if new selection is blank and current is not null
        delete row_data["year_of_implementation"]
        ecm_data_copy[current_imp_year].delete(ecm_identifier)
        set_ecm_years_data({ ...ecm_data_copy })
      } else if (selectedOption !== current_imp_year) {
        // if year is selected and new selection is not the same
        row_data["year_of_implementation"] = parseInt(selectedOption)
        if (ecm_data_copy[current_imp_year]) {
          ecm_data_copy[current_imp_year].delete(ecm_identifier)
        }
        set_ecm_years_data({
          ...ecm_data_copy,
          [selectedOption]: ecm_data_copy[selectedOption].add(ecm_identifier),
        })
      }
    }

    return (
      <select
        onChange={onClickHandler}
        value={
          row_data.year_of_implementation
            ? row_data.year_of_implementation
            : "-"
        }
        className='select-year'
      >
        <option value={""}>Select</option>
        {options.map((option, i) => {
          return (
            <option key={i} value={option}>
              {option}
            </option>
          )
        })}
      </select>
    )
  }

  // create function that will change the classNames of the ecms in the ecm_list of restricted ecms
  // enter state as the className for the tr element "tableRowClassName"
  // if the ecm_identifier is in the restricted_ecms_list then setTableRowClassName to "disabled-row"

  function updateRowClasses() {
    // loop through selected_ecms and if selected_ecms[ecm_id] === true, add the class 'selected-row' to the tr element
    // if selected_ecms[ecm_id] === false, add the class 'disabled-row' to the tr element
    // if selected_ecms[ecm_id] === undefined, remove the class 'selected-row' and 'disabled-row' from the tr element
    for (let ecm of ecm_list) {
      if (selected_ecms[ecm.ecm_identifier] === true) {
        document
          .getElementById(ecm.ecm_identifier)
          .classList.add("selected-row")
      } else if (selected_ecms[ecm.ecm_identifier] === false) {
        document
          .getElementById(ecm.ecm_identifier)
          .classList.add("disabled-row")
      } else {
        document
          .getElementById(ecm.ecm_identifier)
          .classList.remove("selected-row", "disabled-row")
      }
    }
  }

  // when adding to bundle also remove the restricted ones from remaining_ecms_list
  // format the numbers in td as currency / thousands

  function onClickSelectHandler(data) {
    let group_ecms = fn_get_group_ecms(data)
    let selected_ecms_copy = { ...selected_ecms }

    for (let ecm_id of group_ecms) {
      // if ecm is selected already
      if (selected_ecms_copy[ecm_id] !== undefined) {
        delete selected_ecms_copy[ecm_id]
        // if ecm is a new selection
      } else {
        // for each ecm in the group, if it is the one selected, set it to true, else set it to false
        if (ecm_id == data.ecm_identifier) {
          selected_ecms_copy = { ...selected_ecms_copy, [ecm_id]: true }
        } else {
          selected_ecms_copy = { ...selected_ecms_copy, [ecm_id]: false }
        }
      }
    }
    set_selected_ecms(selected_ecms_copy)
    updateRowClasses()
  }

  function selectAllHandler(e) {
    // if the ecm has a group_number, check hierarchy and select if hierarchy is 1 and select it
    // else if the ecm has no group_number, select it

    let selected_ecms_copy = { ...selected_ecms }

    if (selectedAll) {
      set_selected_ecms({})
      setSelectedAll(false)
    } else {
      ecm_list.forEach((ecm) => {
        if (selected_ecms_copy[ecm.ecm_identifier] == null) {
          if (ecm.ecm_group_number) {
            if (ecm.ecm_hierarchy === 1) {
              selected_ecms_copy[ecm.ecm_identifier] = true
            } else {
              selected_ecms_copy[ecm.ecm_identifier] = false
            }
          } else {
            selected_ecms_copy[ecm.ecm_identifier] = true
          }
        }
      })
      set_selected_ecms(selected_ecms_copy)
      setSelectedAll(true)
    }
  }

  function handleOnDeleteEcmRow(event, data) {
    const delete_ecm_identifier = data.ecm_identifier
    const ecm_list_copy = ecm_list.filter(
      (item) => item.ecm_identifier !== delete_ecm_identifier
    )
    const ecm_years_data_copy = { ...ecm_years_data }
    if (ecm_years_data_copy[data.year_of_implementation]) {
      ecm_years_data_copy[data.year_of_implementation].delete(
        delete_ecm_identifier
      )
    }

    set_ecm_list(ecm_list_copy)
    set_ecm_years_data(ecm_years_data_copy)
    update_decarb_plan_ecms(delete_ecm_identifier)
  }

  return (
    <>
      <div className='ecm-table-body-scroll'>
        <table className='ecm-table-container'>
          <thead className='table-header-sticky'>
            <tr>
              {table_headers.map((column, i) => {
                if (column["column"] === "select") {
                  return (
                    <th
                      key={i}
                      className='button-user-projects-check'
                      onClick={selectAllHandler}
                    >
                      <SelectAllIndicator />
                    </th>
                  )
                } else if (column["column"] === "delete") {
                  return (
                    <th key={i} className=''>
                      {column["header"]}
                    </th>
                  )
                } else {
                  return (
                    <th key={i} className=''>
                      <div className='table-header-sort'>
                        <div onClick={is_sorted ? null : sort}>
                          {column["header"]}
                        </div>
                        <SortIndicator column_name={column["column"]} />
                      </div>
                    </th>
                  )
                }
              })}
            </tr>
          </thead>
          <tbody>
            {ecm_list.length > 0 ? (
              ecm_list.map((data, i) => (
                <tr key={i} id={data.ecm_identifier}>
                  {table_headers.map((column, i) => {
                    if (column["column"] === "select") {
                      return (
                        <td
                          key={i}
                          className='button-user-projects-check'
                          onClick={(e) => {
                            onClickSelectHandler(data)
                          }}
                        >
                          <SelectedIndicator
                            ecm_identifier={data.ecm_identifier}
                          />
                        </td>
                      )
                    } else if (column["column"] === "delete") {
                      return (
                        <td key={i} className='delete-ecm-row'>
                          <button
                            className='delete-ecm-row-button'
                            onClick={(event) =>
                              handleOnDeleteEcmRow(event, data)
                            }
                          >
                            <span className='material-symbols-outlined'>
                              delete
                            </span>
                          </button>
                        </td>
                      )
                    } else if (column["column"] === "dropdown") {
                      return (
                        <td key={i} className='button-user-projects-check'>
                          <DropDownSelector
                            options={ecm_year_range}
                            row_data={data}
                          />
                        </td>
                      )
                    } else {
                      return (
                        <td key={i} className=''>
                          {data[column["column"]]
                            ? data[column["column"]]
                            : "0"}
                        </td>
                      )
                    }
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={table_headers.length}>
                  <div className='error-no-data-text'>No Records</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ECMTable
