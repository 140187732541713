import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import "./expandableHeader.css"

function ExpandableHeader({ header_text, inner_components }) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div className='expandable__container'>
      <div
        className='header__container'
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className='header__icon'>
          {isExpanded ? (
            <FontAwesomeIcon icon='fa-light fa-minus' />
          ) : (
            <FontAwesomeIcon icon='fa-light fa-plus' />
          )}
        </div>
        {header_text && <p className='header--title'>{header_text}</p>}
      </div>
      {isExpanded && <div className='expandable__body'>{inner_components}</div>}
    </div>
  )
}

export default ExpandableHeader
