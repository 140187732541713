import { useState, useEffect } from "react"
import ECMTable from "../components/ECMTable"

function BundleSummary(props) {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (props.bundle_ecms) {
      setTableData(props.bundle_ecms)
    }
  }, [props.bundle_ecms])

  const [selectedECMs, setSelectedECMs] = useState({})

  const header_names = [
    { column: "ecm_description", header: "Description" },
    { column: "ecm_gas_gj_savings", header: "Annual Gas Energy Saving (GJ/yr)" },
    {
      column: "ecm_electric_kwh_savings",
      header: "Annual Electrical Energy Saving (kWh/yr)",
    },
    { column: "ecm_electric_kw_savings", header: "Annual Electrical Power Reduction (kW)" },
    { column: "ecm_water_m3_savings", header: "Annual Water Saving (m³/yr)" },
    { column: "ecm_capital_cost", header: "ECM Capital Cost ($)" },
    { column: "ecm_roi", header: "ROI (%)" },
    { column: "ecm_npv", header: "NPV ($)" },
    { column: "ecm_payback", header: "Simple Payback (yrs)" },
    { column: "ecm_incentive", header: "Incentive ($)" },
    { column: "ecm_savings", header: "First Year Saving ($)" },
    {
      column: "ecm_annual_onsite_emission_cost",
      header: "Annual GHG Emission/Cost (Ton CO₂e/$)",
    },
  ]

  return (
    <div className='flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>Bundle Summary</p>
      {props.bundle_ecms.length > 0 ? (
        <>
          <div className='h-full flex flex-col '>
            <ECMTable
              table_headers={header_names}
              ecm_list={tableData}
              selected_ecms={selectedECMs}
              set_selected_ecms={setSelectedECMs}
            />
          </div>
        </>
      ) : (
        <div className='h-full flex flex-col custom-frame p-6'>
          <p className='error-no-data-text'>No ECMs Added</p>
        </div>
      )}
    </div>
  )
}
export default BundleSummary
