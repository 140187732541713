import React from "react"
import "../../styles/profile.css"
import Tabs from "./Tabs"

/**
 * See HomePage.js for similar comments.
 *  Portfolio
 * @returns
 */

export const Profile = (props) => {
  return (
    <div className='mb-16 w-full'>
      <div className='title'>
        <h2 className='section_heading_1 account-main-header'>
          Account Settings
        </h2>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div className='flex flex-col gap-10 w-full h-full'>
        <Tabs userData={props}></Tabs>
      </div>
    </div>
  )
}
