export const BuildingDataLabels = {
  property_type_label: "Building type",
  gross_floor_area_label: "Total floor area (sqft)",
  total_building_perimeter_label: "Total building perimeter (ft)",
  no_of_bachelor_suites_label: "No. of bachelors/studios",
  no_of_1_bedroom_suites_label: "No. of 1 bedroom",
  no_of_2_bedroom_suites_label: "No. of 2 bedroom",
  no_of_3_or_more_bedroom_suites_label: "No. of 3 or more bedroom",
  total_no_of_suites_label: "Total No. of suites",
  avg_bachelor_area_label: "Avg. bachelor/studio areas (sqft)",
  avg_1_bedroom_area_label: "Avg. 1 bedroom area (sqft)",
  avg_2_bedroom_area_label: "Avg. 2 bedroom area (sqft)",
  avg_3_or_more_bedroom_area_label: "Avg. 3 or more bedroom area (sqft)",
  total_avg_suite_area_label: "Total suite area (sqft)",
  no_of_workers_on_main_shift_label: "No. of workers on main shift",
  no_of_residents_label: "No. of residents",
  total_no_of_people_label: "Total No. of people ",
  no_of_elevators_label: "No. of elevators",
  no_of_floors_label: "No. of floors",
  original_construction_year_label: "Original construction year",
  last_remodeling_year_label: "Last remodeling year",
  do_suites_have_elec_meters_label: "Do suites have electricity meters?",
  has_cooling_label: "Does this property have cooling?",
  domestic_hot_water_primary_fuel_label: "Domestic hot water primary fuel",
  outdoor_air_ventilation_heating_primary_fuel_label:
    "Outdoor air ventilation heating primary fuel",
  space_heating_primary_fuel_label: "Space heating primary fuel",
}

export const NoOfBedroomFields = [
  "no_of_bachelor_suites",
  "no_of_1_bedroom_suites",
  "no_of_2_bedroom_suites",
  "no_of_3_or_more_bedroom_suites",
]

export const AvgAreaFields = [
  "avg_bachelor_area",
  "avg_1_bedroom_area",
  "avg_2_bedroom_area",
  "avg_3_or_more_bedroom_area",
]

export const NoOfPeopleFields = [
  "no_of_workers_on_main_shift",
  "no_of_residents",
]
