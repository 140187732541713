import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import {
  addCityElectricityRatePost,
  getAllProvinces,
  getAllCities,
} from '../../../utils/HttpClient';

export const AddCityElectricityRatePage = (props) => {
  const [defaultCities, setDefaultCities] = useState([]);
  const [province, setProvince] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);

  const [data, setData] = useState({
    provinceId: '',
    isNewProvince: false,
    cityId: '',
    isNewCity: false,
    valuePerkWh: '',
  });
  const addCityElectricityRate = (e) => {
    e.preventDefault();
    data.provinceId = province.value;
    data.isNewProvince = province.isNew ?? false;
    data.cityId = city.value;
    data.isNewCity = city.isNew ?? false;
    addCityElectricityRatePost(data)
      .then((res) => {
        window.location.href = '/rates';
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setData({
      ...data,
      [event.target.name]: value,
    });
  };
  const handleChangeProvince = (newProvince) => {
    setProvince(newProvince);
    setCity(null);
    setCities(defaultCities.filter((pc) => pc.provinceId == newProvince.value));
  };
  const handleCreateProvince = (newProvince) => {
    const newProvinceObj = {
      label: newProvince,
      value: newProvince,
      isNew: true,
    };
    setProvince(newProvinceObj);
    setProvinces((prev) => [...prev, newProvinceObj]);
    setCity(null);
    setCities([]);
  };
  const handleCreateCity = (newCity) => {
    const newCityObj = {
      label: newCity,
      value: newCity,
      isNew: true,
    };
    setCity(newCityObj);
    setCities((prev) => [...prev, newCityObj]);
  };

  useEffect(() => {
    getAllProvinces()
      .then((res) => {
        const allRes = res.data.map((r) => {
          return {
            value: r.province_id,
            label: r.province_name,
          };
        });
        setProvinces(allRes);
      })
      .catch((err) => console.log(err));
    getAllCities()
      .then((res) => {
        const allRes = res.data.map((r) => {
          return {
            value: r.city_id,
            provinceId: r.province_id,
            label: r.city_name,
          };
        });
        setDefaultCities(allRes);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className='mt-7 mb-16'>
      <h1 className='mb-7 mt-3 text-center text-black'>
        Add City Electricity Rate
      </h1>
      <div className='pl-14 pr-14 pb-7'>
        <div>
          <div className='widget-header'>
            <h2>{props.title}</h2>
          </div>
          <table id='description'>
            <tbody>
              <tr>
                <td>Province Name</td>
                <td>
                  <CreatableSelect
                    name='province'
                    options={provinces}
                    onChange={handleChangeProvince}
                    onCreateOption={handleCreateProvince}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={province}
                  />
                </td>
              </tr>
              <tr>
                <td>City</td>
                <td>
                  <CreatableSelect
                    name='city'
                    options={cities}
                    onChange={setCity}
                    onCreateOption={handleCreateCity}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={city}
                  />
                </td>
              </tr>
              <tr>
                <td>Value per kWh</td>
                <td>
                  <TextField name='valuePerkWh' onChange={handleChange} />
                </td>
              </tr>
            </tbody>
          </table>
          <hr></hr>
          <div className='grid grid-cols-6 mt-5 mb-3'>
            <div className='col-start-6 mt-1'>
              <Button
                onClick={() => (window.location.href = '/rates')}
                sx={{ borderRadius: 28 }}
              >
                <Typography style={{ color: '#636363', textTransform: 'none' }}>
                  Cancel
                </Typography>
              </Button>
            </div>
            <div className='col-start-7'>
              <Button
                color='primary'
                onClick={addCityElectricityRate}
                sx={{ borderRadius: 28 }}
                style={{
                  backgroundColor: '#03045e',
                  width: 150,
                  padding: 10,
                }}
                variant='contained'
              >
                <Typography style={{ color: 'var(--white)', textTransform: 'none' }}>
                  Save
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
