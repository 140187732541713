import React, { useState, useEffect } from "react"
import "../../styles/verticalTable.css"

function VerticalTableMultipleColumns(props) {
  const [tableValues, setTableValues] = useState([])

  useEffect(() => {
    setTableValues(props.data)
  }, [tableValues])

  return (
    <table id='table_description'>
      {props.tableHeaders && (
        <thead>
          <tr>
            {props.tableHeaders.map((header, index) => (
              <th key={"header-" + index}>{header}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {props.categories.map((category, index) => {
          return (
            <tr key={"category-" + index}>
              <td className='columnItem'>
                <span className='table-headers'>Category</span>
                {category}
              </td>
              {tableValues.map((column, inx) => {
                return (
                  <td className='columnItem' key={"columnItem-" + inx}>
                    <span className='table-headers'>
                      {props.tableHeaders[inx + 1]}
                    </span>
                    {column[index] &&
                    !column[index].includes("NaN") &&
                    !column[index].includes("null")
                      ? column[index]
                      : "-"}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default VerticalTableMultipleColumns
