function parseFloatOrNull(data) {
  if (data == null || isNaN(data)) {
    return "-"
  } else {
    return parseFloat(data)
  }
}

function parseIntOrNull(data) {
  if (data == null || isNaN(data)) {
    return "-"
  } else {
    return parseInt(data)
  }
}

function formatNumberGrouping(number) {
  try {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } catch {
    return "-"
  }
}

function parseFloatAndFormat(data) {
  let parsed_number = parseFloatOrNull(data)
  if (parsed_number != "-") {
    let formatted_number = parsed_number.toFixed(2)
    return formatNumberGrouping(formatted_number)
  }
  return parsed_number
}

function isFloat(n) {
  return typeof n === "number" && Number.isFinite(n) && !Number.isInteger(n)
}

function parseIntAndFormat(data) {
  let parsed_number = parseIntOrNull(data)
  if (parsed_number != "-") {
    return formatNumberGrouping(parsed_number)
  }
  return parsed_number
}

function parseIntCurrencyRoundedFormat(data) {
  let parsed_number = parseIntOrNull(data)

  if (parsed_number !== "-") {
    if (Math.abs(parsed_number) < 1000) {
      parsed_number = Math.round(parsed_number)
    } else if (
      Math.abs(parsed_number) >= 1000 &&
      Math.abs(parsed_number) < 10000
    ) {
      parsed_number = Math.round(parsed_number / 10) * 10
    } else if (
      Math.abs(parsed_number) >= 10000 &&
      Math.abs(parsed_number) < 100000
    ) {
      parsed_number = Math.round(parsed_number / 100) * 100
    } else {
      parsed_number = Math.round(parsed_number / 1000) * 1000
    }
    return "$ " + formatNumberGrouping(parsed_number)
  }
  return parsed_number
}

// date and time format : MM/DD/YY HH:MM AM/PM
function dateTimeFormatter(date) {
  const day = date.getDate().toString().padStart(2, "0")
  const month = (date.getMonth() + 1).toString().padStart(2, "0") // Months are zero-based
  const year = date.getFullYear().toString().substr(-2)
  const hours = date.getHours()
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const ampm = hours >= 12 ? "PM" : "AM"

  let hours12 = hours % 12
  if (hours12 === 0) {
    hours12 = 12
  }
  return `${month}/${day}/${year} ${hours12}:${minutes} ${ampm}`
}

function filterProperties(obj, filter_func) {
  if (Array.isArray(obj))
    return obj.map((item) => filterProperties(item, filter_func))

  const filteredObj = {}
  for (const key in obj) {
    if (filter_func(key)) {
      if (
        typeof obj[key] == "object" &&
        obj[key] != null &&
        !(obj[key] instanceof File)
      ) {
        filteredObj[key] = filterProperties(obj[key], filter_func)
      } else filteredObj[key] = obj[key]
    }
  }
  return filteredObj
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export {
  parseFloatOrNull,
  parseIntOrNull,
  formatNumberGrouping,
  parseFloatAndFormat,
  parseIntAndFormat,
  parseIntCurrencyRoundedFormat,
  dateTimeFormatter,
  filterProperties,
  isFloat,
  capitalizeFirstLetter,
}
