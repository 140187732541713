import StatCard from "./StatCard"
import { useEffect, useState } from "react"
import { parseFloatAndFormat } from "../../../utils/DataFormatter"

function TotalSavings(props) {
  const [tableBody, setTableBody] = useState([])

  useEffect(() => {
    if (props.data) {
      let total_energy_savings = [
        {
          title_text: "Electricity",
          units_text: "(kWh/yr)",
          value_text: parseFloatAndFormat(props.data.ElectricitykWh),
          top_bg_color: "#A7EDF8",
          bot_bg_color: "var(--white)",
          top_text_color: "#112B4E",
          bot_text_color: "#112B4E",
        },
        {
          title_text: "Electricity",
          units_text: "(kW)",
          value_text: parseFloatAndFormat(props.data.ElectricitykW),
          top_bg_color: "#A7EDF8",
          bot_bg_color: "var(--white)",
          top_text_color: "#112B4E",
          bot_text_color: "#112B4E",
        },
        {
          title_text: "Gas",
          units_text: "(GJ/yr)",
          value_text: parseFloatAndFormat(props.data.Gas),
          top_bg_color: "#B7F2CC",
          bot_bg_color: "var(--white)",
          top_text_color: "#112B4E",
          bot_text_color: "#112B4E",
        },
        {
          title_text: "Fuel",
          units_text: "(GJ/yr)",
          value_text: parseFloatAndFormat(props.data.Fuel),
          top_bg_color: "#B7F2CC",
          bot_bg_color: "var(--white)",
          top_text_color: "#112B4E",
          bot_text_color: "#112B4E",
        },
        {
          title_text: "Water",
          units_text: "(m³/yr)",
          value_text: parseFloatAndFormat(props.data.Water),
          top_bg_color: "#C6C6CA",
          bot_bg_color: "var(--white)",
          top_text_color: "#112B4E",
          bot_text_color: "#112B4E",
        },
        {
          title_text: "GHG",
          units_text: "(%)",
          value_text: parseFloatAndFormat(props.data.GHGEmissions),
          top_bg_color: "#B7F2CC",
          bot_bg_color: "var(--white)",
          top_text_color: "#112B4E",
          bot_text_color: "#112B4E",
        },
      ]

      setTableBody(total_energy_savings)
    }
  }, [props.data])

  return (
    <div className=' flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>Total Energy Savings</p>
      <div className='total-savings-container'>
        {tableBody.map((item, index) => {
          return (
            <StatCard
              key={index}
              title_text={item.title_text}
              units_text={item.units_text}
              value_text={item.value_text}
              top_bg_color={item.top_bg_color}
              top_text_color={item.top_text_color}
              bot_bg_color={item.bot_bg_color}
              bot_text_color={item.bot_text_color}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TotalSavings
