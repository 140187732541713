import { useEffect, useState, useContext } from "react"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import "../../../../styles/addProperty/InputContainer.css"
import {
  UserDraftContext,
  DefaultDraftContext,
} from "../../context/DraftContext"
import {
  BuildingDataLabels,
  NoOfBedroomFields,
  AvgAreaFields,
  NoOfPeopleFields,
} from "./BuildingDataOptions"
import Input from "../../../global/components/input/Input"
import Select from "../../../global/components/select/Select"
import "../../style/dataCollection.css"
import Button from "../../../global/components/button/Button"

const BuildingData = ({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
}) => {
  const START_YEAR = 1900
  const END_YEAR = 2100

  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)
  const { defaultDraftObject, siteInputOptions, propertyTypes } =
    useContext(DefaultDraftContext)

  const [inputs, setInputs] = useState({})

  const [totalUnitsDisabled, setTotalUnitsDisabled] = useState(false)
  const [totalAreaDisabled, setTotalAreaDisabled] = useState(false)
  const [totalPeopleDisabled, setTotalPeopleDisabled] = useState(false)

  const [propertyTypesOptionsList, setPropertyTypesOptionsList] = useState([])
  const [propertyTypeDetailsList, setPropertyTypeDetailsList] = useState([])

  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})
  const [propertyMeterFuels, setPropertyMeterFuels] = useState([])

  // year selection list
  const [yearSelectionList, setYearSelectionList] = useState([])

  useEffect(() => {
    if (userDraftState.building_data) {
      setInputs(userDraftState.building_data)
    }
    if (userDraftState.property_energy_utilities.length > 0) {
      let energy_utilities = userDraftState.property_energy_utilities.map(
        (utility) => {
          return utility.fuel
        }
      )
      setPropertyMeterFuels(energy_utilities)
    } else {
      setPropertyMeterFuels(["N/A"])
    }

    let years_array = []
    for (let i = START_YEAR; i <= END_YEAR; i++) {
      let year = new String(i)
      years_array.push(year)
    }
    setYearSelectionList(years_array)
  }, [userDraftState])

  useEffect(() => {
    generatePropertyTypesOptionsList()
    // setComputedValues(inputs)
  }, [siteInputOptions])

  const handleInputChange = (newValue) => {
    let changed_input = newValue.name
    let changed_value = newValue.value

    let updated_inputs = { ...inputs, [changed_input]: changed_value }

    if (NoOfBedroomFields.includes(changed_input)) {
      let sum_of_suites = NoOfBedroomFields.reduce(
        (sum, key) => sum + (Number(updated_inputs[key]) || 0),
        0
      )

      if (
        updated_inputs["no_of_bachelor_suites"] ||
        updated_inputs["no_of_1_bedroom_suites"] ||
        updated_inputs["no_of_2_bedroom_suites"] ||
        updated_inputs["no_of_3_or_more_bedroom_suites"]
      ) {
        setTotalUnitsDisabled(true)
      } else {
        setTotalUnitsDisabled(false)
      }
      updated_inputs = { ...updated_inputs, total_no_of_suites: sum_of_suites }
    }

    // if we have the breakdowns or the avg area for each suite type changed,
    // then we need to update the total_avg_suite_area
    // otherwise use formula to calculate the avg area from total units
    if (
      AvgAreaFields.includes(changed_input) ||
      updated_inputs["no_of_bachelor_suites"] ||
      updated_inputs["no_of_1_bedroom_suites"] ||
      updated_inputs["no_of_2_bedroom_suites"] ||
      updated_inputs["no_of_3_or_more_bedroom_suites"]
    ) {
      let sum_of_area = 0

      for (let i = 0; i < AvgAreaFields.length; i++) {
        sum_of_area +=
          (Number(updated_inputs[AvgAreaFields[i]]) || 0) *
          (Number(updated_inputs[NoOfBedroomFields[i]]) || 0)
      }

      if (
        updated_inputs["avg_bachelor_area"] ||
        updated_inputs["avg_1_bedroom_area"] ||
        updated_inputs["avg_2_bedroom_area"] ||
        updated_inputs["avg_3_or_more_bedroom_area"]
      ) {
        setTotalAreaDisabled(true)
      } else {
        setTotalAreaDisabled(false)
      }
      updated_inputs = { ...updated_inputs, total_avg_suite_area: sum_of_area }
    }

    if (NoOfPeopleFields.includes(changed_input)) {
      let sum_of_people = NoOfPeopleFields.reduce(
        (sum, key) => sum + (Number(updated_inputs[key]) || 0),
        0
      )

      if (
        updated_inputs["no_of_workers_on_main_shift"] ||
        updated_inputs["no_of_residents"]
      ) {
        setTotalPeopleDisabled(true)
      } else {
        setTotalPeopleDisabled(false)
      }
      updated_inputs = { ...updated_inputs, total_no_of_people: sum_of_people }
    }

    userDraftDispatch({
      type: "building_data_save",
      payload: updated_inputs,
    })
  }

  function generatePropertyTypesOptionsList() {
    // categories = optgroup
    // options =
    // {
    //    property_types_list[i].property_types[i].name
    //    :
    //    property_types_list[i].property_types[i].details
    //  }
    // optgroup name = category_name
    // option name = property_types_list[i].property_types[i].name
    // sub-option name = property_types_list[i].property_types[i].primary_functions[i].name

    // details
    // option details = property_types_list[i].property_types[i].details
    // sub-option details = property_types_list[i].property_types[i].primary_functions[i].details

    let property_types_raw_list = propertyTypes
    let property_types_names_list = []
    let property_type_details_list = []

    for (let category of property_types_raw_list) {
      let category_name = category.category_name
      let cat_property_types = category.property_types

      let category_obj = { category_name: category_name, property_types: [] }

      // check each property_type if it has primary_functions prop
      for (let property_type of cat_property_types) {
        let property_type_name = property_type.name

        if (Object.hasOwn(property_type, "primary_functions")) {
          // get names of each primary_function
          let primary_functions = property_type.primary_functions
          for (let primary_function of primary_functions) {
            let final_property_type_name = property_type_name.concat(
              "-",
              primary_function.name
            )
            let redundant_word = "Other - "
            if (final_property_type_name.includes(redundant_word)) {
              final_property_type_name = final_property_type_name.replace(
                redundant_word,
                ""
              )
            }
            category_obj.property_types.push({ name: final_property_type_name })
            let property_details_obj = { property_type: "", details: [] }
            property_details_obj.property_type = final_property_type_name
            property_details_obj.details = primary_function.details
            property_type_details_list.push(property_details_obj)
          }
        } else {
          category_obj.property_types.push({ name: property_type_name })
          let property_details_obj = { property_type: "", details: [] }
          property_details_obj.property_type = property_type_name
          property_details_obj.details = property_type.details
          property_type_details_list.push(property_details_obj)
        }
      }
      property_types_names_list.push(category_obj)
    }
    setPropertyTypesOptionsList(property_types_names_list)
    setPropertyTypeDetailsList(property_type_details_list)
  }

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isEmpty ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation(null)
    }
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>Building Data</p>
        <Button
          type='button'
          className='button__large button__large--gray complete-button-upper'
          buttonText='Check this tab'
          disabled={isButtonDisabled === null}
          onClick={handleValidation}
        />
      </div>
      <div className='form__content'>
        <InstructionalText
          type='Instructional Text'
          content='Select the most appropriate building type from the dropdown menu.'
        />
        <div className='form__inputs__container'>
          <div className='input_width--1-3'>
            <Select
              label={BuildingDataLabels.property_type_label}
              options={propertyTypesOptionsList}
              subOptions='true'
              name='property_type'
              value={inputs.property_type}
              onChange={handleInputChange}
              placeholder='Select Property Type'
              className='default'
              validation={onValidation}
              set_validation_object={setValidationObject}
              required
            />
          </div>
          <div className='input_width--1-3'>
            <Input
              label={BuildingDataLabels.gross_floor_area_label}
              type='number'
              name='gross_floor_area'
              value={inputs.gross_floor_area}
              onChange={handleInputChange}
              placeholder='Enter total floor area'
              className='default'
              validation={onValidation}
              set_validation_object={setValidationObject}
              required
            />
          </div>
          <div className='input_width--1-3'>
            <Input
              label={BuildingDataLabels.total_building_perimeter_label}
              type='number'
              name='total_building_perimeter'
              value={inputs.total_building_perimeter}
              onChange={handleInputChange}
              placeholder='Enter total building perimeter'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Select
              label={BuildingDataLabels.original_construction_year_label}
              options={yearSelectionList}
              name='original_construction_year'
              value={inputs.original_construction_year}
              onChange={handleInputChange}
              placeholder='Construction Year'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Select
              label={BuildingDataLabels.last_remodeling_year_label}
              options={yearSelectionList}
              name='last_remodeling_year'
              value={inputs.last_remodeling_year}
              onChange={handleInputChange}
              placeholder='Remodeling Year'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.no_of_elevators_label}
              type='number'
              name='no_of_elevators'
              onChange={handleInputChange}
              value={inputs.no_of_elevators}
              placeholder='Number of Elevators'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.no_of_floors_label}
              type='number'
              name='no_of_floors'
              onChange={handleInputChange}
              value={inputs.no_of_floors}
              placeholder='Number of Floors'
              className='default'
            />
          </div>
          <div className='input_width--full'>
            <InstructionalText
              type='Additional Instructions'
              content='If the building lacks a residential component, please disregard the input fields related to residential units.'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.no_of_bachelor_suites_label}
              type='number'
              name='no_of_bachelor_suites'
              onChange={handleInputChange}
              value={inputs.no_of_bachelor_suites}
              placeholder='Enter Number of Units'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.no_of_1_bedroom_suites_label}
              type='number'
              name='no_of_1_bedroom_suites'
              onChange={handleInputChange}
              value={inputs.no_of_1_bedroom_suites}
              placeholder='Enter Number of Units'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.no_of_2_bedroom_suites_label}
              type='number'
              name='no_of_2_bedroom_suites'
              onChange={handleInputChange}
              value={inputs.no_of_2_bedroom_suites}
              placeholder='Enter Number of Units'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.no_of_3_or_more_bedroom_suites_label}
              type='number'
              name='no_of_3_or_more_bedroom_suites'
              onChange={handleInputChange}
              value={inputs.no_of_3_or_more_bedroom_suites}
              placeholder='Enter Number of Units'
              className='default'
            />
          </div>

          <div className='form__inputs__container'>
            <div className='input_width--3-4'>
              <hr />
            </div>
            <div className='input_width--1-4'>
              <Input
                label={BuildingDataLabels.total_no_of_suites_label}
                type='number'
                value={inputs.total_no_of_suites}
                name='total_no_of_suites'
                onChange={handleInputChange}
                placeholder='0'
                className='default'
                disabled={totalUnitsDisabled}
                validation={onValidation}
                set_validation_object={setValidationObject}
                required
              />
            </div>
          </div>

          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.avg_bachelor_area_label}
              type='number'
              name='avg_bachelor_area'
              onChange={handleInputChange}
              value={inputs.avg_bachelor_area}
              placeholder='Enter Avg. Area in sqft'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.avg_1_bedroom_area_label}
              type='number'
              name='avg_1_bedroom_area'
              onChange={handleInputChange}
              value={inputs.avg_1_bedroom_area}
              placeholder='Enter Avg. Area in sqft'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.avg_2_bedroom_area_label}
              type='number'
              name='avg_2_bedroom_area'
              onChange={handleInputChange}
              value={inputs.avg_2_bedroom_area}
              placeholder='Enter Avg. Area in sqft'
              className='default'
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={BuildingDataLabels.avg_3_or_more_bedroom_area_label}
              type='number'
              name='avg_3_or_more_bedroom_area'
              onChange={handleInputChange}
              value={inputs.avg_3_or_more_bedroom_area}
              placeholder='Enter Avg. Area in sqft'
              className='default'
            />
          </div>

          <div className='form__inputs__container'>
            <div className='input_width--3-4'>
              <hr />
            </div>
            <div className='input_width--1-4'>
              <Input
                label={BuildingDataLabels.total_avg_suite_area_label}
                type='number'
                pattern='^\d+$'
                value={inputs.total_avg_suite_area}
                name='total_avg_suite_area'
                onChange={handleInputChange}
                placeholder='0 sqft'
                className='default'
                disabled={totalAreaDisabled}
              />
            </div>
          </div>
          <div className='form__inputs__container'>
            <div className='input_width--1-3'>
              <Input
                label={BuildingDataLabels.no_of_workers_on_main_shift_label}
                type='number'
                name='no_of_workers_on_main_shift'
                onChange={handleInputChange}
                value={inputs.no_of_workers_on_main_shift}
                placeholder='Enter Number of Workers'
                className='default'
              />
            </div>
            <div className='input_width--1-3'>
              <Input
                label={BuildingDataLabels.no_of_residents_label}
                type='number'
                name='no_of_residents'
                onChange={handleInputChange}
                value={inputs.no_of_residents}
                placeholder='Enter Number of Residents'
                className='default'
              />
            </div>
            <div className='input_width--1-3'>
              <Input
                label={BuildingDataLabels.total_no_of_people_label}
                type='number'
                pattern='^\d+$'
                value={inputs.total_no_of_people}
                name='total_no_of_people'
                onChange={handleInputChange}
                placeholder='0'
                className='default'
                disabled={totalPeopleDisabled}
                validation={onValidation}
                set_validation_object={setValidationObject}
                required
              />
            </div>
          </div>

          <div className='input_width--1-2'>
            <Select
              label={BuildingDataLabels.do_suites_have_elec_meters_label}
              options={["Yes", "No", "N/A"]}
              name='do_suites_have_elec_meters'
              onChange={handleInputChange}
              value={inputs.do_suites_have_elec_meters}
              className='default'
            />
          </div>
          <div className='input_width--1-2'>
            <Select
              label={BuildingDataLabels.has_cooling_label}
              options={["Yes", "No", "N/A"]}
              name='has_cooling'
              value={inputs.has_cooling}
              onChange={handleInputChange}
              className='default'
            />
          </div>
        </div>
        <div className='form__inputs__container'>
          <div className='input_width--1-3'>
            <Select
              label={BuildingDataLabels.domestic_hot_water_primary_fuel_label}
              options={propertyMeterFuels}
              name='domestic_hot_water_primary_fuel'
              value={inputs.domestic_hot_water_primary_fuel}
              onChange={handleInputChange}
              className='default'
            />
          </div>
          <div className='input_width--1-3'>
            <Select
              label={
                BuildingDataLabels.outdoor_air_ventilation_heating_primary_fuel_label
              }
              options={propertyMeterFuels}
              name='outdoor_air_ventilation_heating_primary_fuel'
              value={inputs.outdoor_air_ventilation_heating_primary_fuel}
              onChange={handleInputChange}
              className='default'
            />
          </div>
          <div className='input_width--1-3'>
            <Select
              label={BuildingDataLabels.space_heating_primary_fuel_label}
              options={propertyMeterFuels}
              name='space_heating_primary_fuel'
              value={inputs.space_heating_primary_fuel}
              onChange={handleInputChange}
              className='default'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BuildingData
