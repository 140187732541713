import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { borderColor } from '@mui/system';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChartComponent(props) {
  const yearMonth = props.dataset.map((data) => data[1]);
  const year = yearMonth.map((y) => parseInt(y.substring(0, 4)));
  const month = yearMonth.map((m) => parseInt(m.substring(5)));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  for (let i = 1; i < 13; i++) {
    if (!month.includes(i)) {
      props.dataset.splice(i - 2, 0, [0, `${year[0]}-0${i}`]);
    }
  }
  const newData = props.dataset;

  const data = {
    labels: newData.map((info) => info[1]),

    datasets: [
      {
        label: 'Users Count',
        data: newData.map((info) => info[0]),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return <Line options={options} data={data} />;
}
