import "../../../styles/importProperty/FileDetailsTable.css"
import { useState, useEffect } from "react"
import FileNameTag from "./FileNameTag"

function FileDetailsTable({ data_list, fn_remove_row }) {
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (data_list.length > 0) {
      setTableData(data_list)
    }
  }, [data_list])

  function calculateFileSize(size) {
    if (size > 1000000) {
      return (size / 1000000).toFixed(1) + " MB"
    } else if (size > 1000) {
      return (size / 1000).toFixed(1) + " kB"
    } else {
      return size + " B"
    }
  }

  return (
    <div className='file-details-table-container'>
      <table className='file-details-table'>
        <thead className='file-details-thead'>
          <tr>
            <th className='header-name'>File Name</th>
            <th className='header-size'>File Size</th>
            <th className='header-message'>Message</th>
            <th className='header-status'>Status</th>
            <th className='header-action'>
              <div className='flex items-center'>
                <span className='material-symbols-outlined'>delete</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className='file-details-tbody'>
          {tableData.map((file, index) => {
            return (
              <tr key={index}>
                <td>
                  <FileNameTag file_name={file.name} status={file.status} />
                </td>
                <td className='td-file-size'>{calculateFileSize(file.size)}</td>
                <td>{file.message.length > 0 ? file.message : "-"}</td>
                <td>{file.status}</td>
                <td className='delete-row'>
                  <div className='flex items-center'>
                    <span
                      className='material-symbols-outlined delete-row-button'
                      onClick={(e) => fn_remove_row(file.name)}
                    >
                      delete
                    </span>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
export default FileDetailsTable
