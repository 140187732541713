import React, { useEffect, useState } from "react"
import GoogleMapReact from "google-map-react"
import Geocode from "react-geocode"
import LoadingSpinner from "../global/LoadingSpinner"
import "../../styles/report-tab.css"

let callCount = 0

function Map(props) {
  // Reference for google map marker functionality
  // https://stackoverflow.com/questions/41405343/adding-marker-to-google-maps-in-google-map-react
  const renderMarkers = (map, maps, latitude, longitude) => {
    let marker = new maps.Marker({
      position: { lat: latitude, lng: longitude },
      map,
      title: "Hello World!",
    })
    return marker
  }

  const [mapCoordinates, setMapCoordinates] = useState({
    center: {
      lat: 49.2827,
      lng: -123.1207,
    },
    zoom: 15,
  })

  const [isLoading, setIsLoading] = useState(false)

  // Nest Geocode inside useEffect and utilize isLoading to prevent infinite Geocode calls
  useEffect(() => {
    setIsLoading(true)
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
    Geocode.fromAddress(props.address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        callCount = callCount + 1

        setMapCoordinates({
          center: {
            lat: lat,
            lng: lng,
          },
          zoom: 15,
        })
        setIsLoading(false)
      },
      (error) => {
        console.error(error)
        setIsLoading(false)
      }
    )
  }, [])

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <GoogleMapReact
          className='map'
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          }}
          center={mapCoordinates.center}
          defaultZoom={mapCoordinates.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            renderMarkers(
              map,
              maps,
              mapCoordinates.center.lat,
              mapCoordinates.center.lng
            )
          }
        ></GoogleMapReact>
      )}
    </>
  )
}

export default Map
