import React from 'react';
import '../../styles/profile.css';
const TabNavItemMobile = ({ id, item, title, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <div onClick={handleClick} className={activeTab === id ? 'active' : ''}>
      {title}
    </div>
  );
};
export default TabNavItemMobile;
