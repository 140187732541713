import { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import '../../../styles/analysisTools/progress-bar.css';

function ProgressBar({ value }) {
  const [ghgReductionValue, setGhgReductionValue] = useState(0);
  const [progressColorObject, setProgressColorObject] = useState({
    height: '30px',
    borderRadius: '10px',
    backgroundColor: 'azureblue',
    '& .MuiLinearProgress-bar': { backgroundColor: 'azureblue' },
  });

  useEffect(() => {
    if (value) {
      setGhgReductionValue(value);
      if (Math.abs(value) < 50) {
        setProgressColorObject({
          ...progressColorObject,
          '& .MuiLinearProgress-bar': { backgroundColor: 'red' },
        });
      } else if (Math.abs(value) < 80) {
        setProgressColorObject({
          ...progressColorObject,
          '& .MuiLinearProgress-bar': { backgroundColor: 'orange' },
        });
      } else {
        setProgressColorObject({
          ...progressColorObject,
          '& .MuiLinearProgress-bar': { backgroundColor: 'green' },
        });
      }
    } else {
      setGhgReductionValue(0);
    }
  }, [value]);

  return (
    <div className='flex flex-col w-full my-2 py-2'>
      <p className='text-center text-lg font-bold text-sisa-header'>
        GHG Emission Reduction
      </p>
      <div className='flex flex-row items-center p-1 justify-center'>
        <div className='w-full'>
          <LinearProgress
            variant='determinate'
            value={Math.abs(ghgReductionValue)}
            sx={progressColorObject}
          />
        </div>
        <p className='w-24 pl-6 text-md font-bold justify-self-end text-sisa-header'>
          {ghgReductionValue.toFixed(2)} %
        </p>
      </div>
      <p className='px-2 text-xs'>
      Deep Retrofit is achieved when GHG Emission Reduction is more than 80 %
      </p>
    </div>
  );
}

export default ProgressBar;
