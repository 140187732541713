import { useState, useEffect, useRef } from "react"
import FileUploaderModal from "../global/components/fileUploader/FileUploaderModal"
import FileDetailsTable from "../global/components/fileUploader/FileDetailsTable"
import InstructionalText from "../global/components/InstructionalText/InstructionalText"
import Button from "../global/components/button/Button"
import "./importBuildings.css"
import {
  validateFrescoBuildings,
  getValidateFrescoBuildingsStatus,
  importFrescoBuildings,
  getImportFrescoBuildingsStatus,
} from "../../utils/HttpClient"
import { LinearProgress } from "@mui/material"
import { parseFloatAndFormat } from "../../utils/DataFormatter"
import Table from "../global/components/Table/Table"
import { Navigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SimpleModal from "../global/components/SimpleModal/SimpleModal"
import ExpandableHeader from "../global/components/ExpandableHeader/ExpandableHeader"

const apiUrl = process.env.REACT_APP_API_URL

function ImportBuildings() {
  // list of uploaded files - for the session
  const [sessionFilesList, setSessionFilesList] = useState([])

  // progress state
  const [jobID, setJobID] = useState("")
  const [importJobID, setImportJobID] = useState("")

  const [progressValue, setProgressValue] = useState(0)
  const [jobCompleted, setJobCompleted] = useState(false)

  const [statusText, setStatusText] = useState("")
  const [modalStatusText, setModalStatusText] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [showModalStatus, setShowModalStatus] = useState(false)

  const [processedBuildingsList, setProcessedBuildingsList] = useState([])
  const [warningBuildingsList, setWarningBuildingsList] = useState([])
  const [errorBuildingsList, setErrorBuildingsList] = useState([])

  // file uploader modal ref
  const file_uploader_modal = useRef(null)
  const validate_modal_ref = useRef(null)

  // remove file from list handler
  function removeFileFromListHandler(index) {
    let new_list = [...sessionFilesList]
    new_list.splice(index, 1)
    setSessionFilesList(new_list)
  }

  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  function handleSelectFiles(files) {
    setSessionFilesList(files)
    file_uploader_modal.current.close()
  }

  function overwriteDataHandler() {
    startImportJobHandler("Overwrite")
    validate_modal_ref.current.close()
  }

  function ignoreDataHandler() {
    startImportJobHandler("Ignore")
    validate_modal_ref.current.close()
  }

  function cancelHandler() {
    setImportJobID("")
  }

  function clearData() {
    setJobID("")
    setImportJobID("")
    setProgressValue(0)
    setProcessedBuildingsList([])
    setWarningBuildingsList([])
    setErrorBuildingsList([])
    setShowProgress(false)
    setShowModalStatus(false)
    setJobCompleted(false)
  }

  // call validate job API
  function startValidateJobHandler() {
    clearData()
    if (sessionFilesList.length !== 0) {
      validateFrescoBuildings(sessionFilesList)
        .then((res) => {
          if (res["job_id"]) {
            setJobID(res["job_id"])
            setShowProgress(true)
          } else {
            // server error
            alert("Server Error: job did not start, job ID is not assigned!")
          }
        })
        .catch((err) => {
          //show error message
          if (err.status === 401) {
            Navigate("/")
          }
          console.log(err)
        })
    } else {
      alert("Please attach files to upload to the server!")
    }
  }

  // check for job status,
  useEffect(() => {
    if (jobID) {
      let interval = setInterval(() => {
        getValidateFrescoBuildingsStatus(jobID)
          .then((res) => {
            if (res["status"] === "In Progress") {
              setProgressValue(parseFloat(res["progress"]) * 100)
            } else {
              if (res["status"] === "Done") {
                clearInterval(interval)
                setShowProgress(false)

                let val_warnings_list = []
                let val_errors_list = []

                if (res["buildings"]) {
                  let processed_list = transformArrayToTableData(
                    res["buildings"]
                  )
                  setProcessedBuildingsList(processed_list)
                }
                if (res["warnings_and_errors"]) {
                  val_warnings_list = separateArrayToWarningsList(
                    res["warnings_and_errors"]
                  )
                  val_errors_list = separateArrayToErrorsList(
                    res["warnings_and_errors"]
                  )
                  setWarningBuildingsList(val_warnings_list)
                  setErrorBuildingsList(val_errors_list)
                }
                let text =
                  val_errors_list.length === 0 && val_warnings_list.length === 0
                    ? "Validate Job Completed!"
                    : val_errors_list.length > 0 &&
                      val_warnings_list.length === 0
                    ? "Validate Job Completed with Errors!"
                    : val_errors_list.length === 0 &&
                      val_warnings_list.length > 0
                    ? "Validate Job Completed with Warnings!"
                    : "Validate Job Completed with Warnings and Errors!"
                setModalStatusText(text)
                setShowModalStatus(true)
                validate_modal_ref.current.showModal()
              } else {
                clearInterval(interval)
                setShowProgress(false)
                let val_errors_list = [
                  {
                    errors: [
                      "Server Error! Please notify us at info@sisaenergy.com",
                    ],
                  },
                ]
                setErrorBuildingsList(
                  separateArrayToErrorsList(val_errors_list)
                )
                setModalStatusText("Server Error: Internal Runtime Error")
                setShowModalStatus(true)
                validate_modal_ref.current.showModal()
              }
            }
          })
          .catch((err) => {
            console.log(
              "Error in getting job status, check Job ID and server status"
            )
            console.log(err)
            clearInterval(interval)
            setStatusText("Server Error: Job ID not found!")
            clearData()
          })
      }, 1000)
      return () => clearInterval(interval)
    }
    // job id set, check for job status
  }, [jobID])

  // call import job API
  // if successful, show job started message
  // call status API every 2 seconds for job status
  // when successful stop loader and show results
  // if failed show error message
  function startImportJobHandler(overwrite_choice) {
    clearData()
    if (sessionFilesList.length !== 0) {
      importFrescoBuildings(sessionFilesList, overwrite_choice)
        .then((res) => {
          if (res["job_id"]) {
            setImportJobID(res["job_id"])
            setShowProgress(true)
          } else {
            // server error
            alert("Server Error: job did not start, job ID is not assigned!")
          }
        })
        .catch((err) => {
          //show error message
          if (err.status === 401) {
            Navigate("/")
          }
          console.log(err)
        })
    } else {
      alert("Please attach files to upload to the server!")
    }
  }

  // check for job status,
  // if in progress, update progress bar
  // if done, show results and errors
  useEffect(() => {
    if (importJobID) {
      let interval = setInterval(() => {
        getImportFrescoBuildingsStatus(importJobID)
          .then((res) => {
            if (res["status"] === "In Progress") {
              setProgressValue(parseFloat(res["progress"]) * 100)
            } else {
              if (res["status"] === "Done") {
                clearInterval(interval)
                setShowProgress(false)
                setJobCompleted(true)

                let warnings_list = []
                let errors_list = []

                if (res["buildings"]) {
                  let processed_list = transformArrayToTableData(
                    res["buildings"]
                  )
                  setProcessedBuildingsList(processed_list)
                }
                if (res["warnings_and_errors"]) {
                  warnings_list = separateArrayToWarningsList(
                    res["warnings_and_errors"]
                  )
                  errors_list = separateArrayToErrorsList(
                    res["warnings_and_errors"]
                  )
                  setWarningBuildingsList(warnings_list)
                  setErrorBuildingsList(errors_list)
                }

                let text =
                  errors_list.length === 0 && warnings_list.length === 0
                    ? "Import Job Completed!"
                    : errors_list.length > 0 && warnings_list.length === 0
                    ? "Import Job Completed with Errors!"
                    : errors_list.length === 0 && warnings_list.length > 0
                    ? "Import Job Completed with Warnings!"
                    : "Import Job Completed with Warnings and Errors!"
                setStatusText(text)
                setSessionFilesList([])
              } else {
                clearInterval(interval)
                let errors_list = [
                  {
                    errors: [
                      "Server Error! Please notify us at info@sisaenergy.com",
                    ],
                  },
                ]
                setErrorBuildingsList(separateArrayToErrorsList(errors_list))
                setStatusText("Server Error: Internal Runtime Error")
                clearData()
              }
            }
          })
          .catch((err) => {
            console.log(
              "Error in getting job status, check Job ID and server status"
            )
            console.log(err)
            clearInterval(interval)
            setStatusText("Server Error: Job ID not found!")
            clearData()
          })
      }, 1000)
      return () => clearInterval(interval)
    }
    // job id set, check for job status
  }, [importJobID])

  // TODO EXTRA: keep upload history and show as list while in session

  // transform array of data into table data
  function transformArrayToTableData(input_array) {
    let table_data = []
    input_array.forEach((item) => {
      let new_data = {
        row_data: {
          ...item,
        },
        is_selected: false,
      }
      table_data.push(new_data)
    })
    return table_data
  }

  // separate warnings array
  function separateArrayToWarningsList(input_array) {
    let warnings_list = []
    input_array.forEach((item) => {
      if (item.warnings) {
        let text_join = item.warnings.join(", ")
        let new_data = {
          row_data: {
            building_address: item.building_address,
            file_name: item.file_name,
            warnings: text_join,
          },
          is_selected: false,
        }
        warnings_list.push(new_data)
      }
    })
    return warnings_list
  }

  // separate errors array
  function separateArrayToErrorsList(input_array) {
    let errors_list = []
    input_array.forEach((item) => {
      if (item.errors) {
        let text_join = item.errors.join(", ")
        let new_data = {
          row_data: {
            building_address: item.building_address,
            file_name: item.file_name,
            errors: text_join,
          },
          is_selected: false,
        }
        errors_list.push(new_data)
      }
    })
    return errors_list
  }

  // Table constants
  const buildingsTableHeaders = [
    {
      header_name: "Building ID",
      header_key: "BuildingId",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Building Address",
      header_key: "BuildingAddress",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Building Name",
      header_key: "BuildingName",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
  ]

  const errorTableHeaders = [
    {
      header_name: "Building Address",
      header_key: "building_address",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "File Name",
      header_key: "file_name",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Errors",
      header_key: "errors",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
  ]

  const warningTableHeaders = [
    {
      header_name: "Building Address",
      header_key: "building_address",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "File Name",
      header_key: "file_name",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Warnings",
      header_key: "warnings",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
  ]

  return (
    <div className='mb-16'>
      <div className='title'>
        <h1 className='section_heading_1'>Import Buildings</h1>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div className='import_container'>
        <div className='instruction_container'>
          <InstructionalText
            type='Main Instructions'
            title='Upload formatted excel files to import building data for various reports.'
            content={
              <>
                Select formatted excel files for uploading to import building
                data into SISA's database and generate reports.
              </>
            }
            additionalContent={[
              "Unsupported files will be rejected and ignored during the uploading process.",
            ]}
          />
          <InstructionalText
            type='Instructional Text'
            content={
              <>
                When the files are uploaded, the server will start processing
                the files and when completed, any warnings or errors will be
                shown to prevent overwriting existing building data.
              </>
            }
            additionalContent={[
              "If you still want the data to be overwritten, you can select to 'Continue and overwrite existing data' or 'Ignore conflicting buildings'",
            ]}
          />
          <InstructionalText
            type='Additional Instructions'
            content={
              <>
                The report data model is automatically refreshed every hour
                during the business day starting with the first update of the
                day at{" "}
                <span className='inline_text--bold'>
                  9AM and at every hour until 4PM PST.
                </span>
                <br />
                Please note that the report data model
                <span className='inline_text--bold'> must be refreshed</span> to
                populate the report with the updated data.
              </>
            }
          />
        </div>

        <div className='buttons_container'>
          <Button
            type='button'
            className='button  button__large button__large--secondary'
            buttonText='Select Files'
            onClick={() => file_uploader_modal.current.showModal()}
          />
        </div>

        <FileDetailsTable
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          data_list={sessionFilesList}
          set_data_list={setSessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          fn_download_file={handleOnDownloadFile}
        />

        <FileUploaderModal
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "message",
            "status",
            "delete",
          ]}
          modal_header='Upload Building Data'
          content_title='Select formatted excel files.'
          content_description='The maximum file size supported is 5MB. This tool supports the following file types: Formatted Excel spreadsheets (.xlsx).'
          modal_ref={file_uploader_modal}
          uploaded_tableData={sessionFilesList}
          fn_remove_row={removeFileFromListHandler}
          set_uploaded_tableData={setSessionFilesList}
          accepted_file_types={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel",
          ]}
          fn_upload_handler={handleSelectFiles}
        />

        <div className='buttons_container'>
          <Button
            type='button'
            className='button  button__large button__large--primary'
            buttonText='Validate and Import Building Data'
            onClick={startValidateJobHandler}
            disabled={showProgress}
          />
        </div>

        <SimpleModal
          modal_ref={validate_modal_ref}
          content_title={"Building Data Validate and Import"}
          content_body={
            <>
              Please check for any warnings or errors found and choose the
              desired outcome for the conflicting Building data.
              <br />
              Building Data with Errors cannot be imported unless the error is
              resolved.
            </>
          }
          inner_components={
            <>
              <InstructionalText
                type='Additional Instructions'
                content={
                  <>
                    <span className='inline_text--bold '>
                      Import All and Overwrite
                    </span>
                    , this option will Import all Buildings without Errors, and
                    Overwrite conflicting Buildings with new data.
                    <br />
                    <span className='inline_text--bold'>
                      Import All and Ignore
                    </span>
                    , this option will Import all Buildings without any Errors
                    or Warnings, ignoring the conflicting Building data.
                  </>
                }
              />
              {showModalStatus && (
                <div
                  className={
                    "text_with_icon " +
                    (errorBuildingsList.length === 0 &&
                    warningBuildingsList.length === 0
                      ? "status_text--complete"
                      : errorBuildingsList.length > 0 &&
                        warningBuildingsList.length === 0
                      ? "status_text--error"
                      : "status_text--warning")
                  }
                >
                  {errorBuildingsList.length === 0 ? (
                    <FontAwesomeIcon icon='fa-light fa-circle-check' />
                  ) : (
                    <FontAwesomeIcon icon='fa-light fa-circle-exclamation' />
                  )}
                  {modalStatusText}
                </div>
              )}
              {errorBuildingsList.length > 0 && (
                <ExpandableHeader
                  header_text={"Errors (" + errorBuildingsList.length + ")"}
                  inner_components={
                    <div className='error_buildings_table'>
                      <Table
                        headers_array={errorTableHeaders}
                        data_array={errorBuildingsList}
                        table_entity_name={"Building"}
                      />
                    </div>
                  }
                />
              )}
              {warningBuildingsList.length > 0 && (
                <ExpandableHeader
                  header_text={"Warnings (" + warningBuildingsList.length + ")"}
                  inner_components={
                    <div className='error_buildings_table'>
                      <Table
                        headers_array={warningTableHeaders}
                        data_array={warningBuildingsList}
                        table_entity_name={"Building"}
                      />
                    </div>
                  }
                />
              )}
            </>
          }
          btn_confirm_primary={
            errorBuildingsList.length === 0 && warningBuildingsList.length === 0
              ? "Import new Building Data"
              : errorBuildingsList.length > 0 &&
                warningBuildingsList.length === 0
              ? ""
              : "Import All and Overwrite"
          }
          btn_confirm_secondary={
            errorBuildingsList.length === 0 && warningBuildingsList.length === 0
              ? ""
              : errorBuildingsList.length > 0 &&
                warningBuildingsList.length === 0
              ? ""
              : "Import All and Ignore"
          }
          btn_cancel={"Cancel"}
          btn_close
          fn_confirm_primary={
            errorBuildingsList.length > 0 && warningBuildingsList.length === 0
              ? null
              : overwriteDataHandler
          }
          fn_confirm_secondary={
            errorBuildingsList.length === 0 && warningBuildingsList.length === 0
              ? null
              : errorBuildingsList.length > 0 &&
                warningBuildingsList.length === 0
              ? null
              : ignoreDataHandler
          }
          fn_close={cancelHandler}
        />

        {showProgress && (
          <div>
            <p>
              {importJobID === ""
                ? "Validating Building Data"
                : "Importing Building Data"}
              : {parseFloatAndFormat(progressValue)} %
            </p>
            <LinearProgress variant='determinate' value={progressValue} />
          </div>
        )}

        {
          // show status text
          jobCompleted && (
            <div
              className={
                "text_with_icon " +
                (errorBuildingsList.length === 0 &&
                warningBuildingsList.length === 0
                  ? "status_text--complete"
                  : errorBuildingsList.length > 0 &&
                    warningBuildingsList.length === 0
                  ? "status_text--error"
                  : "status_text--warning")
              }
            >
              {errorBuildingsList.length === 0 ? (
                <FontAwesomeIcon icon='fa-light fa-circle-check' />
              ) : (
                <FontAwesomeIcon icon='fa-light fa-circle-exclamation' />
              )}
              {statusText}
            </div>
          )
        }
        {
          // show processed buildings
          jobCompleted && processedBuildingsList.length > 0 && (
            <ExpandableHeader
              header_text={
                "Processed Buildings (" + processedBuildingsList.length + ")"
              }
              inner_components={
                <div className='processed_buildings_table'>
                  <Table
                    headers_array={buildingsTableHeaders}
                    data_array={processedBuildingsList}
                    table_entity_name={"Building"}
                  />
                </div>
              }
            />
          )
        }
        {
          // show error buildings
          jobCompleted && errorBuildingsList.length > 0 && (
            <ExpandableHeader
              header_text={"Errors (" + errorBuildingsList.length + ")"}
              inner_components={
                <div className='error_buildings_table'>
                  <Table
                    headers_array={errorTableHeaders}
                    data_array={errorBuildingsList}
                    table_entity_name={"Building"}
                  />
                </div>
              }
            />
          )
        }
        {
          // show warning buildings
          jobCompleted && warningBuildingsList.length > 0 && (
            <ExpandableHeader
              header_text={"Warnings (" + warningBuildingsList.length + ")"}
              inner_components={
                <div className='error_buildings_table'>
                  <Table
                    headers_array={warningTableHeaders}
                    data_array={warningBuildingsList}
                    table_entity_name={"Building"}
                  />
                </div>
              }
            />
          )
        }
      </div>
    </div>
  )
}

export default ImportBuildings
