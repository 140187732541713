import { CSVLink } from 'react-csv';
import '../../styles/download-csv.css';

const DownloadCSV = (props) => {
  const csvReport = {
    data: props.data,
    headers: props.headers,
    filename: props.filename,
  };
  return (
    <button className='download'>
      <CSVLink {...csvReport} className='download-text'>
        Download CSV
      </CSVLink>
    </button>
  );
};

export default DownloadCSV;
