import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { dateTimeFormatter } from "../../utils/DataFormatter"
import {
  discardDraft,
  loadDrafts,
  getESPM_Connection,
  acceptESPM_ConnectionRequest,
  disconnectESPM_Account,
  getESPM_PendingProperties,
  importESPM_Properties,
} from "../../utils/HttpClient"

// React Components
import InstructionalText from "../global/components/InstructionalText/InstructionalText"
import Button from "../global/components/button/Button"
import Table from "../global/components/Table/Table"
import DownloadDropdown from "../global/components/DownloadDropdown/DownloadDropdown"
import XLSXReport from "./components/XLSXReport"
import ExportCSV from "./components/ExportCSV"
import SimpleModal from "../global/components/SimpleModal/SimpleModal"
import Input from "../global/components/input/Input"
import CheckBox from "../global/components/CheckBox/CheckBox"

// Styles
import "./styles/data_handler.css"

// Main Component
function DataHandler(props) {
  const import_espm_modal_ref = useRef(null)
  const [ESPM_AccountId, setESPM_AccountId] = useState("")
  const [ESPM_isConnected, setESPM_isConnected] = useState(false)
  const [ESPM_sentConnectionRequest, setESPM_sentConnectionRequest] =
    useState(false)
  const [ESPM_acceptConnectionRequest, setESPM_acceptConnectionRequest] =
    useState(false)
  const [ESPM_sharedProperties, setESPM_sharedProperties] = useState(false)
  const [ESPM_acceptSharedProperties, setESPM_acceptSharedProperties] =
    useState(false)
  const [ESPM_PendingProperties, setESPM_PendingProperties] = useState([])

  const navigate = useNavigate()

  // Saved Drafts Table Headers
  const savedDraftsHeadersArray = [
    {
      header_name: "Site Name",
      header_key: "site_name",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Status",
      header_key: "status",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Date Created",
      header_key: "date_created",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Date Submitted",
      header_key: "date_submitted",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Edit",
      header_key: "edit",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Download",
      header_key: "download",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Delete",
      header_key: "delete_row",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
  ]

  // Saved Drafts Table Data
  const [savedDraftsDataArray, setSavedDraftsDataArray] = useState([])

  // function to set site names to drafts
  const add_site_names_to_drafts = (drafts) => {
    drafts.forEach((draft) => {
      draft.content_object = JSON.parse(draft.content)
      draft.site_name = draft.content_object.basic_information.name
    })
    return drafts
  }

  const convert_to_saved_draft_data = (drafts) => {
    return drafts.map((draft, index) => {
      let date_submitted = "-"
      if (draft.submitted_date)
        date_submitted = dateTimeFormatter(new Date(draft.submitted_date))
      return {
        row_data: {
          row_index: index,
          identifier: draft.identifier,
          site_name: draft.site_name,
          status: draft.property_status,
          date_created: dateTimeFormatter(new Date(draft.created_date)),
          date_submitted: date_submitted,
          edit:
            draft.property_status !== "Submitted" ? (
              <span
                className='flex draft-edit-button'
                onClick={() => {
                  navigate("/addProperty/form/" + draft.identifier)
                }}
              >
                <span className='material-symbols-outlined pr-2'>Edit</span>Edit
              </span>
            ) : (
              <></>
            ),
          download: (
            <DownloadDropdown>
              {/* <ExportCSV
                data={draft.content_object}
                tabTitle='Download CSV Files'
                fileName={(draft.site_name || "<no_name>") + "-all_bills.zip"}
              /> */}
              <XLSXReport
                data={draft.content_object}
                tabTitle='Download XLS Files'
                fileName={(draft.site_name || "<no_name>") + ".xlsx"}
              />
            </DownloadDropdown>
          ),
          // delete: (
          //   <span
          //     className='flex draft-delete-button'
          //     onClick={() => removeSavedDraft(drafts, draft.identifier)}
          //   >
          //     <span className='material-symbols-outlined pr-2'>Delete</span>
          //     Delete
          //   </span>
          // ),
        },
        is_selected: false,
      }
    })
  }

  useEffect(() => {
    loadDraftsHandler()
    getESPM_Connection().then((res) => {
      if (res && res.espm_account_number) {
        setESPM_isConnected(true)
        setESPM_AccountId(res.espm_account_number)
      }
    })
  }, [props.data])

  function loadDraftsHandler() {
    loadDrafts().then((res) => {
      if (res) {
        res = add_site_names_to_drafts(res)
        setSavedDraftsDataArray(convert_to_saved_draft_data(res))
      } else {
        setSavedDraftsDataArray([])
      }
    })
  }

  function removeSavedDraftHandler(row_index) {
    let draft_id = savedDraftsDataArray[row_index].row_data.identifier
    discardDraft(draft_id).then((res) => {
      if (res) {
        props.get_user_data()
      } else {
        alert("Error deleting draft")
      }
    })
  }

  function resetImportESPM_Dialog() {
    setESPM_AccountId("")
    setESPM_sentConnectionRequest(false)
    setESPM_acceptConnectionRequest(false)
    setESPM_sharedProperties(false)
    setESPM_acceptSharedProperties(false)
    setESPM_PendingProperties([])
  }

  return (
    <div className='mb-16'>
      <div className='title'>
        <h1 className='section_heading_1'>Data Handler</h1>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      <div id='landing-page' className='data-collection__main-container'>
        <div className='form__container'>
          <p className='form__title'>Data Survey</p>
          <div className='form__content'>
            <div className='form__description'>
              <InstructionalText
                type='Main Instructions'
                title='New Property'
                content='Enter the data of a property manually to add it to your account. If you do not have all your property information ready at once, you can save your progress and continue editing later.'
                additionalContent={[
                  "You will need to include basic information, building details such as energy meters, equipments, lights, water fixtures, etc. You can attach images, files and folders to complement your property data.",
                ]}
                additionalClassName='additional_p'
                containerClassName='basicInfo-instruction__container'
              />
              <InstructionalText
                type='Additional Instructions'
                title='Request an energy assessment of your property.'
                content='To request a performance evaluation of your property you need to fill all the required fields, complete all forms and submit your data. A SISA Energy representative will contact you when your energy assessment report is ready.'
              />
            </div>
            <div className='form__buttons__right'>
              <div></div>
              <Button
                type='button'
                buttonText='Get Started!'
                className={"button__large button__large--primary"}
                onClick={() => navigate("/dataHandler/dataSurvey")}
              />
            </div>
            <div className='form__description'>
              <InstructionalText
                type='Main Instructions'
                title='Saved Properties'
                content='Select one of the properties below and continue filling in
                    the data where you left off.'
                containerClassName='basicInfo-instruction__container'
              />

              <Table
                headers_array={savedDraftsHeadersArray}
                data_array={savedDraftsDataArray}
                no_data_message={"There are no saved properties"}
                fn_delete_row={removeSavedDraftHandler}
                table_entity_name={"property"}
              />
            </div>
          </div>
        </div>

        <div className='form__container'>
          <p className='form__title'>Import Properties</p>
          <div className='form__content'>
            <div className='form__description'>
              <InstructionalText
                type='Main Instructions'
                title='Import properties from other sources'
                containerClassName='basicInfo-instruction__container'
              />
            </div>
            <div className='form__buttons'>
              <div>
                <SimpleModal
                  modal_ref={import_espm_modal_ref}
                  content_title='Import from Portfolio Manager'
                  content_body='Import buildings from Portfolio Manager.'
                  btn_confirm_primary={false}
                  btn_cancel={false}
                  btn_close={true}
                  on_validation={true}
                  inner_components={
                    <>
                      <Input
                        label='Portfolio Manager Account ID'
                        type='text'
                        name='portfolio_manager_account_id'
                        onChange={(newValue) => {
                          setESPM_AccountId(newValue.value)
                        }}
                        value={ESPM_AccountId}
                        placeholder='Enter Portfolio Manager Account ID'
                        className='default'
                        required
                        validation
                        disabled={ESPM_isConnected}
                      />
                      <CheckBox
                        label='I have sent the connection request.'
                        name='sent_connection_request'
                        checked={ESPM_isConnected || ESPM_sentConnectionRequest}
                        disabled={
                          ESPM_isConnected || ESPM_acceptConnectionRequest
                        }
                        onChange={(e) => {
                          setESPM_sentConnectionRequest(e.checked)
                        }}
                      />
                      <Button
                        type='button'
                        buttonText='Connect Account'
                        className='button__large button__large--primary'
                        onClick={() => {
                          acceptESPM_ConnectionRequest(ESPM_AccountId)
                            .then((res) => {
                              if (!res || res.error === true) {
                                alert(
                                  res?.errorMessage
                                    ? 'Error connecting account: "' +
                                        res?.errorMessage +
                                        '"'
                                    : "Error connecting account"
                                )
                                return
                              }
                              setESPM_acceptConnectionRequest(true)
                              setESPM_isConnected(true)
                            })
                            .catch((err) => {
                              alert("Error connecting account")
                              console.error(err)
                            })
                        }}
                        disabled={
                          ESPM_isConnected ||
                          !ESPM_sentConnectionRequest ||
                          ESPM_AccountId == ""
                        }
                      />
                      {(ESPM_acceptConnectionRequest || ESPM_isConnected) && (
                        <>
                          <hr className='horizontal-light' />
                          <CheckBox
                            label='I have shared my properties.'
                            name='shared_properties'
                            checked={ESPM_sharedProperties}
                            disabled={ESPM_acceptSharedProperties}
                            onChange={(e) => {
                              setESPM_sharedProperties(e.checked)
                            }}
                          />
                          <Button
                            type='button'
                            buttonText='Get Properties'
                            className='button__large button__large--primary'
                            disabled={
                              !ESPM_sharedProperties || ESPM_AccountId == ""
                            }
                            onClick={() => {
                              getESPM_PendingProperties(ESPM_AccountId)
                                .then((res) => {
                                  if (!res || res.error === true) {
                                    alert(
                                      res?.errorMessage
                                        ? 'Error getting properties: "' +
                                            res?.errorMessage +
                                            '"'
                                        : "Error getting properties"
                                    )
                                    return
                                  }
                                  setESPM_acceptSharedProperties(true)
                                  setESPM_PendingProperties(res ?? [])
                                })
                                .catch((err) => {
                                  alert("Error getting properties")
                                  console.error(err)
                                })
                            }}
                          />
                        </>
                      )}
                      {ESPM_acceptSharedProperties && (
                        <div className='full-width'>
                          {ESPM_PendingProperties.length} properties available
                          for import
                        </div>
                      )}
                      <hr className='horizontal-light' />
                      <div className='form__buttons__right'>
                        {ESPM_isConnected && (
                          <Button
                            type='button'
                            buttonText='Disconnect Account'
                            className='button__large button__large--secondary'
                            onClick={() => {
                              disconnectESPM_Account(ESPM_AccountId)
                                .then((res) => {
                                  if (!res || res.error === true) {
                                    alert("Error disconnecting account")
                                    return
                                  }
                                  setESPM_isConnected(false)
                                  resetImportESPM_Dialog()
                                })
                                .catch((err) => {
                                  alert("Error disconnecting account")
                                  console.error(err)
                                })
                            }}
                          />
                        )}
                        <Button
                          type='button'
                          buttonText='Import Properties'
                          className='button__large button__large--primary'
                          disabled={ESPM_PendingProperties.length == 0}
                          onClick={() => {
                            let property_ids = ESPM_PendingProperties.map(
                              (prop) => prop.propertyId
                            )
                            importESPM_Properties(ESPM_AccountId, property_ids)
                              .then((res) => {
                                if (!res || res.error === true) {
                                  alert(
                                    res?.errorMessage
                                      ? 'Error importing properties: "' +
                                          res?.errorMessage +
                                          '"'
                                      : "Error importing properties"
                                  )
                                  return
                                }
                                props.get_user_data()
                                import_espm_modal_ref.current.close()
                                setESPM_isConnected(true)
                                setESPM_sharedProperties(false)
                                setESPM_acceptSharedProperties(false)
                                setESPM_PendingProperties([])
                              })
                              .catch((err) => {
                                console.error(err)
                              })
                          }}
                        />
                      </div>
                    </>
                  }
                  fn_confirm_primary={() => {
                    return true
                  }}
                />
              </div>
              <Button
                type='button'
                buttonText='From Energy Star Portfolio Manager'
                className={"button__large button__large--primary"}
                onClick={() => {
                  import_espm_modal_ref.current.showModal()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataHandler
