import "../../../styles/importProperty/FileUploader.css"
import { useEffect, useState, useRef } from "react"

function FileNameTag({ file_name, status }) {
  const [iconName, setIconName] = useState("")
  const icon_ref = useRef(null)

  useEffect(() => {
    if (status.includes("Accepted")) {
      setIconName("task")
      icon_ref.current.style.color = "green"
    } else if (status.includes("Rejected") || status.includes("Error")) {
      setIconName("scan_delete")
      icon_ref.current.style.color = "red"
    } else {
      setIconName("draft")
      icon_ref.current.style.color = "#112e4b"
    }
  }, [status])

  return (
    <div className='file-name-tag'>
      <span ref={icon_ref} className='material-symbols-outlined'>
        {iconName}
      </span>
      <p>{file_name}</p>
    </div>
  )
}

export default FileNameTag
