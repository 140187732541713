export const defaultDraft = {
  draft_name: "",
  draft_date: "",
  project_number: "",
  basic_information: {
    name: "",
    street_address: "",
    city_municipality: "",
    state_province: "",
    county: "",
    postal_code: "",
    owner_organization: "",
    office_address: "",
    applicant_name: "",
    telephone: "",
    email: "",
    program_job_no: "",
    site_contact_name: "",
    site_auditors: "",
    program: "N/A",
    responsible_project_engineer: "",
    email_of_responsible_engineer: "",
    site_visit_date: "",
  },
  building_data: {
    property_type: "Other",
    gross_floor_area: "",
    total_building_perimeter: "",
    no_of_bachelor_suites: "",
    no_of_1_bedroom_suites: "",
    no_of_2_bedroom_suites: "",
    no_of_3_or_more_bedroom_suites: "",
    total_no_of_suites: "0",
    avg_bachelor_area: "250",
    avg_1_bedroom_area: "400",
    avg_2_bedroom_area: "600",
    avg_3_or_more_bedroom_area: "1000",
    total_avg_suite_area: "",
    no_of_workers_on_main_shift: "",
    no_of_residents: "",
    total_no_of_people: "",
    no_of_elevators: "",
    no_of_floors: "",
    original_construction_year: "",
    last_remodeling_year: "",
    do_suites_have_elec_meters: "No",
    has_cooling: "No",
    domestic_hot_water_primary_fuel: "Natural Gas",
    outdoor_air_ventilation_heating_primary_fuel: "Natural Gas",
    space_heating_primary_fuel: "Natural Gas",
  },
  property_energy_ack: {
    isNoEnergyInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_energy_utilities: [
    {
      utility_name: "Electric-Grid - 1",
      rate_structure: "Other",
      fuel: "Electric-Grid",
      energy_unit: "kWh",
      average_rate: "",
      average_demand_rate: "",
    },
    {
      utility_name: "Natural Gas - 1",
      rate_structure: "Other",
      fuel: "Natural Gas",
      energy_unit: "GJ",
      average_rate: "",
      average_demand_rate: "",
    },
  ],
  property_energy_meters: [
    {
      meter_name: "Electric-Grid-Meter - 1",
      meter_utility: "Electric-Grid - 1",
    },
    {
      meter_name: "Natural Gas-Meter - 1",
      meter_utility: "Natural Gas - 1",
    },
  ],
  property_energy_bills: [],
  property_equipments_ack: {
    isNoEquipmentInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_equipments: [
    {
      created_date: "",
      type: "",
      quantity: "",
      name: "",
      description: "",
      installation_year: "",
      make: "",
      model: "",
      area_served: "",
      location: "",
      frequency_of_service_per_year: "",
      service_maintenance_procedure_comments: "",
      electrical_power_current: "",
      electrical_power_voltage: "",
      single_phase_or_three_phase: "3-Phase",
      general_specifications: {
        power_or_capacity: "Input Power",
        rating: "",
        unit: "",
        meter: "",
        control_type: "",
        average_annual_hours_of_operation: "",
        general_comments: "",
      },
      end_uses: [],
      end_uses_list: [
        {
          label: "Space Heating",
          name: "space_heating",
          type: "heating",
          checked: false,
        },
        {
          label: "Space Cooling",
          name: "space_cooling",
          type: "cooling",
          checked: false,
        },
        {
          label: "Domestic Hot Water",
          name: "domestic_hot_water",
          type: "heating",
          checked: false,
        },
        {
          label: "Fans",
          name: "fans",
          type: "fans",
          checked: false,
        },
        {
          label: "Laundry",
          name: "laundry",
          type: "general",
          checked: false,
        },
        {
          label: "Lighting",
          name: "lighting",
          type: "general",
          checked: false,
        },
        {
          label: "Pumps",
          name: "pumps",
          type: "pumps",
          checked: false,
        },
        {
          label: "Outdoor Air Cooling",
          name: "outdoor_air_cooling",
          type: "cooling",
          checked: false,
        },
        {
          label: "Outdoor Air Heating",
          name: "outdoor_air_heating",
          type: "heating",
          checked: false,
        },
        {
          label: "Water",
          name: "water",
          type: "general",
          checked: false,
        },
        {
          label: "Other",
          name: "other",
          type: "general",
          checked: false,
        },
      ],
      equipment_files_list: [],
    },
  ],
  property_mechanical_electrical_ack: {
    isNoMechanicalElectricalInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_mechanical_electrical: {
    mechanical_rooms: [],
    electrical_rooms: [],
    electrical_panels: [],
  },
  property_lights_ack: {
    isNoLightInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_lights: [
    {
      technology: "T8 (Fluorescent)",
      area_served: "N/A",
      space_description: "",
      power_information: "Detailed power info",
      watts_per_lamp: 34,
      num_lamps_per_fixture: 1,
      num_fixtures: 1,
      watts_per_area: "",
      area_if_not_whole_building: "",
      lights_on_time: "",
      control_type: "Always on",
      control_comments: "",
      light_files_list: [],
    },
  ],
  property_water_ack: {
    isNoWaterInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_water_utilities: [
    {
      utility_name: "Potable Water - Indoor - 1",
      fuel: "Potable Water - Indoor",
      energy_unit: "cm (cubic meters)",
      average_rate: "",
    },
  ],
  property_water_meters: [
    {
      meter_name: "Potable Water - Indoor - Meter - 1",
      meter_utility: "Potable Water - Indoor - 1",
    },
  ],
  property_water_bills: [],
  property_water_fixtures_ack: {
    isNoWaterFixtureInformation: false,
    isOnlyUploadDocuments: false,
    general_comments: "",
  },
  property_water_fixtures: [],
  property_envelope_ack: {
    isNoEnvelopeInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_envelope: {
    construction_material_type: "Concrete",
    no_of_exterior_doors: 2,
    is_parkade_ceiling_insulated: "Yes",
    parkade_ceiling_r_factor: "",
    parkade_ceiling_r_factor_label: "Parkade ceiling R factor (ft2·°F·h/BTU):",
    is_roof_insulated: "No",
    roof_r_factor: "",
    roof_r_factor_label: "Roof R factor (ft2·°F·h/BTU):",
    walls_r_factor: "",
    walls_r_factor_label: "Walls R factor (ft2·°F·h/BTU):",
    windows_type: "Double pane",
    windows_coverage: 0.1,
    windows_draftyness: "Tight",
    windows_u_factor: "",
    windows_u_factor_label: "Windows R factor (ft2·°F·h/BTU):",
    general_comment: "",
  },
  property_documents_ack: {
    isNoRelatedDocuments: false,
    isNoEnergyAudits: false,
  },
  property_supporting_documents: [],
  property_energy_audits: [],
}

export const newUserDraft = {
  draft_name: "",
  draft_date: "",
  project_number: "",
  basic_information: {
    name: "",
    street_address: "",
    city_municipality: "",
    state_province: "",
    county: "",
    postal_code: "",
    owner_organization: "",
    office_address: "",
    applicant_name: "",
    telephone: "",
    email: "",
    program_job_no: "",
    site_contact_name: "",
    site_auditors: "",
    program: "N/A",
    responsible_project_engineer: "",
    email_of_responsible_engineer: "",
    site_visit_date: "",
  },
  building_data: {
    property_type: "Other",
    gross_floor_area: "",
    total_building_perimeter: "",
    no_of_bachelor_suites: "",
    no_of_1_bedroom_suites: "",
    no_of_2_bedroom_suites: "",
    no_of_3_or_more_bedroom_suites: "",
    total_no_of_suites: "0",
    avg_bachelor_area: "250",
    avg_1_bedroom_area: "400",
    avg_2_bedroom_area: "600",
    avg_3_or_more_bedroom_area: "1000",
    total_avg_suite_area: "",
    no_of_workers_on_main_shift: "",
    no_of_residents: "",
    total_no_of_people: "",
    no_of_elevators: "",
    no_of_floors: "",
    original_construction_year: "",
    last_remodeling_year: "",
    do_suites_have_elec_meters: "No",
    has_cooling: "No",
    domestic_hot_water_primary_fuel: "Natural Gas",
    outdoor_air_ventilation_heating_primary_fuel: "Natural Gas",
    space_heating_primary_fuel: "Natural Gas",
  },
  property_mechanical_electrical: {
    mechanical_rooms: [],
    electrical_rooms: [],
    electrical_panels: [],
  },
  property_energy_ack: {
    isNoEnergyInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_energy_utilities: [
    {
      utility_name: "Electric-Grid - 1",
      rate_structure: "Other",
      fuel: "Electric-Grid",
      energy_unit: "kWh",
      average_rate: "",
      average_demand_rate: "",
    },
    {
      utility_name: "Natural Gas - 1",
      rate_structure: "Other",
      fuel: "Natural Gas",
      energy_unit: "GJ",
      average_rate: "",
      average_demand_rate: "",
    },
  ],
  property_energy_meters: [
    {
      meter_name: "Electric-Grid-Meter - 1",
      meter_utility: "Electric-Grid - 1",
    },
    {
      meter_name: "Natural Gas-Meter - 1",
      meter_utility: "Natural Gas - 1",
    },
  ],
  property_energy_bills: [],
  property_water_ack: {
    isNoWaterInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_water_utilities: [
    {
      utility_name: "Potable Water - Indoor - 1",
      fuel: "Potable Water - Indoor",
      energy_unit: "cm (cubic meters)",
      average_rate: "",
    },
  ],
  property_water_meters: [
    {
      meter_name: "Potable Water - Indoor - Meter - 1",
      meter_utility: "Potable Water - Indoor - 1",
    },
  ],
  property_water_bills: [],
  property_equipments_ack: {
    isNoEquipmentInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_equipments: [],
  property_lights_ack: {
    isNoLightInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_lights: [],
  property_water_fixtures_ack: {
    isNoWaterFixtureInformation: false,
    isOnlyUploadDocuments: false,
    general_comments: "",
  },
  property_water_fixtures: [],
  property_envelope_ack: {
    isNoEnvelopeInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_envelope: {
    construction_material_type: "Concrete",
    no_of_exterior_doors: 2,
    is_parkade_ceiling_insulated: "Yes",
    parkade_ceiling_r_factor: "",
    is_roof_insulated: "No",
    roof_r_factor: "",
    walls_r_factor: "",
    windows_type: "Double pane",
    windows_coverage: 0.1,
    windows_draftyness: "Tight",
    windows_u_factor: "",
    general_comment: "",
  },
  property_documents_ack: {
    isNoRelatedDocuments: false,
    isNoEnergyAudits: false,
  },
  property_supporting_documents: [],
  property_energy_audits: [],
}

// defaults, value types and mapping
const API_DEFAULTS = {
  draft_name: "",
  draft_date: "",
  project_number: "",
  basic_property_information: {
    name: "", // output_participant.site_name
    street_address: "", // output_participant.site_address
    city_municipality: "", // output_participant.city
    state_province: "", // output_participant.province
    county: "", // Not used
    postal_code: "", // Not used
    owner_organization: "", // Not used
    office_address: "", // output_participant.office_address
    applicant_name: "", // output_participant.applicant_name
    telephone: "", // output_participant.telephone
    email: "", // output_participant.email
    program_job_no: "", // Not used
    site_contact_name: "", // Not used
    site_auditors: "", // output_consultant.study_authors
    program: "N/A", // Not used
    other_program: "",
    responsible_project_engineer: "", // output_consultant.consultant_name, output_consultant.responsible_individual
    email_of_responsible_engineer: "", // output_consultant.email
    site_visit_date: "", // Not used
  },
  property_usage: {
    property_type: "", // output_development.building_type
    gross_floor_area: 0, // output_development.total_floor_area
    no_of_bachelor_suites: 0, // Not used
    no_of_1_bedroom_suites: 0, // Not used
    no_of_2_bedroom_suites: 0, // Not used
    no_of_3_or_more_bedroom_suites: 0, // Not used
    total_no_of_suites: 0, // output_development.num_suites
    avg_bachelor_area: 0, // Not used
    avg_1_bedroom_area: 0, // Not used
    avg_2_bedroom_area: 0, // Not used
    avg_3_or_more_bedroom_area: 0, // Not used
    total_avg_suite_area: 0, // Not used
    no_of_workers_on_main_shift: 0, // output_development.full_time_employees
    no_of_residents: 0, // output_development.num_residents
    total_no_of_people: 0, // Not used
    no_of_elevators: 0, // Not used
    no_of_floors: 0, // Not used
    year_built: 0, // output_development.year_of_construction
    last_remodeling_year: 0, // output_development.year_of_last_remodel
    do_tenants_pay_for_electricity: "No", // Not used
    has_cooling: "No", // Not used
  },
  property_usage_ESPM: {
    // Not used
    // changes depending on the type of property chosen
    // the object will be built dynamically and added here
  },
  property_energy_meters: [
    // Not used
    {
      meter_name: "", // Not used
      meter_type: "Natural Gas", // Not used
      other_type: "", // Not used
      units: "", // Not used
      in_use: "", // Not used
      date_meter_became_active: "", // Not used
      date_meter_became_inactive: "", // Not used
      enter_as_delivery: "", // Not used
      bills: [
        // Electric/Gas bill only
        {
          start_date: "2023-08-01", // output_gas_bills.month, output_electrical_bills.month
          end_date: "", // Not used
          usage: 10, // output_gas_bills.gas_consumption, output_electrical_bills.electrical_consumption_kwh
          total_cost: "", // Not used
          estimation: "", // Not used
          energy_used_on_site: "", // Not used
          energy_exported_offsite: "", // Not used
          rec_ownership: "", // Not used
          green_power: "", // Not used
          demand_kw: 0, // output_electrical_bills.electrical_demand
          demand_cost: "", // Not used
        },
      ],
    },
    {
      meter_name: "", // Not used
      meter_type: "Electric-Grid", // Not used
      other_type: "", // Not used
      units: "", // Not used
      in_use: "", // Not used
      date_meter_became_active: "", // Not used
      date_meter_became_inactive: "", // Not used
      enter_as_delivery: "", // Not used
      bills: [
        // Electric/Gas bill only
        {
          start_date: "2023-08-01", // output_gas_bills.month, output_electrical_bills.month
          end_date: "", // Not used
          usage: 20, // output_gas_bills.gas_consumption, output_electrical_bills.electrical_consumption_kwh
          total_cost: "", // Not used
          estimation: "", // Not used
          energy_used_on_site: "", // Not used
          energy_exported_offsite: "", // Not used
          rec_ownership: "", // Not used
          green_power: "", // Not used
          demand_kw: 0, // output_electrical_bills.electrical_demand
          demand_cost: "", // Not used
        },
      ],
    },
  ],
  property_utility_rates: {
    electricity_provider: "", // output_utility_rates.provider
    electricity_rate_kwh: 0, // output_utility_rates.marginal_rate, total_blended_rate, energy_rate_before_taxes
    electricity_demand_rate_kw: 0, // output_utility_rates.demand_rate
    gas_or_fuels_provider: "", // output_utility_rates.provider
    gas_or_fuels_rate_gj: 0, // output_utility_rates.marginal_rate, total_blended_rate, energy_rate_before_taxes
    water_provider: "", // output_utility_rates.provider
    water_rate_m3: 0, // output_utility_rates.marginal_rate, total_blended_rate, energy_rate_before_taxes
  },
  property_energy_sources: {
    domestic_hot_water_primary: "Gas", // output_development.dhwpf
    outdoor_air_ventilation_heating_primary: "Gas", // output_development.oa_vent_heat
    space_heating_primary: "Gas", // output_development.shpf
  },
  property_equipments_ack: {
    isNoEquipmentInformation: false,
    isOnlyUploadDocuments: false,
  },
  property_equipments: [
    {
      type: "Boiler",
      quantity: 1,
      name: "Boiler 1",
      description: "",
      installation_year: "",
      make: "",
      model: "",
      area_served: "",
      location: "",
      frequency_of_service_per_year: "",
      service_maintenance_procedure_comments: "",
      electrical_power_current: "",
      electrical_power_voltage: "",
      single_phase_or_three_phase: "",
      general_specifications: {
        power_or_capacity: "",
        rating: "",
        unit: "",
        meter: "",
        control_type: "",
        average_annual_hours_of_operation: "",
        general_comments: "",
      },
      end_uses: [
        {
          end_use: "Space Heating",
          heating_unit_type: "",
          primary_or_secondary_unit: "Primary",
          average_efficiency_or_cop: "",
          capacity_rating: "",
          unit: "",
          meter: "",
          control_type: "",
          average_annual_hours_of_operation: "",
          unit_has_backup: "No",
          backup_heating_unit_type: "",
          backup_efficiency: "",
          meter: "",
          backup_control_comment: "",
          unit_has_heat_recovery: "No",
          heat_recovery_efficiency: "",
          heat_recovery_comment: "",
          general_comments: "",
        },
        {
          end_use: "Space Cooling",
          cooling_unit_type: "",
          primary_or_secondary_unit: "Primary",
          capacity_rating: "",
          unit: "",
          meter: "",
          average_efficiency_or_cop: "",
          control_type: "",
          average_annual_hours_of_operation: "",
          general_comments: "",
        },
        {
          end_use: "Pumps",
          water_flow: "",
          variable_speed_or_frequency: "No",
          variable_speed_or_frequency_comment: "",
        },
        {
          end_use: "Fans",
          air_flow: "",
          outside_air_percentage: "",
          variable_speed_or_frequency: "No",
          variable_speed_or_frequency_comment: "",
        },
      ],
    },
  ],
  property_lights: [
    {
      technology: "T8 (Fluorescent)", // output_light.technology
      area_served: "Other", // output_light.area_served
      space_description: "", // output_light.space_description
      watts_per_lamp: 10, // output_light.watt_per_lamp
      no_of_lamps_per_fixture: 1, // output_light.lamp_per_fixture
      no_of_fixtures: 1, // output_light.num_fixtures
      lights_on_time: 24, // Not used
      control_type: "Always on", // Not used
      control_comments: "", // Not used
    },
  ],
  property_water_fixtures: {
    // Not used
    kitchen_sinks: 0,
    lavatories: 0,
    urinals: 0,
    showers: 0,
    toilets: 0,
    others: 0,
    meters: [
      {
        meter_name: "",
        meter_type: "",
        meter_units: "",
        in_use: "",
        date_meter_became_active: "",
        date_meter_became_inactive: "",
        bills: [
          {
            start_date: "",
            end_date: "",
            usage: "",
            total_cost: "",
            estimation: "",
          },
        ],
      },
    ],
  },
  property_envelope: {
    construction_type: "Concrete", // output_envelope.envelope_type = "Construction type" + output_envelope.description = "Concrete"
    no_of_exterior_doors: 2, // output_envelope.envelope_type = "Number of exterior doors" + output_envelope.description = "2"
    is_parkade_ceiling_insulated: "", // output_envelope.envelope_type = "Parkade ceiling insulation material" + output_envelope.description = "..."
    parkade_ceiling_r_factor: "", // output_envelope.envelope_type = "Parkade ceiling R-factor" + output_envelope.description = "..."
    is_roof_insulated: "",
    roof_r_factor: "", // output_envelope.envelope_type = "Roof R-factor" + output_envelope.description = "..."
    walls_r_factor: "", // output_envelope.envelope_type = "Walls R-factor" + output_envelope.description = "..."
    windows_type: "Double Pane", // output_envelope.envelope_type = "Windows type" + output_envelope.description = "Double Pane"
    windows_coverage: 0.1, // output_envelope.envelope_type = "Windows coverage (%)" + output_envelope.description = "10"
    windows_draftyness: "Tight", // output_envelope.envelope_type = "Windows draftyness" + output_envelope.description = "Medium"
    windows_u_factor: "", // output_envelope.envelope_type = "Windows R-factor" + output_envelope.description = "..."
    general_comment: "",
  },
  property_supporting_documents: [], // Not used
}

export const requiredFields = [
  {
    section: "basic_information",
    tabIndex: 0,
    option: "all_fields_required",
    fields: ["name", "city_municipality", "owner_organization"],
  },
  {
    section: "building_data",
    tabIndex: 1,
    option: "all_fields_required",
    fields: [
      "property_type",
      "gross_floor_area",
      "total_no_of_suites",
      "total_no_of_people",
    ],
  },
  {
    ack_section: "property_energy_ack",
    tabIndex: 2,
    option: "acknowledge_one_or_one_entry_required",
    ack_fields: ["isNoEnergyInformation", "isOnlyUploadDocuments"],
    array_sections: ["property_energy_utilities", "property_energy_meters"],
  },
  {
    ack_section: "property_water_ack",
    tabIndex: 3,
    option: "acknowledge_one_or_one_entry_required",
    ack_fields: ["isNoWaterInformation", "isOnlyUploadDocuments"],
    array_sections: ["property_water_utilities", "property_water_meters"],
  },
  {
    ack_section: "property_equipments_ack",
    tabIndex: 4,
    option: "acknowledge_one_or_one_entry_required",
    ack_fields: ["isNoEquipmentInformation", "isOnlyUploadDocuments"],
    array_sections: ["property_equipments"],
  },
  {
    ack_section: "property_lights_ack",
    tabIndex: 5,
    option: "acknowledge_one_or_one_entry_required",
    ack_fields: ["isNoLightInformation", "isOnlyUploadDocuments"],
    array_sections: ["property_lights"],
  },
  {
    ack_section: "property_water_fixtures_ack",
    tabIndex: 6,
    option: "acknowledge_one_or_one_entry_required",
    ack_fields: ["isNoWaterFixtureInformation", "isOnlyUploadDocuments"],
    array_sections: ["property_water_fixtures"],
  },
  {
    section: "property_envelope",
    ack_section: "property_envelope_ack",
    tabIndex: 7,
    option: "acknowledge_one_or_all_fields_required",
    fields: ["windows_coverage", "windows_type"],
    ack_fields: ["isNoEnvelopeInformation", "isOnlyUploadDocuments"],
  },
  {
    ack_section: "property_documents_ack",
    tabIndex: 8,
    option: "acknowledge_both_or_one_entry_required",
    ack_fields: ["isNoRelatedDocuments", "isNoEnergyAudits"],
    array_sections: ["property_supporting_documents", "property_energy_audits"],
  },
]
