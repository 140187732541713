import "../../../styles/analysisTools/analysis-decarb.css"
import { useEffect, useState, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import MultiFilter from "./MultiFilter"
import DecarbPlanner from "./DecarbPlanner"
import {
  calculateDecarbPlan,
  delete_decarb_plan,
} from "../../../utils/HttpClient"
import SavingsTable from "../components/SavingsTable"
import FinancialAnalysis from "./FinancialAnalysis"
import BaselineBarChart from "../components/BaselineBarChart"
import ModalDialog from "../../home/components/ModalDialog"
import {
  parseFloatAndFormat,
  parseIntAndFormat,
} from "../../../utils/DataFormatter.js"
import Incentives from "../bundle/Incentives"
import { Sofiac, CMHC } from "../bundle/IncentiveOrganiztionDetail.js"
import { LinearProgress } from "@mui/material"

/**
 *  props.data = data.all_data.analysis
 *  {
 *     0: ecm.ecm_identifier
 *     1: participant.site_name
 *     2: participant.province
 *     3: ecm.description
 *     4: ecm.proposed_cost_to_install
 *     5: ecm.base_case_cost_to_install
 *     6: ecm.irr
 *     7: ecm.simple_payback
 *     8: ecm.npv
 *     9: ecm.roi
 *     10: ecm.gas_gj_savings_per_year
 *     11: ecm.electric_kwh_savings_per_year
 *     12: ecm.electric_kw_savings
 *     13: ecm.water_savings_m3
 *     14: ecm.incentive
 *     15: ecm.first_year_utility_savings
 *     16: ecm.group_number
 *     17: ecm.hierarchy
 *     18: ecm.year_of_implementation
 *     19: ecm.annual_onsite_ghg_savings
 *     20: ecm.project_number
 *  }
 *
 *  0 - 8 are for the Analysis page
 *  9 - 17 are for the Bundle page
 *  18 - 20 are for the Decarb page
 *
 */

function Decarb(props) {
  const CURRENT_YEAR = new Date().getFullYear()
  const [selectedProjectNumbers, setSelectedProjectNumbers] = useState([])
  const [selectedPlanOption, setSelectedPlanOption] = useState([])

  // for the decarb planner table
  const [decarbPlanECMs, setDecarbPlanECMs] = useState([])
  const [startYear, setStartYear] = useState(CURRENT_YEAR)
  const [endYear, setEndYear] = useState(CURRENT_YEAR)
  const [selectedPlanContents, setSelectedPlanContents] = useState({})
  const [selectedPlanName, setSelectedPlanName] = useState("")

  // for the results summary tables
  const [analyzePlan, setAnalyzePlan] = useState(false)
  const [savingsData, setSavingsData] = useState({})
  const [financialData, setFinancialData] = useState({})
  const [ghgEmissionsBreakdown, setGHGEmissionsBreakdown] = useState({
    labels: [],
  })
  const [euiBreakdown, setEUIBreakdown] = useState({
    labels: [],
  })
  const [utilityCostBreakdown, setUtilityCostBreakdown] = useState({
    labels: [],
  })

  const [isAnalyzing, setIsAnalyzing] = useState(false)

  const [loadedPlan, setLoadedPlan] = useState(false)
  const [updatePlan, setUpdatePlan] = useState(false)
  const [sofiacQualification, setSofiacQualification] = useState({})
  const [cmhcLevel, setCmhcLevel] = useState("")
  const [redirectFromAssessments, setRedirectFromAssessments] = useState(false)
  const [isPlanDeleted, setIsPlanDeleted] = useState(false)

  const navigate = useNavigate()
  const delete_modal = useRef(null)

  useEffect(() => {
    // if a plan is selected, load it
    if (selectedPlanOption.value) {
      props.clearCacheData()
      props.get_user_data()
    } else {
      setLoadedPlan(false)
      resetECMYears()
      setAnalyzePlan(false)
      setSavingsData({})
      setFinancialData({})
    }
  }, [selectedPlanOption])

  useEffect(() => {
    if (selectedPlanOption.value) {
      setLoadedPlan(true)
      loadSavedPlan(selectedPlanOption.value)
    }
  }, [props.data.decarb_plans])

  useEffect(() => {
    if (decarbPlanECMs.length > 0 && loadedPlan && !updatePlan) {
      analyzePlanClick()
    }
  }, [decarbPlanECMs])

  // function for building the decarbPlanECMs state for loading saved plans
  // use ecm_table_data to build the table of ECMs

  // parse plan_contents into JSON object
  // loop through plan_contents to get years and ecm_identifiers

  // loop through ecm_table_data and get each ecm using the identifier
  // add the year to each ecm object
  // add the ecm object to decarbPlanECMs
  function loadSavedPlan(plan_id) {
    setUpdatePlan(false)
    let ecm_table_data = []
    let ecm_years = []
    let plan = props.data.decarb_plans.find((plan) => plan.plan_id === plan_id)
    if (plan) {
      let plan_contents = JSON.parse(plan.plan_contents)
      setSelectedPlanContents(plan_contents)
      setSelectedPlanName(plan.plan_name)

      for (let year of Object.keys(plan_contents)) {
        ecm_years.push(Number(year))
      }
      setStartYear(ecm_years[0])
      setEndYear(ecm_years[ecm_years.length - 1])

      // add year for each ecm
      for (let year of ecm_years) {
        // if (!plan_contents[year]) continue
        for (let ecm_id of plan_contents[year]) {
          let ecm_data = props.data.ecm_analysis_table.filter(
            (ecm) => ecm[0] === ecm_id
          )[0]
          if (ecm_data) {
            ecm_table_data.push({
              ecm_identifier: parseInt(ecm_data[0]),
              site_name: ecm_data[1],
              ecm_description: ecm_data[3],
              ecm_gas_gj_savings: parseIntAndFormat(ecm_data[10]),
              ecm_electric_kwh_savings: parseIntAndFormat(ecm_data[11]),
              ecm_electric_kw_savings: parseIntAndFormat(ecm_data[12]),
              ecm_water_m3_savings: parseIntAndFormat(ecm_data[13]),
              ecm_capital_cost: parseIntAndFormat(ecm_data[4]),
              ecm_roi: parseIntAndFormat(ecm_data[9]),
              ecm_npv: parseIntAndFormat(ecm_data[8]),
              ecm_payback: parseIntAndFormat(ecm_data[7]),
              ecm_incentive: parseIntAndFormat(ecm_data[14]),
              ecm_savings: parseIntAndFormat(ecm_data[15]),
              ecm_group_number: parseInt(ecm_data[16])
                ? parseInt(ecm_data[16])
                : null,
              ecm_hierarchy: parseInt(ecm_data[17])
                ? parseInt(ecm_data[17])
                : null,
              year_of_implementation: year,
              annual_onsite_ghg_savings: parseFloatAndFormat(ecm_data[19]),
              project_number: parseInt(ecm_data[20]),
            })
          }
        }
      }

      if (ecm_table_data.length > 0) {
        setDecarbPlanECMs(ecm_table_data)
      }
    }
  }

  function createPlan(ecm_decarb_plan) {
    resetECMYears()
    if (ecm_decarb_plan.length > 0) setDecarbPlanECMs(ecm_decarb_plan)
  }

  function checkECMYearsValid() {
    for (let ecm of decarbPlanECMs) {
      if (!ecm.year_of_implementation) {
        return false
      }
    }
    return true
  }

  function updateDecarbPlanECMs(update_ecm_identifier) {
    setAnalyzePlan(false)
    setSofiacQualification({})
    setCmhcLevel("")

    let decarbPlanECMsCopy = [...decarbPlanECMs]

    for (let i = 0; i < decarbPlanECMsCopy.length; i++) {
      if (decarbPlanECMsCopy[i].ecm_identifier === update_ecm_identifier) {
        decarbPlanECMsCopy.splice(i, 1)
      }
      setDecarbPlanECMs(decarbPlanECMsCopy)
    }
  }

  function analyzePlanClick() {
    // build the data structure to pass to the API
    if (checkECMYearsValid()) {
      let req_body = {
        details: [],
        year_start: startYear,
        year_end: endYear,
      }

      for (let ecm of decarbPlanECMs) {
        req_body.details.push({
          ecm_identifier: ecm["ecm_identifier"],
          year_of_implementation: ecm["year_of_implementation"],
        })
      }

      if (req_body.details.length > 0) {
        getDecarbonizationData(req_body)
        setIsAnalyzing(true)
      }
    } else {
      alert("Please select the Year for each Upgrade!")
    }
  }

  function resetECMYears() {
    setLoadedPlan(false)
    let newECMs = []
    for (let ecm of decarbPlanECMs) {
      ecm.year_of_implementation = null
      newECMs.push(ecm)
    }
    setDecarbPlanECMs(newECMs)
    setAnalyzePlan(false)
  }

  // get decarbonization calculations
  function getDecarbonizationData(req_body) {
    calculateDecarbPlan(req_body)
      .then((res) => {
        setSavingsData(res["EnergySavings"])
        setFinancialData(res["FinancialAnalysis"])
        setSofiacQualification(res["IsSofiacQualified"])

        if (selectedProjectNumbers.length > 1) {
          setCmhcLevel("multi")
        } else {
          setCmhcLevel(res["CmhcLevel"].CmhcLevel)
        }

        let range =
          res["GHGEmissionsBreakdown"].YearEnd -
          res["GHGEmissionsBreakdown"].YearStart +
          1
        let rangeDataWithoutBaseline = [...Array(range).keys()].map(
          (i) => i + res["GHGEmissionsBreakdown"].YearStart
        )
        let rangeData = ["Baseline"].concat(rangeDataWithoutBaseline)
        setGHGEmissionsBreakdown({
          ...plot_data,
          labels: rangeData,
          datasets: [
            {
              ...plot_data.datasets[0],
              data: [res["GHGEmissionsBreakdown"].Baseline.toFixed(2)].concat(
                res["GHGEmissionsBreakdown"].Details.map((i) => i.toFixed(2))
              ),
              backgroundColor: ["#39795F"].concat(
                rangeData.map(() => "#86C6AB")
              ),
            },
          ],
        })
        setEUIBreakdown({
          ...plot_data,
          labels: rangeData,
          datasets: [
            {
              ...plot_data.datasets[0],
              data: [res["EUIBreakdown"].Baseline.toFixed(2)].concat(
                res["EUIBreakdown"].Details.map((i) => i.toFixed(2))
              ),
              backgroundColor: ["#0A93A8"].concat(
                rangeData.map(() => "#57DFF4")
              ),
            },
          ],
        })
        setUtilityCostBreakdown({
          ...plot_data,
          labels: rangeDataWithoutBaseline,
          datasets: [
            {
              label: "Baseline Utility Cost ($/yr)",
              data: res["UtilityCostBreakdown"].BaselineDetails.map((value) =>
                value.toFixed(2)
              ),
              backgroundColor: "#415672",
            },
            {
              label: "Proposed Plan Utility Cost ($/yr)",
              data: res["UtilityCostBreakdown"].Details.map((value) =>
                value.toFixed(2)
              ),
              backgroundColor: "#8DA2BE",
            },
          ],
        })
        setIsAnalyzing(false)
        setAnalyzePlan(true)
      })
      .catch((err) => {
        console.error(err)
      })
    return
  }

  const plot_options = {
    responsive: true,
    plugins: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 35,
          left: 10,
          right: 15,
          bottom: 65,
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      responsive: true,
    },
  }

  const plot_data = {
    datasets: [
      {
        label: "",
      },
    ],
  }

  const handleDeleteSavedPlan = () => {
    setIsPlanDeleted(true)
    return new Promise((resolve, reject) =>
      delete_decarb_plan(selectedPlanOption.value)
        .then((response) => {
          props.get_user_data()
          setSelectedPlanOption([])
          setDecarbPlanECMs([])
          setSelectedPlanContents({})
          // setIsPlanDeleted(false)
          setSofiacQualification({})
          setCmhcLevel("")
          return resolve({ error: false, message: "successfully deleted" })
        })
        .catch((error) => {
          console.error(error)
          return reject({ error: true, message: "failed to delete" })
        })
    )
  }

  function getSofiacIncentive() {
    let result = []

    if (Object.keys(sofiacQualification).length !== 0) {
      result[0] = sofiacQualification.IsSofiacCriteria1
        ? "Eligible"
        : "Not Eligible"
      result[1] = sofiacQualification.IsSofiacCriteria2
        ? "Eligible"
        : "Not Eligible"
      result[2] = sofiacQualification.IsSofiacCriteria3
        ? "Eligible"
        : "Not Eligible"
    } else {
      result[0] = "No available data"
      result[1] = "No available data"
      result[2] = "No available data"
    }
    return result
  }

  return (
    <div className='mb-16'>
      <div className='title w-full flex justify-between items-center'>
        <h1 className='section_heading_1'>Decarbonization</h1>
        <button className='button-action-white' onClick={() => navigate(-1)}>
          {redirectFromAssessments
            ? "back to assessments"
            : "back to analysis tools"}
        </button>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>

      <ModalDialog
        modal_ref={delete_modal}
        content_title='Delete Plan?'
        content_body={<p>Are you sure you want to delete this saved plan?</p>}
        btn_confirm='Delete'
        btn_cancel='Cancel'
        fn_confirm={handleDeleteSavedPlan}
      />

      <MultiFilter
        data={props.data}
        set_redirect_from_assessments={setRedirectFromAssessments}
        selected_project_numbers={selectedProjectNumbers}
        set_selected_project_numbers={setSelectedProjectNumbers}
        selected_plan_option={selectedPlanOption}
        set_selected_plan_option={setSelectedPlanOption}
        create_group={createPlan}
        submit_button_text='Create Plan'
        update_button_text='Update Plan'
        cancel_button_text='Clear'
        is_plan_deleted={isPlanDeleted}
        set_is_plan_deleted={setIsPlanDeleted}
        planned_ecms={decarbPlanECMs}
        set_decarb_plan_ecms={setDecarbPlanECMs}
        reset_ecm_years={resetECMYears}
        set_selected_plan_contents={setSelectedPlanContents}
        set_sofiac_qualification={setSofiacQualification}
        set_cmhc_level={setCmhcLevel}
        loaded_plan={loadedPlan}
        set_loaded_plan={setLoadedPlan}
        set_update_plan={setUpdatePlan}
        set_analyze_plan={setAnalyzePlan}
      />

      <div className='flex items-center justify-between mx-5'>
        <h2 className='section_heading_1'>{selectedPlanOption.label}</h2>
        <button
          className='button-action-white'
          disabled={!loadedPlan}
          onClick={() => {
            if (selectedPlanOption.value) delete_modal.current.showModal()
          }}
        >
          Delete Saved Plan
        </button>
      </div>

      <DecarbPlanner
        data={props.user_data}
        analysis_data={props.data}
        fn_get_user_data={props.get_user_data}
        planned_ecms={decarbPlanECMs}
        selected_project_numbers={selectedProjectNumbers}
        selected_plan_option={selectedPlanOption}
        selected_plan_contents={selectedPlanContents}
        selected_plan_name={selectedPlanName}
        set_selected_plan_contents={setSelectedPlanContents}
        set_decarb_plan_ecms={setDecarbPlanECMs}
        reset_ecm_years={resetECMYears}
        analyze_plan={analyzePlanClick}
        set_start_year={setStartYear}
        set_end_year={setEndYear}
        set_selected_plan_option={setSelectedPlanOption}
        selected_plan_start_year={startYear}
        selected_plan_end_year={endYear}
        set_is_plan_deleted={setIsPlanDeleted}
        loaded_plan={loadedPlan}
        set_loaded_plan={setLoadedPlan}
        set_sofiac_qualification={setSofiacQualification}
        set_cmhc_level={setCmhcLevel}
        update_decarb_plan_ecms={updateDecarbPlanECMs}
        set_analyze_plan={setAnalyzePlan}
      />

      {analyzePlan && !isAnalyzing ? (
        <>
          <SavingsTable data={savingsData} financialData={financialData} />
          <FinancialAnalysis data={financialData} />
          <BaselineBarChart
            data={ghgEmissionsBreakdown}
            options={{
              ...plot_options,
              plugins: {
                ...plot_options.plugins,
                title: {
                  ...plot_options.plugins.title,
                  text: "Annual GHG Emissions Breakdown",
                },
              },
              scales: {
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "GHG Emissions (Ton CO₂e/yr)",
                  },
                  grace: "17%",
                },
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: "Year",
                  },
                },
              },
            }}
          />
          <BaselineBarChart
            data={euiBreakdown}
            options={{
              ...plot_options,
              plugins: {
                ...plot_options.plugins,
                title: {
                  ...plot_options.plugins.title,
                  text: "Energy Use Intensity Breakdown",
                },
              },
              scales: {
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "Energy Use Intensity (GJ/m²)",
                  },
                  grace: "17%",
                },
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: "Year",
                  },
                },
              },
            }}
          />
          <BaselineBarChart
            data={utilityCostBreakdown}
            options={{
              ...plot_options,
              plugins: {
                ...plot_options.plugins,
                title: {
                  ...plot_options.plugins.title,
                  text: "Annual Utility Cost Forecast",
                },
                legend: {
                  display: true,
                },
              },
              scales: {
                y: {
                  display: true,
                  title: {
                    display: true,
                    text: "Utility Cost ($/yr)",
                  },
                  grace: "17%",
                },
                x: {
                  display: true,
                  title: {
                    display: true,
                    text: "Year",
                  },
                },
              },
            }}
          />
        </>
      ) : isAnalyzing ? (
        <LinearProgress />
      ) : (
        <></>
      )}
      <Incentives data={Sofiac} result={getSofiacIncentive()} />
      <Incentives data={CMHC} result={cmhcLevel} />
    </div>
  )
}
export default Decarb
