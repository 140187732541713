import { useEffect, useState, useContext, useRef } from "react"
import {
  DefaultDraftContext,
  UploadFileListContext,
  UserDraftContext,
} from "../../context/DraftContext"
import FileDetailsTable from "../../../global/components/fileUploader/FileDetailsTable"
import FileUploaderModal from "../../../global/components/fileUploader/FileUploaderModal"
import CheckBox from "../../../global/components/CheckBox/CheckBox"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import { dateTimeFormatter } from "../../../../utils/DataFormatter"
import Button from "../../../global/components/button/Button"

const apiUrl = process.env.REACT_APP_API_URL

function PropertyDocuments({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
}) {
  const [propertyDocuments, setPropertyDocuments] = useState([])
  const [propertyEnergyAudits, setPropertyEnergyAudits] = useState([])
  const [propertyDocumentsTableData, setPropertyDocumentsTableData] = useState(
    []
  )
  const [propertyEnergyAuditsTableData, setPropertyEnergyAuditsTableData] =
    useState([])

  const file_uploader_modal = useRef(null)
  const energy_audit_file_uploader_modal = useRef(null)

  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const [isRelatedDocumentsDisabled, setIsRelatedDocumentsDisabled] =
    useState(false)
  const [isEnergyAuditsDisabled, setIsEnergyAuditsDisabled] = useState(false)

  const { userDraftState, userDraftDispatch, userData } =
    useContext(UserDraftContext)

  useEffect(() => {
    if (userDraftState.property_documents_ack) {
      setIsRelatedDocumentsDisabled(
        userDraftState.property_documents_ack.isNoRelatedDocuments
      )
      setIsEnergyAuditsDisabled(
        userDraftState.property_documents_ack.isNoEnergyAudits
      )
    }
    setPropertyDocuments(userDraftState.property_supporting_documents)
    setPropertyEnergyAudits(userDraftState.property_energy_audits)
  }, [userDraftState])

  useEffect(() => {
    if (isRelatedDocumentsDisabled) {
      setPropertyDocuments([])
    } else {
      setPropertyDocuments(userDraftState.property_supporting_documents)
    }
  }, [isRelatedDocumentsDisabled])

  useEffect(() => {
    if (isEnergyAuditsDisabled) {
      setPropertyEnergyAudits([])
    } else {
      setPropertyEnergyAudits(userDraftState.property_energy_audits)
    }
  }, [isEnergyAuditsDisabled])

  useEffect(() => {
    if (propertyDocuments) {
      populateUploadedFilesTable(propertyDocuments)
    }
  }, [propertyDocuments])

  useEffect(() => {
    if (propertyEnergyAudits) {
      populateEnergyAuditsTable(propertyEnergyAudits)
    }
  }, [propertyEnergyAudits])

  function uploadSupportingDocsHandler(file_blobs_list) {
    // add files to the list of supporting documents
    // create a new array of objects with the file_blobs_list
    let new_supporting_docs_list = file_blobs_list.map((file_blob) => {
      return {
        folder_name: file_blob.folder_name,
        org_folder_name: file_blob.folder_name,
        file_name: file_blob.file_name,
        org_file_name: file_blob.file_name,
        file_path: file_blob.file_path,
        file_size: file_blob.file_size,
        message: file_blob.message,
        status: file_blob.status,
        is_deleted: false,
        uploaded_date: dateTimeFormatter(new Date()),
        file_blob: file_blob.file_blob,
      }
    })
    userDraftDispatch({
      type: "property_documents_add",
      payload: new_supporting_docs_list,
    })
  }

  function uploadEnergyAuditsHandler(file_blobs_list) {
    // add files to the list of energy audits
    // create a new array of objects with the file_blobs_list
    let new_energy_audit_list = file_blobs_list.map((file_blob) => {
      return {
        folder_name: file_blob.folder_name,
        org_folder_name: file_blob.folder_name,
        file_name: file_blob.file_name,
        org_file_name: file_blob.file_name,
        file_path: file_blob.file_path,
        file_size: file_blob.file_size,
        message: file_blob.message,
        status: file_blob.status,
        is_deleted: false,
        uploaded_date: dateTimeFormatter(new Date()),
        file_blob: file_blob.file_blob,
      }
    })
    userDraftDispatch({
      type: "energy_audits_add",
      payload: new_energy_audit_list,
    })
  }

  function removeSupportingDocsHandler(index) {
    userDraftDispatch({
      type: "property_documents_delete",
      row_index: index,
    })
  }

  function removeEnergyAuditsHandler(index) {
    userDraftDispatch({
      type: "energy_audits_delete",
      row_index: index,
    })
  }

  function populateUploadedFilesTable(files) {
    if (files.length > 0) {
      let generated_file_list = files.map((file) => {
        return {
          folder_name: file.folder_name,
          org_folder_name: file.org_folder_name || file.folder_name,
          file_name: file.file_name,
          org_file_name: file.org_file_name || file.file_name,
          file_path: file.file_path,
          file_size: file.file_size || file.size,
          message: file.message,
          status: file.status || "Uploaded",
          uploaded_date: file.uploaded_date,
          file_blob: file.file_blob,
        }
      })
      setPropertyDocumentsTableData(generated_file_list)
    } else {
      setPropertyDocumentsTableData([])
    }
  }

  function populateEnergyAuditsTable(files) {
    if (files.length > 0) {
      let generated_file_list = files.map((file) => {
        return {
          folder_name: file.folder_name,
          org_folder_name: file.org_folder_name || file.folder_name,
          file_name: file.file_name,
          org_file_name: file.org_file_name || file.file_name,
          file_path: file.file_path,
          file_size: file.file_size || file.size,
          message: file.message,
          status: file.status || "Uploaded",
          uploaded_date: file.uploaded_date,
          file_blob: file.file_blob,
        }
      })
      setPropertyEnergyAuditsTableData(generated_file_list)
    } else {
      setPropertyEnergyAuditsTableData([])
    }
  }

  const file_headers = [
    "file_name",
    "file_size_in_mb",
    "file_path",
    "is_deleted",
  ]

  function handleOnEditSupportingDocuments(index, new_name, isFolder) {
    let new_property_supporting_documents =
      userDraftState.property_supporting_documents
    if (!isFolder) {
      new_property_supporting_documents[index].file_name = new_name
    } else {
      new_property_supporting_documents[index].folder_name = new_name
    }
    userDraftDispatch({
      type: "property_documents_update_all",
      payload: new_property_supporting_documents,
    })
  }

  function handleOnEditEnergyAudits(index, new_name, isFolder) {
    let new_property_energy_audits = userDraftState.property_energy_audits
    if (!isFolder) {
      new_property_energy_audits[index].file_name = new_name
    } else {
      new_property_energy_audits[index].folder_name = new_name
    }
    userDraftDispatch({
      type: "property_energy_audits_update_all",
      payload: new_property_energy_audits,
    })
  }

  // create handleOnDownloadFile function here
  function handleOnDownloadFile(file) {
    let url = ""
    if (file.file_blob instanceof File) {
      const fileType = file.file_blob.type || "application/octet-stream"
      // Create a URL for the blob object
      url = window.URL.createObjectURL(
        new Blob([file.file_blob], { type: fileType })
      )
    } else {
      url = apiUrl + file.file_path
    }

    // Create an anchor element (`<a>`) for the download
    const link = document.createElement("a")

    // Set the download attribute with the filename
    link.setAttribute("download", file.file_name)

    // Hide the element
    link.style.display = "none"

    // Set the href of the link to the blob URL
    link.href = url

    // Append the link to the body
    document.body.appendChild(link)

    // Programmatically trigger the click event
    link.click()

    setTimeout(() => {
      window.URL.revokeObjectURL(url)
      document.body.removeChild(link)
    }, 100) // Delay cleanup by 100ms or more if needed
  }

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isEmpty ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation(null)
    }
  }

  function handleCheckedBoxChange(newValue) {
    userDraftDispatch({
      type: "property_documents_save_ack",
      payload: { [newValue.name]: newValue.checked },
    })
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>Documents</p>
        <Button
          type='button'
          className='button__large button__large--gray complete-button-upper'
          buttonText='Check this tab'
          disabled={isButtonDisabled === null}
          onClick={handleValidation}
        />
      </div>

      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Upload related documents and energy audits'
          content={
            <>
              In this section, upload this building's related documents and
              energy audits.
              <br />
              Feel free to skip this section if you don't have any documents to
              upload at this time.
            </>
          }
        />
        <div className='form__description'>
          <CheckBox
            label='I do not have any related documents to upload.'
            name='isNoRelatedDocuments'
            checked={userDraftState.property_documents_ack.isNoRelatedDocuments}
            onChange={handleCheckedBoxChange}
          />
        </div>
        <div className='form__description'>
          <CheckBox
            label='I do not have any energy audits to upload.'
            name='isNoEnergyAudits'
            checked={userDraftState.property_documents_ack.isNoEnergyAudits}
            onChange={handleCheckedBoxChange}
          />
        </div>
      </div>

      <div className='form__content'>
        <InstructionalText
          type='Additional Instructions'
          title='File status : '
          content={
            <>
              When a file is <span className='bold_text'>Attached</span>, it is
              not uploaded to our servers yet. Once you save the draft, we then
              upload the files to our secure cloud servers and the status will
              become <span className='bold_text'>Uploaded</span>.
            </>
          }
        />

        <div className='form-title-button__container'>
          <div className='page__subheader'>
            <h2>Related Documents</h2>
          </div>
          <Button
            type='button'
            className='button  button__large button__large--secondary'
            buttonText='Attach Files'
            onClick={() => file_uploader_modal.current.showModal()}
            disabled={isRelatedDocumentsDisabled}
          />
        </div>
        <FileDetailsTable
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          data_list={propertyDocumentsTableData}
          set_data_list={setPropertyDocumentsTableData}
          fn_remove_row={removeSupportingDocsHandler}
          fn_download_file={handleOnDownloadFile}
          fn_edit_name={handleOnEditSupportingDocuments}
          table_disabled={isEnergyAuditsDisabled}
        />

        <div className='form-title-button__container'>
          <div className='page__subheader'>
            <h2>Energy Audits</h2>
          </div>
          <Button
            type='button'
            className='button  button__large button__large--secondary'
            buttonText='Attach Files'
            onClick={() => energy_audit_file_uploader_modal.current.showModal()}
            disabled={isEnergyAuditsDisabled}
          />
        </div>
        <FileDetailsTable
          table_headers={[
            "folder_name",
            "file_name",
            "file_size",
            "uploaded_date",
            "status",
            "download",
            "delete",
          ]}
          data_list={propertyEnergyAuditsTableData}
          set_data_list={setPropertyEnergyAuditsTableData}
          fn_remove_row={removeEnergyAuditsHandler}
          fn_download_file={handleOnDownloadFile}
          fn_edit_name={handleOnEditEnergyAudits}
          table_disabled={isEnergyAuditsDisabled}
        />
      </div>

      <FileUploaderModal
        table_headers={[
          "folder_name",
          "file_name",
          "file_size",
          "message",
          "status",
          "delete",
        ]}
        modal_header='Attach Related Documents'
        content_title='Attach your related documents.'
        content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB.
        Our platform supports the following file types: images (.png, .jpg, .jpeg, .heic), documents (.pdf, .docx, .doc) and spreadsheets (.xlsx, .csv).'
        modal_ref={file_uploader_modal}
        uploaded_tableData={propertyDocumentsTableData}
        fn_remove_row={removeSupportingDocsHandler}
        set_uploaded_tableData={setPropertyDocumentsTableData}
        user_data={userData}
        accepted_file_types={[
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/heic",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv",
        ]}
        fn_upload_handler={uploadSupportingDocsHandler}
        disabled={false}
      />

      <FileUploaderModal
        table_headers={[
          "folder_name",
          "file_name",
          "file_size",
          "message",
          "status",
          "delete",
        ]}
        modal_header='Attach Energy Audits'
        content_title='Attach your energy audits.'
        content_description='You can store up to 100MB in our secure cloud servers. The maximum file size supported is 5MB.
        Our platform supports the following file types: images (.png, .jpg, .jpeg, .heic), documents (.pdf, .docx, .doc) and spreadsheets (.xlsx, .csv).'
        modal_ref={energy_audit_file_uploader_modal}
        uploaded_tableData={propertyEnergyAuditsTableData}
        fn_remove_row={removeEnergyAuditsHandler}
        set_uploaded_tableData={setPropertyEnergyAuditsTableData}
        user_data={userData}
        accepted_file_types={[
          "image/png",
          "image/jpg",
          "image/jpeg",
          "image/heic",
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "text/csv",
        ]}
        fn_upload_handler={uploadEnergyAuditsHandler}
      />
    </div>
  )
}

export default PropertyDocuments
