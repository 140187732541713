import React, { useEffect, useState } from 'react';
import RateTabs from './RateTabs';
import LoadingSpinner from '../../global/LoadingSpinner';

export const RatePage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setIsLoading(true);
    if (props.data) {
      setData(props.data);
      setIsLoading(false);
    }
  }, [props]);

  return (
    <div className='mt-7 mb-16'>
      {isLoading ? <LoadingSpinner /> : <RateTabs data={data} />}
    </div>
  );
};
