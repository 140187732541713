import EnvelopeTable from '../global/TemplateTable';

const EnvelopeTab = (props) => {
  // filter list of image urls to include images in envelope folder only
  // const envelope_images = props.images.filter(image => image.includes('envelope'))

  const data = props.data.map((item) => {
    // Check if item[0] is 'Windows coverage %', and if so, add ' (%)'
    if (item[0] === "Windows coverage %") {
      item[0] = item[0].replace("%", " (%)");
    }
    // Always return the modified or unmodified item
    return item;
  });

  return (
    <div>
      <EnvelopeTable
        data={data}
        skipFirstCol={false}
        title={'Building Envelope'}
        filename={'building_envelope.csv'}
        headers={['Envelope Type', 'Description']}
      />
    </div>
  );
};

export default EnvelopeTab;
