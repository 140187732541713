import React, { useState, useEffect } from "react"
import { Bar } from "react-chartjs-2"
import ChartDataLabels from "chartjs-plugin-datalabels"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarController,
  BarElement,
} from "chart.js"
import "../../styles/portfolio.css"
import { parseIntCurrencyRoundedFormat } from "../../utils/DataFormatter"

ChartJS.register(ArcElement, Tooltip, Legend, BarController, BarElement)

const BarChartStacked = (props) => {
  const [utilityData, setUtilityData] = useState({
    labels: ["No data to show"],
    datasets: [
      {
        label: "Utility Breakdown",
        data: 56,
        backgroundColor: "#204e8d",
      },
    ],
  })

  useEffect(() => {
    let res = props.dataset
    let ghgConsump = []
    let ghgValues = []
    let costValues = []
    const currentYear = parseInt(new Date().getFullYear())
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      )

    for (let i = 0; i < res.length; i++) {
      let cTax = 50
      let costGhg = 0
      let sumTotalCost = 0

      costGhg = cTax * parseFloat(res[i][3])
      sumTotalCost = parseFloat(res[i][6]) - costGhg

      ghgConsump.push(costGhg)
      ghgValues.push(parseFloat(res[i][3]))
      costValues.push(sumTotalCost)
    }

    let valuesContainer = []
    let ghgConsumpContainer = []
    valuesContainer.push(costValues)
    ghgConsumpContainer.push(ghgConsump)

    for (let i = 0; i < 29; i++) {
      let newCostValue = 0
      let cTax = 0
      let newGhgValue = 0
      let newCostsContainer = []
      let newGhgConsumpContainer = []
      for (let j = 0; j < costValues.length; j++) {
        newCostValue = valuesContainer[i][j] * 1.03
        newCostsContainer.push(newCostValue)
      }
      valuesContainer.push(newCostsContainer)
      switch (i) {
        case 0:
          cTax = 65
          break
        case 1:
          cTax = 80
          break
        case 2:
          cTax = 95
          break
        case 3:
          cTax = 110
          break
        case 4:
          cTax = 125
          break
        case 5:
          cTax = 140
          break
        case 6:
          cTax = 155
          break
        default:
          cTax = 170
      }
      for (let x = 0; x < ghgValues.length; x++) {
        newGhgValue = ghgValues[x] * cTax
        newGhgConsumpContainer.push(newGhgValue)
      }
      ghgConsumpContainer.push(newGhgConsumpContainer)
    }

    let finalTotalValues = []
    let finalGhgValues = []
    for (let y = 0; y < valuesContainer.length; y++) {
      let valueSum = valuesContainer[y].reduce((a, b) => a + b, 0)
      let ghgSum = ghgConsumpContainer[y].reduce((a, b) => a + b, 0)
      finalTotalValues.push(Math.round(valueSum / 1000) * 1000)
      finalGhgValues.push(Math.round(ghgSum / 1000) * 1000)
    }

    setUtilityData({
      labels: range(currentYear, currentYear + 28, +1), // Set x-axis labels from current year to 28 years later.
      datasets: [
        // Each dataset should represent projected total cost/yr of the portfolio (All buildings combined).
        // Each bar will stack on top of each other for each data point.
        {
          // First dataset will be at the base of the stack.
          label: "Total Utilities Cost Excluding GHG ($/yr)",
          data: finalTotalValues,
          backgroundColor: "#70b0ff", // green
        },
        {
          // Last dataset will be at the top of the stack.
          label: "Cost of GHG ($/yr)",
          data: finalGhgValues,
          backgroundColor: "#3458eb", // tan
        },
      ],
    })
  }, [])

  return (
    <div className='w-full'>
      <div className='widget-header'>
        <h2 className='section_heading_2'>Utility and GHG Cost Projection</h2>
      </div>
      <div className='h-full w-full bg-white custom-frame'>
        <Bar
          height={"100px"}
          width={"200px"}
          data={utilityData}
          plugins={[ChartDataLabels]}
          options={{
            layout: {
              padding: {
                top: 30,
                bottom: 30,
                left: 30,
                right: 30,
              },
            },
            plugins: {
              legend: {
                display: true,
                position: "top",
              },
              datalabels: {
                display: false,
              },
            },
            responsive: true,
            scales: {
              // stacked property on x and y scales set to true to create stacked bar.
              x: {
                stacked: true,
                display: true,
                title: {
                  display: true,
                  text: "Year",
                },
              },
              y: {
                stacked: true,
                display: true,
                title: {
                  display: true,
                  text: "Projected Utility Cost ($)",
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}

export default BarChartStacked
