import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import {
  loadESPM_Settings,
  saveESPM_Settings
} from "../../../utils/HttpClient"

function EspmSettings() {
  const [data, setData] = useState({
    username: "",
    password: "",
    syncInterval: ""
  })

  function handleChange(event) {
    const value = event.target.value
    setData({
      ...data,
      [event.target.name]: value,
    })
  }

  function onClick() {
    saveESPM_Settings(data.username, data.password, data.syncInterval).then((res) => {
      if (res?.status === 200 && res.data === true) {
        alert("Settings saved successfully.")
      }
      else {
        alert("Failed to save settings. Please try again.")
      }
    })
  }

  useEffect(() => {
    loadESPM_Settings().then((res) => {
      if (res?.status === 200) {
        setData({
          "username": res.data["user_name"],
          "password": res.data["password"],
          "syncInterval": res.data["sync_interval"]
        })
      }
    })
  }, [])

  return (
    <div className='mb-16'>
      <div className='title w-full flex justify-between items-center'>
        <h1 className='section_heading_1'>ESPM Settings</h1>
      </div>
      <hr className='hr_breaker' />
      <div className='flex flex-col gap-10 w-full h-full'>
        <h2 className='section_heading_2 p-4'>Edit ESPM Settings</h2>
        <div className='tabContainer editProfile'>
          <div className='textFieldContainer'>
            <label htmlFor='username'>Username</label>
            <input
              id='username'
              type='text'
              name='username'
              onChange={handleChange}
              value={data.username}
            />
          </div>

          <div className='textFieldContainer'>
            <label htmlFor='password'>Password</label>

            <input
              id='password'
              name='password'
              type='text'
              onChange={handleChange}
              value={data.password}
            />
          </div>

          <div className='textFieldContainer'>
            <label htmlFor='syncInterval'>Sync interval in seconds</label>

            <input
              id='syncInterval'
              name='syncInterval'
              type='number'
              onChange={handleChange}
              value={data.syncInterval}
            />
          </div>

          {/* new save and cancel btns */}
          <div className='account-btn-box'>
            <button
              className='account-save-btn'
              onClick={onClick}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default EspmSettings
