import { useState } from 'react';

function UserProjectsTable({
  list_of_data,
  header_names,
  onClick_function,
  set_selected_projects,
  selected_projects,
}) {
  // Table sticky header and scrollable body
  // sort functions ascending, descending, for each column
  // sort depending on data type
  // add 'Action' column with link to manage user_projects page
  // add 'Filters' button to open filters tab
  // add 'Set Filters' button to set filters and refresh table
  // add 'Clear Filters' button to clear filters and refresh table
  // add 'Search' input to search for specific user

  // parameters: list_of_data, header_names
  // list_of_data: list of data to be displayed in table
  // header_names: list of column names to be displayed in table
  // onChange_function: function to be called when checkbox is clicked

  const [sortOrder, setSortOrder] = useState({});

  function sort(e) {
    // sort list_of_data by column_name
    // return sorted list_of_data

    let column_name = e.target.innerText.toLowerCase().replace(' ', '_');

    if (column_name !== 'select') {
      if (sortOrder[column_name] === 'asc') {
        list_of_data.reverse();
        setSortOrder({ ...sortOrder, [column_name]: 'dsc' });
      } else {
        if (list_of_data[0]) {
          if (typeof list_of_data[0][column_name] === 'number') {
            list_of_data.sort((a, b) => a[column_name] - b[column_name]);
          } else {
            list_of_data.sort((a, b) => {
              if (!a[column_name]) {
                return 1;
              } else if (!b[column_name]) {
                return -1;
              } else {
                return a[column_name].localeCompare(b[column_name]);
              }
            });
          }
        }
        setSortOrder({ [column_name]: 'asc' });
      }
    }
  }

  function SortIndicator({ column_name }) {
    if (sortOrder[column_name] === 'asc') {
      return <span className='material-symbols-outlined'>arrow_drop_up</span>;
    } else if (sortOrder[column_name] === 'dsc') {
      return <span className='material-symbols-outlined'>arrow_drop_down</span>;
    } else {
      return (
        <span
          className='material-symbols-outlined'
          style={{ visibility: 'hidden' }}
        >
          remove
        </span>
      );
    }
  }

  function SelectedIndicator({ project_number }) {
    return (
      <span className='material-symbols-outlined'>
        {selected_projects[project_number] &&
        selected_projects[project_number] === true
          ? 'check_box'
          : 'check_box_outline_blank'}
      </span>
    );
  }

  return (
    <>
      <div className='projects-table-body-scroll'>
        <table className='projects-table-container'>
          <thead className='table-header-sticky'>
            <tr>
              {header_names.map((column_name, i) => (
                <th key={i} className='px-4 py-2 justify-center'>
                  <div className='table-header-sort'>
                    <div onClick={sort}>{column_name.replace('_', ' ')}</div>
                    <SortIndicator column_name={column_name} />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {list_of_data.length > 0 ? (
              list_of_data.map((data, i) => (
                <tr
                  key={i}
                  onClick={(e) => {
                    onClick_function(data.project_number);
                    set_selected_projects({
                      ...selected_projects,
                      [data.project_number]:
                        !selected_projects[data.project_number],
                    });
                  }}
                >
                  {header_names.map((column_name, i) => {
                    // if column_name is 'select' then return a check box input
                    if (column_name === 'select') {
                      return (
                        <td key={i} className='button-user-projects-check'>
                          <SelectedIndicator
                            project_number={data.project_number}
                          />
                        </td>
                      );
                    } else {
                      return (
                        <td key={i} className='border px-4 py-2'>
                          {data[column_name] ? data[column_name] : '-'}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={header_names.length}>
                  <div className='error-no-data-text'>No Records</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UserProjectsTable;
