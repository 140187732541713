import React from "react"
import "./instructionalText.css"

export default function InstructionalText({
  type,
  title,
  content,
  additionalContent,
  containerClassName,
  titleClassName,
  pClassName,
  additionalClassName,
}) {
  // type - Main Instructions, Additional Instructions, Instructional Text
  //  title - string
  //  content - string
  //  additionalContent - array of strings
  //  containerClassName - string
  //  titleClassName - string
  //  pClassName - string
  //  additionalClassName - string

  let defaultContainerClassName = ""
  let defaultTitleClassName = ""
  let defaultPClassName = ""

  // add classes based on type
  if (type === "Main Instructions") {
    defaultContainerClassName = "instructional-text__main-instructions"
    defaultTitleClassName = "instructional-text__main-instructions-title"
    defaultPClassName = "instructional-text__main-instructions-p"
  } else if (type === "Additional Instructions") {
    defaultContainerClassName = "instructional-text__additional-instructions"
    defaultTitleClassName = "instructional-text__additional-instructions-title"
    defaultPClassName = "instructional-text__additional-instructions-p"
  } else if (type === "Instructional Text") {
    defaultContainerClassName = "instructional-text__instructional-text"
    defaultTitleClassName = "instructional-text__instructional-text-title"
    defaultPClassName = "instructional-text__instructional-text-p"
  }

  return (
    <React.Fragment>
      {type === "Additional Instructions" ? (
        <p className={`${defaultContainerClassName} ${containerClassName}`}>
          <span className={`${defaultTitleClassName} ${titleClassName}`}>
            {title}
          </span>{" "}
          <span className={`${defaultPClassName} ${pClassName}`}>
            {content}
          </span>
          {additionalContent &&
            additionalContent.map((text, index) => (
              <span
                key={index}
                className={`${defaultPClassName} ${additionalClassName}`}
              >
                {text}
              </span>
            ))}
        </p>
      ) : (
        <div className={`${defaultContainerClassName} ${containerClassName}`}>
          <h2 className={`${defaultTitleClassName} ${titleClassName}`}>
            {title}
          </h2>
          {type === "Main Instructions" || type === "Instructional Text" ? (
            <div className={`${defaultPClassName} ${pClassName}`}>
              {content}
              {additionalContent &&
                additionalContent.map((text, index) => (
                  <p
                    key={index}
                    className={`${defaultPClassName} ${additionalClassName}`}
                  >
                    {text}
                  </p>
                ))}
            </div>
          ) : null}
        </div>
      )}
    </React.Fragment>
  )
}
