import React, { useEffect, useState } from 'react';
import OutputTable from './OutputTable';
import '../../../../styles/table.css';
import '../../../../styles/adminBuildings/adminTableBuildings.css';

export default function AccountTableRow(props) {
  // States:
  const [account, setAccount] = useState(null);
  const [expandedTableData, setExpandedTableData] = useState(null);
  const [expandedAccount, setExpandedAccount] = useState(false);
  const [expandedProjects, setExpandedProjects] = useState({});

  // Pull data from props and set the table data
  useEffect(() => {
    setAccount(props.account);
    setExpandedTableData(props.projects);
  }, [props]);

  // generating project number tables with buttons that expand and collapse
  const GenerateTableData = () => {
    const projectNumbers = Object.keys(expandedTableData);
    if (projectNumbers.includes('null')) {
      return (
        <tbody className=''>
          <tr className='row'>
            <td className='columnItem text-center align-center w-full'>
              No Projects
            </td>
          </tr>
        </tbody>
      );
    } else {
      return projectNumbers.map((project_id) => {
        const outputParticipant = Object.keys(
          expandedTableData[project_id]
        ).includes('output_participant')
          ? Object.values(
              expandedTableData[project_id]['output_participant']
            )[0]?.site_name
          : null;
        return (
          <React.Fragment key={project_id}>
            <thead>
              <tr className='row'>
                <td className='columnItem text-center align-center w-40'>
                  {project_id}
                </td>
                <td className='columnItem text-center align-center w-40'>
                  {outputParticipant ? outputParticipant : '-'}
                </td>
                <td
                  className='sticky-header columnItem align-middle w-[36rem]'
                  style={{ borderBottom: '1px solid #ddd' }}
                >
                  <button
                    className='flex justify-center items-center h-12 px-14 mx-auto bg-[#2359A2] w-32 whitespace-nowrap md:w-auto;
            '
                    onClick={() => {
                      setExpandedProjects((prevState) => ({
                        ...prevState,
                        [project_id]: !prevState[project_id],
                      }));
                    }}
                  >
                    {expandedProjects[project_id] ? 'Hide' : 'Show'} Tables
                  </button>
                </td>
              </tr>
            </thead>
            {Object.keys(expandedTableData[project_id]).map((outputTable) => {
              // Capitalize the table name and remove "output_" prefix
              const substrings = outputTable.split('_');
              const tableName = substrings
                .slice(1)
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');

              // Get the table data
              const tableInfo = expandedTableData[project_id][outputTable];

              // Get the table headers and values
              const tableInfoArray = Object.values(tableInfo);
              const tableInfoKeys = tableInfoArray[0]
                ? Object.keys(tableInfoArray[0])
                : null;

              // Get the table values
              const tableInfoValues = tableInfoArray[1]
                ? Object.values(tableInfoArray[1])
                : null;
              // check if there is data in the table then return the table
              return (
                <React.Fragment key={outputTable}>
                  {expandedProjects[project_id] && (
                    <tbody>
                      {(tableInfoKeys && tableInfoKeys.length > 0) ||
                      (tableInfoValues && tableInfoValues.length > 0) ? (
                        <tr>
                          <td colSpan='3'>
                            <OutputTable
                              key={`${project_id}_${outputTable}`}
                              dataset={tableInfo}
                              value={tableInfoValues}
                              title={tableName}
                              projectId={project_id}
                              headers={tableInfoKeys}
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan='3'>
                            <OutputTable
                              key={`${project_id}_${outputTable}`}
                              dataset={tableInfo}
                              value={['-']}
                              title={tableName}
                              projectId={project_id}
                              headers={['No Data']}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      });
    }
  };

  // Renders the table header row
  const renderTableHeaderRow = () => {
    return props.headers.map((value, index) => {
      return (
        <th className='expanded-header top-0' key={index}>
          <div className='flex justify-between items-center'>
            {value}
          </div>
        </th>
      );
    });
  };

  // Renders the expanded ECM table container
  function ExpandedData() {
    return (
      <>
        <div className='widget-header-expanded mt-3 w-full md:w-auto'>
          <h2>Projects</h2>
        </div>
        <table id='expanded-ecm' className='items-center m-auto mb-5'>
          <thead>
            <tr>{renderTableHeaderRow()}</tr>
          </thead>
          {GenerateTableData()}
        </table>
      </>
    );
  }
  // return account information with account_number and info
  return (
    <>
      <tr className='row columnItem align-middle'>
        <td
          key={account}
          className='columnItem bg-white align-middle text-center border-t-2'
          style={{ borderBottom: '1px solid #ddd' }}
        >
          {account}
        </td>
        <td
          className='columnItem bg-white align-middle text-center border-t-2'
          style={{ borderBottom: '1px solid #ddd' }}
        >
          {props.username}
        </td>
        <td
          className='columnItem bg-white align-middle text-center border-t-2'
          style={{ borderBottom: '1px solid #ddd' }}
        >
          {props.email}
        </td>
        <td
          className='columnItem bg-white align-middle border-t-2'
          style={{ borderBottom: '1px solid #ddd' }}
        >
          <button
            className='flex justify-center items-center h-12 px-14 mx-auto bg-[#2359A2]'
            onClick={() => setExpandedAccount(!expandedAccount)}
          >
            {expandedAccount ? 'Hide' : 'Show'} Projects
          </button>
        </td>
      </tr>
      {expandedAccount && (
        <td colSpan='4'>
          <ExpandedData />
        </td>
      )}
    </>
  );
}
