function ProjectDetails({ project_data }) {
  // parameters: project_data
  // project_data: project data to be displayed

  return (
    <div className='bg-white rounded drop-shadow-md mb-5'>
      <div className='bg-sisa-header w-full'>
        <p className='text-md text-white px-4 py-2'>Project Details</p>
      </div>
      <div className='user-details-container'>
        {Object.keys(project_data).map((key, i) => {
          return (
            <div key={i}>
              <div className='user-details-key'>{key.replace('_', ' ')}</div>
              <div className='user-details-value'>
                {project_data[key] ? project_data[key] : '-'}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectDetails;
