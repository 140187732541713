import React, { useEffect, useState } from "react"
import { createTableWithUploadFile } from "../../../../utils/HttpClient"
import { WorksheetConverter } from "./WorksheetConverter"
import TableDisplayFile from "./TableDisplayFile"
import ConfirmationPopup from "./ConfirmationPopup"
import LoadingSpinner from "../../../global/LoadingSpinner"
import "../../../../styles/adminBuildings/adminUploadBuildings.css"
import SelectManyAccounts from "./SelectManyAccounts"

export const AddNewBuildingComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState(null)
  const excelData = WorksheetConverter(file)
  const [tableData, setTableData] = useState(null)
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [selectedAccountEmails, setSelectedAccountEmails] = useState("")
  const [selectedAccountNumbers, setSelectedAccountNumbers] = useState("")
  const [data, setData] = useState(props.data)
  const [resultMessage, setResultMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [failureMessage, setfailureMessage] = useState(null)
  const [serverErrorMessage, setServerErrorMessage] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    setTableData(excelData)
  }, [excelData])

  const handleDrop = (event) => {
    if (selectedAccountNumbers.length === 0) return
    event.preventDefault()
    const file = event.dataTransfer.files[0]
    setFile(file)
  }

  const handleFileInput = (event) => {
    setFile(event.target.files[0])
  }

  const handleFileSubmit = async () => {
    if (!file) return
    setIsLoading(true)
    setTableData(null)
    setSelectedAccountNumbers([])
    const formData = new FormData()
    formData.append("uploadfile", file)
    for (let i = 0; i < selectedAccountNumbers.length; i++) {
      formData.append(`accountNumber[${i}]`, selectedAccountNumbers[i])
    }
    const res = await createTableWithUploadFile(formData)
    if (res.data) {
      setResultMessage(res.data)
      setIsLoading(false)
      setShowConfirmationPopup(true)
      if (res.data.result) {
        const successfully = res.data.result.filter(
          (message) =>
            message.includes("successfully") ||
            message.includes("Passed") ||
            message.includes("project_number")
        )
        setSuccessMessage(successfully.length > 0 ? successfully : null)
        const failed = res.data.result.filter(
          (message) => message.includes("Failed") || message.includes("Error")
        )
        setfailureMessage(failed.length > 0 ? failed : null)
        props.clearCacheData()
        await props.getUserData()
      }
      if (res.data.errorMessage) {
        setServerErrorMessage(res.data.errorMessage)
      }
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    setTableData(null)
    setSelectedAccountEmails("")
    setSelectedAccountNumbers([])
  }

  const handleClosePopup = () => {
    setShowConfirmationPopup(false)
    setFile(null)
    setTableData(null)
    setSelectedAccountEmails("")
    setSelectedAccountNumbers([])
    setSubmitted(!submitted)
  }

  const handleSelectChange = (selectedOptions) => {
    let account_numbers = selectedOptions.map((option) => {
      return option.value
    })

    let email_addresses = selectedOptions.map((option) => {
      return option.label
    })

    setSelectedAccountEmails(email_addresses)
    setSelectedAccountNumbers(account_numbers)
  }

  return (
    <>
      {data ? (
        <>
          <div>
            <SelectManyAccounts
              optionsData={data}
              onChangeHandler={handleSelectChange}
              keyValue={submitted}
            />
          </div>

          <div className='dragDropContainer'>
            <div className='dragDropMiddleBox'>
              <div
                className='dragDropSmallBox'
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                {file ? (
                  <div className='overflow-y-auto text-center'>
                    <p>
                      File <b>{file.name}</b> will be uploaded to account(s) :{" "}
                    </p>
                    <p>
                      {selectedAccountEmails.map((x) => (
                        <div>
                          <b>{x}</b>
                        </div>
                      ))}
                    </p>
                    <div className='fileHandle'>
                      <button onClick={handleRemoveFile} className='removeFile'>
                        Remove file
                      </button>
                      <button onClick={handleFileSubmit} className='submitFile'>
                        Submit file
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    {selectedAccountEmails[0] ? (
                      <>
                        <label htmlFor='fileupload' className='fileuploadlabel'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='30'
                            height='30'
                            fill='currentColor'
                            className='bi bi-upload uploadIcon'
                            viewBox='0 0 16 16'
                          >
                            <path d='M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z' />
                            <path d='M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z' />
                          </svg>
                          Drag and drop a file or click to select a file
                        </label>
                        <input
                          id='fileupload'
                          name='fileupload'
                          type='file'
                          onChange={handleFileInput}
                          accept='.xlsx'
                          className='fileUploadInput'
                        />
                      </>
                    ) : (
                      <p className='disbaleInput'>
                        Please select account(s) to upload file
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {tableData ? (
            <div className='tableDisplay'>
              <TableDisplayFile data={tableData} />
            </div>
          ) : null}

          {isLoading ? (
            <div className='popup-container'>
              <LoadingSpinner className='popup-body' />
            </div>
          ) : null}
          {showConfirmationPopup ? (
            <ConfirmationPopup
              fileName={file.name}
              handleClosePopup={handleClosePopup}
              successMessage={successMessage}
              failureMessage={failureMessage}
              serverErrorMessage={serverErrorMessage}
            />
          ) : null}

          {resultMessage?.result ? (
            <div className='resultOutput'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='currentColor'
                className='bi bi-x-square svgbtn'
                viewBox='0 0 16 16'
                onClick={() => {
                  setResultMessage(null)
                }}
              >
                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
              </svg>
              <b>Output for file {file?.name} uploaded :</b>

              {resultMessage.result.map((message, index) => {
                return <p key={index}>{message}</p>
              })}
            </div>
          ) : serverErrorMessage ? (
            <p>{serverErrorMessage}</p>
          ) : null}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
