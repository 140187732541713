import * as React from 'react';
import { useState, useEffect } from 'react';
import '../../../../styles/adminBuildings/adminTableBuildings.css';
import Button from '@mui/material/Button';
import NoBuildingsDiv from '../../../global/NoBuildingDiv';
import SearchIcon from '@mui/icons-material/Search';
import AccountTable from './AccountTable';
import LoadingSpinner from '../../../global/LoadingSpinner';

export const DisplayTableComponent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    all_projects: [],
    user_email_and_account_number: [],
  });
  const [visible, setVisible] = useState(false);
  const [selectedAccountNum, setSelectedAccountNum] = useState('');
  const [selectedProjectNum, setSelectedProjectNum] = useState('');
  const [selectedAccountProjects, setSelectedAccountProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expandedSearch, setExpandedSearch] = useState(false);
  const [searchDataset, setSearchDataset] = useState();

  useEffect(() => {
    /* on load, turn on spinner awaiting getData response, then turn off spinner when data is set */
    setIsLoading(true);
    if (props.data) {
      setData(props.data);
      setIsLoading(false);
      setSearchDataset(JSON.parse(JSON.stringify(props.data.all_projects)));
    }
  }, [props.data]);

  function HomePageContainer() {
    const accountKeys = Object.keys(searchDataset);
    accountKeys.map((account) => {
      const projectNumberArry = Object.keys(searchDataset[account].projects);
      projectNumberArry.forEach((project) => {
        if (props.data.all_projects[account].projects[project]) {
          searchDataset[account].projects[project]['output_ecm'] =
            props.data.all_projects[account].projects[project][
              'output_ecm'
            ]?.filter((output) =>
              output.description
                .toLowerCase()
                .includes(searchQuery?.toLowerCase())
            );
        }
      });
    });

    return (
      <>
        {visible ? (
          <div className='h-full flex flex-col gap-10'>
            <div className='bg-white flex flex-col rounded drop-shadow-md p-5 overflow-y-scroll h-[90vh] w-[50vw]'>
              <AccountTable
                searchQuery={searchQuery}
                expandedSearch={expandedSearch}
                id='grid-5'
                dataset={searchQuery ? searchDataset : data.all_projects}
                title={'Accounts'}
                filename={'your_properties.csv'}
                headers={['Account Id', 'User Name', 'Email', 'View']}
                selectedAccountNum={
                  selectedAccountNum ? selectedAccountNum : null
                }
                selectedProjectNum={
                  selectedProjectNum ? selectedProjectNum : null
                }
              />
            </div>
          </div>
        ) : (
          <div className='showBtn'>
            <h2>Click the button to see all accounts or Search...</h2>
          </div>
        )}
      </>
    );
  }

  function Display() {
    return (
      <div>
        {data['all_projects'].length !== 0 ? (
          <HomePageContainer />
        ) : (
          <NoBuildingsDiv />
        )}
      </div>
    );
  }

  const handleSelectAccount = (e) => {
    setSelectedProjectNum('');
    e.preventDefault();
    if (e.target.value === '') {
      setSelectedAccountNum('');
      setSelectedAccountProjects([]);
      setVisible(false);
    } else {
      setSelectedAccountNum(e.target.value);
      setVisible(true);
      const projects = data.all_projects[e.target.value].projects;
      const projectNumbers = Object.keys(projects);
      setSelectedAccountProjects(projectNumbers);
    }
  };

  const handleSelectProject = (e) => {
    e.preventDefault();
    setSelectedProjectNum(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery === '') {
      alert('Please enter a search query');
    } else {
      setVisible(true);
      setExpandedSearch(true);
    }
  };

  return (
    <div className='border'>
      <div className='upperBox'>
        <div className='filter'>
          <select value={selectedAccountNum} onChange={handleSelectAccount}>
            <option value=''>Accounts</option>
            {data?.user_email_and_account_number.map((account, index) => {
              return (
                <option value={account.account_number} key={index}>
                  {account.email}
                </option>
              );
            })}
          </select>
          <select value={selectedProjectNum} onChange={handleSelectProject}>
            <option value=''>Projects</option>
            {selectedAccountProjects?.map((project, index) => {
              if (project !== 'null') {
                return (
                  <option value={project} key={index}>
                    {project}
                  </option>
                );
              }
            })}
          </select>
        </div>
        <form className='searchBar' onSubmit={handleSearchSubmit}>
          <input
            type='text'
            value={searchQuery}
            className='searchBar'
            placeholder='Search Project or Account... '
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button variant='outlined' className='searchBtn' type='submit'>
            <SearchIcon className='searchIcon' />
          </button>
        </form>
        <div className='view'>
          {visible ? (
            <button
              className='viewBtn'
              // variant='contained'
              onClick={() => {
                setVisible(false);
                setSelectedAccountNum('');
                setSelectedProjectNum('');
                setSelectedAccountProjects([]);
              }}
            >
              Hide All Accounts
            </button>
          ) : (
              <button
                className='viewBtn'
              // variant='contained'
              onClick={() => {
                setVisible(true);
                setSelectedAccountNum('');
                setSelectedProjectNum('');
                setSelectedAccountProjects([]);
              }}
            >
              View All Accounts
            </button>
          )}
        </div>
      </div>
      {isLoading ? <LoadingSpinner /> : <Display />}
    </div>
  );
};
