import React, { useState, useRef } from "react"
import Modal from "react-modal"
import "./AddEquipmentButton.css"
import ModalDialog from "../../home/components/ModalDialog"
import { addEquipment } from "../../../utils/HttpClient"

const AddEquipmentButton = (props) => {
  const modalRef = useRef(null)
  const form_ref = useRef(null)

  const empty_form = {
    project_id: props.project_id,
    equipment_type: "",
    quantity: "",
    year_of_equipment: "",
    make_and_model: "",
    identifier_tag: "",
    end_use: "",
    area_served: "",
    fuel: "",
    electrical_power_rating: "",
    gas_power_rating: "",
    unit: "",
  }

  const openModal = () => {
    setData(empty_form)
    form_ref.current.reset()
    modalRef.current.showModal()
  }

  const closeModal = () => {
    modalRef.current.close()
  }

  const equipment_elements = {
    equipment_type: {
      label: "Equipment Type *",
      type: "text",
      example: "e.g. Boiler, Chiller, etc.",
      required: true,
    },
    quantity: {
      label: "Quantity *",
      type: "number",
      example: "e.g. 1, 2, etc.",
      required: true,
    },
    year_of_equipment: {
      label: "Year Of Equipment",
      type: "number",
      example: "e.g. 2000, 2001, etc.",
      required: false,
    },
    make_and_model: {
      label: "Make And Model",
      type: "text",
      example: "e.g. Carrier 30XA, etc.",
      required: false,
    },
    identifier_tag: {
      label: "Identifier Tag *",
      type: "text",
      example: "e.g. Laundry, Pump and Gas, etc.",
      required: true,
    },
    end_use: {
      label: "End Use",
      type: "text",
      example: "e.g. Laundry, Pump and Gas, etc.",
      required: false,
    },
    area_served: {
      label: "Area Served",
      type: "text",
      example: "e.g. Common Areas, All-DHW, etc.",
      required: false,
    },
    fuel: {
      label: "Fuel",
      type: "text",
      example: "e.g. Electricity, Natural Gas, etc.",
      required: false,
    },
    electrical_power_rating: {
      label: "Electrical Power Rating",
      type: "number",
      example: "e.g. 1, 2, etc.",
      required: false,
    },
    gas_power_rating: {
      label: "Gas Power Rating",
      type: "number",
      example: "e.g. 1, 2, etc.",
      required: false,
    },
    unit: {
      label: "Unit",
      type: "text",
      example: "e.g. kW, Btu/hr, etc.",
      required: false,
    },
  }

  const [data, setData] = useState(empty_form)

  function validateInput(e, key) {
    let value = e.target.value

    if (equipment_elements[key].type === "number") {
      let cleaned_val = value.replace(/[^0-9]/g, "")

      if (cleaned_val.length === 0) {
        setData({ ...data, [key]: "" })
      } else {
        let parsed_val = parseFloat(cleaned_val)
        setData({ ...data, [key]: parsed_val })
      }
    } else {
      setData({ ...data, [key]: value })
    }
  }

  function checkRequiredFields() {
    let keys = Object.keys(equipment_elements)
    for (let key of keys) {
      if (equipment_elements[key].required && !data[key]) {
        return false
      }
    }
    return true
  }

  async function onSubmit() {
    if (checkRequiredFields()) {
      return new Promise((resolve, reject) => {
        addEquipment(data)
          .then((res) => {
            if (res.data.result === true) {
              data.equipment_id = res.data.new_equipment_id
              props.onAdded(data)
              resolve({ error: false, message: "Successfully added equipment" })
              closeModal()
            } else {
              reject({ error: true, message: "Failed to add equipment" })
            }
          })
          .catch((err) => {
            console.error(err)
            reject({ error: true, message: "Server Error" })
          })
      })
    } else {
      return new Promise((resolve, reject) => {
        reject({
          error: true,
          message: "Please enter all required data",
        })
      })
    }
  }

  const modalContent = (
    <form className='popup__container' ref={form_ref}>
      {Object.keys(equipment_elements).map((key, index) => (
        <div key={index}>
          <label className='popup__label' for={"inline-" + key}>
            {equipment_elements[key].label}
          </label>
          <input
            className='popup__input'
            id={"inline-" + key}
            type='text'
            placeholder={equipment_elements[key].example}
            maxLength='100'
            value={data[key]}
            onChange={(e) => validateInput(e, key)}
            required={equipment_elements[key].required}
          />
        </div>
      ))}
    </form>
  )

  return (
    <>
      {/* Render the modal */}
      <ModalDialog
        modal_ref={modalRef}
        content_title='Add Equipment?'
        content_body={modalContent}
        btn_confirm='Add equipment'
        btn_cancel='Cancel'
        btn_close={true}
        fn_confirm={onSubmit}
        fn_close={closeModal}
      />
      {/* Render the add button */}
      <button className='add-button add-equip-btn' onClick={openModal}>
        Add Equipment
      </button>
    </>
  )
}

export default AddEquipmentButton
