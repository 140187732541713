import { useNavigate } from "react-router-dom"
import "../../../styles/adminUsers.css"

function UsersIndexPage() {
  const navigate = useNavigate()

  return (
    <div className='mt-7 mb-16'>
      <div className='bg-white flex flex-col rounded drop-shadow-md p-5 h-fit'>
        <div className='bg-sisa-header w-full'>
          <p className='text-xl text-white p-2 m-2'>Users and Projects</p>
        </div>
        <div className='h-full flex flex-col gap-10'>
          <div className='bg-white flex flex-col drop-shadow-md p-5'>
            <div className='index-page-container'>
              <div
                className='bordered-box'
                onClick={() => navigate("/users/manage-users")}
              >
                <span className='material-symbols-outlined box-menu-icon'>
                  people
                </span>
                <span className='box-menu-text'>Users</span>
              </div>
              <div
                className='bordered-box'
                onClick={() => navigate("/users/manage-projects")}
              >
                <span className='material-symbols-outlined box-menu-icon'>
                  apartment
                </span>
                <span className='box-menu-text'>Projects</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default UsersIndexPage
