import { useEffect, useRef } from "react"
import "../../styles/spinner.css"
import ModalDialog from "../home/components/ModalDialog"

export default function LoadingSpinner({ is_loading }) {
  /* This is the loading spinner for all app pages */

  const modal_ref = useRef(null)

  useEffect(() => {
    /* on load, turn on spinner awaiting getData response, then turn off spinner when data is set */
    if (is_loading) {
      modal_ref.current.showModal()
    } else {
      modal_ref.current.close()
    }
  }, [is_loading])

  return (
    <ModalDialog
      inner_components={
        <div className='spinner-container'>
          <div className='loading-spinner'></div>
        </div>
      }
      modal_ref={modal_ref}
    />
  )
}
