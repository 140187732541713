import Select from 'react-select';
import { useState } from 'react';

function SelectManyAccounts({ optionsData, onChangeHandler, keyValue }) {
  let option_accounts = optionsData.map((account) => {
    return { value: account.account_number, label: account.email };
  });

  return (
    <div key={keyValue} style={{ width: 400, paddingBottom: 20 }}>
      <Select
        options={option_accounts}
        onChange={onChangeHandler}
        closeMenuOnSelect={false}
        isSearchable
        isMulti
      />
    </div>
  );
}

export default SelectManyAccounts;
