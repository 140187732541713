import { useState, useEffect } from 'react';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { getAllRegisteredProjects } from '../../../../utils/HttpClient';
import ProjectsRegisteredPage from './ProjectsRegisteredPage';

function ProjectsPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (props.data.user_role === 'admin') {
      getAllRegisteredProjects()
        .then((res) => {
          setProjectsList(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <div className='mt-7 mb-16'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ProjectsRegisteredPage projects_list={projectsList} />
      )}
    </div>
  );
}

export default ProjectsPage;
