import { Typography, Button } from '@mui/material';

export default function NoBuildingsDiv() {
  return (
    <div className='flex flex-col gap-10 h-full'>
      <h2 className='mt-5 text-center'>
        Your portfolio is currently empty! 🏠
      </h2>
    </div>
  );
}
