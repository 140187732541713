/**
 * UserDraftContext is used to store the user's draft data
 * if new draft, only root level properties will be available
 * if draft is loaded, all properties will be available
 *
 * useReducer function to manipulate data for every step (9 steps)
 *
 */

import { createContext } from "react"

export const DefaultDraftContext = createContext()
export const UserDraftContext = createContext()
export const UploadFileListContext = createContext()

export const UploadFileListObject = {
  equipments_documents: [],
  supporting_documents: [],
}

/**
 * Reducer function to manipulate the draft object
 * @param {*} state draft object
 * @param {*} action object with type and payloads
 * @returns returns the new draft object
 *
 * ===================================
 * dispatch function example
 * ===================================
 * dispatch(action)
 *
 * dispatch({
 *  type: 'edit_property_equipment',
 *  row_index: 6,
 *  payload: {}
 * })
 *
 * ===================================
 * Action objects
 * ===================================
 * - Add Property Equipment
 * {
 *  type: 'add_property_equipment',
 *  payload: {}
 * }
 *
 * - Edit Property Equipment
 * {
 *  type: 'edit_property_equipment',
 *  row_index: 6,
 *  payload: {}
 * }
 *
 * - Delete Property Equipment
 * {
 *  type: 'edit_property_equipment',
 *  row_index: 6,
 *  payload: {}
 * }
 *
 */
export function UserDraftReducer(state, action) {
  switch (action.type) {
    // load draft
    case "load_all":
      return { ...action.payload }

    // 1 - basic_information
    case "basic_information_save": {
      let new_payload = {
        ...state.basic_information,
        ...action.payload,
      }
      return {
        ...state,
        basic_information: new_payload,
      }
    }

    // 2 - building_data
    case "building_data_save": {
      let new_payload = {
        ...state.building_data,
        ...action.payload,
      }
      return {
        ...state,
        building_data: new_payload,
      }
    }

    // 3 - property_energy_ack
    case "property_energy_save_ack": {
      let new_payload = {
        ...state.property_energy_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_energy_ack: new_payload,
      }
    }
    // 3 - property_energy_utilities
    case "property_energy_utilities_add_utility": {
      // append payload to property_utilities
      return {
        ...state,
        property_energy_utilities: [
          ...state.property_energy_utilities,
          action.payload,
        ],
      }
    }
    case "property_energy_utilities_update_utility": {
      // replace the old utility at index with new utility in payload
      let utilities_list = [...state.property_energy_utilities]
      utilities_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_energy_utilities: [...utilities_list],
      }
    }
    case "property_energy_utilities_delete_utility": {
      // remove the old utility at index
      let utilities_list = [...state.property_energy_utilities]
      let deleted_utilities = utilities_list.splice(action.row_index, 1)
      let remaining_meters = state.property_energy_meters
      deleted_utilities.forEach(element => {
        remaining_meters = remaining_meters.filter((meter) => {
          return meter.meter_utility !== element.utility_name
        })
      });
      let all_bills = state.property_energy_bills
      let remaining_bills = []
      remaining_meters.forEach(meter => {
        remaining_bills = remaining_bills.concat(all_bills.filter((bill) => {
          return bill.meter_name === meter.meter_name
        }))
      });
      let new_state = {
        ...state,
        property_energy_meters: [...remaining_meters],
        property_energy_utilities: [...utilities_list],
        property_energy_bills: remaining_bills
      }
      return new_state
    }
    // 3 - property_energy_meters
    case "property_energy_meters_add_meter": {
      // append payload to property_energy_meters
      return {
        ...state,
        property_energy_meters: [
          ...state.property_energy_meters,
          action.payload,
        ],
      }
    }
    case "property_energy_meters_update_meter": {
      // replace the old meter at index with new meter in payload
      let meters_list = [...state.property_energy_meters]
      meters_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_energy_meters: [...meters_list],
      }
    }
    case "property_energy_meters_delete_meter": {
      // remove energy meter with index
      let meters_list = [...state.property_energy_meters]
      let deleted_meters = meters_list.splice(action.row_index, 1)
      let remaining_bills = state.property_energy_bills
      deleted_meters.forEach(meter => {
        remaining_bills = remaining_bills.filter((bill) => {
          return bill.meter_name !== meter.meter_name
        })
      });
      return {
        ...state,
        property_energy_meters: [...meters_list],
        property_energy_bills: remaining_bills
      }
    }
    // 3 - property_energy_bills
    case "property_energy_bills_add_bills": {
      // append payload to property_energy_bills
      // array of file objects

      let meter_name = action.meter_name
      let file_objects = action.payload
      let energy_bills = [...state.property_energy_bills]

      let energy_meter = energy_bills.find(
        (energy_bill) => energy_bill.meter_name === meter_name
      )

      // if meter_name already exists, append to bills list
      if (energy_meter) {
        energy_bills = energy_bills.map((energy_bill) => {
          if (energy_bill.meter_name === meter_name) {
            energy_bill.bill_files = [
              ...energy_bill.bill_files,
              ...file_objects,
            ]
          }
          return energy_bill
        })
        // else create new meter_name and bills list
      } else {
        let new_meter = {
          meter_name: meter_name,
          bill_files: [...file_objects],
        }
        energy_bills.push(new_meter)
      }

      return {
        ...state,
        property_energy_bills: [...energy_bills],
      }
    }
    case "property_energy_bills_delete_bills": {
      // remove energy bills with index
      let energy_bills = state.property_energy_bills.map((energy_bill) => {
        if (energy_bill.meter_name === action.meter_name) {
          let bills_list = [...energy_bill.bill_files]
          bills_list.splice(action.row_index, 1)
          energy_bill.bill_files = bills_list
        }
        return energy_bill
      })

      return {
        ...state,
        property_energy_bills: [...energy_bills],
      }
    }
    case "property_energy_bills_update_meter_name": {
      // update meter_name
      let energy_bills = state.property_energy_bills.map((energy_bill) => {
        if (energy_bill.meter_name === action.meter_name) {
          energy_bill.meter_name = action.new_meter_name
        }
        return energy_bill
      })

      return {
        ...state,
        property_energy_bills: [...energy_bills],
      }
    }
    case "property_energy_bills_update_all": {
      return {
        ...state,
        property_energy_bills: action.payload,
      }
    }

    // 4 - property_equipments_ack
    case "property_equipments_save_ack": {
      let new_payload = {
        ...state.property_equipments_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_equipments_ack: new_payload,
      }
    }
    // 4 - property_equipments
    case "property_equipments_add_equipment": {
      // append payload to property_equipment
      return {
        ...state,
        property_equipments: [...state.property_equipments, action.payload],
      }
    }
    case "property_equipments_delete_equipment": {
      // remove equipment with index check with payload
      let equipments_list = [...state.property_equipments]
      equipments_list.splice(action.row_index, 1)
      return {
        ...state,
        property_equipments: [...equipments_list],
      }
    }
    case "property_equipments_save_equipment": {
      // update equipment with index check with payload
      let equipments_list = [...state.property_equipments]
      equipments_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_equipments: [...equipments_list],
      }
    }
    case "property_equipments_update_all": {
      return {
        ...state,
        property_equipments: action.payload,
      }
    }

    // 5 - property_lights_ack
    case "property_lights_save_ack": {
      let new_payload = {
        ...state.property_lights_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_lights_ack: new_payload,
      }
    }
    // 5 - property_lights
    case "property_lights_add_light": {
      // append payload to property_lights
      return {
        ...state,
        property_lights: [...state.property_lights, action.payload],
      }
    }
    case "property_lights_delete_light": {
      // remove light with index check with payload
      let lights_list = [...state.property_lights]
      lights_list.splice(action.row_index, 1)
      return {
        ...state,
        property_lights: [...lights_list],
      }
    }
    case "property_lights_save_light": {
      // update light with index check with payload
      let lights_list = [...state.property_lights]
      lights_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_lights: [...lights_list],
      }
    }
    case "property_lights_update_all": {
      return {
        ...state,
        property_lights: action.payload,
      }
    }

    // 6 - property_water_ack
    case "property_water_save_ack": {
      let new_payload = {
        ...state.property_water_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_water_ack: new_payload,
      }
    }
    // 6 - property_water_utilities
    case "property_water_utilities_add_utility": {
      // append payload to property_utilities
      return {
        ...state,
        property_water_utilities: [
          ...state.property_water_utilities,
          action.payload,
        ],
      }
    }
    case "property_water_utilities_update_utility": {
      // replace the old utility at index with new utility in payload
      let utilities_list = [...state.property_water_utilities]
      utilities_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_water_utilities: [...utilities_list],
      }
    }
    case "property_water_utilities_delete_utility": {
      // remove the old utility at index
      let utilities_list = [...state.property_water_utilities]
      let deleted_utilities = utilities_list.splice(action.row_index, 1)
      let remaining_meters = state.property_water_meters
      deleted_utilities.forEach(element => {
        remaining_meters = remaining_meters.filter((meter) => {
          return meter.meter_utility !== element.utility_name
        })
      });
      let all_bills = state.property_water_bills
      let remaining_bills = []
      remaining_meters.forEach(meter => {
        remaining_bills = remaining_bills.concat(all_bills.filter((bill) => {
          return bill.meter_name === meter.meter_name
        }))
      });
      return {
        ...state,
        property_water_utilities: [...utilities_list],
        property_water_meters: [...remaining_meters],
        property_water_bills: remaining_bills
      }
    }
    // 6 - property_water_meters
    case "property_water_meters_add_meter": {
      // append payload to property_water_meters
      return {
        ...state,
        property_water_meters: [...state.property_water_meters, action.payload],
      }
    }
    case "property_water_meters_update_meter": {
      // replace the old meter at index with new meter in payload
      let meters_list = [...state.property_water_meters]
      meters_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_water_meters: [...meters_list],
      }
    }
    case "property_water_meters_delete_meter": {
      // remove energy meter with index
      let meters_list = [...state.property_water_meters]
      let deleted_meters = meters_list.splice(action.row_index, 1)
      let remaining_bills = state.property_water_bills
      deleted_meters.forEach(meter => {
        remaining_bills = remaining_bills.filter((bill) => {
          return bill.meter_name !== meter.meter_name
        })
      });
      return {
        ...state,
        property_water_meters: [...meters_list],
        property_water_bills: remaining_bills
      }
    }
    // 6 - property_water_bills
    case "property_water_bills_add_bills": {
      // append payload to property_water_bills
      // array of file objects

      let meter_name = action.meter_name
      let file_objects = action.payload
      let water_bills = [...state.property_water_bills]

      let water_meter = water_bills.find(
        (water_bill) => water_bill.meter_name === meter_name
      )

      // if meter_name already exists, append to bills list
      if (water_meter) {
        water_bills = water_bills.map((water_bill) => {
          if (water_bill.meter_name === meter_name) {
            water_bill.bill_files = [...water_bill.bill_files, ...file_objects]
          }
          return water_bill
        })
        // else create new meter_name and bills list
      } else {
        let new_meter = {
          meter_name: meter_name,
          bill_files: [...file_objects],
        }
        water_bills.push(new_meter)
      }

      return {
        ...state,
        property_water_bills: [...water_bills],
      }
    }
    case "property_water_bills_delete_bills": {
      // remove water bills with index
      let water_bills = state.property_water_bills.map((water_bill) => {
        if (water_bill.meter_name === action.meter_name) {
          let bills_list = [...water_bill.bill_files]
          bills_list.splice(action.row_index, 1)
          water_bill.bill_files = bills_list
        }
        return water_bill
      })

      return {
        ...state,
        property_water_bills: [...water_bills],
      }
    }
    case "property_water_bills_update_meter_name": {
      // update meter_name
      let water_bills = state.property_water_bills.map((water_bill) => {
        if (water_bill.meter_name === action.meter_name) {
          water_bill.meter_name = action.new_meter_name
        }
        return water_bill
      })

      return {
        ...state,
        property_water_bills: [...water_bills],
      }
    }
    case "property_water_bills_update_all": {
      return {
        ...state,
        property_water_bills: action.payload,
      }
    }

    // 7 - property_water_fixtures
    case "property_water_fixtures_save_ack": {
      let new_payload = {
        ...state.property_water_fixtures_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_water_fixtures_ack: new_payload,
      }
    }
    case "property_water_fixtures_add_fixture": {
      // append payload to property_water_fixtures
      return {
        ...state,
        property_water_fixtures: [
          ...state.property_water_fixtures,
          action.payload,
        ],
      }
    }
    case "property_water_fixtures_update_fixture": {
      // update fixture with index check with payload
      let fixtures_list = [...state.property_water_fixtures]
      fixtures_list.splice(action.row_index, 1, action.payload)
      return {
        ...state,
        property_water_fixtures: [...fixtures_list],
      }
    }
    case "property_water_fixtures_delete_fixture": {
      // remove fixture with index check with payload
      let fixtures_list = [...state.property_water_fixtures]
      fixtures_list.splice(action.row_index, 1)
      return {
        ...state,
        property_water_fixtures: [...fixtures_list],
      }
    }

    // 8 - property_envelope
    case "property_envelope_save_ack": {
      let new_payload = {
        ...state.property_envelope_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_envelope_ack: new_payload,
      }
    }
    case "property_envelope_save": {
      let new_payload = {
        ...state.property_envelope,
        ...action.payload,
      }
      return {
        ...state,
        property_envelope: new_payload,
      }
    }

    // 9 - property_documents_ack
    case "property_documents_save_ack": {
      let new_payload = {
        ...state.property_documents_ack,
        ...action.payload,
      }
      return {
        ...state,
        property_documents_ack: new_payload,
      }
    }
    // 9 - property_supporting_documents
    case "property_documents_add": {
      let new_list = [...state.property_supporting_documents, ...action.payload]
      return {
        ...state,
        property_supporting_documents: new_list,
      }
    }
    case "energy_audits_add": {
      let new_list = [...state.property_energy_audits, ...action.payload]
      return {
        ...state,
        property_energy_audits: new_list,
      }
    }
    case "property_documents_delete": {
      let new_list = [...state.property_supporting_documents]
      new_list.splice(action.row_index, 1)
      return {
        ...state,
        property_supporting_documents: new_list,
      }
    }
    case "energy_audits_delete": {
      let new_list = [...state.property_energy_audits]
      new_list.splice(action.row_index, 1)
      return {
        ...state,
        property_energy_audits: new_list,
      }
    }
    case "property_documents_update_all": {
      return {
        ...state,
        property_supporting_documents: action.payload,
      }
    }
    case "property_energy_audits_update_all": {
      return {
        ...state,
        property_energy_audits: action.payload,
      }
    }

    // default case for unknown action
    default: {
      try {
        throw Error("Unknown action: " + action.type)
      } catch (error) {
        console.log(error)
      } finally {
        return state
      }
    }
  }
}

// export function UploadFileListReducer(state, action) {
//   switch (action.type) {
//     case "equipments_documents_add": {
//       let new_list = [...state.equipments_documents, ...action.payload]
//       return {
//         ...state,
//         equipments_documents: new_list,
//       }
//     }

//     case "equipments_documents_remove": {
//       let new_list = [...state.equipments_documents]
//       new_list.splice(action.row_index, 1)
//       return {
//         ...state,
//         equipments_documents: new_list,
//       }
//     }

//     case "supporting_documents_add": {
//       let new_list = [...state.supporting_documents, ...action.payload]
//       return {
//         ...state,
//         supporting_documents: new_list,
//       }
//     }

//     case "supporting_documents_remove": {
//       let new_list = [...state.supporting_documents]
//       new_list.splice(action.row_index, 1)
//       return {
//         ...state,
//         supporting_documents: new_list,
//       }
//     }
//   }
// }
