import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import CreatableSelect from 'react-select/creatable';
import {
  editCityElectricityRatePost,
  getAllProvinces,
  getAllCities,
} from '../../../utils/HttpClient';
import { useLocation } from 'react-router-dom';

export const EditCityElectricityRatePage = (props) => {
  const location = useLocation();

  const [defaultCities, setDefaultCities] = useState([]);
  const [province, setProvince] = useState(null);
  const [provinces, setProvinces] = useState([]);
  const [city, setCity] = useState(null);
  const [cities, setCities] = useState([]);

  const [data, setData] = useState({
    provinceId: '',
    isNewProvince: false,
    cityId: '',
    isNewCity: false,
    valuePerkWh: '',
  });
  const editCityElectricityRate = (e) => {
    e.preventDefault();
    data.city_other_energy_rate_id = location.state[0];
    data.provinceId = province.value;
    data.isNewProvince = province.isNew ?? false;
    data.cityId = city.value;
    data.isNewCity = city.isNew ?? false;
    editCityElectricityRatePost(data)
      .then((res) => {
        window.location.href = '/rates';
      })
      .catch((err) => console.log(err));
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setData({
      ...data,
      [event.target.name]: value,
    });
  };
  const handleChangeProvince = (newProvince) => {
    setProvince(newProvince);
    setCity(null);
    setCities(defaultCities.filter((pc) => pc.provinceId == newProvince.value));
  };
  const handleCreateProvince = (newProvince) => {
    const newProvinceObj = {
      label: newProvince,
      value: newProvince,
      isNew: true,
    };
    setProvince(newProvinceObj);
    setProvinces((prev) => [...prev, newProvinceObj]);
    setCity(null);
    setCities([]);
  };
  const handleCreateCity = (newCity) => {
    const newCityObj = {
      label: newCity,
      value: newCity,
      isNew: true,
    };
    setCity(newCityObj);
    setCities((prev) => [...prev, newCityObj]);
  };

  useEffect(() => {
    var promise1 = getAllProvinces()
      .then((res) => {
        const allRes = res.data.map((r) => {
          return {
            value: r.province_id,
            label: r.province_name,
          };
        });
        setProvinces(allRes);
        return allRes;
      })
      .catch((err) => console.log(err));
    var promise2 = getAllCities()
      .then((res) => {
        const allRes = res.data.map((r) => {
          return {
            value: r.city_id,
            provinceId: r.province_id,
            label: r.city_name,
          };
        });
        setDefaultCities(allRes);
        return allRes;
      })
      .catch((err) => console.log(err));
    Promise.all([promise1, promise2]).then(function (valArray) {
      setData({
        ...data,
        provinceId: valArray[0].filter((c) => c.label == location.state[1])[0]
          .value,
        province: valArray[0].filter((c) => c.label == location.state[1])[0]
          .label,
        cityId: valArray[1].filter((c) => c.label == location.state[2])[0]
          .value,
        city: valArray[1].filter((c) => c.label == location.state[2])[0].label,
        valuePerkWh: location.state[3],
      });
      setProvince(valArray[0].filter((c) => c.label == location.state[1])[0]);
      setCity(valArray[1].filter((c) => c.label == location.state[2])[0]);
      setCities(
        valArray[1].filter(
          (pc) =>
            pc.provinceId ==
            valArray[0].filter((c) => c.label == location.state[1])[0].value
        )
      );
    });
  }, []);

  return (
    <div className='mt-7 mb-16 drop-shadow-md bg-white p-5'>
      <h1 className='mb-7 mt-3 text-center text-black'>
        Edit City Electricity Rate
      </h1>
      <div className='pl-14 pr-14 pb-7'>
        <div>
          <div className='widget-header'>
            <h2>{props.title}</h2>
          </div>
          <table id='description'>
            <tbody>
              <tr>
                <td>Province</td>
                <td>
                  <CreatableSelect
                    name='province'
                    options={provinces}
                    onChange={handleChangeProvince}
                    onCreateOption={handleCreateProvince}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={province}
                  />
                </td>
              </tr>
              <tr>
                <td>City</td>
                <td>
                  <CreatableSelect
                    name='city'
                    options={cities}
                    onChange={setCity}
                    onCreateOption={handleCreateCity}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    value={city}
                  />
                </td>
              </tr>
              <tr>
                <td>Value per kWh</td>
                <td>
                  <TextField
                    id='valuePerkWh'
                    name='valuePerkWh'
                    onChange={handleChange}
                    value={data.valuePerkWh}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <hr></hr>
          <div className='grid grid-cols-6 mt-5 mb-3'>
            <div className='col-start-6 mt-1'>
              <Button
                onClick={() => (window.location.href = '/rates')}
                sx={{ borderRadius: 28 }}
              >
                <Typography style={{ color: '#636363', textTransform: 'none' }}>
                  Cancel
                </Typography>
              </Button>
            </div>
            <div className='col-start-7'>
              <Button
                color='primary'
                onClick={editCityElectricityRate}
                sx={{ borderRadius: 28 }}
                style={{
                  backgroundColor: '#03045e',
                  width: 150,
                  padding: 10,
                }}
                variant='contained'
              >
                <Typography style={{ color: 'var(--white)', textTransform: 'none' }}>
                  Save
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
