import { useEffect, useState } from "react"
import { parseFloatAndFormat } from "../../../../utils/DataFormatter"
import "../../../../styles/analysisTools/savings-table.css"

function SavingsTable(props) {
  // props.data = {
  //     "Details": [
  //         {
  //             "Year": 2020,
  //             "ElectricitykWh": 0,
  //             "ElectricitykW": 0,
  //             "Gas": 0,
  //             "Fuel": 0,
  //             "Water": 0,
  //             "GHG": 0
  //         },
  //         {
  //             "Year": 2021,
  //             "ElectricitykWh": 0,
  //             "ElectricitykW": 0,
  //             "Gas": 0,
  //             "Fuel": 0,
  //             "Water": 0,
  //             "GHG": 0
  //         },
  //         {
  //             "Year": 2022,
  //             "ElectricitykWh": 0,
  //             "ElectricitykW": 0,
  //             "Gas": 0,
  //             "Fuel": 0,
  //             "Water": 0,
  //             "GHG": 0
  //         }
  //     ],
  //     "YearStart": 2020,
  //     "YearEnd": 2022
  // }
  //
  //

  const [tableBody, setTableBody] = useState([])
  const [tableTotal, setTableTotal] = useState([])

  useEffect(() => {
    if (props.data && props.financialData) {
      const updatedDetails = props.data.Details.map((item, i) => {
        const capitalCost = props.financialData.CapitalCostDetails[i] || 0

        return {
          ...item,
          CapitalCost: capitalCost,
        }
      })

      let totals = {
        CapitalCost: props.financialData.TotalCapitalCostDetails,
        Gas: props.data.Totals.Gas,
        Fuel: props.data.Totals.Fuel,
        ElectricitykWh: props.data.Totals.ElectricitykWh,
        ElectricitykW: props.data.Totals.ElectricitykW,
        Water: props.data.Totals.Water,
        GHGEmissions: props.data.Totals.GHGEmissions,
      }

      let total_row = [
        { bg_color: "var(--white)", total: "Total" },
        {
          bg_color: "#F3C3A9",
          total: parseFloatAndFormat(totals.CapitalCost),
        },
        {
          bg_color: "#A7EDF8",
          total: parseFloatAndFormat(totals.ElectricitykWh),
        },
        {
          bg_color: "#A7EDF8",
          total: parseFloatAndFormat(totals.ElectricitykW),
        },
        { bg_color: "#B7F2CC", total: parseFloatAndFormat(totals.Gas) },
        { bg_color: "#B7F2CC", total: parseFloatAndFormat(totals.Fuel) },
        { bg_color: "#C6C6CA", total: parseFloatAndFormat(totals.Water) },
        {
          bg_color: "#F3C3A9",
          total: parseFloatAndFormat(totals.GHGEmissions),
        },
      ]
      setTableBody(updatedDetails)
      setTableTotal(total_row)
    }
  }, [props.data, props.financialData])

  let headers = [
    { column: "Year", units: "", bg_color: "var(--white)" },
    { column: "Capital Cost", units: "($)", bg_color: "#F3C3A9" },
    { column: "Electricity", units: "(kWh/yr)", bg_color: "#A7EDF8" },
    { column: "Electricity", units: "(kW)", bg_color: "#A7EDF8" },
    { column: "Gas", units: "(GJ/yr)", bg_color: "#B7F2CC" },
    { column: "Fuel", units: "(GJ/yr)", bg_color: "#B7F2CC" },
    { column: "Water", units: "(m³/yr)", bg_color: "#C6C6CA" },
    { column: "GHG", units: "(%)", bg_color: "#F3C3A9" },
  ]
  return (
    <div className=' flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>
        Total Energy Savings from {props.data.YearStart} to {props.data.YearEnd}
      </p>

      <div className='h-full flex flex-col gap-8'>
        <table className='savings-table'>
          <thead>
            <tr className='savings-table-rows'>
              {headers.map((item, i) => {
                return (
                  <th
                    key={i}
                    className='savings-table-cell'
                    style={{ backgroundColor: item.bg_color }}
                  >
                    <p className='savings-header'>{item.column}</p>
                    <p className='savings-units'>{item.units}</p>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {tableBody.map((item, i) => {
              return (
                <tr key={i} className='savings-table-rows'>
                  <td className='savings-table-cell'>{item.Year}</td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.CapitalCost)}
                  </td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.ElectricitykWh)}
                  </td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.ElectricitykW)}
                  </td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.Gas)}
                  </td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.Fuel)}
                  </td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.Water)}
                  </td>
                  <td className='savings-table-cell'>
                    {parseFloatAndFormat(item.GHGEmissions)}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr className='savings-table-rows'>
              {tableTotal.map((item, i) => {
                return (
                  <td
                    key={i}
                    className='savings-table-cell'
                    style={{ backgroundColor: item.bg_color }}
                  >
                    <p className='savings-header'>{item.total}</p>
                  </td>
                )
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}
export default SavingsTable
