import ReviewSubmitStyle from "../../../../styles/addProperty/ReviewSubmit.module.css"
import styles from "../../../../styles/addProperty/index.module.css"
import AccordionForm from "../AccordionForm"
import { parseFloatAndFormat, isFloat } from "../../../../utils/DataFormatter"

const ReviewData = ({ data, categoryName, activeStep }) => {
  const renderData = (objectData) => {
    return Object.entries(objectData)
      .filter((x) => !x[0].endsWith("_label"))
      .map(([objectKey, value], keyIndex) => {
        const label = objectData[objectKey + "_label"]
          ? objectData[objectKey + "_label"]
          : objectKey
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ") + ":"

        if (Array.isArray(value)) {
          if (
            objectKey.includes("end_use") ||
            objectKey.includes("files_list")
          ) {
            return <></>
          } else {
            return value.map((item, index) => (
              <div key={index}>
                <AccordionForm
                  summary_jsx={label}
                  details_jsx={renderData(item)}
                />
              </div>
            ))
          }
        }

        if (typeof value === "object") {
          if (objectKey.includes("blob") || objectKey.includes("handle")) {
            return <></>
          } else {
            return (
              <div key={keyIndex}>
                <AccordionForm
                  summary_jsx={label}
                  details_jsx={renderData(value)}
                />
              </div>
            )
          }
        }

        return (
          <div key={keyIndex} className={ReviewSubmitStyle.reviewDivRow}>
            <div className={ReviewSubmitStyle.reviewDivLabel}>{label}</div>
            <div className={ReviewSubmitStyle.reviewDivValue}>
              {isFloat(value) ? parseFloatAndFormat(value) : value}
            </div>
          </div>
        )
      })
  }

  return (
    <div className={ReviewSubmitStyle.reviewContainer}>
      <div>
        {data &&
          Array.isArray(data) &&
          data.map((item, index) => (
            <div key={index}>
              {index > 0 && <hr className={styles.hr_break} />}
              {renderData(item)}
            </div>
          ))}
        {data &&
          typeof data === "object" &&
          !Array.isArray(data) &&
          !categoryName.includes("end_use") &&
          renderData(data)}
      </div>
    </div>
  )
}

export default ReviewData
