import StatCard from "../components/StatCard"
import { useEffect, useState } from "react"
import {
  parseFloatAndFormat,
  parseIntCurrencyRoundedFormat,
} from "../../../utils/DataFormatter"

function FinancialAnalysis(props) {
  // Generic Component for displaying financial analysis data
  // props.data = {
  //                simple_payback: data.SimplePayback
  //                roi: data.ROI,
  //                npv: data.NPV,
  //                capital_cost: data.CapitalCost,
  //                incentive: data.Incentive,
  //                first_year_savings: data.FirstYearSavings,
  //                total_utility_savings: data.TotalUtilitySavings,
  //                year_start: data.YearStart,
  //                year_end: data.YearEnd
  //              }
  //

  const [financialData, setFinancialData] = useState([])

  const duration = props.data.YearEnd - props.data.YearStart + 1
  const currency_list = [
    "NPV",
    "CapitalCost",
    "Incentive",
    "TotalUtilitySavings",
  ]

  useEffect(() => {
    if (props.data) {
      const data = props.data
      for (let key of Object.keys(data)) {
        if (!isNaN(data[key])) {
          if (currency_list.includes(key)) {
            data[key] = parseIntCurrencyRoundedFormat(data[key])
          } else if (key === "YearEnd" || key === "YearStart") {
            data[key] = parseInt(data[key])
          } else {
            data[key] = parseFloatAndFormat(data[key])
          }
        } else {
          data[key] = "-"
        }
      }

      let financial_analysis = [
        {
          title_text: "Simple Payback",
          units_text: "(yrs)",
          value_text: data.SimplePayback,
          top_bg_color: "#245CA8",
          bot_bg_color: "var(--white)",
          top_text_color: "var(--white)",
          bot_text_color: "#112B4E",
          width: "100%",
        },
        {
          title_text: "ROI",
          units_text: "(%)",
          value_text: data.ROI,
          top_bg_color: "#245CA8",
          bot_bg_color: "var(--white)",
          top_text_color: "var(--white)",
          bot_text_color: "#112B4E",
          width: "100%",
        },
        {
          title_text: "NPV",
          units_text: "($)",
          value_text: data.NPV,
          top_bg_color: "#245CA8",
          bot_bg_color: "var(--white)",
          top_text_color: "var(--white)",
          bot_text_color: "#112B4E",
          width: "100%",
        },
        {
          title_text: "ECM Capital Cost",
          units_text: "($)",
          value_text: data.CapitalCost,
          top_bg_color: "#245CA8",
          bot_bg_color: "var(--white)",
          top_text_color: "var(--white)",
          bot_text_color: "#112B4E",
          width: "100%",
        },
        {
          title_text: "Incentive",
          units_text: "($)",
          value_text: data.Incentive,
          top_bg_color: "#245CA8",
          bot_bg_color: "var(--white)",
          top_text_color: "var(--white)",
          bot_text_color: "#112B4E",
          width: "100%",
        },
        {
          title_text: "Total Savings",
          units_text: "($)",
          value_text: data.TotalUtilitySavings,
          top_bg_color: "#245CA8",
          bot_bg_color: "var(--white)",
          top_text_color: "var(--white)",
          bot_text_color: "#112B4E",
          width: "100%",
        },
      ]

      setFinancialData(financial_analysis)
    }
  }, [props.data])

  return (
    <div className='flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>
        Financial Analysis for {duration} years
      </p>
      <div className='financial-analysis-container'>
        {financialData.slice(0, 3).map((item, index) => {
          return (
            <StatCard
              key={index}
              width={item.width}
              title_text={item.title_text}
              units_text={item.units_text}
              value_text={item.value_text}
              top_bg_color={item.top_bg_color}
              top_text_color={item.top_text_color}
              bot_bg_color={item.bot_bg_color}
              bot_text_color={item.bot_text_color}
            />
          )
        })}
        {financialData.slice(3).map((item, index) => {
          return (
            <StatCard
              key={index}
              width={item.width}
              title_text={item.title_text}
              units_text={item.units_text}
              value_text={item.value_text}
              top_bg_color={item.top_bg_color}
              top_text_color={item.top_text_color}
              bot_bg_color={item.bot_bg_color}
              bot_text_color={item.bot_text_color}
            />
          )
        })}
      </div>
    </div>
    // </div>
  )
}
export default FinancialAnalysis
