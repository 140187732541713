import LoadingSpinner from "../../../global/LoadingSpinner"
import UserProjectsTable from "./UserProjectsTable"
import { useState, useRef } from "react"
import ModalDialog from "../../../home/components/ModalDialog"

function UserProjects({
  user_projects,
  all_projects_but_user,
  add_user_project,
  remove_user_project,
  is_loading,
  set_is_loading,
}) {
  const header_names = [
    'project_number',
    "site_name",
    "project_status",
    "date_audited",
    "select",
  ]

  const [selectedProjectsAdd, setSelectedProjectsAdd] = useState({})
  const [selectedProjectsDelete, setSelectedProjectsDelete] = useState({})
  const modal_ref = useRef(null);

  const [isRemoveButtonDisabled, setIsRemoveButtonDisabled] = useState(true);

  function handleOpen() {
    modal_ref.current.showModal();
  };

  function onClickSelectProjectAdd(project_number) {
    if (selectedProjectsAdd[project_number]) {
      setSelectedProjectsAdd({
        ...selectedProjectsAdd,
        [project_number]: false,
      })
    } else {
      setSelectedProjectsAdd({
        ...selectedProjectsAdd,
        [project_number]: true,
      })
    }
  }

  function onClickSelectProjectDelete(project_number) {
    const updatedSelectedProjectsDelete = {
      ...selectedProjectsDelete,
      [project_number]: !selectedProjectsDelete[project_number],
    };
  
    setSelectedProjectsDelete(updatedSelectedProjectsDelete);
  
    const isDisabled = Object.values(updatedSelectedProjectsDelete).every(
      (isSelected) => !isSelected
    );
    setIsRemoveButtonDisabled(isDisabled);
  }
  
  function onClickAddQuery(e) {
    let project_numbers = []

    if (Object.keys(selectedProjectsAdd).length > 0) {
      set_is_loading(true)

      for (let project_number in selectedProjectsAdd) {
        if (selectedProjectsAdd[project_number]) {
          project_numbers.push(project_number)
        }
      }
      add_user_project(project_numbers)
      setSelectedProjectsAdd({})
    }
  }

  function onClickDeleteQuery(e) {
    let project_numbers = []

    if (Object.keys(selectedProjectsDelete).length > 0) {
      set_is_loading(true)

      for (let project_number in selectedProjectsDelete) {
        if (selectedProjectsDelete[project_number]) {
          project_numbers.push(project_number)
        }
      }
      remove_user_project(project_numbers)
      setSelectedProjectsDelete({})
    }
  }

  return (
    <div className='projects-container bg-white rounded drop-shadow-md'>
      {is_loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div id='user_projects'>
            <div className='bg-sisa-header w-full'>
              <p className='text-md text-white p-2 m-2'>User Projects</p>
            </div>
            <UserProjectsTable
              list_of_data={user_projects}
              header_names={header_names}
              selected_projects={selectedProjectsDelete}
              set_selected_projects={setSelectedProjectsDelete}
              onClick_function={onClickSelectProjectDelete}
            />
            <div className='table-button-container'>
              <button
                className='button-action'
                onClick={handleOpen}
                disabled={isRemoveButtonDisabled}
              >
                remove from user
              </button>
              <ModalDialog
                content_title={"Delete Project?"}
                content_body={
                  <>
                    Deleting the project(s) will result in the loss of all saved
                    analysis data associated with the project(s). <br />
                    Are you sure you want to delete the project(s)?
                  </>
                }
                btn_confirm={"Delete Project"}
                btn_cancel={"Cancel"}
                btn_close={true}
                fn_confirm={onClickDeleteQuery}
                modal_ref={modal_ref}
              />
            </div>
          </div>

          <div id='all_projects'>
            <div className='bg-sisa-header w-full'>
              <p className='text-md text-white p-2 m-2'>All Projects</p>
            </div>
            <UserProjectsTable
              list_of_data={all_projects_but_user}
              header_names={header_names}
              selected_projects={selectedProjectsAdd}
              set_selected_projects={setSelectedProjectsAdd}
              onClick_function={onClickSelectProjectAdd}
            />
            <div className='table-button-container'>
              <button className='button-action' onClick={onClickAddQuery}>
                add to user
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default UserProjects
