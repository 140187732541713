import React from "react"
import * as XLSX from "xlsx"
import DropdownTab from "../../global/components/DownloadDropdown/DownloadTab"

export default function XLSXReport(props) {
  const {
    project_number,
    draft_name: project_name,
    // draft_date: project_date,
    basic_information,
    building_data,
    property_energy_utilities,
    property_energy_meters,
    property_energy_bills,
    property_water_utilities,
    property_water_meters,
    property_water_bills,
    property_equipments_ack,
    property_equipments,
    property_lights_ack,
    property_lights,
    property_water_fixtures_ack,
    property_water_fixtures,
    property_envelope_ack,
    property_envelope,
    property_supporting_documents,
    property_energy_audits,
  } = props.data

  const headers = {
    basicInformationHeaders: [
      "Name",
      "Street Address",
      "City Municipality",
      "State Province",
      "County",
      "Postal Code",
      "Owner Organization",
      "Office Address",
      "Applicant Name",
      "Telephone",
      "Email",
      "Program Job No",
      "Site Contact Name",
      "Site Auditors",
      "Program",
      "Responsible Project Engineer",
      "Email of Responsible Engineer",
      "Site Visit Date",
    ],
    buildingDataHeaders: [
      "Property Type",
      "Gross Floor Area",
      "No of Bachelor Suites",
      "No of 1 Bedroom Suites",
      "No of 2 Bedroom Suites",
      "No of 3 or More Bedroom Suites",
      "Total No of Suites",
      "Avg Bachelor Area",
      "Avg 1 Bedroom Area",
      "Avg 2 Bedroom Area",
      "Avg 3 or More Bedroom Area",
      "Total Avg Suite Area",
      "No of Workers on Main Shift",
      "No of Residents",
      "Total No of People",
      "No of Elevators",
      "No of Floors",
      "Year Built",
      "Last Remodeling Year",
      "Do Tenants Pay for Electricity",
      "Has Cooling",
    ],
    energyUtilitiesHeaders: [],
    energyMetersHeaders: [],
    waterUtilitiesHeaders: [],
    waterMetersHeaders: [],
    equipmentsHeaders: [
      "Equipment",
      "Comments",
      "Area Served",
      "Make Model",
      "Quantity",
      "Electrical Power Rating",
      "Electrical Power Rating Units",
      "Gas Rating",
      "Gas Rating Units",
      "Meter",
      "Year",
      "Average Efficiency Or COP",
      "Control Type",
      "Hours Of Operation",
      "Hours Of Operation Comments",
      "Frequency Of Service Per Year",
      "Service Maintenance Procedure Comments",
      "Ru Air Water Flow",
      "Ru Outside Air",
      "Ru Variable Speed Frequency",
      "Ru Variable Speed Frequency Comments",
      "Hu Heating Unit Type",
      "Hu Heating Source Delivery",
      "Hu Feeding Equipment If External",
      "Hu Heating Source",
      "Hu Do Units Have Heat Recovery",
      "Hu Heat Recovery Efficiency",
      "Hu Heat Recovery Comments",
      "Hu Backup Type",
      "Hu Backup Efficiency",
      "Hu Backup Control Comments",
      "Cu Cooling Unit Type",
      "Cu Cooling Source Delivery",
      "Cu Feeding Equipment If External",
    ],
    lightsHeaders: [
      "Technology",
      "Area Served",
      "Space Description",
      "Watts Per Lamp",
      "Number Of Lamps Per Fixture",
      "Number Of Fixtures",
      "Lights On Time",
      "Control Type",
      "Control Comments",
    ],
    waterFixturesHeaders: [
      "Kitchen Sinks",
      "Lavatories",
      "Urinals",
      "Showers",
      "Toilets",
      "Others",
      "Meters",
    ],
    envelopeHeaders: [
      "Construction Type",
      "Number of Exterior Doors",
      "Parkade Ceiling Insulation Material",
      "Parkade Ceiling R-Factor",
      "Roof Condition",
      "Roof Insulation Material",
      "Roof R-Factor",
      "Walls Type",
      "Walls R-Factor",
      "Windows Type",
      "Windows Coverage",
      "Windows R-Factor",
      "Windows Draftyness",
    ],
  }

  const processData = (data) => {
    if (data) {
      return [Object.values(data)]
    } else {
      console.error("Data is not defined or is null")
      return []
    }
  }

  // Fit to column width
  const fitToColumn = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      console.error("Invalid input format for fitToColumn")
      return []
    }

    const columnWidths = new Array(data[0].length).fill(0)

    data.forEach((row) => {
      row.forEach((cell, colIndex) => {
        if (cell) {
          const cellLength = cell.toString().length
          if (cellLength > columnWidths[colIndex]) {
            columnWidths[colIndex] = cellLength + 2
          }
        }
      })
    })

    return columnWidths.map((width) => ({ wch: width }))
  }

  const getSheetData = (headers, data) => {
    try {
      const columnWidths = fitToColumn([headers, ...data])

      const ws = XLSX.utils.aoa_to_sheet([headers, ...data])
      ws["!cols"] = columnWidths

      return ws
    } catch (error) {
      console.error(error)
      return null
    }
  }

  const exportToCSV = () => {
    const workbook = XLSX.utils.book_new()

    const sheetNames = {
      basicInformationHeaders: "Basic Information",
      buildingDataHeaders: "Building Data",
      energyUtilitiesHeaders: "Energy Utilities",
      energyMetersHeaders: "Energy Meters",
      waterUtilitiesHeaders: "Water Utilities",
      waterMetersHeaders: "Water Meters",
      equipmentsHeaders: "Equipments",
      lightsHeaders: "Lights",
      waterFixturesHeaders: "Water Fixtures",
      envelopeHeaders: "Envelope",
    }

    Object.entries(headers).forEach(([key, value]) => {
      const data = processData(
        key === "basicInformationHeaders"
          ? basic_information
          : key === "buildingDataHeaders"
          ? building_data
          : key === "energyUtilitiesHeaders"
          ? property_energy_utilities
          : key === "energyMetersHeaders"
          ? property_energy_meters
          : key === "waterUtilitiesHeaders"
          ? property_water_utilities
          : key === "waterMetersHeaders"
          ? property_water_meters
          : key === "equipmentsHeaders"
          ? property_equipments
          : key === "lightsHeaders"
          ? property_lights
          : key === "waterFixturesHeaders"
          ? property_water_fixtures
          : key === "envelopeHeaders"
          ? property_envelope
          : null
      )

      if (data) {
        const sheetName = sheetNames[key]
        XLSX.utils.book_append_sheet(
          workbook,
          getSheetData(value, data),
          sheetName
        )
      }
    })

    XLSX.writeFile(
      workbook,
      props.fileName || `${project_number} - ${project_name}.xlsx`
    )
  }

  return (
    <DropdownTab
      onClick={exportToCSV}
      tabTitle={props.tabTitle || "Download XLS Files"}
    />
  )
}
