import { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import {
  DefaultDraftContext,
  UserDraftContext,
} from "../../context/DraftContext"
import "../../style/lightsPageLayout.css"
import Button from "../../../global/components/button/Button"
import CheckBox from "../../../global/components/CheckBox/CheckBox"
import Table from "../../../global/components/Table/Table"
import { dateTimeFormatter } from "../../../../utils/DataFormatter"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"

function PropertyLights({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
  handleShowLightForm,
}) {
  const savedLightsHeadersArray = [
    {
      header_name: "Technology",
      header_key: "technology",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "lg",
    },
    {
      header_name: "Area Served",
      header_key: "area_served",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Date Created",
      header_key: "created_date",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Edit",
      header_key: "edit",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    {
      header_name: "Delete",
      header_key: "delete_row",
      is_required: false,
      is_sortable: false,
      sort_order: null,
    },
  ]
  const [isAddLightEnabled, setIsAddLightEnabled] = useState(true)
  const [selectedLightIndex, setSelectedLightIndex] = useState(null)

  const convert_to_lights_data = (lights) => {
    return lights.map((light, index) => {
      return {
        row_data: {
          row_index: index,
          technology: light.technology,
          area_served: light.area_served,
          created_date: light.created_date,
          edit: (
            <span
              className='flex draft-edit-button'
              onClick={() => editLight(index)}
            >
              <span className='material-symbols-outlined pr-2'>Edit</span>Edit
            </span>
          ),
        },
        is_selected: false,
      }
    })
  }

  const editLight = (index) => {
    setSelectedLightIndex(index)
    setIsAddLight(false)
    setIsEditLight(true)
  }

  const removeLight = (index) => {
    userDraftDispatch({
      type: "property_lights_delete_light",
      row_index: index,
    })
  }

  const [isAddLight, setIsAddLight] = useState(false)
  const [isEditLight, setIsEditLight] = useState(false)

  // property lights list
  const [propertyLights, setPropertyLights] = useState([])

  // global state
  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)
  const { defaultDraftObject, siteInputOptions } =
    useContext(DefaultDraftContext)

  useEffect(() => {
    if (userDraftState.property_lights) {
      setPropertyLights(convert_to_lights_data(userDraftState.property_lights))
    }
    if (userDraftState.property_lights_ack) {
      if (
        userDraftState.property_lights_ack.isNoLightInformation === true ||
        userDraftState.property_lights_ack.isOnlyUploadDocuments === true
      ) {
        setIsAddLightEnabled(false)
      } else {
        setIsAddLightEnabled(true)
      }
    }
  }, [userDraftState])

  useEffect(() => {
    if (isAddLight) {
      handleShowLightForm("new")
    }
  }, [isAddLight])

  useEffect(() => {
    if (isEditLight) {
      handleShowLightForm(selectedLightIndex)
    }
  }, [isEditLight])

  // Form Validation
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isEmpty ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation(null)
    }
  }

  function handleCheckedBoxChange(newValue) {
    userDraftDispatch({
      type: "property_lights_save_ack",
      payload: { [newValue.name]: newValue.checked },
    })
  }

  return (
    <div id='lighting-landing-page' className='form__container'>
      <div className='form__container'>
        <div className='form-title-button__container'>
          <p className='form__title'>Lights</p>
          <Button
            type='button'
            className='button__large button__large--gray complete-button-upper'
            buttonText='Check this tab'
            // disabled={isButtonDisabled === null}
            onClick={handleValidation}
          />
        </div>
        <div className='form__content'>
          <InstructionalText
            type='Main Instructions'
            title='Add New Light'
            content={
              <>
                In this section, you can provide information about the lighting
                within the building. <br />
                Feel free to skip this section if you lack the information, or
                if you intend to upload documents containing the required
                information.
              </>
            }
            containerClassName='main-instructions'
          />
          <div className='form__description'>
            <CheckBox
              label='I do not have the lighting information.'
              name='isNoLightInformation'
              checked={userDraftState.property_lights_ack.isNoLightInformation}
              onChange={handleCheckedBoxChange}
            />
          </div>
          <div className='form__description'>
            <CheckBox
              label='I will ONLY upload documents containing the required lighting information.'
              name='isOnlyUploadDocuments'
              checked={userDraftState.property_lights_ack.isOnlyUploadDocuments}
              onChange={handleCheckedBoxChange}
            />
          </div>
          <div className='form__buttons__right'>
            <Button
              type='button'
              buttonText='Add light manually'
              className={"button__large button__large--primary"}
              onClick={() => {
                setIsAddLight(true)
                setIsEditLight(false)
              }}
              disabled={!isAddLightEnabled}
            />
          </div>
        </div>
      </div>
      <div className='form__container'>
        <div className='form__content'>
          <InstructionalText
            type='Main Instructions'
            title='My Lights'
            content={<p>You can review and edit your light entries below.</p>}
            containerClassName='main-instructions'
          />

          <Table
            headers_array={savedLightsHeadersArray}
            data_array={propertyLights}
            table_entity_name={"Light"}
            no_data_message={
              "There are no light entries, please add a new light."
            }
            fn_delete_row={removeLight}
            table_disabled={!isAddLightEnabled}
          />
        </div>
      </div>
    </div>
  )
}
export default PropertyLights
