import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import styles from "../../../styles/addProperty/AccordionForm.module.css"

function AccordionForm({ summary_jsx, details_jsx }) {
  return (
    <Accordion className={styles.accordion_container} disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {summary_jsx}
      </AccordionSummary>
      <AccordionDetails>{details_jsx}</AccordionDetails>
    </Accordion>
  )
}
export default AccordionForm
