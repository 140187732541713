export const EnvelopeInputLabels = {
  construction_material_type_label: "Construction material type",
  no_of_exterior_doors_label: "No. of exterior doors",
  is_parkade_ceiling_insulated_label: "Is parkade ceiling insulated?",
  parkade_ceiling_r_factor_label: "Parkade ceiling R factor (ft2·°F·h/BTU)",
  is_roof_insulated_label: "Is roof insulated?",
  roof_r_factor_label: "Overall Roof R factor (ft2·°F·h/BTU)",
  walls_r_factor_label: "Walls R factor (ft2·°F·h/BTU)",
  windows_type_label: "Windows type",
  windows_coverage_label: "Windows coverage",
  windows_draftyness_label: "Level of windows draftyness",
  windows_u_factor_label: "Windows U factor (BTU/ft2·°F·h)",
  general_comment_label: "General comment",
}
