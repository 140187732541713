import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectsTable from './ProjectsTable';
import LoadingSpinner from '../../../global/LoadingSpinner';
import { deleteProjectOnAdmin } from '../../../../utils/HttpClient';

function ProjectsRegisteredPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([]);

  const navigate = useNavigate();
  const header_names = [
    'project_number',
    'site_name',
    'project_status',
    'date_audited',
    'users',
    'delete'
  ];

  // get all projects, build list with all projects
  useEffect(() => {
    setIsLoading(true);
    if (props.projects_list) {
      setProjectsList(props.projects_list);
      setIsLoading(false);
    }
  }, [props.projects_list]);

  function onClickManageUsers(data) {
    navigate('/users/manage-projects/project-users', {
      state: { project_data: data },
    });
  }

  function removeUserProjectHandler(project_number) {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      deleteProjectOnAdmin(project_number)
        .then((res) => {
          setProjectsList(projectsList.filter((project) => project.project_number !== project_number));
          setIsLoading(false);
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
          reject(err);
        });
    })
  }

  return (
    <div className='bg-white flex flex-col rounded drop-shadow-md p-5 h-fit'>
      <div className='bg-sisa-header w-full flex justify-between'>
        <p className='text-xl text-white p-2 m-2'>Registered Projects</p>
        <button className='button-action-white' onClick={() => navigate(-1)}>
          back
        </button>
      </div>
      <div className='h-full flex flex-col gap-10'>
        <div className='bg-white flex flex-col drop-shadow-md p-5'>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <ProjectsTable
              list_of_data={projectsList}
              action_function={onClickManageUsers}
              header_names={header_names}
              delete_project_function={removeUserProjectHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectsRegisteredPage;
