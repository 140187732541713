import React, { useEffect, useState } from "react"
import ReactSwitch from "react-switch"
import "../../styles/report-tab.css"
import PieChart from "../global/TemplatePiechart"
import {
  default as DistributionTable,
  default as EquipmentTable,
  default as LightTable,
  default as PlumbingTable,
  default as RecommendedEnergyTable,
} from "../global/TemplateTable"
import Map from "../report/Map"
import BarChart from "./BarChart-Report"
import Carousel, { CarouselItem } from "./Carousel"
import EnvelopeTab from "./EnvelopeTab"
import ExpandedTablePanel from "./ExpandedTablePanel"
import StackedHistogram from "./StackedHistogram"
import VerticalTable from "./VerticalTable"
import UpgradesRecEnergyTable from "./UpgradesRecEnergyTable"
import PDFReport from "./PDF-Report"
import XLSXReport from "./XLSX-Report"
import AddEquipmentButton from "./button/AddEquipmentButton"
import DeleteEquipmentButton from "./button/DeleteEquipmentButton"
import { formatNumberGrouping } from "../../utils/DataFormatter"

const ReportTabs = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const [subHamburgerTitle, setSubHamburgerTitle] = useState("Utility Rates")

  const toggleTab = (index) => {
    setActiveTab(index)
  }

  const [checked, setChecked] = useState(false) //  For toggle-switch: initially set checked boolean to false (show GHG Emissions and Utility Cost bar charts)
  const address = props.data.report_summary_participant_table_data[2]
  const city = props.data.report_summary_participant_table_data[3]
  const province = props.data.report_summary_participant_table_data[4]
  const country = "Canada"
  const participantAddress =
    address + ", " + city + ", " + province + ", " + country

  const handleChange = (val) => {
    setChecked(val)
  }

  const [
    reportUpgradesRecEnergySolutionsData,
    setReportUpgradesRecEnergySolutionsData,
  ] = useState([])
  const [reportEquipmentData, setReportEquipmentData] = useState([])

  useEffect(() => {
    setReportUpgradesRecEnergySolutionsData(
      props.data.report_upgrades_rec_energy_solutions_data
    )
    setReportEquipmentData(props.data.report_equipment_data)
  }, [])

  const onReportUpgradesRecEnergySolutionsDataChange = (newData, row, col) => {
    var newDataArray = [...reportUpgradesRecEnergySolutionsData]
    newDataArray[row][col] = newData
    setReportUpgradesRecEnergySolutionsData(newDataArray)
    props.onReportUpgradesRecEnergySolutionsDataChange(newDataArray)
  }

  const onDeletedEquipment = (equipment_id) => {
    var newDataArray = reportEquipmentData.filter((x) => x[0] !== equipment_id)
    setReportEquipmentData(newDataArray)
  }

  const onAddedEquipment = (new_equipment) => {
    var newEquipmentData = [
      new_equipment.equipment_id,
      new_equipment.equipment_type,
      new_equipment.quantity,
      new_equipment.year_of_equipment,
      new_equipment.make_and_model,
      new_equipment.identifier_tag,
      new_equipment.end_use,
      new_equipment.area_served,
      new_equipment.fuel,
      new_equipment.electrical_power_rating,
      new_equipment.gas_power_rating,
      new_equipment.unit,
    ]
    var newDataArray = [...reportEquipmentData]
    newDataArray.push(newEquipmentData)
    setReportEquipmentData(newDataArray)
  }

  const [navMenu, setNavMenu] = useState("nav_menu")
  const [subNavMenu, setSubNavMenu] = useState("sub_nav_menu")
  const [scrollTop, setScrollTop] = useState(0)

  window.addEventListener("scroll", function () {
    setScrollTop(this.window.scrollY)
  })

  const project_number = window.location.pathname.substring(8)
  const requiredData = props.tableData?.filter((arr) => {
    return arr[0] === parseInt(project_number)
  })
  const GHGEmissions = requiredData
    ? formatNumberGrouping(requiredData[0][4])
    : "-"
  const GHGI = requiredData ? requiredData[0][3] : "-"
  function extractCleanedRate(rateString) {
    const parts = rateString.replace("$", "").split(" ")
    const cleanedRate = parts[0]
    return cleanedRate
  }

  // modify data under the more details for the utility rates
  const replacements = [
    { pattern: "t /kWh", replacement: "Ton CO₂e/kWh" },
    { pattern: "t /m³", replacement: "Ton CO₂e/m³" },
    { pattern: "t /GJ", replacement: "Ton CO₂e/GJ" },
  ]

  function applyReplacements(dataArray, replacements) {
    const updatedArray = dataArray.map((input) => {
      let output = String(input)
      replacements.forEach((item) => {
        output = output.replace(String(item.pattern), String(item.replacement))
      })
      return output
    })
    return updatedArray
  }

  const updatedUtilityRateData = props.data.report_utility_rate_data.map(
    (item) => applyReplacements(item, replacements)
  )

  function formatValuesWithUnits(dataArray) {
    const units = [" kWh/yr", " GJ/yr", " GJ/yr"]

    return dataArray.map((row, index) => {
      const rowWithUnits = row.map((item, subIndex) => {
        if (subIndex === 0 || subIndex === 1) {
          // Format numbers with commas using toLocaleString
          const number = parseInt(item.replace(/,/g, ""))
          if (!isNaN(number)) {
            return number.toLocaleString() + units[index]
          }
        }
        return item
      })
      return rowWithUnits
    })
  }

  // data is string
  function formatStringWithComma(input) {
    try {
      const number = parseFloat(input.replace(/,/g, ""))
      if (!isNaN(number)) {
        return number.toLocaleString("en-US")
      } else {
        return input
      }
    } catch {
      return "-"
    }
  }

  const formattedTotalConsumptionData =
    props.data.report_total_consumption_data.map((dataArray) => {
      return dataArray.map((item) => {
        return formatStringWithComma(item)
      })
    })

  const updatedTotalConsumption = formatValuesWithUnits(
    formattedTotalConsumptionData
  )

  return (
    <div>
      <ul id='tab-container' className='flex'>
        <div id='hamburger-header'>
          {
            // activeTab==0 ? "a" : (activeTab == 1 ? "b" : "c")
            {
              0: "Summary",
              1: "Building Description",
              2: "Energy Consumption",
              3: "ECMs",
              4: "Envelope",
              5: "Equipment",
              6: "Light",
              7: "Plumbing",
              8: "Contact & Terms",
            }[activeTab]
          }
        </div>
        <div
          className={`${navMenu}  ${
            activeTab === 0 ? "tabs active-tab" : "tabs"
          }`}
          onClick={() => [
            toggleTab(0),
            setNavMenu(navMenu === "" ? "nav_menu" : ""),
          ]}
        >
          Summary
        </div>
        <div
          className={`${navMenu}  ${
            activeTab === 1 ? "tabs active-tab" : "tabs"
          }`}
          onClick={() => [
            toggleTab(1),
            setNavMenu(navMenu === "" ? "nav_menu" : ""),
          ]}
        >
          Building&nbsp;Description
        </div>
        <div
          className={`${navMenu}  ${
            activeTab === 2 ? "tabs active-tab" : "tabs"
          }`}
          onClick={() => [
            toggleTab(2),
            setNavMenu(navMenu === "" ? "nav_menu" : ""),
          ]}
        >
          Energy&nbsp;Consumption
        </div>
        <div
          className={`${navMenu}  ${
            activeTab === 3 ? "tabs active-tab" : "tabs"
          }`}
          onClick={() => [
            toggleTab(3),
            setNavMenu(navMenu === "" ? "nav_menu" : ""),
          ]}
        >
          ECMs
        </div>
        {
          // disable tab if there is no data to show
          props.data.report_envelope_data.length > 0 ? (
            <div
              className={`${navMenu}  ${
                activeTab === 4 ? "tabs active-tab" : "tabs"
              }`}
              onClick={() => [
                toggleTab(4),
                setNavMenu(navMenu === "" ? "nav_menu" : ""),
              ]}
            >
              Envelope
            </div>
          ) : (
            <div className={`${navMenu} tabs disabled-tab`} onClick={null}>
              Envelope
            </div>
          )
        }
        {
          // disable tab if there is no data to show
          reportEquipmentData.length > 0 ? (
            <div
              className={`${navMenu}  ${
                activeTab === 5 ? "tabs active-tab" : "tabs"
              }`}
              onClick={() => [
                toggleTab(5),
                setNavMenu(navMenu === "" ? "nav_menu" : ""),
              ]}
            >
              Equipment
            </div>
          ) : (
            <div className={`${navMenu} tabs disabled-tab`} onClick={null}>
              Equipment
            </div>
          )
        }
        {
          // disable tab if there is no data to show
          props.data.report_light_data.length > 0 ? (
            <div
              className={`${navMenu}  ${
                activeTab === 6 ? "tabs active-tab" : "tabs"
              }`}
              onClick={() => [
                toggleTab(6),
                setNavMenu(navMenu === "" ? "nav_menu" : ""),
              ]}
            >
              Lights
            </div>
          ) : (
            <div className={`${navMenu} tabs disabled-tab`} onClick={null}>
              Lights
            </div>
          )
        }
        {
          // disable tab if there is no data to show
          props.data.report_plumbing_data.length > 0 ? (
            <div
              className={`${navMenu}  ${
                activeTab === 7 ? "tabs active-tab" : "tabs"
              }`}
              onClick={() => [
                toggleTab(7),
                setNavMenu(navMenu === "" ? "nav_menu" : ""),
              ]}
            >
              Plumbing
            </div>
          ) : (
            <div className={`${navMenu} tabs disabled-tab`} onClick={null}>
              Plumbing
            </div>
          )
        }
        <div
          id='report-last-tab'
          className={`${navMenu}  ${
            activeTab === 8 ? "tabs active-tab" : "tabs"
          } last-tab`}
          onClick={() => [
            toggleTab(8),
            setNavMenu(navMenu === "" ? "nav_menu" : ""),
          ]}
        >
          Contact&nbsp;&&nbsp;Terms
        </div>

        <div
          className='navbar-toogleBtn'
          onClick={() => {
            setNavMenu(navMenu === "" ? "nav_menu" : "")
          }}
        >
          <i className='fa-solid fa-bars'></i>
        </div>
      </ul>
      {activeTab === 2 ? (
        <ul id='tab-container' className='flex report-tabs  md:hidden'>
          <div id='sub-hamburger-header'>
            <a href='#utility-rates'>{subHamburgerTitle}</a>
          </div>
          <div className={`${subNavMenu} sub-hamberger-element tabs`}>
            <a href='#total-consumption-section'>Total Consumption</a>
          </div>
          <div className={`${subNavMenu} sub-hamberger-element tabs`}>
            <a href='#gas-consumption-section'>Gas Consumption Breakdown</a>
          </div>
          <div className={`${subNavMenu} sub-hamberger-element tabs`}>
            <a href='#electric-consumption-section'>
              Electric Consumption Breakdown
            </a>
          </div>
          <div
            className='navbar-toogleBtn2'
            onClick={() => {
              setSubNavMenu(subNavMenu === "" ? "sub_nav_menu" : "")
            }}
          >
            <i className='fa-solid fa-bars'></i>
          </div>
        </ul>
      ) : null}

      <div className='ecm-header report-header-mobile md:hidden'>
        <h3 className='report-title-text-mobile'>
          {props.data.report_summary_participant_table_data[1]} - Assessment
          Report
        </h3>
      </div>

      <div className='widget-header p-3 flex justify-end report-header-mobile md:hidden'>
        <PDFReport data={props.data} /> <div className='pr-2' />
        <XLSXReport data={props.data} />
      </div>

      <div className='tab-contents'>
        <div className={activeTab === 0 ? "content active-content" : "content"}>
          {/*Map object */}
          <div
            id='tab-content-container'
            className='flex bg-transparent w-full gap-5'
          >
            <div id='tab-contents-partifipant' className='w-1/2'>
              <VerticalTable
                title={"Participant"}
                data={props.data.report_summary_participant_table_data}
                headers={[
                  "Applicant name",
                  "Site name",
                  "Site Address",
                  "City",
                  "Province",
                  "Site contact name",
                  "Office address",
                  "Telephone",
                  "Email",
                ]}
              ></VerticalTable>
            </div>
            <div className='flex flex-col w-1/2'>
              <div className='widget-header w-full'>
                <h2 className='section_heading_2'>Building Location</h2>
              </div>
              <Map title={"Building Location"} address={participantAddress} />
            </div>
          </div>

          {props.data.report_gas_breakdown.length > 0 &&
          props.data.report_electrical_breakdown.length === 0 ? (
            // only show gas if there is no electricity data
            <div id='diagrams-piChart' className='mt-10 '>
              <div className='widget-header flex justify-between items-center'>
                <h2 className='section_heading_2'>
                  Gas Consumption Breakdowns
                </h2>
              </div>
              <div className='h-fit'>
                <div
                  id='diagram-container-1'
                  className='grid grid-cols-2 gap-4'
                >
                  <div className='flex w-full'>
                    <PieChart
                      widgetClassName='h-full w-full flex'
                      containerClassName='h-[32rem] bg-white w-full pt-10 px-5 custom-frame'
                      legendAlignment='start'
                      chartTitle='Gas Consumption Breakdown (GJ)'
                      labelAttribute='0' // labelAttribute="category"
                      dataAttribute='2' // dataAttribute="energy_gj_per_year"
                      dataset={props.data.report_gas_breakdown}
                    />
                  </div>
                  <div className='flex w-full'>
                    <BarChart
                      dataset={props.data.report_gas_breakdown}
                      title='Gas Consumption Breakdown (GJ)'
                      columns={["0", "2"]} // labelAttribute="category", dataAttribute="energy_gj_per_year"
                      label='Gas Consumption Breakdown (GJ)'
                      color='#ad4501'
                      yaxis='GJ/yr'
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {props.data.report_gas_breakdown.length === 0 &&
          props.data.report_electrical_breakdown.length > 0 ? (
            // only show electricity if there is no gas data
            <div id='diagrams-piChart' className='mt-10 '>
              <div className='widget-header flex justify-between items-center'>
                <h2 className='section_heading_2'>
                  Electricity Consumption Breakdowns
                </h2>
              </div>
              <div className='h-fit'>
                <div
                  id='diagram-container-1'
                  className='grid grid-cols-2 gap-4'
                >
                  <div className='flex w-full'>
                    <PieChart
                      widgetClassName='h-full w-full flex gap-5'
                      containerClassName=' h-[32rem] bg-white w-full pt-10 px-5 custom-frame'
                      legendAlignment='start'
                      chartTitle='Electricity Consumption Breakdown (kWh)'
                      labelAttribute='0' // labelAttribute="category"
                      dataAttribute='2' // dataAttribute="energy_kwh_per_year"
                      dataset={props.data.report_electrical_breakdown}
                    />
                  </div>
                  <div className='flex w-full'>
                    <BarChart
                      dataset={props.data.report_electrical_breakdown}
                      title='Electricity Consumption Breakdown (kWh)'
                      columns={["0", "2"]} // labelAttribute="category", dataAttribute="energy_kwh_per_year"
                      label='Electricity Consumption Breakdown (kWh)'
                      color='#6495ED'
                      yaxis='kWh/yr'
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {props.data.report_gas_breakdown.length > 0 &&
          props.data.report_electrical_breakdown.length > 0 ? (
            // show both gas and electricity if there is data for both
            <>
              <div
                id='diagrams-piChart'
                className='mt-10 '
                style={{ display: checked ? "none" : "block" }}
              >
                {/*-- When toggle-switch is OFF (default), show GHG Emissions and Utility Cost PIE charts. Otherwise, hide this section--*/}
                <div className='widget-header flex justify-between items-center'>
                  <h2 className='section_heading_2'>
                    Gas and Electricity Consumption Breakdowns
                  </h2>
                  <ReactSwitch checked={checked} onChange={handleChange} />
                </div>
                <div className='h-fit'>
                  <div
                    id='diagram-container-1'
                    className='grid grid-cols-2 gap-4'
                  >
                    <div className='flex h-fit w-full'>
                      <PieChart
                        widgetClassName='h-full w-full flex gap-5'
                        containerClassName='h-[32rem] bg-white w-full pt-10 px-5 custom-frame'
                        legendAlignment='start'
                        chartTitle='Gas Consumption Breakdown (GJ)'
                        labelAttribute='0' // labelAttribute="category"
                        dataAttribute='2' // dataAttribute="energy_gj_per_year"
                        dataset={props.data.report_gas_breakdown}
                      />
                    </div>
                    <div className='flex w-full'>
                      <PieChart
                        widgetClassName='h-full w-full flex gap-5'
                        containerClassName=' h-[32rem] bg-white w-full pt-10 px-5 custom-frame'
                        legendAlignment='start'
                        chartTitle='Electricity Consumption Breakdown (kWh)'
                        labelAttribute='0' // labelAttribute="category"
                        dataAttribute='2' // dataAttribute="energy_kwh_per_year"
                        dataset={props.data.report_electrical_breakdown}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                id='diagrams-barChart'
                className='mt-10'
                style={{ display: checked ? "block" : "none" }}
              >
                {/*-- When toggle-switch is ON, show GHG Emissions and Utility Cost BAR charts. Otherwise, hide this section. --*/}
                <div className='widget-header flex justify-between items-center'>
                  <h2 className='section_heading_2'>
                    Gas and Electricity Consumption Breakdowns
                  </h2>
                  <ReactSwitch checked={checked} onChange={handleChange} />
                </div>
                <div className='h-fit w-full gap-5'>
                  <div
                    id='diagram-container-2'
                    className='grid grid-cols-2 gap-4'
                  >
                    <div className='flex h-[34rem] w-full'>
                      <BarChart
                        dataset={props.data.report_gas_breakdown}
                        title='Annual Gas Consumption Breakdown (GJ/yr)'
                        columns={["0", "2"]} // labelAttribute="category", dataAttribute="energy_gj_per_year"
                        label='Gas Consumption Breakdown (GJ/yr)'
                        color='#ad4501'
                        yaxis='GJ/yr'
                      />
                    </div>
                    <div className='flex h-[34rem] w-full'>
                      <BarChart
                        dataset={props.data.report_electrical_breakdown}
                        title='Annual Electricity Consumption Breakdown (kWh/yr)'
                        columns={["0", "2"]} // labelAttribute="category", dataAttribute="energy_kwh_per_year"
                        label='Electricity Consumption Breakdown (kWh/yr)'
                        color='#6495ED'
                        yaxis='kWh/yr'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <br></br>
          <div className='mt-5'>
            <RecommendedEnergyTable
              data={props.data.report_summary_rec_energy_solutions_data}
              skipFirstCol={false}
              addLocale={true}
              title={"ECMs"}
              filename={"ECMs.csv"}
              headers={[
                "Description",
                "Lifetime GHG Reduction (Ton CO₂e/yr)",
                "Simple Payback (yrs)",
                "First Year Saving ($)",
                "ECM Capital Cost ($)",
                "Incentive ($)",
              ]}
            />
          </div>
          {/* <a href="#report-container" className={`${scrollTop >= 300 ? "go_top" : "go_top fade-out"}`}>
            <i className="fa-sharp fa-solid fa-arrow-up"></i>
          </a> */}
        </div>
        <div className={activeTab === 1 ? "content active-content" : "content"}>
          {/* <br /> */}
          <VerticalTable
            title={"Building Profile"}
            data={props.data.building_profile_data}
            headers={[
              "Project Number",
              "Facility Use",
              "Building Type",
              "Year of Construction",
              "Year of Last Remodel",
              "Number of Floors",
              "Total Floor Area (sqft)",
              "Envelope Condition",
              "Full Time Employees",
              "Number of Residents",
              "Number of Suites",
              "Occupancy",
              "Space Heating Primary Fuel",
              "Domestic Hot Water Primary Fuel",
              "Outdoor Air Ventilation Heated By",
              "Electric Meters for Individual Suites",
            ]}
          ></VerticalTable>
        </div>
        <div className={activeTab === 2 ? "content active-content" : "content"}>
          <div id='utility-rates' className='widget-header'>
            <h2 className='section_heading_2'>Utility Rates</h2>
          </div>
          <div className='bg-white custom-frame'>
            <ExpandedTablePanel
              data={updatedUtilityRateData}
              tableHeaders={["Category", "Electricity", "Gas", "Water & Sewer"]}
              categories={[
                "Provider",
                "Rate Schedule",
                "Energy Rate Before Taxes",
                "Demand Rate",
                "Carbon Intensity",
                "Current Carbon Tax",
                "Marginal Rate",
                "Total Blended Rate",
              ]}
              numColumns={3}
              panels={{
                "Electricity Rate ($/kWh)": extractCleanedRate(
                  props.data.report_utility_rate_data[0][7]
                ),
                "Gas Rate ($/GJ)": extractCleanedRate(
                  props.data.report_utility_rate_data[1][7]
                ),
                "Water & Sewer Rate ($/m³)": extractCleanedRate(
                  props.data.report_utility_rate_data[2][7]
                ),
              }} // Total blended rates
            />
          </div>
          <br />
          <div
            id='total-consumption-section'
            className='widget-header total-consumption'
          >
            <h2 className='section_heading_2'>Total Consumption</h2>
          </div>
          <div className='bg-white custom-frame'>
            <ExpandedTablePanel
              data={updatedTotalConsumption}
              tableHeaders={["Category", "Electricity", "Gas", "Totals"]}
              categories={[
                "Total Consumption",
                "Suite Usage",
                "Total Cost ($/yr)",
                "Proportion of Cost (%)",
                "GHG Emissions (Ton CO₂e)",
                "EUI (GJ/m²)",
                "EUI (GJ/Occupant)",
                "Total Consumption ($/m²)",
              ]}
              numColumns={4}
              panels={{
                "GHG Emissions (Ton CO₂e/yr)": `${GHGEmissions}`,
                "Annual Electricity Cost ($/yr)": `${props.data.report_total_consumption_data[0][2]}`, // Total Cost/Year
                "Annual Gas Cost ($/yr)": `${props.data.report_total_consumption_data[1][2]}`,
                "Annual Total Utility Cost ($)": `${props.data.report_total_consumption_data[2][2]}`,
                "GHG Intensity (Ton CO₂e/(m².yr))": `${GHGI}`,
                "Electricity EUI (GJ/m²)":
                  props.data.report_total_consumption_data[0][5], // EUI
                "Gas EUI (GJ/m²)":
                  props.data.report_total_consumption_data[1][5],
                "Total EUI (GJ/m²)":
                  props.data.report_total_consumption_data[2][5],
              }}
            />
          </div>
          <br></br>

          {props.data.complete_gas_usage.length > 0 ||
          props.data.report_gas_breakdown.length > 0 ||
          props.data.report_historical_gas.length > 0 ? (
            <>
              <div id='gas-consumption-section' className='widget-header'>
                <h2 className='section_heading_2'>Gas Consumption Breakdown</h2>
              </div>
              <div>
                <div
                  id='gas-piechart-container'
                  className='grid grid-cols-3 gap-5 min-h-[30rem]'
                >
                  {props.data.complete_gas_usage.length > 0 ? (
                    <div>
                      <PieChart
                        widgetClassName='h-full w-full flex gap-5'
                        containerClassName='h-full w-full p-10 bg-white custom-frame'
                        legendAlignment='start'
                        chartTitle='Cost Distribution'
                        labelAttribute='0' // labelAttribute="category"
                        dataAttribute='2' // dataAttribute="energy_gj_per_year"
                        dataset={props.data.complete_gas_usage}
                      />
                    </div>
                  ) : null}
                  {props.data.report_gas_breakdown.length > 0 ? (
                    <div className='col-span-2'>
                      <DistributionTable
                        data={props.data.report_gas_breakdown}
                        skipFirstCol={false}
                        addLocale={true}
                        title={"Consumption Profile"}
                        filename={"gas_distribution.csv"}
                        headers={[
                          "Category",
                          "Consumption Proportion (%)",
                          "Consumption (GJ/yr)",
                          "Cost ($/yr)",
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                {props.data.report_historical_gas.length > 0 ? (
                  <>
                    <div className='mt-5'>
                      <div className='widget-header'>
                        <h2 className='section_heading_2'>
                          Historical Gas Consumption
                        </h2>
                      </div>
                      <div className='bg-white custom-frame'>
                        <StackedHistogram
                          title='Gas Consumption Over Time'
                          line_color='rgba(46, 131, 255, 0.8)'
                          bar_color='rgba(99, 255, 77, 0.7)'
                          y_label='Gas Consumption (GJ)'
                          legend_label='Gas Consumption per Month'
                          data={props.data.report_historical_gas}
                        ></StackedHistogram>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : null}
          <br></br>
          {props.data.complete_electricity_usage.length > 0 ||
          props.data.report_electrical_breakdown.length > 0 ||
          props.data.report_historical_electric.length > 0 ? (
            <>
              {/* hide when data.complete_electrcity_usage ||
                data.report_electrical_breakdown ||
                data.historical_electric is empty */}

              <div id='electric-consumption-section' className='widget-header'>
                <h2 className='section_heading_2'>
                  Electric Consumption Breakdown
                </h2>
              </div>
              <div>
                {props.data.complete_electricity_usage.length > 0 &&
                props.data.report_electrical_breakdown.length > 0 ? (
                  <div
                    id='electric-piechart-container'
                    className='grid grid-cols-3 gap-5 min-h-[30rem]'
                  >
                    <div>
                      <PieChart
                        widgetClassName='h-full w-full flex gap-5'
                        containerClassName='h-full w-full p-10 bg-white custom-frame'
                        legendAlignment='start'
                        chartTitle='Cost Distribution'
                        labelAttribute='0' // labelAttribute="category"
                        dataAttribute='2' // dataAttribute="energy_gj_per_year"
                        dataset={props.data.complete_electricity_usage}
                      />
                    </div>
                    <div className='col-span-2'>
                      <DistributionTable
                        data={props.data.report_electrical_breakdown}
                        skipFirstCol={false}
                        addLocale={true}
                        title={"Consumption Profile"}
                        filename={"electric_distribution.csv"}
                        headers={[
                          "Category",
                          "Consumption Proportion (%)",
                          "Consumption (kWh/yr)",
                          "Cost ($/yr)",
                        ]}
                      />
                    </div>
                  </div>
                ) : null}
                {props.data.report_historical_electric.length > 0 ? (
                  <>
                    <div className='mt-5'>
                      <div className='widget-header'>
                        <h2 className='section_heading_2'>
                          Historical Electrical Consumption
                        </h2>
                      </div>
                      <div className='bg-white custom-frame'>
                        <StackedHistogram
                          title='Electric Consumption Over Time'
                          line_color='rgba(54, 215, 255, 0.8)'
                          bar_color='rgba(182, 54, 255, 0.8)'
                          y_label='Electrical Consumption (kWh)'
                          legend_label='Electrical Consumption per Month'
                          data={props.data.report_historical_electric}
                        ></StackedHistogram>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : null}
          {/* <a href="#report-container" className={`${scrollTop >= 300 ? "go_top" : "go_top fade-out"}`}>
            <i className="fa-sharp fa-solid fa-arrow-up"></i>
          </a> */}
        </div>
        <div className={activeTab === 3 ? "content active-content" : "content"}>
          <div className='mb-6'>
            <UpgradesRecEnergyTable
              data={reportUpgradesRecEnergySolutionsData}
              title={"ECMs"}
              addLocale={true}
              skipFirstCol={false}
              filename={"ECMs_rec_energy.csv"}
              projectNumber={props.data.building_profile_data[0]}
              siteName={props.data.report_summary_participant_table_data[1]}
              onDataChange={onReportUpgradesRecEnergySolutionsDataChange}
            />
          </div>
          {/* <div className='mt-15 '>
            <div className='widget'>
              <div className='widget-header'>
                <h2>Resource Hub</h2>
              </div>
              <div className='mb-3'>
                <Carousel>
                  <CarouselItem>
                    <iframe
                      width='100%'
                      height='510'
                      src='https://www.youtube.com/embed/Xnl26qh5RXc'
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowFullScreen={true}
                    ></iframe>
                  </CarouselItem>
                  <CarouselItem>
                    <iframe
                      width='100%'
                      height='510'
                      src='https://www.youtube.com/embed/FNcJyTqUC4U'
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowFullScreen={true}
                    ></iframe>
                  </CarouselItem>
                  <CarouselItem>
                    <iframe
                      width='100%'
                      height='510'
                      src='https://www.youtube.com/embed/iQaycSD5GWE'
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowFullScreen={true}
                    ></iframe>
                  </CarouselItem>
                  <CarouselItem>
                    <iframe
                      width='100%'
                      height='510'
                      src='https://www.youtube.com/embed/TtcUQel6c64'
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowFullScreen={true}
                    ></iframe>
                  </CarouselItem>
                  <CarouselItem>
                    <iframe
                      width='100%'
                      height='510'
                      src='https://www.youtube.com/embed/4CeFuBDvWxc'
                      title='YouTube video player'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                      allowFullScreen={true}
                    ></iframe>
                  </CarouselItem>
                </Carousel>
              </div>
            </div>
          </div> */}
        </div>
        <div className={activeTab === 4 ? "content active-content" : "content"}>
          <EnvelopeTab
            data={props.data.report_envelope_data}
            images={props.data.report_images}
          />
        </div>
        <div className={activeTab === 5 ? "content active-content" : "content"}>
          <EquipmentTable
            data={reportEquipmentData}
            skipFirstCol={true}
            isEditable={true}
            id_col={true}
            project_id={props.data.building_profile_data[0]}
            addButton={AddEquipmentButton}
            onAdded={onAddedEquipment}
            deleteButton={DeleteEquipmentButton}
            onDeleted={onDeletedEquipment}
            title={"Equipment"}
            filename={
              window.location.pathname.substring(8) + "_equipment_details.csv"
            }
            headers={[
              "Equipment Type",
              "Quantity",
              "Year of Equipment",
              "Make and Model",
              "Identifier Tag",
              "End Use",
              "Area Served",
              "Fuel",
              "Electrical Power Rating",
              "Gas Power Rating",
              "Unit",
            ]}
          />
        </div>
        <div className={activeTab === 6 ? "content active-content" : "content"}>
          <LightTable
            data={props.data.report_light_data}
            skipFirstCol={false}
            addLocale={true}
            title={"Lights"}
            filename={"lights.csv"}
            headers={[
              "Space Description",
              "Area Served",
              "Technology",
              "Number of Fixtures",
              "Lamp Per Fixture",
              "W Per Lamp",
            ]}
          />
        </div>
        <div className={activeTab === 7 ? "content active-content" : "content"}>
          <PlumbingTable
            data={props.data.report_plumbing_data}
            skipFirstCol={false}
            addLocale={true}
            title={"Plumbing"}
            filename={
              window.location.pathname.substring(8) + "_plumbing_details.csv"
            }
            headers={[
              "Fixtures",
              "Quantity",
              "Existing (LPM/LPF)",
              "Proposed (LPM/LPF)",
              "Anuual Water Saving (m³/yr)",
              "Annual Cost Saving ($/yr)",
            ]}
          />
        </div>
        <div className={activeTab === 8 ? "content active-content" : "content"}>
          <VerticalTable
            title={"Engineer Responsible"}
            data={props.data.report_consultant_data[0]}
            headers={[
              "Project Number",
              "Consultant Name",
              "Study Author(s)",
              "Responsible Individual",
              "Office Address",
              "Date of Site Visit",
              "Telephone",
              "Email",
            ]}
          ></VerticalTable>
          <br></br>
          <div className='widget-header'>
            <h2 className='section_heading_2'>Terms & Conditions</h2>
          </div>

          <div className='pb-3 custom-frame bg-white'>
            <h1 className='contactHeader'>
              Assumptions made in calculating saving
            </h1>
            <ul className='contactList'>
              <li>Electricity rate increase at 2.5% annually. </li>
              <li>Gas rate increase at 3.2% annually. </li>
              <li>General inflation is about 2% annually. </li>
              <li>Discount rate is assumed to be 6.1%. </li>
              <li>
                Carbon tax escalation in accordance with federal 2018 Greenhouse
                Gas Pricing Act.
              </li>
              <li>
                Proposed ECM and mechanical life spans are set based on the “DSM
                STANDARD, Effective Measure Life and Persistence - Revision 11,
                For External and Internal Use, March 2019” study done by BC
                Hydro.
              </li>
              <li>
                Cost assumptions for systems and equipment ECMs reflect industry
                commercial rates for material and labour.
              </li>
            </ul>
            <h1 className='contactHeader'>Notes</h1>
            <ul className='contactList'>
              <li>
                Figures have been rounded and as a result the totals may not
                reflect the sum or each item.
              </li>
              <li>
                Figures provided are estimated; costs and savings are not
                guaranteed.
              </li>
              <li>
                The intent of estimates, given the time and budget constraints
                of this project, is to provide enough information with a level
                of accuracy that will enable informed decisions about energy
                cost reduction opportunities.
              </li>
              <li>
                Considerations other than estimated costs and savings may be
                relevant; contact SISA Energy Ltd to discuss the various
                options.
              </li>
              <li>
                SISA Energy does not advocate for any particular building
                upgrade option or contractor. If a participant is interested in
                pursuing a building upgrade SISA Energy can provide
                implementation support for major measures, but the owner is
                responsible for deciding whether/how to move forward with a
                building upgrade.
              </li>
              <li>
                SISA Energy has not designed any of the ECMs being considered.
                If a participant moves forward with a building upgrade it is the
                responsibility of each contractor carrying out the ECMs to
                ensure that the work meets all requirements (e.g. code
                restrictions, manufacturer specifications).
              </li>
              <li>
                SISA Energy does not make any representations or warranties
                regarding any of the upgrades considered and is not liable for
                any losses, should any occur, as a result of any upgrades that
                are implemented.
              </li>
              <li>
                Not all factors may be incorporated into the analysis presented
                above; the participant should do their due diligence before
                making any decisions.
              </li>
            </ul>
          </div>
        </div>
        <a
          href='#report-container'
          className={`${scrollTop >= 300 ? "go_top" : "go_top fade-out"}`}
        >
          <i className='fa-sharp fa-solid fa-arrow-up'></i>
        </a>
      </div>
    </div>
  )
}

export default ReportTabs
