import { useState, useEffect, useContext } from "react"
import "../../../../styles/addProperty/InputContainer.css"
import {
  DefaultDraftContext,
  UserDraftContext,
} from "../../context/DraftContext"
import Input from "../../../global/components/input/Input"
import Select from "../../../global/components/select/Select"
import provincesAndCities from "../../../global/provincesAndCities.json"
import "../../style/dataCollection.css"
import Button from "../../../global/components/button/Button"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"
import { BasicInfoLabels } from "./BasicInfoOptions"

function BasicPropertyInformation({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
}) {
  const [programOptionsList, setProgramOptionsList] = useState([])
  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)
  const { siteInputOptions } = useContext(DefaultDraftContext)
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const [cities, setCities] = useState([])
  const [provinces, setProvinces] = useState([])
  // load initial data / loaded draft
  const [inputs, setInputs] = useState({})

  useEffect(() => {
    let provinces = Object.keys(provincesAndCities)
    provinces.sort()
    setProvinces(provinces)

    let allCities = Object.values(provincesAndCities)
    let allCitiesList = []
    allCities.forEach((cities) => {
      allCitiesList = [...allCitiesList, ...cities]
    })
    allCitiesList.sort()
    setCities(allCitiesList)
  }, [])

  useEffect(() => {
    if (inputs.state_province) {
      let citiesByProvince = provincesAndCities[inputs.state_province]
      citiesByProvince.sort()
      setCities(citiesByProvince)
    }
  }, [inputs.state_province])

  useEffect(() => {
    if (userDraftState) {
      setInputs(userDraftState.basic_information)
    }
  }, [userDraftState])

  useEffect(() => {
    let program_option = siteInputOptions.filter(
      (option) => option.field_name === "Program"
    )
    if (program_option.length > 0) {
      let program_option_data = { ...program_option[0] }.options
      if (Array.isArray(program_option_data)) {
        setProgramOptionsList(program_option_data)
      }
    }
  }, [siteInputOptions])

  const handleInputChange = (newValue) => {
    let new_inputs = { ...inputs, [newValue.name]: newValue.value }
    userDraftDispatch({
      type: "basic_information_save",
      payload: new_inputs,
    })
  }

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isEmpty ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation(null)
    }
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>Basic Information</p>
        <Button
          type='button'
          className='button__large button__large--gray complete-button-upper'
          buttonText='Check this tab'
          disabled={isButtonDisabled === null}
          onClick={handleValidation}
        />
      </div>
      <div className='form__content'>
        <InstructionalText
          type='Instructional Text'
          content='Manually enter the basic details of your property, including a name that you will use to look up your property and its address.'
          additionalContent={[
            "The name you choose for your property does not have to be unique. But, it may make it easier for you to work with properties in your portfolio if you do not use the same (or similar) names.",
          ]}
          containerClassName='basicInfo-instruction__container'
        />
        <div className='base-information-form__inputs'>
          <div id='site-name'>
            <Input
              label={BasicInfoLabels.name_label}
              type='text'
              name='name'
              onChange={handleInputChange}
              value={inputs.name}
              placeholder='Enter site name'
              className='default'
              required
              validation={onValidation}
              set_validation_object={setValidationObject}
            />
          </div>
          <div id='street-address'>
            <Input
              label={BasicInfoLabels.street_address_label}
              type='text'
              name='street_address'
              onChange={handleInputChange}
              value={inputs.street_address}
              placeholder='Enter street address'
              className='default'
            />
          </div>
          <div id='city'>
            <Select
              label={BasicInfoLabels.city_municipality_label}
              name='city_municipality'
              options={cities}
              onChange={handleInputChange}
              value={inputs.city_municipality}
              placeholder='Select City'
              className='default'
              required
              validation={onValidation}
              set_validation_object={setValidationObject}
            />
          </div>
          <div id='province'>
            <Select
              label={BasicInfoLabels.state_province_label}
              name='state_province'
              options={provinces}
              onChange={handleInputChange}
              value={inputs.state_province}
              placeholder='Select Province'
              className='default'
            />
          </div>
          <div id='county'>
            <Input
              label={BasicInfoLabels.county_label}
              type='text'
              name='county'
              onChange={handleInputChange}
              value={inputs.county}
              placeholder='Enter county or district'
              className='default'
            />
          </div>
          <div id='postal-code'>
            <Input
              label={BasicInfoLabels.postal_code_label}
              type='text'
              name='postal_code'
              onChange={handleInputChange}
              value={inputs.postal_code}
              placeholder='Enter postal code'
              className='default'
            />
          </div>
          <div id='owner-organization'>
            <Input
              label={BasicInfoLabels.owner_organization_label}
              type='text'
              name='owner_organization'
              onChange={handleInputChange}
              value={inputs.owner_organization}
              placeholder='Enter owner or organization name'
              className='default'
              required
              validation={onValidation}
              set_validation_object={setValidationObject}
            />
          </div>
          <div id='office-address'>
            <Input
              label={BasicInfoLabels.office_address_label}
              type='text'
              name='office_address'
              onChange={handleInputChange}
              value={inputs.office_address}
              placeholder='Enter office address'
              className='default'
            />
          </div>
          <div id='applicant-name'>
            <Input
              label={BasicInfoLabels.applicant_name_label}
              type='text'
              name='applicant_name'
              onChange={handleInputChange}
              value={inputs.applicant_name}
              placeholder='Enter applicant name'
              className='default'
            />
          </div>
          <div id='telephone'>
            <Input
              label={BasicInfoLabels.telephone_label}
              type='text'
              name='telephone'
              onChange={handleInputChange}
              value={inputs.telephone}
              placeholder='Enter a valid telephone number'
              className='default'
            />
          </div>
          <hr id='horizontal-light' />
          <div id='program-job-number'>
            <Input
              label={BasicInfoLabels.program_job_no_label}
              type='number'
              name='program_job_no'
              onChange={handleInputChange}
              value={inputs.program_job_no}
              placeholder='Enter program job number'
              className='default'
            />
          </div>
          <div id='program-select'>
            <Select
              label={BasicInfoLabels.program_label}
              options={programOptionsList}
              value={inputs.program}
              name='program'
              onChange={handleInputChange}
              placeholder='Select Program Type'
              className='default'
              addNewOption={true}
            />
          </div>
          <div id='site-contact-name'>
            <Input
              label={BasicInfoLabels.site_contact_name_label}
              type='text'
              name='site_contact_name'
              onChange={handleInputChange}
              value={inputs.site_contact_name}
              placeholder='Enter Site Contact Name'
              className='default'
            />
          </div>
          <div id='site-auditors'>
            <Input
              label={BasicInfoLabels.site_auditors_label}
              type='text'
              name='site_auditors'
              onChange={handleInputChange}
              value={inputs.site_auditors}
              placeholder='Enter auditor(s) name(s)'
              className='default'
            />
          </div>
          <div id='site-visit-date'>
            <Input
              label={BasicInfoLabels.site_visit_date_label}
              type='date'
              name='site_visit_date'
              onChange={handleInputChange}
              value={inputs.site_visit_date}
              placeholder='Select Date'
              className='default'
            />
          </div>
          <div id='responsible-project-engineer'>
            <Input
              label={BasicInfoLabels.responsible_project_engineer_label}
              type='text'
              name='responsible_project_engineer'
              onChange={handleInputChange}
              value={inputs.responsible_project_engineer}
              placeholder='Enter resposible project engineer name'
              className='default'
            />
          </div>
          <div id='email-of-responsible-engineer'>
            <Input
              label={BasicInfoLabels.email_of_responsible_engineer_label}
              type='email'
              name='email_of_responsible_engineer'
              onChange={handleInputChange}
              value={inputs.email_of_responsible_engineer}
              placeholder="Enter responsible engineer's email address"
              className='default'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicPropertyInformation
