import { LinearProgress } from "@mui/material"
import "../../../styles/importProperty/UserStorageSpace.css"

function UserStorageSpace({ total_storage, used_storage }) {
  const availabeStorage = Number(total_storage - used_storage)
  const usedStorage = Number(used_storage)

  return (
    <div className='user-storage-container'>
      <div className='user-storage-title'>File Storage Space</div>
      <div className='user-storage-progress-container'>
        <LinearProgress
          variant='determinate'
          value={(used_storage / total_storage) * 100}
          className='user-storage-progress-bar'
        />
        <div className='user-storage-progress-text'>
          <div className='user-storage-progress-text-used'>
            <span>Space Used: </span> {usedStorage && usedStorage?.toFixed(2)}{" "}
            MB
          </div>
          <div className='user-storage-progress-text-available'>
            <span>Space Left: </span>{" "}
            {availabeStorage && availabeStorage?.toFixed(2)} MB
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserStorageSpace
