import React from "react"
import "../../styles/profile.css"
import { useState, useEffect } from "react"
const TabNavItem = ({ id, item, title, activeTab, setActiveTab, setActiveSidebarTab, activeSidebarTab }) => {

  const handleClick = () => {
    setActiveTab(id);
    setActiveSidebarTab(id);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (
        !e.target.closest(".account-sidebar__icon--active") &&
        activeSidebarTab === id
      ) {
        setActiveSidebarTab(""); 
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [id, activeSidebarTab, setActiveSidebarTab]);

  return (
    <button
      onClick={handleClick}
      data-testid='tabItem'
      className={`account-sidebar__icon ${activeTab === id ? "active" : ""} ${activeSidebarTab === id ? "account-sidebar__icon--active" : ""}`}
    >
      <div className='flex' data-testid='tabIcon'>
        {item}
      </div>
      <div data-testid='tabName'>{title}</div>
    </button>
)
}
export default TabNavItem
