import React from 'react';

export default function ConfirmationPopup({
  fileName,
  handleClosePopup,
  successMessage,
  failureMessage,
  serverErrorMessage,
}) {
  return (
    <div className='popup-container'>
      <div className='popup-body'>
        <p className='popup-title'>{fileName}</p>
        {successMessage && !failureMessage && !serverErrorMessage && (
          <p>File uploaded successfully! </p>
        )}
        {failureMessage && !serverErrorMessage && <p>File uploaded failed!</p>}
        {serverErrorMessage && <p>{serverErrorMessage}</p>}
        <p>Read the output for more details.</p>
        <button className='popup-button' onClick={handleClosePopup}>
          OK!
        </button>
      </div>
    </div>
  );
}
