import sofiacLogo from "../../../Sofiac.svg"
import cmhcLogo from "../../../CMHC.png"
import IncentivesStyle from "../../../styles/analysisTools/incentives.module.css"

export const Sofiac = {
  name: "sofiac",
  heading: "Eligibility Requirements for SOFIAC's Funding Program",
  logo: sofiacLogo,
  title: (
    <p>
      <a
        href='https://sofiac.ca/our-solution/'
        target='_blank'
        rel='noreferrer'
        className={IncentivesStyle.title}
      >
        SOFIAC Funding Decarbonization Efforts
      </a>
    </p>
  ),
  intro:
    "SOFIAC helps companies reduce their energy costs and achieve ambitious GHG emission reduction targets outlined in their environmental, social, and governance (ESG) strategy without requiring any financial contribution.",
  approachHeader: "The SOFIAC approach is:",
  approachDetails: [
    "Based on performance.",
    "Requires NO investment from clients.",
    "Project costs are reimbursed solely through a portion of generated savings.",
  ],
  approachNotes: [
    "SOFIAC clients benefit from a positive cash flow as of the first day in addition to numerous other financial benefits. For more information visit Considerable Financial Advantages.",
  ],
  eligibilityHeader:
    "Your decarbonization plan meets the following eligibility requirements:",
  eligibilityDetails: [
    "GHG Reduction ",
    "Minimum Utility Consumption",
    "Minimum Capital Costs",
  ],
  annotate1:
    "This material is provided for informational purposes and provides product highlights only. SISA Energy does not guarantee or warrant that the product highlights are complete, adequate or up-to-date.",
  annotate2: (
    <p>
      For more information visit:{" "}
      <a
        href='https://sofiac.ca/our-solution/'
        target='_blank'
        rel='noreferrer'
        className={IncentivesStyle.annotateLink}
      >
        SOFIAC: An investment solution with many advantages
      </a>
    </p>
  ),
}

export const CMHC = {
  name: "cmhc",
  heading: "Eligibility Requirements for CMHC MLI Select Program",
  logo: cmhcLogo,
  title: (
    <p>
      <a
        href='https://assets.cmhc-schl.gc.ca/sites/cmhc/professional/project-funding-and-mortgage-financing/mortgage-loan-insurance/multi-unit-insurance/mliselect/mli-select.pdf'
        target='_blank'
        rel='noreferrer'
        className={IncentivesStyle.title}
      >
        CMHC MLI Select: Improved Mortgage loan insurance for market rental
        housing
      </a>
    </p>
  ),

  intro:
    "An innovative new multi-unit mortgage loan insurance product focused on affordability, accessibility, and energy efficiency.",
  approachHeader: "MLI Select offers",
  approachDetails: [
    "Higher loan-to-value ratios",
    "Increased amortizations",
    "Lower debt coverage ratios",
    "Reduced premiums",
  ],
  approachNotes: [
    "Borrowers can commit to any combination of the affordability, accessibility, and energy efficiency.",
    "A minimum of 50 points is needed to qualify for MLI Select.",
  ],
  eligibilityHeader:
    "Available Energy Efficiency Points for Existing Properties",
  eligibilityDetails: [
    "Level 1 (30 points) – Min. 15% decrease over current baseline levels",
    "Level 2 (50 points) – Min. 25% decrease over current baseline levels",
    "Level 3 (100 points) – Min. 40% decrease over current baseline levels",
  ],
  //   eligibilityResult: "No available data",
  annotate1:
    "This material is provided for informational purposes and provides product highlights only.  SISA Energy does not guarantee or warrant that the product highlights are complete, adequate or up-to-date.",
  annotate2: (
    <p>
      For more information, please visit{" "}
      <a
        href='https://assets.cmhc-schl.gc.ca/sites/cmhc/professional/project-funding-and-mortgage-financing/mortgage-loan-insurance/multi-unit-insurance/mliselect/mli-select.pdf'
        target='_blank'
        rel='noreferrer'
        className={IncentivesStyle.annotateLink}
      >
        CMHC MLI Select
      </a>
    </p>
  ),
}
