import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import UsersTable from "./UsersTable"
import LoadingSpinner from "../../../global/LoadingSpinner"


function UsersRegisteredPage(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [usersList, setUsersList] = useState([])

  const navigate = useNavigate()
  const header_names = [
    "account_number",
    "first_name",
    "last_name",
    "email",
    "user_role",
    "projects",
    "delete",
  ]

  // get all users, build list with all users
  useEffect(() => {
    setIsLoading(true)
    if (props.users_list) {
      setUsersList(props.users_list)
      setIsLoading(false)
    }
  }, [props.users_list])

  function onClickManageProjects(data) {
    navigate("/users/manage-users/user-projects", {
      state: { user_data: data },
    })
  }

  // function handleOnDeleteUser(email) {
  //   setIsLoading(true)
  //   deleteUserOnAdmin(email)
  //     .then(async (res) => {
  //       console.log("delete_user", res)
  //       props.clearCacheData()
  //       console.log("props.clearCacheData22222", props.clearCacheData())
  //       console.log("props.getAllUsersData33333", props.getAllUsersData())
  //       await props.getAllUsersData()
  //       // setUsersList((prev) => {
  //       //   return prev.filter((user) => user.email !== email)
  //       // })

  //       // props.get_user_data()
  //     })
  //     .catch((err) => {
  //       console.error(err)
  //     })
  // }

  return (
    <div className='bg-white flex flex-col rounded drop-shadow-md p-5 h-fit'>
      <div className='bg-sisa-header w-full flex justify-between'>
        <p className='text-xl text-white p-2 m-2'>Registered Users</p>
        <button className='button-action-white' onClick={() => navigate(-1)}>
          back
        </button>
      </div>
      <div className='h-full flex flex-col gap-10'>
        <div className='bg-white flex flex-col drop-shadow-md p-5'>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <UsersTable
              list_of_data={usersList}
              action_function={onClickManageProjects}
              delete_function={props.handleOnDeleteUser}
              header_names={header_names}
              get_user_data={props.get_user_data}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default UsersRegisteredPage
