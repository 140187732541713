import { useEffect, useState, useContext } from "react"
import { UserDraftContext } from "../../context/DraftContext"
import "../../style/equipmentsPageLayout.css"
import Button from "../../../global/components/button/Button"
import CheckBox from "../../../global/components/CheckBox/CheckBox"
import Table from "../../../global/components/Table/Table"
import { dateTimeFormatter } from "../../../../utils/DataFormatter"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"

function PropertyEquipments({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
  handleShowEquipmentForm,
}) {
  const savedEquipmentsHeadersArray = [
    {
      header_name: "Equipment Name",
      header_key: "equipment_name",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Date Created",
      header_key: "date_created",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "md",
    },
    {
      header_name: "Edit",
      header_key: "edit",
      is_required: false,
      is_sortable: false,
      sort_order: null,
      cell_width: "sm",
    },
    // {
    //   header_name: "Download",
    //   header_key: "download",
    //   is_required: false,
    //   is_sortable: false,
    //   sort_order: null,
    //   cell_width: "sm",
    // },
    {
      header_name: "Delete",
      header_key: "delete_row",
      is_required: false,
      is_sortable: false,
      sort_order: null,
    },
  ]

  const [isAddEquipmentEnabled, setIsAddEquipmentEnabled] = useState(true)
  const [selectedEquipmentIndex, setSelectedEquipmentIndex] = useState(null)

  const convert_to_equipments_data = (equipments) => {
    return equipments.map((equipment, index) => {
      return {
        row_data: {
          identifier: equipment.identifier,
          equipment_name: equipment.name,
          date_created: dateTimeFormatter(new Date(equipment.created_date)),
          edit: (
            <span
              className='flex draft-edit-button'
              onClick={() => editEquipment(index)}
            >
              <span className='material-symbols-outlined pr-2'>Edit</span>Edit
            </span>
          ),
          // download: (
          //   <span
          //     className='flex draft-edit-button'
          //     onClick={() => downloadEquipment(equipment)}
          //   >
          //     <span className='material-symbols-outlined pr-2'>Download</span>
          //     Download
          //   </span>
          // ),
        },
        is_selected: false,
      }
    })
  }

  const editEquipment = (index) => {
    setSelectedEquipmentIndex(index)
    setIsAddEquipment(false)
    setIsEditEquipment(true)
  }

  const removeEquipment = (index) => {
    userDraftDispatch({
      type: "property_equipments_delete_equipment",
      row_index: index,
    })
  }

  const downloadEquipment = (equipment) => {
    alert("Download unavailable at this time.")
  }

  // property equipments list
  const [propertyEquipments, setPropertyEquipments] = useState([])

  // global state
  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)

  useEffect(() => {
    if (userDraftState.property_equipments) {
      setPropertyEquipments(
        convert_to_equipments_data(userDraftState.property_equipments)
      )
    }

    if (
      userDraftState.property_equipments_ack.isNoEquipmentInformation ===
        true ||
      userDraftState.property_equipments_ack.isOnlyUploadDocuments === true
    ) {
      setIsAddEquipmentEnabled(false)
    } else {
      setIsAddEquipmentEnabled(true)
    }
  }, [userDraftState])

  const [isAddEquipment, setIsAddEquipment] = useState(false)
  const [isEditEquipment, setIsEditEquipment] = useState(false)

  useEffect(() => {
    if (isAddEquipment) {
      handleShowEquipmentForm("new")
    }
  }, [isAddEquipment])

  useEffect(() => {
    if (isEditEquipment) {
      handleShowEquipmentForm(selectedEquipmentIndex)
    }
  }, [isEditEquipment])

  function handleCheckedBoxChange(newValue) {
    userDraftDispatch({
      type: "property_equipments_save_ack",
      payload: { [newValue.name]: newValue.checked },
    })
  }

  // Form Validation
  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})

  const hasRequiredFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isEmpty = hasRequiredFields()

    if (!isEmpty) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isEmpty ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation(null)
    }
  }

  return (
    <div id='equipments-landing-page' className='form__container'>
      <div className='form__container'>
        <div className='form-title-button__container'>
          <p className='form__title'>Equipments</p>
          <Button
            type='button'
            className='button__large button__large--gray complete-button-upper'
            buttonText='Check this tab'
            // disabled={isButtonDisabled === null}
            onClick={handleValidation}
          />
        </div>
        <div className='form__content'>
          <InstructionalText
            type='Main Instructions'
            title='Add New Equipment'
            content={
              <>
                In this section, you can provide information about the main
                mechanical and electrical equipment within the building, such as
                boilers, rooftop and makeup air units, fans, and pumps. <br />
                Feel free to skip this section if you lack the information, or
                if you intend to upload documents containing the required
                information.
              </>
            }
            containerClassName='main-instructions'
          />
          <div className='form__description'>
            <CheckBox
              label='I do not have the equipment information.'
              name='isNoEquipmentInformation'
              checked={
                userDraftState.property_equipments_ack.isNoEquipmentInformation
              }
              onChange={handleCheckedBoxChange}
            />
          </div>
          <div className='form__description'>
            <CheckBox
              label='I will ONLY upload documents containing the required equipment information.'
              name='isOnlyUploadDocuments'
              checked={
                userDraftState.property_equipments_ack.isOnlyUploadDocuments
              }
              onChange={handleCheckedBoxChange}
            />
          </div>
          <div className='form__buttons__right'>
            <Button
              type='button'
              buttonText='Add equipment manually'
              className={"button__large button__large--primary"}
              onClick={() => {
                setIsAddEquipment(true)
                setIsEditEquipment(false)
              }}
              disabled={!isAddEquipmentEnabled}
            />
          </div>
        </div>
      </div>
      <div className='form__container'>
        <div className='form__content'>
          <InstructionalText
            type='Main Instructions'
            title='My Equipments'
            content={
              <p>
                You can review and edit your previous equipment entries below.
              </p>
            }
            containerClassName='main-instructions'
          />
          <div className='form__description'>
            <Table
              headers_array={savedEquipmentsHeadersArray}
              data_array={propertyEquipments}
              row_key='identifier'
              table_entity_name={"Equipment"}
              no_data_message={
                "There are no equipment entries, please add a new equipment."
              }
              fn_delete_row={removeEquipment}
              table_disabled={!isAddEquipmentEnabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default PropertyEquipments
