import { useState, useEffect, useRef } from "react"
import Select from "react-select"
import ECMTable from "../components/ECMTable"
import {
  parseIntAndFormat,
  parseFloatAndFormat,
} from "../../../utils/DataFormatter.js"
import "../../../styles/analysisTools/multi-filter.css"
import ProgressBar from "../components/ProgressBar"

function Filter(props) {
  // filter the passed in list of objects by the column and value
  // 1. filter form, buttons, layout
  // 2. filter function
  // 3. collapsible additional input fields
  // 4. result table
  // 5. add to bundle button / function

  // [ "site_name_1", "site_name_2", ... ]
  const [siteNameList, setSiteNameList] = useState([])

  // default slider position / update value on slider change
  const [ROIValue, setROIValue] = useState(0)

  // table data to be passed into ECM Table
  const [TableData, setTableData] = useState([])

  // // trigger for displaying the search results
  // const [searchClicked, setSearchClicked] = useState(false)

  // used to filter out ECMs that are selected
  // { <ecm_identifier>: <boolean> }
  const [selectedECMs, setSelectedECMs] = useState({})

  const form_ref = useRef(null)
  const select_ref = useRef(null)
  const ecm_analysis_table = props.data.ecm_analysis_table

  useEffect(() => {
    if (props.data) {
      let select_options = []
      let project_numbers = []
      ecm_analysis_table.forEach((ecm) => {
        if (!project_numbers.includes(ecm[20])) {
          project_numbers.push(ecm[20])
          select_options.push({ value: ecm[20], label: ecm[1] })
        }
      })
      setSiteNameList(select_options)
      if (props.search_site_project_number) {
        let search_option = select_options.find(
          (option) => option.value == props.search_site_project_number
        )

        select_ref.current.setValue(search_option)
        let searchResults = []

        ecm_analysis_table.forEach((ecm) => {
          if (ecm[20] == props.search_site_project_number) {
            let annual_onsite_emission_cost = parseFloat(
              (parseFloat(ecm[19]) / parseInt(ecm[4])) * 1000
            ).toPrecision(3)
            searchResults.push({
              ecm_identifier: parseInt(ecm[0]),
              site_name: ecm[1],
              ecm_description: ecm[3],
              ecm_gas_gj_savings: parseIntAndFormat(ecm[10]),
              ecm_electric_kwh_savings: parseIntAndFormat(ecm[11]),
              ecm_electric_kw_savings: parseIntAndFormat(ecm[12]),
              ecm_water_m3_savings: parseIntAndFormat(ecm[13]),
              ecm_capital_cost: parseIntAndFormat(ecm[4]),
              ecm_roi: parseIntAndFormat(ecm[9]),
              ecm_npv: parseIntAndFormat(ecm[8]),
              ecm_payback: parseIntAndFormat(ecm[7]),
              ecm_incentive: parseIntAndFormat(ecm[14]),
              ecm_savings: parseIntAndFormat(ecm[15]),
              ecm_group_number: parseInt(ecm[16]) ? parseInt(ecm[16]) : null,
              ecm_hierarchy: parseInt(ecm[17]) ? parseInt(ecm[17]) : null,
              year_of_implementation: parseInt(ecm[18])
                ? parseInt(ecm[18])
                : null,
              ecm_annual_onsite_emission_cost: isNaN(
                annual_onsite_emission_cost
              )
                ? "-"
                : annual_onsite_emission_cost,
              annual_onsite_ghg_savings: parseFloatAndFormat(ecm[19]),
              project_number: parseInt(ecm[20]),
            })
          }
        })
        setTableData(searchResults)
      }
    }
  }, [props.data, props.search_site_project_number])

  useEffect(() => {
    handleOnSelect()
  }, [selectedECMs])

  function getFormData() {
    const formData = new FormData(form_ref.current)
    const data = Object.fromEntries(formData)
    return data
  }

  function filterData(e) {
    deleteGroup()
    props.set_is_create_bundle(false)
    props.set_sofiac_qualification({})
    props.set_cmhc_level("")
    setSelectedECMs({})
    e.preventDefault()
    let {
      project_number,
      ecm_description,
      ecm_capital_cost,
      ecm_roi,
      ecm_npv,
      ecm_payback,
      ecm_incentive,
      ecm_savings,
    } = getFormData()

    let searchResults = []

    ecm_analysis_table.forEach((ecm) => {
      if (
        ecm[20] == project_number &&
        ecm[3].toLowerCase().includes(ecm_description) &&
        ecm[4] <=
          (parseInt(ecm_capital_cost)
            ? parseInt(ecm_capital_cost)
            : Number.MAX_SAFE_INTEGER) &&
        ecm[9] >=
          (parseInt(ecm_roi) ? parseInt(ecm_roi) : Number.MIN_SAFE_INTEGER) &&
        ecm[8] >=
          (parseInt(ecm_npv) ? parseInt(ecm_npv) : Number.MIN_SAFE_INTEGER) &&
        ecm[7] <=
          (parseInt(ecm_payback)
            ? parseInt(ecm_payback)
            : Number.MAX_SAFE_INTEGER) &&
        ecm[14] >=
          (parseInt(ecm_incentive)
            ? parseInt(ecm_incentive)
            : Number.MIN_SAFE_INTEGER) &&
        ecm[15] >=
          (parseInt(ecm_savings)
            ? parseInt(ecm_savings)
            : Number.MIN_SAFE_INTEGER)
      ) {
        let annual_onsite_emission_cost = parseFloat(
          (parseFloat(ecm[19]) / parseInt(ecm[4])) * 1000
        ).toPrecision(3)
        searchResults.push({
          ecm_identifier: parseInt(ecm[0]),
          site_name: ecm[1],
          ecm_description: ecm[3],
          ecm_gas_gj_savings: parseIntAndFormat(ecm[10]),
          ecm_electric_kwh_savings: parseIntAndFormat(ecm[11]),
          ecm_electric_kw_savings: parseIntAndFormat(ecm[12]),
          ecm_water_m3_savings: parseIntAndFormat(ecm[13]),
          ecm_capital_cost: parseIntAndFormat(ecm[4]),
          ecm_roi: parseIntAndFormat(ecm[9]),
          ecm_npv: parseIntAndFormat(ecm[8]),
          ecm_payback: parseIntAndFormat(ecm[7]),
          ecm_incentive: parseIntAndFormat(ecm[14]),
          ecm_savings: parseIntAndFormat(ecm[15]),
          ecm_group_number: parseInt(ecm[16]) ? parseInt(ecm[16]) : null,
          ecm_hierarchy: parseInt(ecm[17]) ? parseInt(ecm[17]) : null,
          year_of_implementation: parseInt(ecm[18]) ? parseInt(ecm[18]) : null,
          ecm_annual_onsite_emission_cost: isNaN(annual_onsite_emission_cost)
            ? "-"
            : annual_onsite_emission_cost,
          annual_onsite_ghg_savings: parseFloatAndFormat(ecm[19]),
          project_number: parseInt(ecm[20]),
        })
      }
    })

    setTableData(searchResults)
  }

  const header_names = [
    { column: "select", header: "" },
    { column: "ecm_description", header: "Description" },
    {
      column: "ecm_gas_gj_savings",
      header: "Annual Gas Energy Saving (GJ/yr)",
    },
    {
      column: "ecm_electric_kwh_savings",
      header: "Annual Electrical Energy Saving (kWh/yr)",
    },
    {
      column: "ecm_electric_kw_savings",
      header: "Annual Electrical Power Reduction (kW)",
    },
    { column: "ecm_water_m3_savings", header: "Annual Water Saving (m³/yr)" },
    { column: "ecm_capital_cost", header: "ECM Capital Cost ($)" },
    { column: "ecm_roi", header: "ROI (%)" },
    { column: "ecm_npv", header: "NPV ($)" },
    { column: "ecm_payback", header: "Simple Payback (yrs)" },
    { column: "ecm_incentive", header: "Incentive ($)" },
    { column: "ecm_savings", header: "First Year Savings ($)" },
    {
      column: "ecm_annual_onsite_emission_cost",
      header: "Annual Onsite Emission/Cost (kg CO2/$)",
    },
  ]

  function generateECMGroupsList() {
    let group_list = []
    let ecm_groups = {}

    TableData.forEach((ecm) => {
      if (ecm.ecm_group_number !== null) {
        if (!group_list.includes(ecm.ecm_group_number)) {
          group_list.push(ecm.ecm_group_number)
          ecm_groups = { ...ecm_groups, [ecm.ecm_group_number]: new Set() }
        }
      }
    })

    for (let ecm of TableData) {
      if (ecm.ecm_group_number !== null) {
        ecm_groups = {
          ...ecm_groups,
          [ecm.ecm_group_number]: ecm_groups[ecm.ecm_group_number].add(
            ecm.ecm_identifier
          ),
        }
      }
    }
    return ecm_groups
  }
  function getGroupECMList(data) {
    if (data.ecm_group_number) {
      let ecm_groups = generateECMGroupsList()
      return Array.from(ecm_groups[data.ecm_group_number])
    } else {
      return [data.ecm_identifier]
    }
  }

  function createGroup() {
    let selected_ecms_data = []
    let remaining_ecms_data = []

    for (let ecm of TableData) {
      if (selectedECMs[ecm.ecm_identifier]) {
        selected_ecms_data.push(ecm)
      } else if (selectedECMs[ecm.ecm_identifier] === undefined) {
        remaining_ecms_data.push(ecm)
      }
    }

    // setTableData(remaining_ecms_data)
    setSelectedECMs({})
    props.set_is_create_bundle(true)
    props.create_group(selected_ecms_data)
  }

  //testing:handleOnSelect
  function handleOnSelect() {
    let selected_ecms_data = []

    for (let ecm of TableData) {
      if (selectedECMs[ecm.ecm_identifier]) {
        selected_ecms_data.push(ecm)
      }
    }

    props.set_selected_building_ecms(selected_ecms_data)
  }

  function deleteGroup() {
    props.set_sofiac_qualification({})
    props.set_cmhc_level("")
    setSelectedECMs({})
    props.delete_group()
  }

  return (
    <div className='flex flex-col h-fit mb-8'>
      <p className='section_heading_2 p-4'>Available ECMs</p>

      <div className='h-full flex flex-col gap-8 custom-frame p-6'>
        <form
          id='filter-form'
          ref={form_ref}
          className='filter-form'
          onSubmit={filterData}
          onReset={(e) => {
            select_ref.current.clearValue()
            setROIValue(0)
            setSelectedECMs({})
            setTableData([])
            props.set_sofiac_qualification({})
            props.set_cmhc_level("")
            props.delete_group()
          }}
        >
          <div className='bundle-filter-first-row'>
            <div id='f-sitename-bundle' className='filter-parameter'>
              <p className='filter-label'>Site Name</p>
              <Select
                name='project_number'
                id='project_number'
                className='select-options'
                ref={select_ref}
                options={siteNameList}
                defaultValue={[]}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <div id='f-description-bundle' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_description'>
                Description
              </label>
              <input
                name='ecm_description'
                id='ecm_description'
                className='filter-input'
                type='text'
                size={60}
              />
            </div>
          </div>
          <div className='bundle-filter-second-row'>
            <div id='f-capitalcost' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_capital_cost'>
                Max. ECM Capital Cost ($)
              </label>
              <input
                name='ecm_capital_cost'
                id='ecm_capital_cost'
                className='filter-input'
                type='text'
              />
            </div>
            <div id='f-roi' className='filter-parameter'>
              <p className='filter-label'>Min. ROI (%)</p>
              <div id='f-roi-input'>
                <input
                  type='range'
                  id='ecm_roi'
                  name='ecm_roi'
                  max={20}
                  x
                  value={ROIValue}
                  onChange={(e) => setROIValue(e.target.value)}
                  className='filter-slider'
                />
                <div id='f-roi-text'>{ROIValue}%</div>
              </div>
            </div>
            <div id='f-npv' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_npv'>
                Min. NPV ($)
              </label>
              <input
                name='ecm_npv'
                id='ecm_npv'
                className='filter-input'
                type='text'
              />{" "}
            </div>
            <div id='f-payback' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_payback'>
                Max. Simple Payback (yrs)
              </label>
              <input
                name='ecm_payback'
                id='ecm_payback'
                className='filter-input'
                type='text'
              />{" "}
            </div>
          </div>
          <div className='bundle-filter-third-row'>
            <div id='f-incentive' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_incentive'>
                Min. Incentive ($)
              </label>
              <input
                name='ecm_incentive'
                id='ecm_incentive'
                className='filter-input'
                type='text'
              />{" "}
            </div>
            <div id='f-savings' className='filter-parameter'>
              <label className='filter-label' htmlFor='ecm_savings'>
                Min. First Year Saving ($)
              </label>
              <input
                name='ecm_savings'
                id='ecm_savings'
                className='filter-input'
                type='text'
              />
            </div>
            <div id='filter-buttons'>
              <button type='reset' className='reset-button-gray'>
                Clear
              </button>

              <button type='submit' className='submit-button-blue'>
                Search
              </button>
            </div>
          </div>
        </form>
        <ECMTable
          id='retro-fit-bundle'
          table_headers={header_names}
          ecm_list={TableData}
          selected_ecms={selectedECMs}
          set_selected_ecms={setSelectedECMs}
          fn_get_group_ecms={getGroupECMList}
        />
        <p className='upgrade-restrict-text'>
          Note: Selecting certain ECMs may restrict other ECMs due to their
          effect on each other.
        </p>
        <div className='flex'>
          <ProgressBar value={props.ghg_reduction_value} />
        </div>

        <div id='bundel-buttons'>
          <button className='reset-button-gray' onClick={deleteGroup}>
            {props.cancel_button_text}
          </button>
          <button className='submit-button-blue' onClick={createGroup}>
            {props.submit_button_text}
          </button>
        </div>
      </div>
    </div>
  )
}
export default Filter
