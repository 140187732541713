import axios from "axios"

async function getPowerBiWorkspaces(access_token) {
  return axios
    .get("https://api.powerbi.com/v1.0/myorg/groups", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      return response.data.value
    })
    .catch((error) => {
      console.error(error)
    })
}

async function getPowerBiReports(access_token, workspace_id) {
  return axios
    .get(`https://api.powerbi.com/v1.0/myorg/groups/${workspace_id}/reports`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      return response.data.value
    })
    .catch((error) => {
      console.error(error)
    })
}

async function getPowerBiReport(access_token, workspace_id, report_id) {
  return axios
    .get(
      `https://api.powerbi.com/v1.0/myorg/groups/${workspace_id}/reports/${report_id}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then((response) => {
      if (response) {
        return response.data
      } else {
        return null
      }
    })
    .catch((error) => {
      console.error(error)
    })
}

export { getPowerBiWorkspaces, getPowerBiReports, getPowerBiReport }
