import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import BuildingFilter from "./BuildingFilter"
import TotalSavings from "../components/TotalSavings"
import { calculateECMBundle } from "../../../utils/HttpClient"
import FinancialAnalysisBundle from "../components/FinancialAnalysisBundle"
import "../../../styles/analysisTools/analysis-retrofit.css"

function RetroFit(props) {
  const navigate = useNavigate()

  const [selectedBuilding, setSelectedBuilding] = useState("")
  const [selectedBuildingECMs, setSelectedBuildingECMs] = useState([])

  const [isDataLoaded, setIsDataLoaded] = useState(false)

  const [savingsData, setSavingsData] = useState({})
  const [financialData, setFinancialData] = useState({})

  useEffect(() => {
    if (selectedBuildingECMs.length > 0) {
      buildRetrofitData()
    } else {
      setIsDataLoaded(false)
      setSavingsData({})
      setFinancialData({})
    }
  }, [selectedBuildingECMs])

  function buildRetrofitData() {
    if (selectedBuildingECMs.length > 0) {
      getRetrofitData(selectedBuildingECMs.toString())
      setIsDataLoaded(true)
    } else {
      setIsDataLoaded(false)
    }
  }

  function getRetrofitData(ecm_identifiers) {
    calculateECMBundle(ecm_identifiers)
      .then((res) => {
        if (res) {
          setSavingsData(res.data.TotalEnergySavings)
          setFinancialData(res.data.FinancialAnalysis)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className='mb-16'>
      <div className='title w-full flex justify-between'>
        <h1 className='text-[24px] font-semibold font-mukta'>
          Deep Retrofit
        </h1>
        <button className='button-action-white' onClick={() => navigate(-1)}>
          back to analysis tools
        </button>
      </div>

      <BuildingFilter
        data={props.data}
        set_selected_building_ecms={setSelectedBuildingECMs}
        ghg_reduction_value={savingsData["GHGEmissions"]}
      />

      {isDataLoaded ? (
        <>
          <TotalSavings data={savingsData} />
          <FinancialAnalysisBundle data={financialData} />
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
export default RetroFit
