import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import DownloadCSV from "../global/DownloadCSV"
import "../../styles/table.css"
import "../../styles/index.css"
import "../../styles/report.css"
import { IconButton, Button } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import {
  changeECMIsDone,
  changeECMYearOfImplementation,
} from "../../utils/HttpClient"

function UpgradesRecEnergyTable(props) {
  const [tableData, setTableData] = useState([])
  const [order, setOrder] = useState("0DSC")
  const headers = [
    "Description",
    "Implemented",
    "Implementation Year",
    "Lifetime Onsite GHG Emission Saving (Ton CO₂e)",
    "Simple Payback (yrs)",
    "First Year Saving ($)",
    "ECM Capital Cost ($)",
    "Incentive ($)",
    "ROI (%)",
    "IRR (%)",
    "NPV ($)",
    "Discounted Payback (yrs)",
    "Baseline Replacement Cost ($)",
    "Incremental Cost (ECM Capital - Basline) ($)",
    "Adjusted Incremental Cost (Including Incentives) ($)",
    "ECM Life (yrs)",
    "Baseline Life (yrs)",
    "Baseline Equipment Installation Year",
    "Annual Onsite GHG Emission Saving (Ton CO₂e/yr)",
    "Annual Electrical Energy Saving (kWh/yr)",
    "Annual Electrical Power Reduction (kW)",
    "Annual Gas Energy Saving (GJ/yr)",
    "Annual Water Saving (m³/yr)",
    "Annual O&M Saving ($/yr)",
    "Annual Fuel Energy Saving (GJ/yr)",
    "ECM Emission Saving (%)",
    "Energy Saving (%)",
    "Cost/Lifetime GHG Emission Reduction ($/Ton CO₂e)",
    "NPV/Lifetime GHG Emission Reduction ($/Ton CO₂e)",
    "Annual Electrical Cost Saving ($/yr)",
    "Annual Gas Cost Saving ($/yr)",
    "Annual Water Cost Saving ($/yr)",
    "Lifetime GHG Emission Cost Saving ($)",
    "ECM Identifier Tag",
  ]
  const editableColumns = [
    {
      header: "Implemented",
      render: (index, data, details) => {
        if (index == editingDoneIndex) {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <input
                  type='checkbox'
                  id='isDone'
                  name='isDone'
                  checked={isDone}
                  onChange={(e) => {
                    setIsDone(e.target.checked)
                  }}
                />
                <label
                  style={{
                    color: "inherit",
                    padding: "inherit",
                    display: "inline-block",
                    textTransform: "inherit",
                  }}
                  htmlFor='isDone'
                >
                  Done
                </label>
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <IconButton
                  style={{
                    width: "1rem",
                    height: "1rem",
                    color: "#03045e",
                    border: "1px solid #03045e",
                    padding: "10px",
                  }}
                  onClick={(e) => {
                    details.isDone = isDone
                    changeECMIsDone(details).then(() => {
                      setEditingDoneIndex(-1)
                      props.onDataChange(isDone, index, 1)
                      if (!isDone) {
                        props.onDataChange("-", index, 2)
                      }
                    })
                  }}
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  style={{
                    width: "1rem",
                    height: "1rem",
                    color: "rgb(239 68 68 / var(--tw-bg-opacity))",
                    border: "1px solid rgb(239 68 68 / var(--tw-bg-opacity))",
                    padding: "10px",
                  }}
                  onClick={(e) => {
                    setEditingDoneIndex(-1)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )
        } else {
          return data
        }
      },
      toggleEditMode: (e, index) => {
        if (index != editingDoneIndex) {
          setEditingDoneIndex(index)
          setIsDone(tableData[index][1])
        }
      },
    },
    {
      header: "Implementation Year",
      render: (index, data, details) => {
        if (index == editingYearOfImplementationIndex)
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                whiteSpace: "nowrap",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <input
                  style={{ width: "100px" }}
                  type='number'
                  id='yearOfImplementation'
                  name='yearOfImplementation'
                  value={yearOfImplementation}
                  onChange={(e) => {
                    setYearOfImplementation(e.target.value)
                  }}
                />
              </div>
              <div style={{ display: "flex", gap: "5px" }}>
                <IconButton
                  style={{
                    width: "1rem",
                    height: "1rem",
                    color: "#03045e",
                    border: "1px solid #03045e",
                    padding: "10px",
                  }}
                  onClick={(e) => {
                    if (
                      yearOfImplementation < 1980 ||
                      yearOfImplementation > 2050
                    ) {
                      alert(
                        "Implementation Year must be between 1980 and 2050."
                      )
                      return
                    }
                    details.yearOfImplementation = yearOfImplementation
                    changeECMYearOfImplementation(details).then((res) => {
                      setEditingYearOfImplementationIndex(-1)
                      props.onDataChange(yearOfImplementation, index, 2)
                    })
                  }}
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  style={{
                    width: "1rem",
                    height: "1rem",
                    color: "rgb(239 68 68 / var(--tw-bg-opacity))",
                    border: "1px solid rgb(239 68 68 / var(--tw-bg-opacity))",
                    padding: "10px",
                  }}
                  onClick={(e) => {
                    setEditingYearOfImplementationIndex(-1)
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
          )
        else return data
      },
      toggleEditMode: (e, index) => {
        if (index != editingYearOfImplementationIndex) {
          setEditingYearOfImplementationIndex(index)
          setYearOfImplementation(tableData[index][2])
        }
      },
    },
  ]
  const [editingDoneIndex, setEditingDoneIndex] = useState(-1)
  const [isDone, setIsDone] = useState(false)
  const [
    editingYearOfImplementationIndex,
    setEditingYearOfImplementationIndex,
  ] = useState(-1)
  const [yearOfImplementation, setYearOfImplementation] = useState(-1)
  const navigate = useNavigate()

  useEffect(() => {
    let vals = props.data
    for (let i = 0; i < vals.length; i++) {
      if (Array.isArray(vals[i])) {
        for (let j = 0; j < vals[i].length; j++) {
          if (vals[i][j] == "NaN") {
            vals[i][j] = "-"
          }
        }
      }
    }
    setTableData(props.data)
  }, [props.data])

  const parseCSV = (tableData) => {
    return tableData.map((row) => {
      row = row.map((col) => {
        if (col === true || col === false) {
          col = col === true ? "Yes" : "No"
        }
        return col
      })
      return row
    })
  }

  //generate the table data to columns
  const generateTableData = () => {
    return tableData.map((building, rowIndex) => (
      <tr key={building[0]} className='row row-upgrade'>
        {building.map((data, index) => {
          if (data === true || data === false) {
            return (
              <td
                key={index}
                className='columnItem align-middle portfolio-table-data'
                style={{
                  cursor: editableColumns
                    .map((c) => c.header)
                    .includes(headers[index])
                    ? "pointer"
                    : "default",
                }}
                onClick={(e) => {
                  editableColumns
                    .find((c) => c.header == headers[index])
                    ?.toggleEditMode(e, rowIndex)
                }}
              >
                <div className='table-headers'>{headers[index]}</div>
                <div>
                  {editableColumns.map((c) => c.header).includes(headers[index])
                    ? editableColumns
                        .find((c) => c.header == headers[index])
                        .render(rowIndex, data === true ? "Yes" : "No", {
                          projectNumber: props.projectNumber,
                          description: building[0],
                        })
                    : data === true
                    ? "Yes"
                    : "No"}
                </div>
              </td>
            )
          } else if (!isNaN(data)) {
            if (props.addLocale === true) {
              if (index === 17) {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle portfolio-table-data'
                    style={{
                      cursor: "default",
                    }}
                  >
                    <div className='table-headers'>{headers[index]}</div>
                    <div>{parseInt(data)}</div>
                  </td>
                )
              } else {
                return (
                  <td
                    key={index}
                    className='columnItem align-middle portfolio-table-data'
                    style={{
                      cursor: editableColumns
                        .map((c) => c.header)
                        .includes(headers[index])
                        ? "pointer"
                        : "default",
                    }}
                    onClick={(e) => {
                      editableColumns
                        .find((c) => c.header == headers[index])
                        ?.toggleEditMode(e, rowIndex)
                    }}
                  >
                    <div className='table-headers'>{headers[index]}</div>
                    <div>
                      {editableColumns
                        .map((c) => c.header)
                        .includes(headers[index])
                        ? editableColumns
                            .find((c) => c.header == headers[index])
                            .render(rowIndex, parseInt(data), {
                              projectNumber: props.projectNumber,
                              description: building[0],
                            })
                        : headers[index].includes("construction year")
                        ? parseInt(data)
                        : parseInt(data).toLocaleString("en-us")}
                    </div>
                  </td>
                )
              }
            } else {
              return (
                <td
                  key={index}
                  className='columnItem align-middle portfolio-table-data'
                  style={{
                    cursor: editableColumns
                      .map((c) => c.header)
                      .includes(headers[index])
                      ? "pointer"
                      : "default",
                  }}
                  onClick={(e) => {
                    editableColumns
                      .find((c) => c.header == headers[index])
                      ?.toggleEditMode(e, rowIndex)
                  }}
                >
                  <div className='table-headers'>{headers[index]}</div>
                  <div>
                    {editableColumns
                      .map((c) => c.header)
                      .includes(headers[index])
                      ? editableColumns
                          .find((c) => c.header == headers[index])
                          .render(rowIndex, data, {
                            projectNumber: props.projectNumber,
                            description: building[0],
                          })
                      : data}
                  </div>
                </td>
              )
            }
          } else {
            return (
              <td
                key={index}
                className='columnItem align-middle portfolio-table-data'
                style={{
                  cursor: editableColumns
                    .map((c) => c.header)
                    .includes(headers[index])
                    ? "pointer"
                    : "default",
                }}
                onClick={(e) => {
                  editableColumns
                    .find((c) => c.header == headers[index])
                    ?.toggleEditMode(e, rowIndex)
                }}
              >
                <div className='table-headers'>{headers[index]}</div>
                <div>
                  {editableColumns.map((c) => c.header).includes(headers[index])
                    ? editableColumns
                        .find((c) => c.header == headers[index])
                        .render(rowIndex, data, {
                          projectNumber: props.projectNumber,
                          description: building[0],
                        })
                    : data}
                </div>
              </td>
            )
          }
        })}
      </tr>
    ))
  }

  //sort by columns function, takes column index and state to determine which column to sort
  const sorting = (col) => {
    if (!props.skipFirstCol) {
      if (order.substring(1) === "ASC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(a[col]).localeCompare(
              JSON.stringify(b[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return a[col] - b[col]
          }
        })
        setTableData(sorted)
        setOrder(col + "DSC")
      }

      if (order.substring(1) === "DSC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(b[col]).localeCompare(
              JSON.stringify(a[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return b[col] - a[col]
          }
        })
        setTableData(sorted)
        setOrder(col + "ASC")
      }
    } else {
      col = col + 1
      if (order.substring(1) === "ASC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(a[col]).localeCompare(
              JSON.stringify(b[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return a[col] - b[col]
          }
        })
        setTableData(sorted)
        setOrder(col - 1 + "DSC")
      }

      if (order.substring(1) === "DSC") {
        const sorted = [...tableData].sort((a, b) => {
          if (typeof a[col] != "number" || typeof b[col] != "number") {
            return JSON.stringify(b[col]).localeCompare(
              JSON.stringify(a[col]),
              "en",
              {
                numeric: true,
                sensitivity: "base",
              }
            )
          } else {
            return b[col] - a[col]
          }
        })
        setTableData(sorted)
        setOrder(col - 1 + "ASC")
      }
    }
  }

  function handleRedirectToBundle() {
    localStorage.setItem(
      "search_project_number",
      JSON.stringify(props.projectNumber)
    )
    navigate("../analysis/bundle")
  }

  return (
    <div>
      {/* header */}
      <div className='table-title'>
        <div className='flex justify-between items-center'>
          <p className='table-title-text'>{props.title}</p>

          <div className='btn-container'>
            <button
              className='create-bundle-btn'
              onClick={handleRedirectToBundle}
            >
              Create and Analyze Bundle
            </button>
            <DownloadCSV
              headers={props.id_col ? ["identifier"].concat(headers) : headers}
              data={parseCSV(tableData)}
              filename={props.filename}
              className='upgrade-csv-btn'
            />
          </div>
        </div>
      </div>
      {/* note: cp-5 scroll bar h-[500]*/}
      {/* <div className='overflow-y-scroll h-[500px] table-scroll'> */}
      {/*
       scroll bar shape is visible even though no scroll bar
      <div className='overflow-y-scroll h-full table-scroll'> */}
      {/* scroll bar appear if container exist and overflow of the height */}

      <div className='overflow-auto max-h-[600px] border-r'>
        <table
          id='portfolio'
          className='rounded-lg text-md w-full upgrade-table border-b'
        >
          <link
            href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined'
            rel='stylesheet'
          />
          <thead id='thread-row-status'>
            <tr>
              {headers.map((value, index) => {
                const isDescriptionHeader = value === "Description"
                const stickyClass = isDescriptionHeader ? "sticky-header" : ""

                if (order === index + "ASC") {
                  return (
                    // changes
                    // <th key={index} className='sticky top-0 '>
                    <th key={index} className={`sticky top-0 ${stickyClass}`}>
                      <div className='flex justify-between items-center'>
                        {value}
                        <span
                          className='material-symbols-outlined'
                          onClick={() => sorting(index)}
                        >
                          expand_more
                        </span>
                      </div>
                    </th>
                  )
                }
                return (
                  // <th className='sticky top-0' key={index}>
                  <th className={`sticky top-0 ${stickyClass}`} key={index}>
                    <div className='flex justify-between items-center '>
                      {value}
                      <span
                        className='material-symbols-outlined'
                        onClick={() => sorting(index)}
                      >
                        expand_less
                      </span>
                    </div>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className='mt-12'>{generateTableData()}</tbody>
        </table>
      </div>

      {props.siteName.includes("Fairmont Medical") && (
        <div className='flex flex-col mt-10 mb-10 h-fit'>
          <p className='section_heading_3 p-4'>ECMs and Incentive Details</p>

          <div className='bg-white flex flex-col p-6 w-full custom-frame '>
            <div className='text-sm'>
              Click here to see{" "}
              <span>
                <a
                  target='_blank'
                  rel='noreferrer noopener'
                  href='https://www.betterbuildingsbc.ca/incentives/cleanbc-custom-program/'
                  className='title-link'
                >
                  Incentives for Heat pumps
                </a>
              </span>
            </div>
            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Central Hydronic Air-Source Heat Pump Covering Baseload
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 1080
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 4.0
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  17°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>

            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Heat Pump DHW Covering Baseload
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 120
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 5.5
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  0°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>

            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Heat Pump MUA and Variable Speed Drive
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 250
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 4.3
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  17°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {props.siteName.includes("Camelia") && (
        <div className='flex flex-col mt-10 mb-10 h-fit'>
          <p className='section_heading_3 p-4'>ECMs and Incentive Details</p>

          <div className='bg-white flex flex-col p-6 w-full'>
            <div className='text-sm'>
              Click here to see{" "}
              <span>
                <a
                  target='_blank'
                  rel='noreferrer noopener'
                  href='https://www.betterbuildingsbc.ca/incentives/cleanbc-custom-program/'
                  className='title-link'
                >
                  Incentives for Heat pumps
                </a>
              </span>
            </div>
            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Central Hydronic Air-Source Heat Pump Covering Baseload
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 240
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 4.0
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  17°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>
            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Heat Pump DHW Covering Baseload
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 375
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 5.5
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  0°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>
            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Heat Pump MUA and Variable Speed Drive
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 300
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 4.3
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  17°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {props.siteName.includes("GrandviewHwy-Vancouver-604") && (
        <div className='flex flex-col mt-10 mb-10 h-fit'>
          <p className='section_heading_3 p-4'>ECMs and Incentive Details</p>

          <div className='bg-white flex flex-col p-6 w-full'>
            <div className='text-sm'>
              Click here to see{" "}
              <span>
                <a
                  target='_blank'
                  rel='noreferrer noopener'
                  href='https://www.betterbuildingsbc.ca/incentives/cleanbc-custom-program/'
                  className='title-link'
                >
                  Incentives for Heat pumps
                </a>
              </span>
            </div>
            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: Central Hydronic Air-Source Heat Pump (Electric Backup)
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 3,124
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 4.0
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  17°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {props.siteName.includes("OntarioSt-Vancouver-605") && (
        <div className='flex flex-col mt-10 mb-10 h-fit'>
          <p className='section_heading_3 p-4'>ECMs and Incentive Details</p>

          <div className='bg-white flex flex-col p-6 w-full'>
            <div className='text-sm'>
              Click here to see{" "}
              <span>
                <a
                  target='_blank'
                  rel='noreferrer noopener'
                  href='https://www.betterbuildingsbc.ca/incentives/cleanbc-custom-program/'
                  className='title-link'
                >
                  Incentives for Heat pumps
                </a>
              </span>
            </div>
            <div className='mt-5'>
              <p className='font-bold mb-2'>
                ECM: For Tenant-Air-Source Heat Pump (Estimated)
              </p>
              <ul className='detaiList'>
                <li>
                  <span className='font-semibold'>Heating Capacity:</span> 1,988
                  MBH
                </li>
                <li>
                  <span className='font-semibold'>Maximum COP:</span> 4.0
                </li>
                <li>
                  <span className='font-semibold'>
                    Low-temperature cut-out:
                  </span>{" "}
                  17°F
                </li>
                <li>
                  <span className='font-semibold'>
                    Electrical load upgrade:{" "}
                  </span>
                  Not included
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

UpgradesRecEnergyTable.propTypes = {
  data: PropTypes.array.isRequired,
}

export default UpgradesRecEnergyTable
