import { useEffect, useState, useContext } from "react"
import LoadingSpinner from "../../../global/LoadingSpinner"
import styles from "../../../../styles/addProperty/index.module.css"
import ReviewSubmitStyle from "../../../../styles/addProperty/ReviewSubmit.module.css"
import accordionStyles from "../../../../styles/addProperty/AccordionForm.module.css"
import {
  DefaultDraftContext,
  UserDraftContext,
} from "../../context/DraftContext"
import AccordionForm from "../AccordionForm"
import ReviewData from "./ReviewData"
import { Link } from "react-router-dom"
import CircularProgress from "@mui/material/CircularProgress"
import Fab from "@mui/material/Fab"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import Button from "../../../global/components/button/Button"
import { useNavigate } from "react-router-dom"
import AlertBanner from "../../../global/components/InformationText/AlertBanner/AlertBanner"
import { filterProperties } from "../../../../utils/DataFormatter"

function ReviewSubmit({
  activeStep,
  propertyStatus,
  isSubmiting,
  onValidateAll,
  onSubmit,
}) {
  // draft form object
  const [formObject, setFormObject] = useState({})

  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)
  const { defaultDraftObject, siteInputOptions } =
    useContext(DefaultDraftContext)

  const navigate = useNavigate()

  const [showError, setShowError] = useState(false)
  const [retryAttempt, setRetryAttempt] = useState(0)
  const [isRetrying, setIsRetrying] = useState(false)

  useEffect(() => {
    if (userDraftState) {
      setFormObject({ ...userDraftState })
    }
  }, [userDraftState])

  useEffect(() => {
    if (isSubmiting) {
      if (propertyStatus === undefined || propertyStatus === null) {
        // Set a timeout of 5 seconds before showing the error
        const timer = setTimeout(() => {
          setShowError(true)
        }, 5000)
        // Clear the timeout if the component is unmounted or projectNumber changes
        return () => clearTimeout(timer)
      }
    }
  }, [isSubmiting, propertyStatus])

  const categoriesList = [
    "Basic Information",
    "Building Data",
    "Energy Utilities",
    "Energy Meters",
    "Energy Bills",
    "Water Utilities",
    "Water Meters",
    "Water Bills",
    // "Equipment Ack",
    "Equipments",
    // "Lights Ack",
    "Lights",
    // "Water Fixtures Ack",
    "Water Fixtures",
    // "Envelope Ack",
    "Envelope",
    "Related Documents",
    "Energy Audits",
  ]

  const excludedProperties = [
    "org_folder_name",
    "org_file_name",
    "file_blob",
    "message",
    "is_deleted",
    "file_size",
    "file_path",
    "status",
  ]

  const getReviewData = (categoryName) => {
    switch (categoryName) {
      case "Basic Information":
        return formObject.basic_information
      case "Building Data":
        return formObject.building_data
      case "Energy Utilities":
        return formObject.property_energy_utilities
      case "Energy Meters":
        return formObject.property_energy_meters
      case "Energy Bills":
        return formObject.property_energy_bills
      case "Water Utilities":
        return formObject.property_water_utilities
      case "Water Meters":
        return formObject.property_water_meters
      case "Water Bills":
        return formObject.property_water_bills
      case "Equipment Ack":
        return formObject.property_equipments_ack
      case "Equipments":
        return formObject.property_equipments
      case "Lights Ack":
        return formObject.property_lights_ack
      case "Lights":
        return formObject.property_lights
      case "Water Fixtures Ack":
        return formObject.property_water_fixtures_ack
      case "Water Fixtures":
        return formObject.property_water_fixtures
      case "Envelope Ack":
        return formObject.property_envelope_ack
      case "Envelope":
        return formObject.property_envelope
      case "Related Documents":
        return filterProperties(
          formObject.property_supporting_documents,
          (key) => !excludedProperties.includes(key)
        )
      case "Energy Audits":
        return filterProperties(
          formObject.property_energy_audits,
          (key) => !excludedProperties.includes(key)
        )
      default:
        return null
    }
  }

  const submitingProperty = () => {
    return (
      <div className={ReviewSubmitStyle.review_submition}>
        <CircularProgress color='primary' />
        <p>Submitting your property...</p>
      </div>
    )
  }

  const redirectToDataCollection = () => {
    navigate("/addProperty")
  }

  const submitSucceess = () => {
    return (
      <div className={ReviewSubmitStyle.review_submition}>
        <Fab
          aria-label='save'
          color='primary'
          sx={{ backgroundColor: "#4caf50", size: "200px" }}
          onClick={null}
        >
          <CheckIcon />
        </Fab>
        <p>Thank you for submitting your property!</p>
        <Button
          className='button__large button__large--gray'
          buttonText='View Report'
          onClick={redirectToDataCollection}
        />

        <AlertBanner
          type='success'
          info='Your property has been submitted successfully.'
        />
      </div>
    )
  }

  const submitFail = () => {
    const handleRetry = () => {
      setRetryAttempt(1)
      setIsRetrying(true)

      const retryTimer = setTimeout(() => {
        setIsRetrying(false)
        clearTimeout(retryTimer)
      }, 15000)
      onSubmit()
    }

    if (retryAttempt === 0) {
      return (
        <div className={ReviewSubmitStyle.review_submition}>
          <Fab
            color='primary'
            sx={{ backgroundColor: "#b22222", size: "200px" }}
          >
            <CloseIcon />
          </Fab>
          <p>Submission Failed!</p>
          <Button
            className='button__large button__large--gray'
            buttonText='Retry'
            onClick={handleRetry}
          />

          <AlertBanner
            type='error'
            info='Your property has NOT been submitted.'
          />
        </div>
      )
    }

    if (isRetrying) {
      return (
        <div className={ReviewSubmitStyle.review_submition}>
          <CircularProgress color='primary' />
          <p>Retrying submission...</p>
        </div>
      )
    }

    return (
      <div className={ReviewSubmitStyle.review_submition}>
        <Fab color='primary' sx={{ backgroundColor: "#b22222", size: "200px" }}>
          <CloseIcon />
        </Fab>
        <p>Retry failed. Please try again later.</p>
        <Button
          className='button__large button__large--gray'
          buttonText='Cancel'
          onClick={redirectToDataCollection}
        />

        <AlertBanner
          type='error'
          info='Your property has NOT been submitted.'
        />
      </div>
    )
  }

  return (
    <>
      {isSubmiting ? (
        propertyStatus === "Submitted" ? (
          submitSucceess()
        ) : showError ? (
          submitFail()
        ) : (
          submitingProperty()
        )
      ) : (
        <div className='form__container'>
          <div className='form-title-button__container'>
            <p className='form__title'>Review & Submit</p>
            <Button
              className='button__large button__large--gray complete-button-upper'
              buttonText='Check all tabs'
              onClick={onValidateAll}
            />
          </div>
          <div className='form__content'>
            <div className='form__description'>
              <p>
                Please review the information you have entered and submit your
                property.
              </p>
              <p>
                Upon successful submission, you will be able to access your
                property's Assessment Report.
              </p>
            </div>
            <hr id='horizontal-light' />

            {categoriesList.map((categoryName, index) => {
              return (
                <div key={index}>
                  <AccordionForm
                    summary_jsx={
                      <div className={accordionStyles.accordion_summary_text}>
                        <p>{categoryName}</p>
                      </div>
                    }
                    details_jsx={
                      <ReviewData
                        data={getReviewData(categoryName)}
                        categoryName={categoryName}
                        activeStep={activeStep}
                      />
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
export default ReviewSubmit
