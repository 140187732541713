import React, { useEffect, useState } from 'react';
import '../../../../styles/table.css';
import { BsCheckLg, BsXLg } from 'react-icons/bs';
import { updateOutputTable } from '../../../../utils/HttpClient';
import '../../../../styles/adminBuildings/adminTableBuildings.css';

export default function OutputTableRow(props) {
  // States:
  const [height, setHeight] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [editingCell, setEditingCell] = useState({ row: null, cell: null });

  const tableName = props.title;
  const projectId = props.projectId;

  // Pull data from props and set the table data
  useEffect(() => {
    setTableData(props.dataset);
  }, [props.dataset]);

  // get tableName, projectId, rowIndex, cellIndex when clicked
  const handleCellClick = (tableName, projectId, rowIndex, cellIndex) => {
    // Implement logic for editing the cell value here
    console.log(
      `Cell at row ${rowIndex} and column ${cellIndex} clicked on ${tableName} in project_id: ${projectId}.`
    );

    setEditingCell({ row: rowIndex, cell: cellIndex });
  };

  // close the editing cell
  const handleCancel = () => {
    setEditingCell({ row: null, cell: null });
  };

  // send the changed data to the backend
  const handleSubmit = async (
    event,
    projectId,
    tableName,
    rowIndex,
    cellIndex
  ) => {
    event.preventDefault();

    const inputValue = event.target.elements.input.value;

    console.log(
      `Value in cell ${rowIndex}-${cellIndex}: ${inputValue}, projectId: ${projectId} tableName: ${tableName}`
    );

    setEditingCell({ row: null, cell: null });

    // Call the updateOutputTable function with the edited value
    await updateOutputTable(
      projectId,
      tableName,
      rowIndex,
      cellIndex,
      inputValue
    );

    // Update the table data in the component state
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex][Object.keys(tableData[0])[cellIndex]] =
      inputValue;
    setTableData(updatedTableData);
  };

  // Render the table header and skip the project_number
  const renderCell = (cell, rowIndex, cellIndex) => {
    const headerName = Object.keys(tableData[0])[cellIndex];

    // skip the project_number column to avoid editing
    if (headerName === 'project_number') {
      return <div>{cell}</div>;
    }
    // if the cell is being edited, render the input field
    if (editingCell.row === rowIndex && editingCell.cell === cellIndex) {
      return (
        <form
          onSubmit={(event) =>
            handleSubmit(event, projectId, tableName, rowIndex, cellIndex)
          }
        >
          <input type='text' name='input' defaultValue={cell} />

          <div className='formBtns'>
            <button type='submit' className='iconBtn'>
              <BsCheckLg color='black' />
            </button>

            <button type='button' className='iconBtn' onClick={handleCancel}>
              <BsXLg color='black' />
            </button>
          </div>
        </form>
      );
    } else {
      return (
        <div
          onClick={() =>
            handleCellClick(tableName, projectId, rowIndex, cellIndex)
          }
        >
          {cell === null || cell === undefined || cell === '' ? (
            <div>&nbsp;</div> // display empty space if cell is empty
          ) : (
            cell
          )}
        </div>
      );
    }
  };

  if (tableData.length === 0) {
    return <></>;
  } else {
    //returns each value of objs in an array
    const rowData = tableData.map((obj) => Object.values(obj));
    return (
      <>
        {rowData.map((row, rowIndex) => (
          <tr
            key={rowIndex}
            className='row columnItem align-middle w-24'
            style={
              height === 'auto' ? { borderBottom: '0.1px solid #ddd' } : {}
            }
          >
            {row.map((cell, cellIndex) => (
              <td
                key={`${rowIndex}-${cellIndex}`}
                className='columnItem align-middle'
              >
                {renderCell(cell, rowIndex, cellIndex)}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  }
}
