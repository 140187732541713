import { useState, useEffect, useContext } from "react"
import "../../../../styles/addProperty/InputContainer.css"
import {
  DefaultDraftContext,
  UserDraftContext,
} from "../../context/DraftContext"
import { EnvelopeInputLabels } from "./EnvelopeOptions"
import Input from "../../../global/components/input/Input"
import Select from "../../../global/components/select/Select"
import "../../style/dataCollection.css"
import Button from "../../../global/components/button/Button"
import CheckBox from "../../../global/components/CheckBox/CheckBox"
import InstructionalText from "../../../global/components/InstructionalText/InstructionalText"

const PropertyEnvelope = ({
  tabValidationStatus,
  onTabValidation,
  isButtonDisabled,
  handleSaveDraft,
}) => {
  const { userDraftState, userDraftDispatch } = useContext(UserDraftContext)

  const [envelopeInputs, setEnvelopeInputs] = useState({})

  const [onValidation, setOnValidation] = useState(false)
  const [validationObject, setValidationObject] = useState({})
  const [isEnvelopeDisabled, setIsEnvelopeDisabled] = useState(false)

  useEffect(() => {
    if (userDraftState.property_envelope) {
      setEnvelopeInputs(userDraftState.property_envelope)
    }
    if (userDraftState.property_envelope_ack) {
      if (
        userDraftState.property_envelope_ack.isNoEnvelopeInformation === true ||
        userDraftState.property_envelope_ack.isOnlyUploadDocuments === true
      ) {
        setIsEnvelopeDisabled(true)
      } else {
        setIsEnvelopeDisabled(false)
      }
    }
  }, [userDraftState])

  const handleInputChange = (newValue) => {
    setEnvelopeInputs((inputs) => ({
      ...inputs,
      [newValue.name]: newValue.value,
    }))
    userDraftDispatch({
      type: "property_envelope_save",
      payload: { ...envelopeInputs, [newValue.name]: newValue.value },
    })
  }

  const handleCheckedBoxChange = (newValue) => {
    userDraftDispatch({
      type: "property_envelope_save_ack",
      payload: { [newValue.name]: newValue.checked },
    })
  }

  const hasValidFields = () => {
    // checking reqruied Inputs if they are filled. If false, there are required fields that are not filled.

    let checking = Object.keys(validationObject).filter(
      (key) => validationObject[key] === false
    )
    return checking.length === 0
    //true - filled (empty)/ false - not filled
  }

  const handleValidation = () => {
    const isValid = hasValidFields() //true or false

    if (!isValid) {
      // input validation
      setOnValidation(true)
      // tab validation
      onTabValidation(isValid ? "good" : "error")
    } else {
      // input validation
      setOnValidation(false)
      // tab validation
      onTabValidation("good")
    }
  }

  return (
    <div className='form__container'>
      <div className='form-title-button__container'>
        <p className='form__title'>Envelope</p>
        <Button
          type='button'
          className='button__large button__large--gray complete-button-upper'
          buttonText='Check this tab'
          disabled={isButtonDisabled === null}
          onClick={handleValidation}
        />
      </div>

      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Add Envelope Information'
          content={
            <>
              In this section, provide information about the building's
              envelope. <br />
              Feel free to skip this section if you lack the information, or if
              you intend to upload documents containing the required
              information.
            </>
          }
          containerClassName='main-instructions'
        />
        <div className='form__description'>
          <CheckBox
            label='I do not have the envelope information.'
            name='isNoEnvelopeInformation'
            checked={
              userDraftState.property_envelope_ack.isNoEnvelopeInformation
            }
            onChange={handleCheckedBoxChange}
          />
        </div>
        <div className='form__description'>
          <CheckBox
            label='I will ONLY upload documents containing the required envelope information.'
            name='isOnlyUploadDocuments'
            checked={userDraftState.property_envelope_ack.isOnlyUploadDocuments}
            onChange={handleCheckedBoxChange}
          />
        </div>
      </div>
      <div className='form__content'>
        <InstructionalText
          type='Main Instructions'
          title='Envelope Details'
          content={
            <p>
              For "Windows coverage" enter the average percentage (e.g., "0.6"
              for 60%) of the wall area covered by windows.
            </p>
          }
          containerClassName='main-instructions'
        />

        <div className='form__inputs__container'>
          <div className='input_width--1-3'>
            <Select
              label={EnvelopeInputLabels.construction_material_type_label}
              options={["Concrete", "Masonry", "Steel", "Wood", "Other"]}
              name='construction_type'
              onChange={handleInputChange}
              value={envelopeInputs.construction_type}
              placeholder='Enter construction material type'
              className='default'
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-3'>
            <Select
              label={EnvelopeInputLabels.is_parkade_ceiling_insulated_label}
              options={["Yes", "No"]}
              name='is_parkade_ceiling_insulated'
              onChange={handleInputChange}
              value={envelopeInputs.is_parkade_ceiling_insulated}
              placeholder='Is parkade ceiling insulated?'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-3'>
            <Input
              label={EnvelopeInputLabels.parkade_ceiling_r_factor_label}
              type='number'
              name='parkade_ceiling_r_factor'
              onChange={handleInputChange}
              value={envelopeInputs.parkade_ceiling_r_factor}
              placeholder='Enter parkage ceiling R factor'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
        </div>
        <div className='form__inputs__container'>
          <div className='input_width--1-3'>
            <Input
              label={EnvelopeInputLabels.no_of_exterior_doors_label}
              type='number'
              name='no_of_exterior_doors'
              onChange={handleInputChange}
              value={envelopeInputs.no_of_exterior_doors}
              placeholder='Enter the number of exterior doors'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-3'>
            <Select
              label={EnvelopeInputLabels.is_roof_insulated_label}
              options={["Yes", "No"]}
              name='is_roof_insulated'
              onChange={handleInputChange}
              value={envelopeInputs.is_roof_insulated}
              placeholder='Is the roof insulated?'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-3'>
            <Input
              label={EnvelopeInputLabels.roof_r_factor_label}
              type='number'
              name='roof_r_factor'
              onChange={handleInputChange}
              value={envelopeInputs.roof_r_factor}
              placeholder='Enter roof R factor'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
        </div>
        <div className='form__inputs__container'>
          <div className='input_width--1-4'>
            <Input
              label={EnvelopeInputLabels.windows_coverage_label}
              type='number'
              name='windows_coverage'
              onChange={handleInputChange}
              value={envelopeInputs.windows_coverage}
              placeholder='Enter windows coverage'
              className='default'
              required
              min={0}
              max={1}
              error='Please provide a value between 0 and 1.'
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={EnvelopeInputLabels.walls_r_factor_label}
              type='number'
              name='walls_r_factor'
              onChange={handleInputChange}
              value={envelopeInputs.walls_r_factor}
              placeholder='Enter walls R factor'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-4'>
            <Select
              label={EnvelopeInputLabels.windows_type_label}
              options={["Single pane", "Double pane", "Triple pane"]}
              name='windows_type'
              onChange={handleInputChange}
              value={envelopeInputs.windows_type}
              placeholder='Enter windows type'
              className='default'
              required
              validation={onValidation}
              set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--1-4'>
            <Input
              label={EnvelopeInputLabels.windows_u_factor_label}
              type='number'
              name='windows_u_factor'
              onChange={handleInputChange}
              value={envelopeInputs.windows_u_factor}
              placeholder='Enter windows U factor'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
        </div>
        <div className='form__inputs__container'>
          <div className='input_width--1-3'>
            <Select
              label={EnvelopeInputLabels.windows_draftyness_label}
              options={["Tight", "Medium", "Leaky", "N/A"]}
              name='windows_draftyness'
              onChange={handleInputChange}
              value={envelopeInputs.windows_draftyness}
              placeholder='Enter level of windows draftyness'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
          <div className='input_width--2-3'>
            <Input
              label={EnvelopeInputLabels.general_comment_label}
              type='text'
              name='general_comment'
              onChange={handleInputChange}
              value={envelopeInputs.general_comment}
              placeholder='Enter general comment'
              className='default'
              // validation={onValidation}
              // set_validation_object={setValidationObject}
              disabled={isEnvelopeDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyEnvelope
