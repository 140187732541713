import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getData } from "../../utils/HttpClient"
import { useLocation } from "react-router-dom"
import BarChart from "./BarChart"
import HistoricalGHGEmission from "./HistoricalGHGEmission"
import BuildingTable from "../global/TemplateTable"
import ConsumptionTable from "../global/TemplateTable"
import BarChartStacked from "./BarChartStacked"
import PieChart from "../global/TemplatePiechart"
import LoadingSpinner from "../global/LoadingSpinner"
import NoBuildingsDiv from "../global/NoBuildingDiv"
import Graph from "../home/HistoricalDataHistogram"
import "../../styles/portfolio.css"

export const Portfolio = (props) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({
    consumption_table_data: [
      ["No Consumption data to show", "-", "-", "-", "-", "-", "-"],
    ],
    growth_table_data: [
      ["No building data to show", "-", "-", "-", "-", "-", "-"],
    ],
    pie_data: ["No data to show"],
    historical_data: ["No data to show"],
  })

  const [hamburgerTitle, setHambergerTitle] = useState("Building Details")
  const [activedHamburgerBtn, setActivedHamburgerBtn] = useState("active_menu")
  const [activeSection, setActiveSection] = useState(0)

  const toggleSection = (index) => {
    setActiveSection(index)
  }

  function BarchartRow() {
    return (
      <div id='barchar-in-portfolio' className='grid grid-cols-2 gap-4'>
        <div id='barchart-first-section' className='flex h-[34rem] w-full'>
          <BarChart
            removeToken={props.removeToken}
            dataset={data.consumption_table_data}
            title='Annual GHG Emissions Breakdown (Ton CO₂e/yr)'
            columns={[0, 3]}
            label='GHG Emissions (Ton/yr)'
            color='#ad4501'
            yaxis='GHG Emissions (Ton CO₂e/yr)'
          />
        </div>
        <div id='barchart-second-section' className='flex h-[34rem] w-full'>
          <BarChart
            removeToken={props.removeToken}
            dataset={data.consumption_table_data}
            title='Annual Utility Cost Breakdown ($/yr)'
            columns={[0, 6]}
            label='Utility Cost ($/yr)'
            color='#204e8d'
            yaxis='Utility Cost ($/yr)'
          />
        </div>
      </div>
    )
  }

  function PiechartRow() {
    return (
      <div id='piechart-in-portfolio' className='grid grid-cols-2 gap-4'>
        <div id='piechart-first-section' className='flex  w-full'>
          <PieChart
            removeToken={props.removeToken}
            widgetClassName='h-full w-full flex gap-5'
            containerClassName='h-[36rem] w-full pt-10 px-5 custom-frame'
            legendAlignment='start'
            chartTitle='Annual Electricity Consumption (kWh/yr)'
            labelAttribute='site_name'
            dataAttribute='electric_consumption'
            dataset={data.pie_data}
          />
        </div>
        <div id='piechart-second-section' className='flex bg-white w-full'>
          <PieChart
            removeToken={props.removeToken}
            widgetClassName='h-full w-full flex gap-5'
            containerClassName='h-[36rem] w-full pt-10 px-5 custom-frame'
            legendAlignment='start'
            chartTitle='Annual Gas Consumption (GJ/yr)'
            labelAttribute='site_name'
            dataAttribute='natural_gas_consumption'
            dataset={data.pie_data}
          />
        </div>
      </div>
    )
  }

  function PortfolioContainer() {
    return (
      <div id='portfolio-container' className='flex flex-col gap-10 h-full'>
        <div
          id='building-table-section'
          className='flex flex-col h-fit'
          // className='bg-white flex flex-col rounded drop-shadow-md p-5 h-80'
          // scroll bar appears when height is set
        >
          {/* <div className="widget-header">
            <h2>Your Building Details</h2>
          </div> */}
          <BuildingTable
            removeToken={props.removeToken}
            data={data.growth_table_data}
            skipFirstCol={false}
            fixFirstColumn={true}
            title={"Building Details"}
            filename={"your_building_details.csv"}
            headers={[
              "Site Name",
              "Site Address",
              "City",
              "Province",
              "Consultant Name",
              "Date of Last Audit",
              "Year of Construction",
            ]}
          />
        </div>
        <div
          id='consumption-table-section'
          // className='bg-white flex flex-col rounded drop-shadow-md p-5 h-80'
          className='flex flex-col h-fit '
        >
          <ConsumptionTable
            removeToken={props.removeToken}
            data={data.consumption_table_data}
            skipFirstCol={false}
            fixFirstColumn={true}
            addLocale={true}
            title={"Building Consumption Data"}
            filename={"building_consumption_data.csv"}
            headers={[
              "Site Name",
              "Annual Gas Consumption (GJ/yr)",
              "Annual Electricity Consumption (kWh/yr)",
              "Annual GHG Emission (Ton CO₂e/yr)",
              "Annual Gas Cost ($/yr)",
              "Annual Electricity Cost ($/yr)",
              "Total Annual Utility Cost ($/yr)",
            ]}
          />
        </div>

        <div id='piechar-container' className='inline-block  w-full gap-5'>
          <div>
            <PiechartRow />
          </div>
        </div>
        <div id='barchar-container' className='inline-block  w-full gap-5'>
          <div>
            <BarchartRow />
          </div>
        </div>
        <div id='utility-ghg-section'>
          <BarChartStacked dataset={data.consumption_table_data} />
        </div>

        {/* <div
          id='historical-ghg-emission-section'
          className='bg-white rounded drop-shadow-md p-5'
        >
          <HistoricalGHGEmission title='Historical GHG Emission' />
        </div> */}

        {(Object.keys(data.historical_data["gas"]).length !== 0 ||
          Object.keys(data.historical_data["gas"]).length !== 0) && (
          <div id='historical-data-section' className='h-2/5 w-full'>
            <Graph
              removeToken={props.removeToken}
              dataset={data.historical_data}
            />
          </div>
        )}
      </div>
    )
  }

  function Display() {
    // const [scrollTop, setScrollTop] = useState(0)

    // window.addEventListener("scroll", function () {
    //   setScrollTop(this.window.scrollY)
    // })
    return (
      <div>
        {data["historical_data"]["gas"] !== undefined ? (
          <>
            <ul id='toggle-container' className='flex report-tabs md:hidden'>
              <div id='sub-hamburger-header'>
                {
                  {
                    0: "Building Details",
                    1: "Building Consumption Data",
                    2: "Electricity Consumption",
                    3: "Gas Consumption",
                    4: "GHG Emissions Breakdown",
                    5: "Utility Cost Breakdown",
                    6: "Utility And GHG Cost Projection",
                    7: "Historial Data Breakdown",
                  }[activeSection]
                }
              </div>
              {/* <div className={`${subNavMenu} sub-hamberger-element tabs`}><a href="#total-consumption-section">Total Consumption</a></div> */}
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 0 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(0),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#building-table-section'>Building Details</a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 1 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(1),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#consumption-table-section'>
                  Building Consumption Data
                </a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 2 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(2),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#piechart-first-section'>Electricity Consumption</a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 3 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(3),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#piechart-second-section'>Gas Consumption</a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 4 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(4),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#barchart-first-section'>GHG Emissions Breakdown</a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 5 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(5),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#barchart-second-section'>Utility Cost Breakdown</a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 6 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(6),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#utility-ghg-section'>
                  Utility And GHG Cost Projection
                </a>
              </div>
              <div
                className={`${activedHamburgerBtn} ${
                  activeSection === 7 ? "sections active-section" : "sections"
                }`}
                onClick={() => [
                  toggleSection(7),
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  ),
                ]}
              >
                <a href='#historical-data-section'>Historial Data Breakdown</a>
              </div>

              <div
                className='navbar-toogleBtn2'
                onClick={() => {
                  setActivedHamburgerBtn(
                    activedHamburgerBtn === "" ? "active_menu" : ""
                  )
                }}
              >
                <i className='fa-solid fa-bars'></i>
              </div>
            </ul>
            <PortfolioContainer />
            {/* <a
              href='#portfolio-title'
              className={`${scrollTop >= 300 ? "go_top" : "go_top fade-out"}`}
            >
              <i className='fa-sharp fa-solid fa-arrow-up'></i>
            </a> */}
          </>
        ) : (
          <NoBuildingsDiv />
        )}
      </div>
    )
  }

  /* turn on spinner before getData request, after data is set, turn off spinner */

  useEffect(() => {
    setIsLoading(true)
    // !props.data.gas condition removed
    if (props.data === null || !props.data) {
      getData("user", props.removeToken, props.clearCacheData, navigate)
        .then((res) => {
          if (res.all_data.portfolio["consumption_table_data"].length === 0) {
            setIsLoading(false)
          } else {
            setData(res.all_data.portfolio)
            setIsLoading(false)
          }
        })
        .catch((err) => console.log(err))
    } else {
      setData(props.data)
      setIsLoading(false)
    }
  }, [props.data])

  return (
    <div className='mb-16 portfolio-component'>
      <div className='title'>
        <h1 className='section_heading_1'>Portfolio</h1>
      </div>
      <div className='hr_container'>
        <hr className='hr_breaker' />
      </div>
      {isLoading ? <LoadingSpinner /> : <Display />}
    </div>
  )
}
