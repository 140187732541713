/**
 * ==========================================================
 * File Uploads
 * ==========================================================
 *
 * - Equipments Images
 * - Supporting Documents
 *
 * 1. upload files via API
 * 2. add file name to the equipment/supporting documents in JSON Form Object
 *
 *
 * ==========================================================
 * Save Property Drafts
 * ==========================================================
 *
 * - JSON.stringify() before sending to backend
 * - JSON.parse() after receiving from backend
 *
 * - assign project_number after first stage (bare bones property)
 *
 */

import { useState, useEffect, useReducer } from "react"
import { useNavigate } from "react-router-dom"

import BasicPropertyInformation from "./components/BasicPropertyInformation"
import BuildingData from "./components/BuildingData"
import PropertyEnergyMeters from "./components/PropertyEnergyMeters"
import PropertyEquipments from "./components/PropertyEquipments"
import EquipmentForm from "./components/PropertyEquipments/EquipmentForm"
import PropertyLights from "./components/PropertyLights"
import LightForm from "./components/PropertyLights/LightForm"
import PropertyWaterMeters from "./components/PropertyWaterMeters"
import PropertyEnvelope from "./components/PropertyEnvelope"
import PropertyWaterFixtures from "./components/PropertyWaterFixtures"
import {
  getSiteInputOptions,
  getEspmPropertyTypes,
  saveDraft,
  submitDraft,
  getDraftById,
} from "../../utils/HttpClient"
import { filterProperties } from "../../utils/DataFormatter"
import {
  defaultDraft,
  newUserDraft,
  requiredFields,
} from "./FormObjectStructure"
import {
  DefaultDraftContext,
  UserDraftContext,
  UserDraftReducer,
} from "./context/DraftContext"
import styles from "../../styles/addProperty/index.module.css"
import PropertyDocuments from "./components/PropertyDocuments"
import ReviewSubmit from "./components/ReviewSubmit"
import "./style/dataCollection.css"
import Button from "../global/components/button/Button"

import NavigationTabs from "../global/components/NavigationTabs/NavigationTabs"

function PropertyFormPage(props) {
  const [activeStep, setActiveStep] = useState()
  const [completed, setCompleted] = useState({})

  const [siteInputOptions, setSiteInputOptions] = useState([])
  const [propertyTypes, setPropertyTypes] = useState([])

  // const [projectNumber, setProjectNumber] = useState(null)

  const [isSubmiting, setIsSubmiting] = useState(false)

  const navigate = useNavigate()

  const [userDraftState, userDraftDispatch] = useReducer(
    UserDraftReducer,
    newUserDraft
  )

  const [propertyStatus, setPropertyStatus] = useState(null)

  const requiredFieldsValidation = (tabIndex) => {
    return checkRequiredFieldsInFormObjStructure(tabIndex)
  }

  const checkRequiredFieldsInFormObjStructure = (tabIndex) => {
    let currentTab = requiredFields.find((tab) => tab.tabIndex === tabIndex)

    if (typeof currentTab === "undefined") {
      // No tab defined for this index
      return null
    }

    // check all required fields
    if (currentTab.option === "all_fields_required") {
      let sectionData = userDraftState[currentTab.section]

      return currentTab.fields.every((field) => {
        return sectionData[field] !== ""
      })
    }

    // check at least one ack for true, if both are false, check for entry in array_sections
    if (currentTab.option === "acknowledge_one_or_one_entry_required") {
      let ackData = userDraftState[currentTab.ack_section]
      let is_not_acknowledged = currentTab.ack_fields.every((field) => {
        return ackData[field] === false
      })
      if (is_not_acknowledged) {
        let is_array_occupied = currentTab.array_sections.every((section) => {
          return userDraftState[section].length > 0
        })
        return is_array_occupied
      } else {
        return true
      }
    }

    // check both ack for true, if at least one false, check for one entry in array_sections
    if (currentTab.option === "acknowledge_both_or_one_entry_required") {
      let ackData = userDraftState[currentTab.ack_section]
      let is_acknowledged = currentTab.ack_fields.every((field) => {
        return ackData[field] === true
      })
      if (is_acknowledged) {
        return true
      } else {
        // check the false array_section for at least one entry
        let is_array_occupied = currentTab.ack_fields.every((field, index) => {
          if (ackData[field] === false) {
            return userDraftState[currentTab.array_sections[index]].length > 0
          } else {
            return true
          }
        })
        return is_array_occupied
      }
    }

    // check ack for true, if all false, check all required fields
    if (currentTab.option === "acknowledge_one_or_all_fields_required") {
      let ackData = userDraftState[currentTab.ack_section]
      let is_not_acknowledged = currentTab.ack_fields.every((field) => {
        return ackData[field] === false
      })
      if (is_not_acknowledged) {
        let sectionData = userDraftState[currentTab.section]

        return currentTab.fields.every((field) => {
          return sectionData[field] !== ""
        })
      } else {
        return true
      }
    }
  }

  const isButtonDisabled = checkRequiredFieldsInFormObjStructure(activeStep)

  const requiredFieldsValidationAll = () => {
    return requiredFields.every((_, tabIndex) => {
      return requiredFieldsValidation(tabIndex)
    })
  }

  const steps_property = [
    "Basic Information",
    "Building Data",
    "Energy",
    "Water",
    "Equipments",
    "Lights",
    "Water Fixtures",
    "Envelope",
    "Documents",
    "Review & Submit",
  ]

  const [tabValidationStatus, setTabValidationStatus] = useState(
    new Array(steps_property.length).fill("")
  )

  const defaultDraftValue = {
    defaultDraftObject: defaultDraft,
    siteInputOptions: siteInputOptions,
    propertyTypes: propertyTypes,
  }

  const userDraftValue = {
    userDraftState: userDraftState,
    userDraftDispatch: userDraftDispatch,
    userData: props.user_data,
  }

  const [draftId, setDraftId] = useState(null)

  function copyMissingProperties(source, target) {
    for (let key in source) {
      if (!(key in target)) {
        target[key] = source[key]
      }
    }
  }

  useEffect(() => {
    const draft_id_param = window.location.pathname.substring(
      "/addProperty/form/".length
    )
    if (draft_id_param) {
      setDraftId(draft_id_param)
      getDraftById(draft_id_param).then((res) => {
        let merged_draft = JSON.parse(res.content)
        copyMissingProperties(defaultDraft, merged_draft)
        userDraftDispatch({
          type: "load_all",
          payload: merged_draft,
        })
        setActiveStep(0)
      })
    } else {
      setActiveStep(0)
    }

    getSiteInputOptions()
      .then((res) => {
        setSiteInputOptions(res)
      })
      .catch((err) => {
        console.log(err)
      })

    getEspmPropertyTypes()
      .then((res) => {
        setPropertyTypes(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleNext = () => {
    activeStep === steps_property.length - 1
      ? navigate("/addProperty")
      : setActiveStep((activeStep) => activeStep + 1)
  }

  const resetEquipmentTab = () => {
    setEquipmentId(null)
    setShowEquipmentForm(false)
  }

  const resetLightTab = () => {
    setLightId(null)
    setShowLightForm(false)
  }

  const handleBack = () => {
    if (showEquipmentForm) {
      resetEquipmentTab()
    } else if (showLightForm) {
      resetLightTab()
    } else {
      activeStep === 0
        ? navigate("/addProperty")
        : setActiveStep((activeStep) => activeStep - 1)
    }
  }

  const handleExit = () => {
    navigate("/addProperty")
  }

  const [showEquipmentForm, setShowEquipmentForm] = useState(false)
  const [equipmentId, setEquipmentId] = useState(null)

  const [showLightForm, setShowLightForm] = useState(false)
  const [lightId, setLightId] = useState(null)

  const handleShowEquipmentForm = (equipment_id) => {
    if (isNaN(equipment_id)) {
      setEquipmentId(null)
    } else {
      setEquipmentId(equipment_id)
    }
    setShowEquipmentForm(true)
  }

  const handleShowLightForm = (light_id) => {
    if (isNaN(light_id)) {
      setLightId(null)
    } else {
      setLightId(light_id)
    }
    setShowLightForm(true)
  }

  const handleSaveDraft = () => {
    let formData = new FormData()

    // clean the final draft object
    let finalDraftState = filterProperties(userDraftState, (key) => {
      return !key.endsWith("_label")
    })

    // =========== GET ALL ATTACHED FILE BLOBS ===========
    // get all attached file blobs
    let supporting_documents = finalDraftState.property_supporting_documents
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })
    let energy_audit_documents = finalDraftState.property_energy_audits
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })
    let energy_bill_documents = finalDraftState.property_energy_bills
      .map((energy_bill) => {
        return energy_bill.bill_files
      })
      .flat()
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })
    let equipment_documents = finalDraftState.property_equipments
      .map((equipment) => {
        return equipment.equipment_files_list
      })
      .flat()
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })
    let light_documents = finalDraftState.property_lights
      .map((light) => {
        return light.light_files_list
      })
      .flat()
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })
    // water bill files
    let water_bill_documents = finalDraftState.property_water_bills
      .map((water_bill) => {
        return water_bill.bill_files
      })
      .flat()
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })

    // mechanical electrical files

    // =========== ATTACH ALL FILE BLOBS TO DRAFT OBJECT ===========
    // Supporting Documents
    let final_property_supporting_documents =
      finalDraftState.property_supporting_documents.map((doc) => {
        return { ...doc, status: "Uploaded" }
      })
    userDraftDispatch({
      type: "property_documents_update_all",
      payload: final_property_supporting_documents,
    })
    finalDraftState.property_supporting_documents =
      final_property_supporting_documents

    // Energy Audits
    let final_property_energy_audits =
      finalDraftState.property_energy_audits.map((doc) => {
        return { ...doc, status: "Uploaded" }
      })
    userDraftDispatch({
      type: "property_energy_audits_update_all",
      payload: final_property_energy_audits,
    })
    finalDraftState.property_energy_audits = final_property_energy_audits

    // Energy Bills
    let final_property_energy_bills = finalDraftState.property_energy_bills.map(
      (energy_bill) => {
        energy_bill.bill_files = energy_bill.bill_files.map((file) => {
          return { ...file, status: "Uploaded" }
        })
        return energy_bill
      }
    )
    userDraftDispatch({
      type: "property_energy_bills_update_all",
      payload: final_property_energy_bills,
    })
    finalDraftState.property_energy_bills = final_property_energy_bills

    // Equipment Documents
    let final_property_equipments_documents =
      finalDraftState.property_equipments.map((equipment) => {
        equipment.equipment_files_list = equipment.equipment_files_list.map(
          (file) => {
            return { ...file, status: "Uploaded" }
          }
        )
        return equipment
      })
    userDraftDispatch({
      type: "property_equipments_update_all",
      payload: final_property_equipments_documents,
    })
    finalDraftState.property_equipments = final_property_equipments_documents

    // Light Documents
    let final_property_lights_documents = finalDraftState.property_lights.map(
      (light) => {
        light.light_files_list = light.light_files_list.map((file) => {
          return { ...file, status: "Uploaded" }
        })
        return light
      }
    )
    userDraftDispatch({
      type: "property_lights_update_all",
      payload: final_property_lights_documents,
    })
    finalDraftState.property_lights = final_property_lights_documents

    // Water Bills
    let final_property_water_bills = finalDraftState.property_water_bills.map(
      (water_bill) => {
        water_bill.bill_files = water_bill.bill_files.map((file) => {
          return { ...file, status: "Uploaded" }
        })
        return water_bill
      }
    )
    userDraftDispatch({
      type: "property_water_bills_update_all",
      payload: final_property_water_bills,
    })
    finalDraftState.property_water_bills = final_property_water_bills

    // Mechanical Electrical Documents

    // =========== ATTACH FINAL DRAFT OBJECT TO FORM DATA ===========
    // attach the stringified draft content
    formData.append("draft_content", JSON.stringify(finalDraftState))

    if (draftId) {
      formData.append("draft_id", draftId)
    }

    // =========== ATTACH ALL FILE BLOBS TO FORM DATA ===========
    // attach the supporting docs file blobs for uploading to server
    if (supporting_documents.length > 0) {
      for (let file of supporting_documents) {
        formData.append("supporting_documents", file)
      }
    }

    // attach the energy audit file blobs for uploading to server
    if (energy_audit_documents.length > 0) {
      for (let file of energy_audit_documents) {
        formData.append("energy_audit_documents", file)
      }
    }

    // attach the energy bill file blobs for uploading to server
    if (energy_bill_documents.length > 0) {
      for (let file of energy_bill_documents) {
        formData.append("energy_bill_documents", file)
      }
    }

    // attach the equipment file blobs for uploading to server
    if (equipment_documents.length > 0) {
      for (let file of equipment_documents) {
        formData.append("equipment_documents", file)
      }
    }

    // attach the light file blobs for uploading to server
    if (light_documents.length > 0) {
      for (let file of light_documents) {
        formData.append("light_documents", file)
      }
    }

    // attach water bill files
    if (water_bill_documents.length > 0) {
      for (let file of water_bill_documents) {
        formData.append("water_bill_documents", file)
      }
    }

    // attach mechanical electrical files

    saveDraft(formData)
      .then((res) => {
        if (res.identifier > 0) {
          setDraftId(res.identifier)
          props.get_user_data()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSubmit = () => {
    setIsSubmiting(true)
    let formData = new FormData()
    let finalDraftState = filterProperties(userDraftState, (key) => {
      return !key.endsWith("_label")
    })
    let supporting_documents = finalDraftState.property_supporting_documents
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })
    let energy_audit_documents = finalDraftState.property_energy_audits
      .filter((doc) => doc.status == "Attached")
      .map((doc) => {
        return doc.file_blob
      })

    let final_property_supporting_documents =
      finalDraftState.property_supporting_documents.map((doc) => {
        return { ...doc, status: "Uploaded" }
      })
    userDraftDispatch({
      type: "property_documents_update_all",
      payload: final_property_supporting_documents,
    })
    finalDraftState.property_supporting_documents =
      final_property_supporting_documents
    let final_property_energy_audits =
      finalDraftState.property_energy_audits.map((doc) => {
        return { ...doc, status: "Uploaded" }
      })
    userDraftDispatch({
      type: "property_energy_audits_update_all",
      payload: final_property_energy_audits,
    })
    finalDraftState.property_energy_audits = final_property_energy_audits

    formData.append("draft_content", JSON.stringify(finalDraftState))

    if (draftId) {
      formData.append("draft_id", draftId)
    }
    if (supporting_documents.length > 0) {
      for (let file of supporting_documents) {
        file.message = "File is being uploaded"
        file.status = "Uploaded"
        formData.append("supporting_documents", file)
      }
    }
    if (energy_audit_documents.length > 0) {
      for (let file of energy_audit_documents) {
        formData.append("energy_audit_documents", file)
      }
    }
    submitDraft(formData)
      .then((res) => {
        if (res.property_status === "Submitted") {
          setPropertyStatus(res.property_status)
          props.get_user_data()
          return res
        }
      })
      .catch((err) => {
        console.log(err)
        // navigate("/addProperty")
      })
  }

  const handleActiveStepOnEdit = (step) => {
    setActiveStep(step)
  }

  // each tab validation
  const handleValidateOneTab = (tabIndex) => {
    let areRequiredFields = checkRequiredFieldsInFormObjStructure(tabIndex)
    //null if currentTab has no required fields in formobjstructure

    if (areRequiredFields === null) {
      let updatedStatus = [...tabValidationStatus]
      updatedStatus[tabIndex] = null //set status to null not to have any icon
      setTabValidationStatus(updatedStatus)
    } else {
      let updatedStatus = [...tabValidationStatus]
      updatedStatus[tabIndex] = areRequiredFields ? "good" : "error" // good - green, error - red icon
      setTabValidationStatus(updatedStatus)
    }
  }

  // check all tabs validation
  const updateTabValidationStatus = () => {
    if (typeof requiredFields !== "undefined" && requiredFields.length > 0) {
      const updatedStatus = tabValidationStatus.map((_, index) => {
        const requiredField = requiredFields.find(
          (tab) => tab.tabIndex === index
        )
        if (requiredField === undefined) return ""
        const isValid = checkRequiredFieldsInFormObjStructure(
          requiredField.tabIndex
        )
        return isValid ? "good" : "error"
      })

      setTabValidationStatus(updatedStatus)
      return updatedStatus
    } else {
      // If there are no required fields defined, set all statuses to null
      const updatedStatus = new Array(tabValidationStatus.length).fill(null)
      setTabValidationStatus(updatedStatus)
      return updatedStatus
    }
  }

  const handleValidateAll = () => {
    const validateAll = updateTabValidationStatus()
    return validateAll
  }

  const handleComplete = (tabIndex) => {
    // Perform required field validation for the current tab
    const isValid = requiredFieldsValidation(tabIndex)
    // Update the validation status array
    const updatedStatus = [...tabValidationStatus]
    updatedStatus[tabIndex] = isValid ? "good" : "error"
    setTabValidationStatus(updatedStatus)
  }

  const data = steps_property.map((label, index) => ({
    id: index,
    label: label,
    iconPosition: "right",
    rightIconState: tabValidationStatus[index],
    disabled: false,
  }))

  return (
    <>
      <DefaultDraftContext.Provider value={defaultDraftValue}>
        <UserDraftContext.Provider value={userDraftValue}>
          <div className='mb-16'>
            <div className='title'>
              <h1 className='section_heading_1'>
                {draftId ? "Saved Property" : "New Property"}
              </h1>
            </div>
            <div className='hr_container'>
              <hr className='hr_breaker' />
            </div>

            <NavigationTabs
              data={data}
              activeStep={activeStep}
              onTabClick={(id) => {
                setActiveStep(id)
                resetEquipmentTab()
                resetLightTab()
              }}
              showMobileDropdown={true}
            />

            <div className=' data-collection__main-container '>
              {activeStep === 0 && (
                // necessary
                <BasicPropertyInformation
                  tabValidationStatus={tabValidationStatus[0]}
                  onTabValidation={() => handleValidateOneTab(0)}
                  handleSaveDraft={handleSaveDraft}
                />
              )}
              {/* neccessary - no fileds in objstructure*/}
              {activeStep === 1 && (
                <BuildingData
                  tabValidationStatus={tabValidationStatus[1]}
                  onTabValidation={() => handleValidateOneTab(1)}
                  isButtonDisabled={isButtonDisabled}
                  handleSaveDraft={handleSaveDraft}
                />
              )}
              {/* optional - no fileds in objstructure */}
              {activeStep === 2 && (
                <PropertyEnergyMeters
                  tabValidationStatus={tabValidationStatus[2]}
                  onTabValidation={() => handleValidateOneTab(2)}
                  isButtonDisabled={isButtonDisabled}
                  handleSaveDraft={handleSaveDraft}
                />
              )}
              {/* necessary? but no fileds in objstructure */}
              {activeStep === 3 && (
                <PropertyWaterMeters
                  tabValidationStatus={tabValidationStatus[3]}
                  onTabValidation={() => handleValidateOneTab(3)}
                  isButtonDisabled={isButtonDisabled}
                />
              )}
              {/* optional - no fileds in objstructure*/}
              {activeStep === 4 &&
                (showEquipmentForm ? (
                  <EquipmentForm
                    tabValidationStatus={tabValidationStatus[4]}
                    onTabValidation={() => handleValidateOneTab(4)}
                    isButtonDisabled={isButtonDisabled}
                    handleSaveDraft={handleSaveDraft}
                    handleClose={resetEquipmentTab}
                    equipmentId={equipmentId}
                  />
                ) : (
                  <PropertyEquipments
                    tabValidationStatus={tabValidationStatus[4]}
                    onTabValidation={() => handleValidateOneTab(4)}
                    isButtonDisabled={isButtonDisabled}
                    handleSaveDraft={handleSaveDraft}
                    handleShowEquipmentForm={handleShowEquipmentForm}
                  />
                ))}
              {/* optional - no fileds in objstructure*/}
              {activeStep === 5 &&
                (showLightForm ? (
                  <LightForm
                    tabValidationStatus={tabValidationStatus[5]}
                    onTabValidation={() => handleValidateOneTab(5)}
                    isButtonDisabled={isButtonDisabled}
                    handleSaveDraft={handleSaveDraft}
                    handleClose={resetLightTab}
                    lightId={lightId}
                  />
                ) : (
                  <PropertyLights
                    tabValidationStatus={tabValidationStatus[5]}
                    onTabValidation={() => handleValidateOneTab(5)}
                    isButtonDisabled={isButtonDisabled}
                    handleSaveDraft={handleSaveDraft}
                    handleShowLightForm={handleShowLightForm}
                  />
                ))}
              {activeStep === 6 && (
                <PropertyWaterFixtures
                  tabValidationStatus={tabValidationStatus[6]}
                  onTabValidation={() => handleValidateOneTab(6)}
                  isButtonDisabled={isButtonDisabled}
                />
              )}
              {/* optional - no fileds in objstructure */}
              {activeStep === 7 && (
                <PropertyEnvelope
                  tabValidationStatus={tabValidationStatus[7]}
                  onTabValidation={() => handleValidateOneTab(7)}
                  isButtonDisabled={isButtonDisabled}
                  handleSaveDraft={handleSaveDraft}
                />
              )}
              {/* optional - no fileds in objstructure*/}
              {activeStep === 8 && (
                <PropertyDocuments
                  tabValidationStatus={tabValidationStatus[8]}
                  onTabValidation={() => handleValidateOneTab(8)}
                  isButtonDisabled={isButtonDisabled}
                  handleSaveDraft={handleSaveDraft}
                />
              )}
              {activeStep === 9 && (
                <ReviewSubmit
                  activeStep={handleActiveStepOnEdit}
                  propertyStatus={propertyStatus}
                  // propertyStatus={null}
                  isSubmiting={isSubmiting}
                  onValidateAll={handleValidateAll}
                  onSubmit={handleSubmit}
                />
              )}
              {!isSubmiting && (
                <div className='form__buttons'>
                  <div className='form__buttons__left'>
                    {activeStep !== 9 && (
                      <Button
                        type='button'
                        className='button__large button__large--gray complete-button-lower'
                        buttonText='Complete'
                        // disabled={isButtonDisabled === null}
                        onClick={handleSaveDraft}
                      />
                    )}
                    <Button
                      type='button'
                      buttonText='Back'
                      className={"button__large button__large--secondary"}
                      id='back-button-left'
                      onClick={() => handleBack()}
                    />
                  </div>
                  <div className='form__buttons__right'>
                    <Button
                      type='button'
                      buttonText='Back'
                      className={"button__large button__large--secondary"}
                      id='back-button-right'
                      onClick={() => handleBack()}
                    />
                    {activeStep < 9 ? (
                      <Button
                        type='button'
                        buttonText='Next'
                        className={"button__large button__large--primary"}
                        onClick={() => handleNext()}
                      />
                    ) : (
                      <Button
                        type='button'
                        buttonText='Submit'
                        className={"button__large button__large--primary"}
                        disabled={!requiredFieldsValidationAll()}
                        onClick={
                          requiredFieldsValidationAll()
                            ? () =>
                                alert(
                                  "Submit feature is not available at this time."
                                )
                            : // ? () => handleSubmit()
                              null
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            {!isSubmiting && (
              <div className='form__buttons'>
                <Button
                  type='button'
                  buttonText='Exit'
                  className={"button__large button__large--secondary--gray"}
                  onClick={() => navigate("/addProperty")}
                />
                <Button
                  type='button'
                  buttonText='Save'
                  className={"button__large button__large--gray"}
                  onClick={() => handleSaveDraft()}
                />
              </div>
            )}
          </div>
        </UserDraftContext.Provider>
      </DefaultDraftContext.Provider>
    </>
  )
}
export default PropertyFormPage
