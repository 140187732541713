import "./radioButton.css";

const RadioButton = ({ label, name, value, checked, onChange, invalid, disabled }) => {

  return (
    <div
      className={`radio-button ${disabled ? "disabled" : ""} ${invalid ? "invalid" : ""
        }`}
    >
      <input
        id={`radio-${name}-${label}`}
        type='radio'
        checked={checked}
        className='sr-only'
        onChange={(e) => onChange(e)}
        disabled={disabled}
        aria-checked={checked ? "true" : "false"}
        name={name}
        value={value}
        aria-labelledby={`label-${name}-${label} span-${name}-${label}`}
      />

      <label className='radio-button__label' htmlFor={`radio-${name}-${label}`} id={`label-${name}-${label}`}>
        <span
          className={`radio-button__icon ${checked ? "checked" : ""}`}
          id={`span-${name}-${label}`}
          aria-hidden='true'
        >
        </span>
        {label}
      </label>
    </div>
  )
}

export default RadioButton;
