import FileUploader from "./components/FileUploader"
import ImportESPM from "./components/ImportESPM"
import Table from "../global/components/Table/Table"

function ImportProperty(props) {
  return (
    <>
      <div className='mb-16'>
        <div className='title'>
          <h1 className='section_heading_1'>Import Property</h1>
        </div>
        <div className='hr_container'>
          <hr className='hr_breaker' />
        </div>

        <p className='section_heading_2 p-4'>Import Audit Reports</p>

        <div className='h-full flex flex-col gap-10'>
          <div className='bg-white flex flex-col p-5 custom-frame'>
            <FileUploader
              user_data={props.all_data.user}
              get_user_data={props.get_user_data}
            />
          </div>
        </div>

        {/* <div className='p-5 mb-10 h-fit custom-frame'>
          <p className='section_heading_2 p-4'>
            Import from Energy Star&reg; Portfolio Manager&reg;
          </p>

          <div className='h-full flex flex-col'>
            <div className='bg-white flex flex-col drop-shadow-md p-5'>
              <ImportESPM />
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default ImportProperty
