import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import TableRow from "./TableRow.js"
import DownloadCSV from "./components/DownloadCSV.js"
import "../../styles/table.css"
import "../../styles/index.css"
import {
  parseIntAndFormat,
  parseIntCurrencyRoundedFormat,
} from "../../utils/DataFormatter.js"

function Table(props) {
  // const portfolioSums = [0, 0, 0, 0, 0] // 5 fields: Audit Date (ignore), Status (ignore), Incentive, Net Cost After Incentive, Annualized Cost Savings, GHG Savings Opportunity (Ton Of CO2), ROI
  const [tableData, setTableData] = useState([])

  const [order, setOrder] = useState("1DSC")

  // for (var i = 0; i < tableData.length; i++) {
  //   portfolioSums[0] +=
  //     tableData[i][4] !== "" && parseFloat(tableData[i][4])
  //       ? parseFloat(tableData[i][4])
  //       : 0 // Incentive
  //   portfolioSums[1] +=
  //     tableData[i][5] !== "" && parseFloat(tableData[i][5])
  //       ? parseFloat(tableData[i][5])
  //       : 0 // Net Cost
  //   portfolioSums[2] +=
  //     tableData[i][6] !== "" && parseFloat(tableData[i][6])
  //       ? parseFloat(tableData[i][6])
  //       : 0 // Annualized Cost Savings
  //   portfolioSums[3] +=
  //     tableData[i][7] !== "" && parseFloat(tableData[i][7])
  //       ? parseFloat(tableData[i][7])
  //       : 0 // GHG Savings Opportunity
  //   portfolioSums[4] +=
  //     tableData[i][8] !== "" && parseFloat(tableData[i][8])
  //       ? parseFloat(tableData[i][8])
  //       : 0 // ROI
  // }

  // const portfolioSumsRow = ["Portfolio", "", ""].concat(portfolioSums)

  // const handleDate = (date) => {
  //   // Return just the year given a datetime value.
  //   // return date.split(" ")[3] // Year is 3rd index of datetime (Fri, Jul 15 2022 00:00:00 GMT)
  //   const newDate = new Date(date)
  //   return newDate.getFullYear()
  // }

  // removes project number from api response data (the first value)
  const parseCSV = (tableData) => {
    return tableData.map((row) => row.slice(1))
  }
  // const newTableData = tableData.map((row) =>
  //   // change the indexNumber according to necessary info
  //   row.slice(0, 3).concat(row.slice(6))
  // )
  // return newTableData.map((row) => row.slice(1))
  // }

  useEffect(() => {
    if (props.dataset.length !== 0) {
      let res = props.dataset
      // Replace any null and NaN values with empty string, then set the tableData state with response
      const data = res.map((row) =>
        row.map((cell) => {
          if (cell === "NaN") {
            return ""
          }
          return cell
        })
      )

      // for (var i = 0; i < data.length; i++) {
      //   try {
      //     data[i][8] = (
      //       (parseFloat(data[i][6]) / parseFloat(data[i][5])) *
      //       100
      //     ).toFixed(2) // Calculate ROI = Annualized Cost Savings / Net Cost After Incentive * 100
      //     if (data[i][8] === "NaN") {
      //       data[i][8] = "0.00"
      //     } // In case resultant ROI is NaN, replace with 0.
      //   } catch (e) {
      //     continue
      //   }
      //   try {
      //     data[i][2] = handleDate(data[i][2]) // Get just the year from datetime value
      //   } catch (e) {
      //     continue
      //   }
      // }
      setTableData(data)
    }
  }, [props.dataset])

  const generateTableData = () => {
    /* generates table rows with expandable contents */
    return tableData.map((building, index) => (
      <TableRow
        key={index}
        index={`${building[1]}-${index}`}
        data={building}
        headers={[
          "Upgrade Description",
          "Lifetime GHG Reduction (Ton CO₂e/yr)",
          "Simple Payback (yrs)",
          "First Year Saving ($)",
          "Adjusted Incremental Cost (Including Incentives) ($)",
          "Incentive ($)",
        ]}
        tableHeaders={props.headers}
        fn_remove_building={props.fn_remove_building}
        fn_update_building={props.fn_update_building}
        get_user_data={props.get_user_data}
      />
    ))
  }

  const sorting = (col) => {
    if (order.substring(1) === "ASC") {
      const sorted = [...tableData].sort((a, b) => {
        const valueA = a[col]
        const valueB = b[col]

        if (typeof valueA === "number" && typeof valueB === "number") {
          return valueA - valueB
        } else {
          return valueA.toString().localeCompare(valueB.toString(), "en", {
            numeric: true,
            sensitivity: "base",
          })
        }
      })
      setTableData(sorted)
      setOrder(col + "DSC")
    }

    if (order.substring(1) === "DSC") {
      const sorted = [...tableData].sort((a, b) => {
        const valueA = a[col]
        const valueB = b[col]

        if (typeof valueA === "number" && typeof valueB === "number") {
          return valueB - valueA
        } else {
          return valueB.toString().localeCompare(valueA.toString(), "en", {
            numeric: true,
            sensitivity: "base",
          })
        }
      })
      setTableData(sorted)
      setOrder(col + "ASC")
    }
  }

  const renderTableHeaderRow = () => {
    return props.headers.map((value, index) => {
      return (
        // <th className='' key={index}>
        //   <div className='flex justify-between items-center '>
        //     {value}

        //     {/* This span tag renders a Material UI icon (determined by className),
        //                     the expand_more determines the type of icon*/}
        //     {[
        //       "View Report",
        //       "Status",
        //       "Remove Building",
        //       "Update Building",
        //     ].includes(value) ? null : (
        //       <span
        //         className='material-symbols-outlined'
        //         onClick={() => sorting(index + 1)}
        //       >
        //         {/* Render the up or down icon when activating sort */}
        //         {order === index + 1 + "ASC" ? "expand_more" : "expand_less"}
        //       </span>
        //     )}
        //   </div>
        // </th>
        <th className='sticky top-0' key={index}>
          <div className='flex justify-between items-center '>
            {value}

            {/* This span tag renders a Material UI icon (determined by className),
                            the expand_more determines the type of icon*/}
            {["Decarbonization Plan", "Delete Building"].includes(
              value
            ) ? null : (
              <span
                className='material-symbols-outlined'
                onClick={() => sorting(index + 1)}
              >
                {/* Render the up or down icon when activating sort */}
                {order === index + 1 + "ASC" ? "expand_more" : "expand_less"}
              </span>
            )}
          </div>
        </th>
      )
    })
  }

  // units in the headers in Download CSV
  const originalHeaders = props.headers.slice(0, 5) // Original headers
  const units = [
    "",
    "(GJ/(m².yr))",
    "(Ton CO₂e/(m².yr))",
    "(Ton CO₂e/yr)",
    "(%)",
  ] // Units

  const headersWithUnits = originalHeaders.map((header, index) => {
    return `${header} ${units[index]}`
  })

  return (
    <div>
      <div className='dash-table-container'>
        {/* header */}
        <div className='table-title dash-title'>
          <div className='flex justify-between items-center bg-white'>
            <p className='table-title-text'>{props.title}</p>
            <div className='btn-container'>
              <DownloadCSV
                // remove view decarb plan and delete columns from downloadCSV
                // headers={props.headers.slice(0, 5)}
                headers={headersWithUnits}
                // removing portfolioSumRow in the downloadCSV
                // data={[portfolioSumsRow].concat(
                data={
                  parseCSV(
                    tableData.map(function (val) {
                      return val.slice(0, -1)
                    })
                  )
                  // )}
                }
                filename={props.filename}
              />
            </div>
          </div>
        </div>

        {/* table */}
        <div className='overflow-y-auto h-full'>
          <table id='portfolio' className='rounded-lg text-md w-full table'>
            <link
              href='https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined'
              rel='stylesheet'
            />
            <thead id='thread-row-status'>
              <tr className='header-border'>{renderTableHeaderRow()}</tr>
            </thead>
            {/* <tbody>{generateTableData()}</tbody> */}
            <tbody className='mt-12'>{generateTableData()}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

Table.propTypes = {
  dataset: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
}

export default Table
