import React, { useEffect, useState } from "react"
import RadioButton from "./RadioButton"
import "./radioGroup.css"

const RadioGroup = ({
  legend,
  radioOptions,
  description,
  name,
  value,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState("")

  const handleRadioChange = (e) => {
    console.log("e.target", e.target)
    onChange(e.target)
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  return (
    <fieldset>
      <legend>{legend}</legend>
      <div aria-describedby='radiogroup-description'>
        {radioOptions.map((option) => (
          <RadioButton
            key={option.value}
            label={option.label}
            value={option.value}
            name={name}
            checked={selectedValue === option.value}
            onChange={(e) => handleRadioChange(e)}
            invalid={option.invalid}
            disabled={option.disabled}
          />
        ))}
      </div>
      {description && (
        <div id='radiogroup-description'>
          <p>{description}</p>
        </div>
      )}
    </fieldset>
  )
}

export default RadioGroup
