import { useEffect, useState } from "react"
import TableHeader from "./TableHeader"

function TableHeaderRow({
  headers_array,
  fn_sort_by_header,
  fn_select_all,
  disabled,
}) {
  const [headersArray, setHeadersArray] = useState([])

  // select all / deselect all

  useEffect(() => {
    setHeadersArray(headers_array)
  }, [headers_array])

  function selectAll() {
    fn_select_all()
    console.log("select all")
  }

  return (
    <>
      <div
        className={`table__header ${disabled ? "table__header--disabled" : ""}`}
      >
        {headersArray.length > 0 && (
          <>
            {headersArray.map((header, index) => {
              return (
                <TableHeader
                  key={`${header.header_name}-${index}`}
                  header_data={header}
                  fn_sort_by_header={fn_sort_by_header}
                  fn_select_all={header.name === "select" ? selectAll : null}
                />
              )
            })}
          </>
        )}
      </div>
    </>
  )
}
export default TableHeaderRow
